import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.defaultText,
    };
  }

  onChange = (e) => {
    const text = e.target.value;
    this.setState({ text }, () => {
      this.debouncedOnChange();
    });
  };

  debouncedOnChange = debounce(() => {
    this.props.onChange(this.state.text);
  }, this.props.debounceTimeSec * 1000);

  clearSearch = () => {
    this.onChange({ target: { value: '' } });
  };

  render() {
    return (
      <InputGroup className="mb-3">
        <FormControl
          value={this.state.text}
          onChange={this.onChange}
          placeholder={this.props.placeholder || 'Search'}
        />
        {this.state.text.length > 0 && (
          <InputGroup.Append>
            <Button size="sm" variant="link" onClick={this.clearSearch}>
              Clear
            </Button>
          </InputGroup.Append>
        )}
      </InputGroup>
    );
  }
}

SearchInput.defaultProps = {
  defaultText: '',
  placeholder: '',
  debounceTimeSec: 0,
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,

  // defaults
  defaultText: PropTypes.string,
  placeholder: PropTypes.string,
  debounceTimeSec: PropTypes.number,
};

export default SearchInput;
