import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import OnlineStatusCell from './onlineStatus';
import Pager from '../pager';

const scrollStyle = {
  maxHeight: '60vh',
  overflow: 'auto',
};

const headerStyle = {
  cursor: 'pointer',
};

const rowStyle = {
  cursor: 'pointer',
};

class PlacesGrid extends Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateParent = this.updateParent.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.state = {
      limit: props.limit,
      page: props.page,
      sortOrder: props.sortOrder,
      sortField: props.sortField,
      filter: props.filter,
      onlineStatus: 1,
      searchLastUpdate: false,
      renderSLU: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.searchLast && !state.searchLastUpdate) {
      let filter = '';
      let searchTime = localStorage.getItem('searchTime');
      if (searchTime) {
        let now = new Date();
        let ping = new Date(searchTime);
        if (now - ping < 15 * 60 * 1000) {
          let strCheck = props.searchLast.includes('/places/');
          let type = localStorage.getItem('searchType');
          if (strCheck && type === 'place') {
            filter = localStorage.getItem('searchString');
          }
        } else {
          let strCheck = props.searchLast.includes('/places/');
          let type = localStorage.getItem('searchType');
          if (strCheck && type === 'place') {
            filter = localStorage.getItem('searchString');
          }
        }
      }
      return { searchLastUpdate: true, filter };
    }
    return {};
  }

  handleSort(columnName) {
    let { sortField, sortOrder } = this.state;

    if (this.isSortedBy(columnName)) {
      sortOrder = sortOrder === 1 ? -1 : 1;
    } else {
      sortField = columnName;
      sortOrder = 1;
    }

    this.setState({ sortField: sortField, sortOrder: sortOrder }, () => this.updateParent());
  }

  handleChange(name, event) {
    if (name === 'limit') {
      this.setState(
        {
          [name]: event.target.value,
        },
        () => this.updateParent()
      );
      let time = new Date();
      localStorage.setItem('searchType', 'thing');
      localStorage.setItem('searchTime', time);
    } else if (this.state[name] !== event.target.value) {
      this.setState(
        {
          [name]: event.target.value,
        },
        () => this.updateParent()
      );
      let time = new Date();
      localStorage.setItem('searchString', event.target.value);
      localStorage.setItem('searchType', 'place');
      localStorage.setItem('searchTime', time);
    } else if (
      this.state[name] === event.target.value &&
      this.state.searchLastUpdate &&
      this.state.renderSLU === false
    ) {
      this.setState(
        {
          renderSLU: true,
        },
        () => {
          this.updateParent();
          setTimeout(this.updateParent(), 750);
        }
      );
      let time = new Date();
      localStorage.setItem('searchString', event.target.value);
      localStorage.setItem('searchType', 'place');
      localStorage.setItem('searchTime', time);
    }
  }

  handlePageChange(pageNumber) {
    let { page } = this.state;

    if (page !== pageNumber) {
      this.setState({ page: pageNumber }, () => this.updateParent());
    }
  }

  updateParent() {
    const { page, limit, sortField, sortOrder, filter } = this.state;
    if (page.length === 0 || limit.length === 0) {
      return;
    }
    this.props.onQueryChanged(page, limit, sortField, sortOrder, filter);
  }

  sortIcon(columnName) {
    let { sortDirection } = this.state;
    if (this.isSortedBy(columnName)) {
      return sortDirection === 1 ? (
        <i className="fa fa-caret-up" />
      ) : (
        <i className="fa fa-caret-down" />
      );
    }
  }

  isSortedBy(columnName) {
    let { sortField } = this.state;
    return sortField === columnName;
  }

  render() {
    if (this.state.filter && this.state.searchLastUpdate) {
      let fZ = {
        target: {
          value: this.state.filter,
        },
      };
      this.handleChange('filter', fZ);
    }
    return (
      <div>
        <div className="d-flex justify-content-between align-content-center align-items-center form-inline pl-4 pr-4">
          <div>
            <i className="fa fa-search mr-2" />
            <input
              className="form-control"
              value={this.state.filter}
              onChange={(e) => this.handleChange('filter', e)}
            />
          </div>
          <div>
            <span>Viewing </span>
            <input
              style={{ width: '40px', textAlign: 'center' }}
              className="form-control form-control-sm"
              value={this.state.limit}
              onChange={(e) => this.handleChange('limit', e)}
            />
            <span> of {this.props.totalItems} gateways.</span>
          </div>
          <Pager
            page={this.state.page + ''}
            pageCount={this.props.pageCount + ''}
            onChange={this.handlePageChange}
          />
        </div>

        <div style={scrollStyle}>
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th
                  scope="col"
                  onClick={() => this.handleSort('onlineStatus')}
                  style={headerStyle}
                  className={this.isSortedBy('onlineStatus') ? 'text-primary' : ''}
                />
                <th
                  scope="col"
                  onClick={() => this.handleSort('favorite')}
                  style={headerStyle}
                  className={this.isSortedBy('favorite') ? 'text-primary' : ''}
                />
                <th
                  scope="col"
                  onClick={() => this.handleSort('name')}
                  style={headerStyle}
                  className={this.isSortedBy('name') ? 'text-primary' : ''}
                >
                  Name {this.sortIcon('name')}
                </th>
                <th
                  scope="col"
                  onClick={() => this.handleSort('deviceId')}
                  style={headerStyle}
                  className={this.isSortedBy('deviceId') ? 'text-primary' : ''}
                >
                  Device ID {this.sortIcon('deviceId')}
                </th>

                <th
                  scope="col"
                  onClick={() => this.handleSort('lastPing')}
                  style={headerStyle}
                  className={this.isSortedBy('lastPing') ? 'text-primary' : ''}
                >
                  Last Checkin {this.sortIcon('lastPing')}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.items.length > 0
                ? this.props.items.map((item) => {
                    return (
                      <tr
                        key={item.id}
                        onClick={() => this.props.onRowSelect(item.id)}
                        style={rowStyle}
                      >
                        <td style={{ width: '20px' }}>
                          <div className="pl-4">
                            <OnlineStatusCell onlineStatus={item.onlineStatus} />
                          </div>
                        </td>
                        <td style={{ width: '40px' }}>
                          {item.favorite ? (
                            <div className="pl-1">
                              <i
                                style={{
                                  color: '#FFA500',
                                }}
                                className="fas fa-star"
                              ></i>
                            </div>
                          ) : (
                            <div className="pl-1"></div>
                          )}
                        </td>
                        <td>{item.name}</td>
                        <td>{item.deviceId}</td>
                        <td>{moment(item.lastPing).format('L LTS')}</td>
                      </tr>
                    );
                  })
                : false}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

PlacesGrid.propTypes = {
  items: PropTypes.array,
  totalItems: PropTypes.string,
  pageCount: PropTypes.string,
  allowFilter: PropTypes.bool,
  sortField: PropTypes.string,
  sortOrder: PropTypes.number,
  limit: PropTypes.string,
  page: PropTypes.string,
  filter: PropTypes.string,
  onRowSelect: PropTypes.func,
  onQueryChanged: PropTypes.func,
};

export default PlacesGrid;
