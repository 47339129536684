import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CrumbsHeader from '../../components/layout/crumbsHeader';
import Details from '../../components/details/placeDetails';
import { getPlace, getSees, deletePlace } from '../../redux/actions/placeActions';
import { changeLocation } from '../../redux/actions/navigationActions';

class PlaceDetailsContainer extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.props.getPlace(this.props.match.params.placeId);
    localStorage.setItem('searchLastUrl', window.location.pathname);
  }

  handleDelete() {
    this.props.deletePlace(this.props.place.id);
    this.props.changeLocation(`/places`);
  }

  render() {
    let header = this.props.place && this.props.place.name ? this.props.place.name : '';
    return (
      <div>
        {this.props.place.name && (
          <CrumbsHeader
            header={'Places'}
            crumbs={[
              { route: '/', label: 'Dashboard' },
              { route: '/places', label: 'Places' },
              { route: '', label: header },
            ]}
          />
        )}

        <section>
          <div className="container-fluid">
            <div className="tab-content" id="thingContent">
              <div className="row">
                <Details place={this.props.place} deletePlace={this.handleDelete} />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPlace,
      getSees,
      deletePlace,
      changeLocation,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    place: state.places.currentPlace,
    sees: state.places.sees.items,
    pages: state.places.sees.pages,
    isLoading: state.things.isLoading,
  };
};

PlaceDetailsContainer.propTypes = {
  pages: PropTypes.number,
  isLoading: PropTypes.bool,
  place: PropTypes.object,
  sees: PropTypes.array,
  getPlace: PropTypes.func,
  getSees: PropTypes.func,
  handleTableChange: PropTypes.func,
  handleEditDetails: PropTypes.func,
  match: PropTypes.object,
  deletePlace: PropTypes.func,
  changeLocation: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceDetailsContainer);
