import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import moment from 'moment';

import CrumbsHeader from '../../components/layout/crumbsHeader';
import Details from '../../components/details/thingDetails';
import { getPerson, getPersonDevice } from '../../redux/actions/personActions';

// import { getPeripherals } from '../../actions/peripheralActions';

class PersonDetailsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPeripherals: false,
    };
  }

  componentDidMount() {
    this.props.getPerson(this.props.match.params.personId);
    this.props.getPersonDevice(this.props.match.params.personId, this.props.match.params.deviceId);
    // this.props.getPeripherals(this.props.match.params.thingId);
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Persons'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/people', label: 'People' },
            {
              route: `/people/${this.props.person.id}`,
              label: `${this.props.person.firstName} ${this.props.person.lastName}`,
            },
            { route: `/people/${this.props.person.id}`, label: 'Devices' },
            { route: '', label: this.props.device.name },
          ]}
        />

        <section>
          <div className="container-fluid">
            <div className="row">
              <Details
                thing={this.props.device}
                editUrl={`/people/${this.props.person.id}/devices/${this.props.device.id}/edit`}
              />
              {/*                                         <BatteryLevelChart readings={this.props.peripherals} /> */}
            </div>

            {/* {this.state.showPeripherals && (
                            <EnvironmentalCharts peripherals={this.props.peripherals} />
                        )} */}
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPersonDevice,
      getPerson,
      // getPeripherals
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    person: state.people.currentPerson,
    device: state.people.currentDevice,
    peripherals: state.peripherals.items,
    isLoading: state.things.isLoading,
  };
};

PersonDetailsContainer.propTypes = {
  isLoading: PropTypes.bool,
  person: PropTypes.object,
  device: PropTypes.object,
  // peripherals: PropTypes.array,
  getPerson: PropTypes.func,
  getPersonDevice: PropTypes.func,
  // getPeripherals: PropTypes.func,
  handleEditDetails: PropTypes.func,
  match: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonDetailsContainer);
