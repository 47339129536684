import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from './sonar-dark.png';
import '../../components/auth/auth.css';
import './newDash.css';

class TriggerResponseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    let user = localStorage.getItem('email');
    return (
      <div className="confirmationPage">
        <div className="confirmationModalBox">
          <img width="300" src={logo} alt="Sonar by Thinaer" />
          <div className="confirmFlex">
            <div className="emailSuccess">Success!</div>
            <div style={{ textAlign: 'center' }}>Your trigger action has been recorded</div>
          </div>
          <div>
            {user ? (
              <Link className="confirmationLinkStyle" to="/">
                Go to Dashboard
              </Link>
            ) : (
              <Link className="confirmationLinkStyle" to="/login">
                Go to Login
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TriggerResponseContainer;
