const assignPropsToLastLocations = (locations) => {
  return locations.map((l, index) => {
    l.index = index;
    l.isVisible = true;
    l.isHighlighted = false;
    return l;
  });
};

export default { assignPropsToLastLocations };
