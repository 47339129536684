import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

import beaconsDataContext from '../services/dataContexts/beaconsDataContext';
import gatewaysDataContext from '../services/dataContexts/gatewaysDataContext';
import triggerService from './../services/triggerService';
import moment from 'moment';
import './dashboardContainer.css';
import toast from './../components/utils/toast';
import userServices from '../services/userServices';
import thingService from '../services/thingService';
import placeService from '../services/placeService';
import DashboardCard from '../components/cards/dashboardCard/dashboardCard';
import onlineStatusFunctions from '../utils/onlineStatusFunctions';
import { KEYBOARD_KEYS } from '../constants';
import { isKeyboardKey } from '../utils';
import TriggerModal from './../components/modals/triggerModal';
import swal from '@sweetalert/with-react';
let intervalId;
let count = 0;
Sentry.setUser({ email: localStorage.getItem('email') || 'no@email.com' });

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gatewaysOnline: '',
      gatewaysOffline: '',
      beaconsOnline: '',
      beaconsOffline: '',
      triggers: [],
      currentApplication: '',
      recentlyViewedOpen: true,
      favoritesOpen: true,
      recentTriggersOpen: true,
      recentlyViewedArr: [],
      favoritesArr: [],
      favorites: [],
      recentlyViewed: [],
      width: '0',
      height: '0',
    };
    this.updateData = this.updateData.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.resolveTrigger = this.resolveTrigger.bind(this);
    this.snoozeTrigger = this.snoozeTrigger.bind(this);
    this.snoozeTriggerFunc = this.snoozeTriggerFunc.bind(this);
    this.resolveTriggerFunc = this.resolveTriggerFunc.bind(this);
    this.intervalUpdater = this.intervalUpdater.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  intervalUpdater() {
    // Manually SetTimeout after successful run of updateData
    this.updateData();
    intervalId = setTimeout(this.intervalUpdater, 60000);
  }

  updateData() {
    this.updateWindowDimensions();
    let things;
    let places;
    if (localStorage.getItem('currentApplicationID')) {
      let currentApplication = localStorage.getItem('currentApplicationID');
      thingService.getAll(1, 10000).then((res) => {
        if (res && res.data && res.data.results) {
          things = res.data.results;
          placeService.getAll(1, 10000).then((results) => {
            if (results && results.data && results.data.places) {
              places = results.data.places;
              userServices.getFavorites().then((resA) => {
                let faveArr = [];
                if (resA && resA.data) {
                  this.setState({ favorites: resA.data });
                  if (resA.data.length > 0) {
                    for (let i = 0; i < resA.data.length; i++) {
                      // Checks that this item is in the current Application
                      if (currentApplication !== resA.data[i].applicationId) {
                        continue;
                      }
                      // Skips Maps as we currently don't support favoriting a map
                      if (resA.data[i].itemType === 'map') {
                        continue;
                      }
                      // If the item is in the application and is a thing
                      if (resA.data[i].itemType === 'thing') {
                        let thingData = things.filter((obj) => obj.id === resA.data[i].itemId);
                        let x = {
                          type: 'thing',
                          data: thingData[0],
                        };
                        if (x && x.data && !x.data.lastLocation) {
                          thingService.get(resA.data[i].itemId).then((resC) => {
                            if (resC && resC.data && resC.data.lastLocation) {
                              x.data.lastLocation = resC.data.lastLocation;
                              faveArr.push(x);
                            }
                          });
                        } else {
                          faveArr.push(x);
                        }
                      }
                      // If the item is in the application and is a place
                      if (resA.data[i].itemType === 'place') {
                        let placeData = places.filter((obj) => obj.id === resA.data[i].itemId);
                        let x = {
                          type: 'place',
                          data: placeData[0],
                        };
                        faveArr.push(x);
                      }
                    }
                    if (resA && resA.data) {
                      this.setState({ favorites: resA.data, favoritesArr: faveArr });
                    }
                  }
                }
              });

              userServices.getRecentlyViewed().then((resB) => {
                let recentViewArr = [];
                if (resB && resB.data && resB.data.length > 0) {
                  for (let i = resB.data.length - 1; i > 0; i--) {
                    // MAX 4
                    if (i < 5) {
                      continue;
                    }
                    // NOT IN APPLICATION
                    if (currentApplication !== resB.data[i].applicationId) {
                      continue;
                    }
                    // NO MAP SUPPORT
                    if (resB.data[i].itemType === 'map') {
                      continue;
                    }
                    if (resB.data[i].itemType === 'thing') {
                      let thingData = things.filter((obj) => obj.id === resB.data[i].itemId);
                      let x = {
                        type: 'thing',
                        data: thingData[0],
                      };
                      recentViewArr.push(x);
                    }
                    if (resB.data[i].itemType === 'place') {
                      let placeData = places.filter((obj) => obj.id === resB.data[i].itemId);
                      let x = {
                        type: 'place',
                        data: placeData[0],
                      };
                      recentViewArr.push(x);
                    }
                  }
                  this.setState({ recentlyViewed: resB.data, recentlyViewedArr: recentViewArr });
                }
              });
            }
          });
        }
      });
      this.setState({ currentApplication });
      // Online / Offline Summarys at the top of the Dashboard
      beaconsDataContext.summary().then((x) => {
        if (x && x.data && x.data.online && x.data.offline) {
          this.setState({ beaconsOnline: x.data.online, beaconsOffline: x.data.offline });
        }
      });
      gatewaysDataContext.summary().then((x) => {
        if (x && x.data && x.data.online && x.data.offline) {
          this.setState({ gatewaysOnline: x.data.online, gatewaysOffline: x.data.offline });
        }
      });
      // Grab Trigger Information
      triggerService
        .getDashboardTriggers()
        .then((res) => {
          if (res && res.data) {
            this.setState({ triggers: res.data });
          }
        })
        .catch(() => {
          this.setState({ error: true });
        });
    } else {
      setTimeout(this.updateData, 1000);
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    localStorage.setItem('searchLastUrl', '');
    // this.updateData();
    this.intervalUpdater();
  }

  snoozeTrigger(trigger) {
    swal(<TriggerModal trigger={trigger} snoozeFunc={this.snoozeTriggerFunc} action="snooze" />, {
      buttons: false,
    });
  }

  snoozeTriggerFunc(trigger, recipient, time) {
    triggerService.snoozeDashboardTrigger(trigger, recipient, time).then(() => {
      toast.success();
      this.updateData();
    });
  }

  resolveTrigger(trigger) {
    swal(
      <TriggerModal trigger={trigger} resolveFunc={this.resolveTriggerFunc} action="resolve" />,
      {
        buttons: false,
      }
    );
  }

  resolveTriggerFunc(trigger, recipient, comment) {
    triggerService.resolveDashboardTrigger(trigger, recipient, comment).then(() => {
      toast.success();
      this.updateData();
    });
  }

  componentWillUnmount() {
    clearInterval(intervalId);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  hasAccess() {
    if (!this.props.navigation.currentApplication.appID) {
      return 'isDisabled';
    }
    return '';
  }

  handleToggle(type) {
    if (type === 'recentTriggers') {
      let recentTriggersOpen = !this.state.recentTriggersOpen;
      count = 0;
      this.setState({ recentTriggersOpen });
    }
    if (type === 'recentlyViewed') {
      let recentlyViewedOpen = !this.state.recentlyViewedOpen;
      count = 0;
      this.setState({ recentlyViewedOpen });
    }
    if (type === 'favorites') {
      let favoritesOpen = !this.state.favoritesOpen;
      count = 0;
      this.setState({ favoritesOpen });
    }
  }

  renderTopCard = ({ title, leftCard, rightCard }) => {
    return (
      <div
        className="d-flex flex-column"
        style={{
          minWidth: '300px',
          boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px 0px',
        }}
      >
        <h1 className="m-0 p-2">{title}</h1>

        <div className="d-flex flex-row justify-content-around">
          <button className="summaryCard summaryCard-l" onClick={leftCard.onClick}>
            <div>{leftCard.title}</div>
            <div style={{ color: leftCard.color, fontSize: '1.5em', fontWeight: 'bold' }}>
              {leftCard.subtitle}
            </div>
          </button>

          <button className="summaryCard summaryCard-r" onClick={rightCard.onClick}>
            <div>{rightCard.title}</div>
            <div
              style={{
                color: rightCard.color,
                fontSize: '1.5em',
                fontWeight: 'bold',
              }}
            >
              {rightCard.subtitle}
            </div>
          </button>
        </div>
      </div>
    );
  };

  getIcons = ({ temperature = {}, humidity = {}, battery = {} }) => {
    const icons = [];

    if (temperature.value) {
      icons.push({
        name: 'thermometer',
        text: `${temperature.value.toFixed(2)}°F`,
      });
    }
    if (humidity.value) {
      icons.push({ name: 'humidity', text: `${humidity.value.toFixed(2)}%` });
    }
    if (battery.value) {
      icons.push({ name: 'battery', text: `${Math.round(battery.value)}%` });
    }

    return icons;
  };

  renderDashboardCard = ({ data = {}, type }) => {
    if (!data.id) return null;

    const allowedTypes = ['thing', 'place'];
    if (!allowedTypes.includes(type)) return null;

    const route = type === 'thing' ? 'things' : 'places';

    return (
      <DashboardCard
        key={data.id}
        title={data.name}
        subtitle={data.lastLocation || data.deviceId}
        onlineStatus={
          type === 'thing'
            ? onlineStatusFunctions.getOnlineStatusFromPing(data.lastPing)
            : onlineStatusFunctions.getOnlineStatusFromPing(data.lastCheckin)
        }
        onClick={() => this.props.history.push(`/${route}/${data.id}`)}
        icons={this.getIcons({
          temperature: data.temperature,
          humidity: data.humidity,
          battery: data.battery,
        })}
      />
    );
  };

  userHasApplicationsRender() {
    const { history } = this.props;

    count = 0;
    return (
      <div className="container-fluid" id="dashboard-primary">
        {/* Gateways/Beacons Section */}
        <div className="topRowDashboard mt-2">
          {/* Gateways */}
          {this.renderTopCard({
            title: 'Gateways',
            leftCard: {
              title: 'Online',
              subtitle: this.state.gatewaysOnline,
              color: 'green',
              onClick: () => history.push('/places?sortOrder=1'),
            },
            rightCard: {
              title: 'Offline',
              subtitle: this.state.gatewaysOffline,
              color: 'red',
              onClick: () => history.push('/places?sortOrder=2'),
            },
          })}

          {/* Beacons */}
          {this.renderTopCard({
            title: 'Beacons',
            leftCard: {
              title: 'Online',
              subtitle: this.state.beaconsOnline,
              color: 'green',
              onClick: () => history.push('/things?sortOrder=1'),
            },
            rightCard: {
              title: 'Offline',
              subtitle: this.state.beaconsOffline,
              color: 'red',
              onClick: () => history.push('/things?sortOrder=2'),
            },
          })}
        </div>

        {/* Recent Triggers */}
        <div className="secondRowDashboard">
          {this.state.recentTriggersOpen ? (
            <div className="recentTriggeredDashboard">
              <div
                className="dashboardRowApart"
                style={{
                  fontSize: '1rem',
                }}
              >
                <div
                  role="button"
                  tabIndex={0}
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleToggle('recentTriggers');
                  }}
                  onKeyDown={(e) =>
                    isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => {
                      e.preventDefault();
                      this.handleToggle('recentTriggers');
                    })
                  }
                >
                  <i className="fas fa-caret-down"></i> Recent Triggers
                </div>
                <Link to="/triggers">See all triggers</Link>
              </div>
              <ul className="list-group">
                {this.state.triggers.map((val, i) => {
                  if (val.triggerStatus.toLowerCase() === 'met') {
                    if (count >= 5) {
                      return false;
                    }
                    count++;
                    return (
                      <li
                        className="list-group-item d-flex justify-content-between align-items-center"
                        key={`trig-${i}`}
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontWeight: 'bold',
                              fontSize: '1.125em',
                            }}
                          >
                            {val.thingName}
                          </div>
                          <div
                            style={{
                              fontWeight: '500',
                            }}
                          >
                            {val.expectedValue}
                            {val.triggerType.startsWith('environ') ? (
                              <span>&deg;</span>
                            ) : (
                              ''
                            )} to{' '}
                            <span style={{ color: 'red' }}>
                              {val.reportedValue}
                              {val.triggerType.startsWith('environ') ? <span>&deg;</span> : ''}
                            </span>
                          </div>{' '}
                          <div>{moment(val.sent).format('LLL')}</div>
                        </div>
                        <div
                          style={{
                            minWidth: '45px',
                          }}
                        >
                          <i
                            className="fas fa-alarm-snooze fa-lg mr-2 ml-2"
                            role="button"
                            tabIndex={0}
                            style={{
                              cursor: 'pointer',
                              color: '#b19cd9',
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              this.snoozeTrigger(val, localStorage.getItem('email'), '86400');
                            }}
                            onKeyDown={(e) =>
                              isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => {
                                e.preventDefault();
                                this.snoozeTrigger(val, localStorage.getItem('email'), '86400');
                              })
                            }
                          ></i>

                          <i
                            role="button"
                            tabIndex={0}
                            style={{
                              cursor: 'pointer',
                              color: '#9ac6a2',
                            }}
                            className="fas fa-check-square fa-lg mr-2 ml-2"
                            onClick={(event) => {
                              event.preventDefault();
                              this.resolveTrigger(val);
                            }}
                            onKeyDown={(e) =>
                              isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => {
                                e.preventDefault();
                                this.resolveTrigger(val);
                              })
                            }
                          ></i>
                        </div>
                      </li>
                    );
                  } else {
                    return false;
                  }
                })}
              </ul>
            </div>
          ) : (
            <div className="recentTriggeredDashboard">
              <div
                className="dashboardRowApart"
                style={{
                  fontSize: '1rem',
                }}
              >
                <div
                  role="button"
                  tabIndex={0}
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleToggle('recentTriggers');
                  }}
                  onKeyDown={(e) =>
                    isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => {
                      e.preventDefault();
                      this.handleToggle('recentTriggers');
                    })
                  }
                >
                  <i className="fas fa-caret-right"></i> Recent Triggers
                </div>
                <Link to="/triggers">See all triggers</Link>
              </div>
            </div>
          )}
        </div>

        {/* Favorites Section */}
        <div className="secondRowDashboard">
          {this.state.favoritesOpen ? (
            <div className="recentTriggeredDashboard">
              <div
                className="dashboardRowApart"
                style={{
                  fontSize: '1rem',
                }}
              >
                <div
                  role="button"
                  tabIndex={0}
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleToggle('favorites');
                  }}
                  onKeyDown={(event) =>
                    isKeyboardKey(event, KEYBOARD_KEYS.ENTER, () => {
                      event.preventDefault();
                      this.handleToggle('favorites');
                    })
                  }
                >
                  <i className="fas fa-caret-down"></i> Favorites
                </div>
                <Link to="/favorites">See all favorites</Link>
              </div>
              <div>
                {this.state.favorites &&
                this.state.favorites.length > 0 &&
                this.state.favoritesArr &&
                this.state.favoritesArr.length > 0 ? (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {this.state.favoritesArr.map((val, i) => {
                      if (i >= 8) {
                        return null;
                      }
                      if (!val.data || !val.data.name || !val.data.deviceId) {
                        return null;
                      }
                      return this.renderDashboardCard({
                        data: val.data,
                        type: val.type,
                      });
                    })}
                  </div>
                ) : (
                  <div>No items to display</div>
                )}
              </div>
            </div>
          ) : (
            <div className="recentTriggeredDashboard">
              <div
                className="dashboardRowApart"
                style={{
                  fontSize: '1rem',
                }}
              >
                <div
                  role="button"
                  tabIndex={0}
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleToggle('favorites');
                  }}
                  onKeyDown={(event) =>
                    isKeyboardKey(event, KEYBOARD_KEYS.ENTER, () => {
                      event.preventDefault();
                      this.handleToggle('favorites');
                    })
                  }
                >
                  <i className="fas fa-caret-right"></i> Favorites
                </div>
                <Link to="/favorites">See all favorites</Link>
              </div>
            </div>
          )}
        </div>

        {/* Recently Viewed */}
        <div className="secondRowDashboard">
          {this.state.recentlyViewedOpen ? (
            <div className="recentTriggeredDashboard">
              <div
                className="dashboardRowApart"
                style={{
                  fontSize: '1rem',
                }}
              >
                <div
                  role="button"
                  tabIndex={0}
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleToggle('recentlyViewed');
                  }}
                  onKeyDown={(event) =>
                    isKeyboardKey(event, KEYBOARD_KEYS.ENTER, () => {
                      event.preventDefault();
                      this.handleToggle('recentlyViewed');
                    })
                  }
                >
                  <i className="fas fa-caret-down"></i> Recently Viewed
                </div>
              </div>
              <div>
                {this.state.recentlyViewed &&
                this.state.recentlyViewed.length > 0 &&
                this.state.recentlyViewedArr &&
                this.state.recentlyViewedArr.length > 0 ? (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {this.state.recentlyViewedArr.map((val, i) => {
                      // only display 4 most recently viewed
                      if (i >= 4) {
                        return null;
                      }
                      if (!val.data || !val.data.name || !val.data.deviceId) {
                        return null;
                      }

                      return this.renderDashboardCard({
                        data: val.data,
                        type: val.type,
                      });
                    })}
                  </div>
                ) : (
                  <div>No items to display</div>
                )}
              </div>
            </div>
          ) : (
            <div className="recentTriggeredDashboard">
              <div
                className="dashboardRowApart"
                style={{
                  fontSize: '1rem',
                }}
              >
                <div
                  role="button"
                  tabIndex={0}
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleToggle('recentlyViewed');
                  }}
                  onKeyDown={(event) =>
                    isKeyboardKey(event, KEYBOARD_KEYS.ENTER, () => {
                      event.preventDefault();
                      this.handleToggle('recentlyViewed');
                    })
                  }
                >
                  <i className="fas fa-caret-right"></i> Recently Viewed
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  noApplicationRender() {
    return (
      <div className="container-fluid" id="dashboard-primary">
        <h1 style={{ margin: '15px' }}>
          Your user account does not have any applications attached to it. Please contact
          help@thinaer.io if you believe this to be an error or contact your application
          administrator.
        </h1>
      </div>
    );
  }

  render() {
    let appID = localStorage.getItem('currentApplicationID');
    return <div>{appID ? this.userHasApplicationsRender() : this.noApplicationRender()}</div>;
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(withRouter(DashboardContainer));
