import React from 'react';
import swal from '@sweetalert/with-react';

/**
 * @desc Displays x icon to close swal modals
 */
export default function CloseModal() {
  return (
    <div className="d-flex justify-content-end">
      <button type="button" onClick={() => swal.close()}>
        <i className="fa fa-times" />
      </button>
    </div>
  );
}
