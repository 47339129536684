// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// locals
import PeopleGrid from '../../components/grids/peopleGrid';
import { getAllPeople, deletePerson } from '../../redux/actions/personActions';
import CrumbsHeader from '../../components/layout/crumbsHeader';

class PeopleContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { pageSize: 20, currentPage: 1 };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  handleRowClick(person) {
    this.props.deletePerson(person);
  }

  componentDidMount() {
    this.props.getAllPeople(1, 20);
    localStorage.setItem('searchLastUrl', '');
  }

  handleTableChange(table, _instance) {
    let sort;
    let order;

    if (table.sorted.length === 1) {
      sort = table.sorted[0].id;
      order = table.sorted[0].desc ? 'desc' : 'asc';
    }

    this.props.getAllPeople(table.page + 1, table.pageSize, sort, order);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentApplication.appID !== this.props.currentApplication.appID) {
      this.props.getAllPeople(1, 20);
    } else {
      return;
    }
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'People'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/People', label: 'People' },
          ]}
        />
        <section>
          <div className="container-fluid">
            <div className="h-100">
              <PeopleGrid
                totalPages={this.props.pages}
                people={this.props.people}
                isLoading={this.props.isLoading}
                onTableChange={this.handleTableChange}
                onRowClick={this.handleRowClick}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllPeople,
      deletePerson,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    people: state.people.items,
    totalPeople: state.people.total,
    pages: state.people.pages,
    isLoading: state.people.isLoading,
    currentApplication: state.navigation.currentApplication,
  };
};

PeopleContainer.propTypes = {
  getAllPeople: PropTypes.func,
  deletePerson: PropTypes.func,
  people: PropTypes.array,
  isLoading: PropTypes.bool,
  pages: PropTypes.number,
  currentApplication: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleContainer);
