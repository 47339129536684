import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import moment from 'moment';

import CrumbsHeader from '../../components/layout/crumbsHeader';
import Details from '../../components/details/personDetails';
import ScaleCard from '../../components/cards/scaleCard';
import VitalsCard from '../../components/cards/vitalsCard';
import AdvancedHealthCard from '../../components/cards/advancedHealthCard';
import PillBottlCard from '../../components/cards/pillBottleCard';
import personService from '../../services/personService';
import {
  getPerson,
  deletePersonDevice,
  getPersonDevices,
  deletePerson,
} from '../../redux/actions/personActions';
import { changeLocation } from '../../redux/actions/navigationActions';

class PersonDetailsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      weightReading: {},
      healthReading: {},
      pillReading: {},
      ringReading: {},
      width: 'col-lg-12',
    };
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleRowClick(thing) {
    this.props.deletePersonDevice(this.props.match.params.personId, thing);
  }

  handleDelete() {
    this.props.deletePerson(this.props.person.id);
    this.props.changeLocation(`/people`);
  }

  componentDidMount() {
    this.props.getPerson(this.props.match.params.personId);
    // this.props.getPersonDevices(this.props.match.params.personId);
    personService
      .getPersonDevices(this.props.match.params.personId)
      .then((devices) => {
        let scale = devices.data.find((el) => {
          return el.name === 'Scale';
        });
        let pillBottle = devices.data.find((el) => {
          return el.name === 'Pill Bottle';
        });
        let health = devices.data.find((el) => {
          return el.name === 'Vitalband';
        });
        let ring = devices.data.find((el) => {
          return el.name === 'SensoRing';
        });

        let promises = [];
        if (scale) {
          promises.push(
            personService.getPersonDeviceLastReading(this.props.match.params.personId, scale.id)
          );
        }

        if (pillBottle) {
          promises.push(
            personService.getPersonDeviceLastReading(
              this.props.match.params.personId,
              pillBottle.id
            )
          );
        }

        if (health) {
          promises.push(
            personService.getPersonDeviceLastReading(this.props.match.params.personId, health.id)
          );
        }

        if (ring) {
          promises.push(
            personService.getPersonDeviceLastReading(this.props.match.params.personId, ring.id)
          );
        }

        if (promises.length === 0) return Promise.resolve('none');

        return Promise.all(promises);
      })
      .then((results) => {
        if (!results || results === 'none') return;

        results.forEach((element) => {
          let data = element.data;

          if (typeof data.openCount !== 'undefined') {
            this.setState({ pillReading: data });
          } else if (typeof data.batteryLevel !== 'undefined') {
            if (typeof data.spO2Normal !== 'undefined') {
              this.setState({ ringReading: data });
            } else {
              this.setState({ healthReading: data });
            }
          } else if (typeof data.weight !== 'undefined') {
            this.setState({
              weightReading: data,
              width: 'col-lg-6',
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleTableChange(_table, _instance) {
    //        this.props.getSees(this.props.match.params.personId, table.page + 1, table.pageSize);
  }

  render() {
    let header = this.props.person
      ? this.props.person.firstName + ' ' + this.props.person.lastName
      : 'Unamed';
    return (
      <div>
        <CrumbsHeader
          header={'People'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/People', label: 'People' },
            { route: '', label: header },
          ]}
        />

        <section>
          <div className="container-fluid">
            <div className="row">
              <Details
                person={this.props.person}
                width={this.state.width}
                deletePerson={this.handleDelete}
              />
              <ScaleCard
                weight={this.state.weightReading.weight}
                units={this.state.weightReading.units}
                recordedAt={this.state.weightReading.recordedAt}
              />
            </div>
            <div className="row pt-4">
              <AdvancedHealthCard
                heartRate={this.state.ringReading.heartRate}
                spO2={this.state.ringReading.spO2Normal}
                batteryLevel={this.state.ringReading.batteryLevel}
                recordedAt={this.state.ringReading.recordedAt}
              />
              <PillBottlCard
                openCount={this.state.pillReading.openCount}
                recordedAt={this.state.pillReading.recordedAt}
              />
            </div>
            <div className="row pt-4">
              <VitalsCard
                heartRate={this.state.healthReading.heartRate}
                calories={this.state.healthReading.calories}
                steps={this.state.healthReading.steps}
                recordedAt={this.state.healthReading.recordedAt}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPerson,
      deletePersonDevice,
      getPersonDevices,
      deletePerson,
      changeLocation,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    person: state.people.currentPerson,
    devices: state.people.devices,
    isLoading: state.things.isLoading,
  };
};

PersonDetailsContainer.propTypes = {
  isLoading: PropTypes.bool,
  person: PropTypes.object,
  devices: PropTypes.array,
  getPerson: PropTypes.func,
  getPersonDevices: PropTypes.func,
  deletePersonDevice: PropTypes.func,
  handleTableChange: PropTypes.func,
  handleEditDetails: PropTypes.func,
  match: PropTypes.object,
  deletePerson: PropTypes.func,
  changeLocation: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonDetailsContainer);
