import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CrumbsHeader from '../../../components/layout/crumbsHeader';
import thingService from '../../../services/thingService';
import LocationMap from '../components/locationMap';
import timeSeriesFunctions from '../services/timeSeriesFunctions';
import moment from 'moment';
import locationsDataContext from '../../../services/dataContexts/locationsDataContext';
import BreadcrumbsTable from '../components/breadcrumbsTable';
import Slider from 'react-rangeslider';
import GeoLocationMap from './../components/geoLocationBreadcrumbMap';
import GeoLocationMap2 from './../components/geoLocationMap';
import mapService from '../../../services/mapService';
import 'react-rangeslider/lib/index.css';
import './breadcrumbs.css';

let playTimeout;

// TYPE 1 === GPS
// TYPE 0 === STATIC MAP

class BreadcrumbsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maps: [],
      locations: [],
      totalItems: '0',
      totalPages: '0',
      thing: {},
      query: {
        limit: '50', // TODO: CHANGE BACK TO 50
        page: '1',
        sortOrder: 1,
        filter: '',
        startDate: moment().startOf('day'), //TODO: DELETE DATE
        endDate: moment().endOf('day'), //TODO: DELETE DATE '03-25-2020'
      },
      mapDisplayType: 'lines',
      playback: false,
      visibility: {
        gateways: false,
        zones: false,
        beacons: false,
      },
      currentlySelected: null,
      seeAllBreadcrumbs: false,
      barVal: 0,
      pace: null,
      paused: false,
      ready: false,
    };

    this.handlePlayback = this.handlePlayback.bind(this);
    this.changeMapDisplayType = this.changeMapDisplayType.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.queryChange = this.queryChange.bind(this);
    this.changeSelection = this.changeSelection.bind(this);
    this.frameChange = this.frameChange.bind(this);
    this.play = this.play.bind(this);
    this.resetToDefault = this.resetToDefault.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
  }

  // loop through primary array start to finish
  //

  play(startIndex) {
    let i = 1;
    if (startIndex) {
      i = startIndex;
    }
    let pace;
    let drawMarkerAtPace = () => {
      if (!this.state.playback) {
        return;
      }

      playTimeout = setTimeout(
        () => {
          if (!this.state.locations[i]) {
            return;
          }
          this.changeSelection(i);
          this.setState({ currentlySelected: i }, () => {
            i++;
            if (i < this.state.locations.length) {
              drawMarkerAtPace();
            }
          });
        },
        pace ? pace : 50
      );
    };

    drawMarkerAtPace();
  }

  handlePlayback() {
    let condition = !this.state.playback;
    if (this.state.paused && this.state.currentlySelected) {
      // CONTINUING THE PLAY
      this.setState({ playback: true, paused: false }, () => {
        this.play(this.state.currentlySelected);
      });
    } else {
      // NEW PLAY
      this.setState(
        { playback: !this.state.playback, paused: false, currentlySelected: null },
        () => {
          if (condition) {
            this.play();
          }
        }
      );
    }
  }

  handlePause(pausedNum) {
    clearTimeout(playTimeout);
    this.setState({ playback: true, paused: true, currentlySelected: pausedNum });
    this.changeSelection(pausedNum);
  }

  resetToDefault() {
    clearTimeout(playTimeout);
    this.changeSelection(0);
    this.setState({ playback: false, paused: false, currentlySelected: 0 });
  }

  frameChange(direction) {
    this.handlePause();
    let index;
    // Setting starting Index
    if (!this.state.currentlySelected) {
      this.changeSelection(0);
      index = 0;
    } else {
      index = this.state.currentlySelected;
    }

    if (direction === 'up') {
      index = index + 1;
    } else {
      index = index - 1;
    }

    if (index < 0) {
      index = this.state.locations.length - 1;
    } else if (index >= this.state.locations.length) {
      index = 0;
    }
    this.changeSelection(index);
  }

  handleChange(name, event) {
    this.setState({
      [name]: event.target.value,
    });
  }

  componentDidMount() {
    let thingId = this.props.match.params.thingId;
    this.getThingDetails(thingId);
    // Get Map and Timeseries data
    this.getTimeSeries();
  }

  getTimeSeries() {
    const { thingId } = this.props.match.params;
    const { startDate, endDate, page, limit, sortOrder } = this.state.query;
    locationsDataContext
      .getGeoTimeSeries(thingId, startDate, endDate, page, limit, sortOrder)
      .then((x) => {
        // return object looks like {locations: {results: [...], total: int, pageCount: int}, maps: [...] }
        const { results, total, pageCount } = x.data.locations;
        let locations = timeSeriesFunctions.assignPropsToTimeSeries(results);
        this.setState({
          locations,
          totalItems: total + '',
          pageCount: pageCount + '',
          maps: x.data.maps,
          currentlySelected: 0,
          ready: true,
        });
        this.changeSelection(0);
      });
  }

  getThingDetails(thingId) {
    thingService.get(thingId).then((x) => {
      this.setState({ thing: x.data });
      if (x.data.lastLocationCoordinates && x.data.lastLocationCoordinates.type === 0) {
        mapService.getNoTrack(x.data.lastLocationMapId).then((x) => {
          this.setState({ fallbackMap: x.data });
        });
      }
    });
  }

  changeMapDisplayType(value) {
    this.setState({ mapDisplayType: value });
  }

  toggleVisibility(name) {
    let { maps, visibility } = this.state;
    let currentMap;
    if (
      this.state.ready &&
      typeof this.state.currentlySelected === 'number' &&
      this.state.locations &&
      this.state.locations.length > 0
    ) {
      if (this.state.locations[this.state.currentlySelected].location.type === 0) {
        currentMap = this.state.maps.filter(
          (obj) => obj.id === this.state.locations[this.state.currentlySelected].mapId
        );
      }
    }
    let map;
    if (currentMap && currentMap.length > 0) {
      map = currentMap[0];
    } else {
      map = this.state.fallbackMap;
    }

    map[name].map((i) => {
      i.isVisible = !visibility[name];
      return i;
    });

    let position = maps
      .map((val) => {
        return val.id;
      })
      .indexOf(map.id);
    // eslint-disable-next-line
    this.state.maps[position] = map;

    visibility[name] = !visibility[name];
    this.setState({ maps: maps, visibility: visibility });
  }

  queryChange(page, limit, sortOrder, startDate, endDate) {
    this.setState(
      {
        query: {
          page: page,
          limit: limit,
          sortOrder: sortOrder,
          startDate: startDate,
          endDate: endDate,
        },
      },
      () => {
        this.getTimeSeries();
      }
    );
  }

  changeSelection(index) {
    let { locations } = this.state;
    locations = locations.map((l) => {
      if (l.index === index) {
        if (!l.isHighlighted) {
          l.isHighlighted = true;
          l.isVisible = true;
        } else {
          l.isHighlighted = false;
        }
      } else {
        l.isHighlighted = false;
      }

      return l;
    });

    this.setState({ currentlySelected: index, locations: locations });
  }

  handleSliderChange(value) {
    this.handlePause(value);
  }

  renderFallback() {
    return (
      <div>
        {this.state.thing &&
        this.state.thing.lastLocation &&
        this.state.thing.lastLocationMapId &&
        this.state.thing.lastLocationCoordinates &&
        this.state.thing.lastLocationCoordinates.x &&
        this.state.thing.lastLocationCoordinates.y ? (
          this.state.thing.lastLocationCoordinates.type === 1 ? (
            <GeoLocationMap2
              map={undefined}
              marker={this.state.thing.lastLocationCoordinates}
              height="800px"
            />
          ) : this.state.thing.lastLocationCoordinates.type === 0 && this.state.fallbackMap ? (
            <LocationMap
              map={this.state.fallbackMap}
              gateways={this.state.fallbackMap.gateways}
              beacons={this.state.fallbackMap.beacons}
              zones={this.state.fallbackMap.zones}
              marker={this.state.thing.lastLocationCoordinates}
              height={800}
            />
          ) : (
            <h1>No Data is currently available for the selected time range or current location.</h1>
          )
        ) : (
          <h1>No Data is currently available for the selected time range or current location.</h1>
        )}
      </div>
    );
  }

  renderMap() {
    let currentMap;
    if (
      this.state.ready &&
      typeof this.state.currentlySelected === 'number' &&
      this.state.locations &&
      this.state.locations.length > 0
    ) {
      if (this.state.locations[this.state.currentlySelected].location.type === 0) {
        currentMap = this.state.maps.filter(
          (obj) => obj.id === this.state.locations[this.state.currentlySelected].mapId
        );
      }
    }

    return (
      <div>
        {this.state.ready &&
        typeof this.state.currentlySelected === 'number' &&
        this.state.locations &&
        this.state.locations.length > 0 ? (
          this.state.locations[this.state.currentlySelected] &&
          this.state.locations[this.state.currentlySelected].location.type === 0 ? (
            <div className="card">
              <LocationMap
                pace={this.state.pace}
                map={currentMap ? currentMap[0] : false}
                locations={this.state.locations}
                gateways={currentMap ? currentMap[0].gateways : ''}
                zones={currentMap ? currentMap[0].zones : ''}
                beacons={currentMap ? currentMap[0].beacons : ''}
                marker={this.state.thing.lastLocationCoordinates}
                height="800px"
                playback={this.state.playback}
                displayType={this.state.mapDisplayType}
                selectedIndex={this.state.currentlySelected}
                paused={this.state.paused}
              />
            </div>
          ) : (
            <div className="card">
              <GeoLocationMap
                pace={this.state.pace}
                locations={this.state.locations}
                marker={this.state.thing.lastLocationCoordinates}
                height="800px"
                playback={this.state.playback}
                displayType={this.state.mapDisplayType}
                selectedIndex={this.state.currentlySelected}
                paused={this.state.paused}
              />
            </div>
          )
        ) : (
          this.renderFallback()
        )}
      </div>
    );
  }

  // TODO: SETUP A DEFAULT RENDER FOR THE EVENT THERE ARE 0 MAPS && 0 LOCATIONS
  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Thing Location'}
          crumbs={[
            {
              route: '/',
              label: 'Dashboard',
            },
            {
              route: '/things',
              label: 'Things',
            },
            {
              route: `/things/${window.location.pathname.slice(8, 32)}`,
              label: 'Thing Details',
            },
            {
              route: '/maps',
              label: 'Maps',
            },
          ]}
        />
        <div className="pl-5 pr-5">
          <div className="d-flex app-toolbar align-items-center justify-content-between mb-3 mt-3">
            <h4 className=" mb-0">
              <i className="fa fa-map" /> {this.state.map ? this.state.map.name : 'Earth'}
            </h4>
            <span className="ml-4 mb-0">
              {this.state.map
                ? this.state.map.description
                : 'This items breadcrumb history contains gps coordinates'}
            </span>

            <h4 className="ml-4  mb-0">
              <i className="fa fa-tags" /> {this.state.thing.name ? this.state.thing.name : ''}
            </h4>
          </div>

          <div className="card" style={{ marginBottom: '16px' }}>
            {this.state.locations < 1 ? (
              <BreadcrumbsTable
                allowFilter={true}
                locations={[
                  {
                    timestamp: this.state.thing.lastLocationCheckin,
                    distanceMoved: 0,
                  },
                ]}
                totalItems={this.state.totalItems}
                pageCount={this.state.pageCount}
                sortField={this.state.query.sortField}
                sortOrder={this.state.query.sortOrder}
                limit={this.state.query.limit}
                page={this.state.query.page}
                startDate={this.state.query.startDate}
                endDate={this.state.query.endDate}
                onQueryChanged={this.queryChange}
                onRowSelect={this.changeSelection}
                current={this.state.currentlySelected}
              />
            ) : (
              <BreadcrumbsTable
                allowFilter={true}
                locations={this.state.locations}
                totalItems={this.state.totalItems}
                pageCount={this.state.pageCount}
                sortField={this.state.query.sortField}
                sortOrder={this.state.query.sortOrder}
                limit={this.state.query.limit}
                page={this.state.query.page}
                startDate={this.state.query.startDate}
                endDate={this.state.query.endDate}
                onQueryChanged={this.queryChange}
                onRowSelect={this.changeSelection}
                current={this.state.currentlySelected}
              />
            )}
          </div>
          <div className="d-flex app-toolbar align-items-center justify-content-between mb-2 mt-3">
            <div className="btn-group">
              <button
                type="button"
                className={`btn ${this.state.playbackBackward ? 'btn-primary' : 'btn-default'}`}
                onClick={(e) => {
                  e.preventDefault();
                  this.frameChange('down');
                }}
                title="Play previous tick in sequence"
              >
                <i className="fas fa-backward" />
              </button>

              <button
                type="button"
                className={`btn ${this.state.playbackStop ? 'btn-primary' : 'btn-default'}`}
                onClick={this.resetToDefault}
                title="Stop sequence"
              >
                <i className="fas fa-undo-alt"></i>
              </button>

              <button
                type="button"
                className={`btn ${
                  this.state.playback && !this.state.paused ? 'btn-primary' : 'btn-default'
                }`}
                onClick={this.handlePlayback}
                title="Play sequence"
              >
                <i className="fa fa-play" />
              </button>

              <button
                type="button"
                className={`btn ${this.state.paused ? 'btn-primary' : 'btn-default'}`}
                onClick={(e) => {
                  e.preventDefault();
                  this.handlePause(this.state.currentlySelected);
                }}
                title="Play sequence"
              >
                <i className="fa fa-pause" />
              </button>
              <button
                type="button"
                className={`btn ${this.state.playbackForward ? 'btn-primary' : 'btn-default'}`}
                onClick={(e) => {
                  e.preventDefault();
                  this.frameChange('up');
                }}
                title="Play next tick in sequence"
              >
                <i className="fas fa-forward" />
              </button>
            </div>
            <div
              style={{
                paddingLeft: '24px',
                paddingRight: '24px',
                marginBottom: '16px',
                width: '60%',
              }}
            >
              <Slider
                min={0}
                max={this.state.locations.length ? this.state.locations.length - 1 : 0}
                value={this.state.currentlySelected ? this.state.currentlySelected : 0}
                orientation="horizontal"
                onChange={this.handleSliderChange}
              />
            </div>
            <div className="btn-group" style={{ marginBottom: '16px' }}>
              <button
                type="button"
                className={`btn ${
                  this.state.mapDisplayType === 'points' ? 'btn-primary' : 'btn-default'
                }`}
                onClick={() => this.changeMapDisplayType('points')}
                title="Show Points"
              >
                <i className="fa fa-chart-scatter" />
              </button>
              <button
                type="button"
                className={`btn ${
                  this.state.mapDisplayType === 'lines' ? 'btn-primary' : 'btn-default'
                }`}
                onClick={() => this.changeMapDisplayType('lines')}
                title="Show Path"
              >
                <i className="fa fa-chart-line-down" />
              </button>
              <button
                type="button"
                className={`btn ${this.state.visibility.gateways ? 'btn-primary' : 'btn-default'}`}
                title="Toggle Gateways"
                onClick={() => this.toggleVisibility('gateways')}
              >
                <i className="fa fa-router" />
              </button>
              <button
                type="button"
                className={`btn ${this.state.visibility.zones ? 'btn-primary' : 'btn-default'}`}
                onClick={() => this.toggleVisibility('zones')}
                title="Toggle Zones"
              >
                <i className="fa fa-map" />
              </button>
              <button
                type="button"
                className={`btn ${this.state.visibility.beacons ? 'btn-primary' : 'btn-default'}`}
                onClick={() => this.toggleVisibility('beacons')}
                title="Toggle Beacons"
              >
                <i className="fa fa-tags" />
              </button>
            </div>
          </div>
          {this.renderMap()}
        </div>
      </div>
    );
  }
}
BreadcrumbsContainer.propTypes = {
  map: PropTypes.object,
  locations: PropTypes.array,
  lastLocation: PropTypes.object,
  match: PropTypes.object,
};
export default BreadcrumbsContainer;
