export const OPEN_NAV_WIDTH = '250px';
export const CLOSED_NAV_WIDTH = '0px';

export const OPEN_LOGO_HEIGHT = '40px';
export const CLOSED_LOGO_HEIGHT = '0px';

/**
 * Helper to get the opposite value between 2 toggled values.
 * @param {Object} obj The previous value
 * @param {any} obj.prevValue The previous value
 * @param {any} obj.firstValue The first of 2 values
 * @param {any} obj.secondValue The second of 2 values
 */
export function getToggledValue({ prevValue, firstValue, secondValue }) {
  if (prevValue === firstValue) return secondValue;
  return firstValue;
}
