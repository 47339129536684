import Api from './../config/api';
import { store } from '../redux/store/store';

let state;

// returns all things
const getAll = (id) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${id}/peripherals`
  );
};

export default {
  getAll,
};
