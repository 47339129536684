import axios from 'axios';
import moment from 'moment';
import cognitoActions from '../redux/actions/cognitoActions';
import toast from './../components/utils/toast';
const cognitoApi = `${process.env.REACT_APP_AUTH_API}/authentication`;

let refreshing = false;

let timeout = setInterval(() => {
  if (!axios.defaults.headers.Authorization && localStorage.token) {
    axios.defaults.headers.Authorization = localStorage.token;
    clearInterval(timeout);
  } else if (axios.defaults.headers.Authorization) {
    clearInterval(timeout);
  }
}, 5000);

if (!axios.defaults.headers.Authorization && localStorage.token) {
  axios.defaults.headers.Authorization = localStorage.token;
  clearInterval(timeout);
}

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  timeout: 30000,
});

let tokenIsExpired = () => {
  let tokenExpiration = localStorage.getItem('tokenExpiration');
  let now = moment().unix();
  return now > tokenExpiration;
};

Api.interceptors.request.use(
  async (config) => {
    // effectively "await refreshing"
    while (refreshing) {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, 50);
      });
    }

    // because we awaited refreshing, the original token may have changed
    if (config.headers.Authorization !== axios.defaults.headers.Authorization) {
      config.headers.Authorization = axios.defaults.headers.Authorization;
    }

    let originalRequest = config;
    if (
      tokenIsExpired() === true &&
      config.url.includes('//auth') === false &&
      refreshing === false
    ) {
      refreshing = true;

      let result;

      try {
        result = await axios.post(`${cognitoApi}/refresh`, {
          token: localStorage.getItem('refreshToken'),
        });
      } catch (error) {
        refreshing = false;
        toast.warn('Your session has expired. Please log in again.');
        cognitoActions.logout();
        return error;
      }

      let accessToken = 'Bearer ' + result.data.accessToken;
      axios.defaults.headers.Authorization = accessToken;
      originalRequest.headers.Authorization = accessToken;
      refreshing = false;
      localStorage.setItem('token', accessToken);
      localStorage.setItem('tokenExpiration', result.data.tokenExpiration);
      localStorage.setItem('refreshToken', result.data.refreshToken);
      localStorage.setItem('refreshTokenExpiration', result.data.refreshTokenExpiration);

      return originalRequest;
    }

    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    if (err.response) {
      if (
        err.response.config &&
        err.response.config.url.includes('//auth') &&
        err.response.config.url.endsWith('/refresh')
      ) {
        cognitoActions.logout();
      } else if (err.response.config && err.response.status === 401 && refreshing === false) {
        console.log(err.response.config.url);
        if (
          err.response.config.url.includes('/triggers') &&
          err.response.config.url.includes('/notifications')
        ) {
          return;
        }
        cognitoActions.logout();
      }

      return Promise.reject(err);
    }
  }
);
Api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    if (err.response) {
      if (
        err.response.config &&
        err.response.config.url.includes('//auth') &&
        err.response.config.url.endsWith('/refresh')
      ) {
        cognitoActions.logout();
      } else if (err.response.config && err.response.status === 401 && refreshing === false) {
        cognitoActions.logout();
      }

      return Promise.reject(err);
    }
  }
);

export const apiGateway = process.env.REACT_APP_API_URL;

export default Api;
