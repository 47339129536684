import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Update from '@material-ui/icons/Update';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import cognitoActions from '../../redux/actions/cognitoActions';
import { Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import './auth.css';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 1,
  },
  progress: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      oldPassword: '',
      password: '',
      verifyPassword: '',
      validationMessage: '',
      successMessage: '',
      hasNumber: false,
      hasLowercase: false,
    };
    this.submit = this.submit.bind(this);
  }

  passwordCheck(data) {
    let score = 0;
    if (data.match(/[0-9]/)) {
      score++;
      this.setState({ hasNumber: true });
    } else {
      this.setState({ hasNumber: false });
    }
    if (data.match(/[a-z]/)) {
      score++;
      this.setState({ hasLowercase: true });
    } else {
      this.setState({ hasLowercase: false });
    }
    if (data.match(/[A-Z]/)) {
      score++;
      this.setState({ hasUppercase: true });
    } else {
      this.setState({ hasUppercase: false });
    }
    if (data.match(/[!@#$%^&*()_+={}`~-]/)) {
      score++;
      this.setState({ hasSpecial: true });
    } else {
      this.setState({ hasSpecial: false });
    }
    if (data.length >= 8) {
      score++;
      this.setState({ isEightChars: true });
    } else {
      this.setState({ isEightChars: false });
    }
    if (score === 5) {
      this.setState({ solidPass: true });
      return true;
    } else {
      this.setState({ solidPass: false });
    }
    return false;
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.oldPassword !== this.state.password &&
      this.state.verifyPassword === this.state.password &&
      this.passwordCheck(this.state.password) &&
      this.state.oldPassword.length > 0
    );
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      validationMessage: '',
      successMessage: '',
    });
  };

  onUpdate(err, result) {
    if (err) {
      this.setState({ validationMessage: err.response.data.message });
      return;
    } else if (result.data.message) {
      this.setState({ validationMessage: result.data.message });
      return;
    } else {
      this.setState({ successMessage: 'Password changed. Redirecting...' });
      setTimeout(() => {
        this.setState({ authenticated: true });
      }, 3000);
      return;
    }
  }

  submit(event) {
    event.preventDefault();
    if (!this.validateForm()) {
      if (this.state.password === this.state.oldPassword) {
        this.setState({
          validationMessage:
            'Provided current password can not be the same as provided new password',
        });
      } else if (!this.passwordCheck(this.state.password)) {
        this.setState({ validationMessage: 'Password does not meet requirements' });
      } else if (this.state.verifyPassword !== this.state.password) {
        this.setState({ validationMessage: 'Passwords do not match' });
      } else if (!this.state.email) {
        this.setState({ validationMessage: 'Email is missing' });
      }
      return;
    }
    cognitoActions.updatePassword(
      this.state.email,
      this.state.oldPassword,
      this.state.password,
      this
    );
  }

  render() {
    const { classes } = this.props;
    if (this.state.authenticated) {
      return <Redirect to="/" />;
    }
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Update />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <TextField
                autoComplete="email"
                type="email"
                id="email"
                label="Email Address"
                className={classes.textField}
                margin="normal"
                helperText="Enter your email address"
                onChange={this.handleChange('email')}
              />
              <TextField
                autoComplete="password"
                type="password"
                id="current-password"
                label="Current Password"
                className={classes.textField}
                margin="normal"
                helperText="Enter your current password"
                onChange={this.handleChange('oldPassword')}
              />
              <TextField
                autoComplete="password"
                type="password"
                id="password"
                label="New Password"
                className={classes.textField}
                margin="normal"
                helperText="Enter a password 8 or more characters. Requirements: 1 Lowercase, 1 Uppercase, 1 Number & 1 Special character"
                onChange={this.handleChange('password')}
              />
              <TextField
                autoComplete="password"
                type="password"
                id="verify-password"
                label="Verify New Password"
                className={classes.textField}
                margin="normal"
                helperText="Enter your password again"
                onChange={this.handleChange('verifyPassword')}
              />
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.submit}
            >
              Change
            </Button>
            {this.state.validationMessage ? (
              <div className="alert alert-danger">{this.state.validationMessage}</div>
            ) : (
              false
            )}
            {this.state.successMessage ? (
              <div
                className="alert alert-success"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {this.state.successMessage}
                <CircularProgress thickness={8} size={20} className={classes.progress} />
              </div>
            ) : (
              false
            )}
          </form>
        </Paper>
      </main>
    );
  }
}

UpdatePassword.propTypes = { classes: PropTypes.object.isRequired };

export default withStyles(styles)(UpdatePassword);
