import BaseService from './BaseService';

class BeaconService extends BaseService {
  /**
   * @desc Get Beacon
   * @param {number} id Beacon id
   * @param {QueryOptions} queryOpts Query String Options
   */
  getBeacon = async (id, queryOpts = {}) => {
    queryOpts.isGetOperation = true;
    const data = await this.get(`/odata/beacons(${id})`, queryOpts);
    return data;
  };

  /**
   * @desc List Beacons
   * @param {QueryOptions} queryOpts Query String Options
   */
  listBeacons = async (queryOpts = {}) => {
    const data = await this.get('/odata/beacons', queryOpts);
    return data;
  };

  /**
   * @desc List contact tracing
   * @param {QueryOptions} Query String Options
   */
  listContactTracing = async ({ id, from, to }, queryOpts) => {
    const data = await this.get(
      `/odata/beacons/contacttracing(id=${id},from=${from},to=${to})`,
      queryOpts
    );
    return data;
  };
}

export default BeaconService;
