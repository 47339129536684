import * as types from '../types';
// import toast from './../components/utils/toast';
import mapServices from '../../services/mapService';
// import { fakeData } from './../containers/maps/fakeData';

function requestMaps() {
  return { type: types.REQUEST_MAPS };
}

function requestMap() {
  return { type: types.REQUEST_MAP };
}

function requestDeleteMap() {
  return { type: types.REQUEST_MAP_DELETE };
}

function startUpdateMap() {
  return { type: types.START_MAP_UPDATE };
}

function startMapUpload() {
  return { type: types.START_MAP_UPLOAD };
}

function startMapCreation() {
  return { type: types.START_MAP_CREATION };
}

export function clear() {
  return function (dispatch) {
    return dispatch({ type: types.MAPS_CLEAR });
  };
}

export function getAllMaps() {
  return function (dispatch) {
    dispatch(requestMaps());
    mapServices
      .getAll()
      .then((res) => {
        if (!res.data) {
          return dispatch({
            type: types.MAPS_RECEIVED,
            maps: [{ name: 'No maps in Application' }],
          });
        }
        return dispatch({
          type: types.MAPS_RECEIVED,
          maps: res.data,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.MAPS_REQUEST_FAILURE,

          error: true,
        })
      );
  };
}

export function getMap(id) {
  return function (dispatch) {
    dispatch(requestMap());
    mapServices
      .get(id)
      .then((res) => {
        return dispatch({
          type: types.MAP_RECEIVED,
          map: res.data,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.MAP_REQUEST_FAILURE,

          error: true,
        })
      );
  };
}

export function deleteMap(id) {
  return function (dispatch) {
    dispatch(requestDeleteMap());
    mapServices
      .remove(id)
      .then((res) => {
        return dispatch({
          type: types.MAP_DELETED,
          map: [],
        });
      })
      .catch((res) =>
        dispatch({
          type: types.MAP_REQUEST_FAILURE,

          error: true,
        })
      );
  };
}

export function updateMap(id, map) {
  return function (dispatch) {
    dispatch(startUpdateMap());
    mapServices
      .update(id, map)
      .then((res) => {
        return dispatch({
          type: types.MAP_UPDATED,
          map: [],
        });
      })
      .catch((res) =>
        dispatch({
          type: types.MAP_REQUEST_FAILURE,
          error: true,
        })
      );
  };
}

export function uploadMap(map) {
  return function (dispatch) {
    dispatch(startMapUpload());
    mapServices
      .upload(map)
      .then((res) => {
        return dispatch({ type: types.MAP_UPLOADED, imageUrl: res.data });
      })
      .catch((res) => dispatch({ type: types.MAP_REQUEST_FAILURE, error: true }));
  };
}

export function createMap(map) {
  return function (dispatch) {
    dispatch(startMapCreation());
    mapServices
      .create(map)
      .then((res) => {
        return dispatch({ type: types.MAP_CREATED, payload: res.data, imageUrl: '' });
      })
      .catch((res) => dispatch({ type: types.MAP_REQUEST_FAILURE, error: true }));
  };
}
