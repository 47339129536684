import React, { Component } from 'react';

import userServices from './../../../services/userServices';
import { toast } from './../../../components';
import { emitter, EVENTS } from './utils';
import './css.css';

export default class DeleteButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };
  }

  componentDidMount() {
    emitter.on(EVENTS.EDIT_USER, this.checkIfStopEditing);
  }

  componentWillUnmount() {
    emitter.removeListener(EVENTS.EDIT_USER, this.checkIfStopEditing);
  }

  /**
   * @desc Called for each delete button when a delete button is clicked
   * to check if this button should be editing or not.
   * @param {string} userId User id
   */
  checkIfStopEditing = (userId) => {
    if (userId !== this.props.data.userId) {
      this.setState({ editing: false });
    }
  };

  startEditingUser = () => {
    let rowIndex = 0;
    // the user may have applied filters, sorts
    this.props.api.forEachNodeAfterFilterAndSort((node, nodeIndex) => {
      if (node.id === this.props.data.userId) rowIndex = nodeIndex;
    });

    this.props.api.startEditingCell({
      rowIndex,
      colKey: 'phoneNumber',
    });

    toast.success('Edits are being recorded for ' + this.props.data.userName);
    this.setState({ editing: true });
    emitter.emit(EVENTS.EDIT_USER, this.props.data.userId);
  };

  deleteUser = async () => {
    const deleteString = `Deleting user account: ${this.props.data.userName} \n\nPlease confirm this change.`;
    if (!window.confirm(deleteString)) return;

    userServices
      .deleteUser(this.props.data.userId)
      .then(() => {
        emitter.emit(EVENTS.REFETCH_USERS); // use event emitting over reload to persist filters, sorts
        toast.success(`Successfully deleted user: ${this.props.data.userName} `);
      })
      .catch(() => {
        toast.warn(
          `Failed to delete user: ${this.props.data.userName} please try again or contact your application administrator`
        );
      });
  };

  renderEditing = () => {
    return (
      <span style={{ float: 'right' }}>
        <button
          onClick={() => {
            if (window.confirm(`Please confirm your changes to ${this.props.data.userName}`)) {
              this.props.api.stopEditing();
              this.setState({ editing: false });
            }
          }}
        >
          <i className="fas fa-check fa-lg" style={{ color: 'green' }}></i>
        </button>
        <button
          onClick={() => {
            this.props.api.stopEditing(true);
            this.setState({ editing: false });
          }}
        >
          <i className="fas fa-window-close fa-lg" style={{ color: 'grey' }}></i>
        </button>
      </span>
    );
  };

  renderDefault = () => {
    return (
      <span className="deleteandedit" style={{ float: 'right' }}>
        <button onClick={this.startEditingUser}>
          <i className="fas fa-pencil-alt fa-lg"></i>
        </button>
        <button onClick={this.deleteUser}>
          <i style={{ color: 'red' }} className="fas fa-times fa-lg"></i>
        </button>
      </span>
    );
  };

  render() {
    return <div>{this.state.editing ? this.renderEditing() : this.renderDefault()}</div>;
  }
}
