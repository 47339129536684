import Api from './../config/api';
import { store } from '../redux/store/store';

let state;

// returns all places
const getAll = (page = 1, limit = 20, sort, order) => {
  state = store.getState();

  let queryString = `places?page=${page}&limit=${limit}`;

  if (sort) {
    queryString += `&sort=${sort}`;
  }

  if (order) {
    queryString += `&order=${order}`;
  }

  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/${queryString}`
  );
};

const getLight = () => {
  state = store.getState();
  let queryString = `places/light`;
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/${queryString}`
  );
};

// returns all things
const getPlaceSummary = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/placessummary`
  );
};

const getSees = (id, page = 1, limit = 5) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/places/${id}/sees?page=${page}&limit=${limit}`
  );
};

// patches a place-
const update = (id, place) => {
  state = store.getState();
  return Api.put(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/places/${id}`,
    place
  );
};

// creates a place
const create = (place) => {
  state = store.getState();
  return Api.post(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/places`,
    place
  );
};

// removes a place
const remove = (id) => {
  state = store.getState();
  return Api.delete(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/places/${id}`
  );
};

// returns a place
const get = (id) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/places/${id}`
  );
};

const getNoTrack = (id) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/places/${id}?trackview=false`
  );
};

// returns gateways
const getGateways = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_GATEWAY_API_URL}/${state.navigation.currentApplication.appID}/gateways`
  );
};

// returns gateways
const getGateway = (registrationId) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_GATEWAY_API_URL}/${state.navigation.currentApplication.appID}/gateways/${registrationId}`
  );
};

// puts gateway
const putGateway = (registrationId, registration) => {
  state = store.getState();
  return Api.put(
    `${process.env.REACT_APP_GATEWAY_API_URL}/${state.navigation.currentApplication.appID}/gateways/${registrationId}`,
    registration
  );
};

// resets gateway
const resetGateway = (registrationId) => {
  state = store.getState();
  return Api.delete(
    `${process.env.REACT_APP_GATEWAY_API_URL}/${state.navigation.currentApplication.appID}/gateways/${registrationId}/reset`
  );
};

export default {
  getAll,
  getLight,
  getGateways,
  getGateway,
  putGateway,
  resetGateway,
  get,
  getSees,
  update,
  create,
  remove,
  getPlaceSummary,
  getNoTrack,
};
