import * as types from '../types';
import readingsService from '../../services/readingsService';

export const requestReadings = () => {
  return {
    type: types.READINGS_REQUEST,
  };
};

export function clearReadings() {
  return function (dispatch) {
    dispatch({
      type: types.READINGS_CLEAR,
    });
  };
}

export function getLatestReading(id, ability) {
  return function (dispatch) {
    dispatch(requestReadings());

    readingsService
      .getLatestReading(id, ability)
      .then((res) => {
        return dispatch({
          type: types.READINGS_RECEIVED,
          ability: ability,
          data: res.data,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.READINGS_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export function getLatestReadings(id, ability, count) {
  return function (dispatch) {
    dispatch(requestReadings());

    readingsService
      .getLatestReadings(id, ability, count)
      .then((res) => {
        return dispatch({
          type: types.READINGS_RECEIVED,
          ability: ability,
          data: res.data,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.READINGS_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export function getReadings(id, ability, start, end) {
  return function (dispatch) {
    dispatch(requestReadings());

    readingsService
      .getReadingsRange(id, ability, start, end)
      .then((res) => {
        return dispatch({
          type: types.READINGS_RECEIVED,
          ability: ability,
          data: res.data,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.READINGS_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}
