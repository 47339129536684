// packages
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { PersistGate } from 'redux-persist/integration/react';

import LoginContainer from './containers/loginContainer';
import PrivateRoute from './components/utils/privateRoute';
import TriggerResponseContainer from './containers/TriggerResponseContainer';
import AccountManagementContainer from './containers/admin/accountManagement/AccountManagement';
import TriggerEmailConfirmationContainer from './containers/dashboard/triggerConfirmationDashboardContainer';

import ThingsContainer from './containers/things/thingsContainer';
import ThingDetailsContainer from './containers/things/thingDetailsContainer';
import ThingNewContainer from './containers/things/thingNewContainer';
import ThingEditContainer from './containers/things/thingEditContainer';

import PosNegPressureContainer from './containers/positiveNegativePressureContainer';

import PlaceDetailsContainer from './containers/places/placeDetailsContainer';
import PlaceEditContainer from './containers/places/placeEditContainer';
import PlaceNewContainer from './containers/places/placeNewContainer';
import PlacesContainer from './containers/places/placesContainer';
// import { PlaceContainer } from './screens/places';

import ReportContainer from './containers/reports/reportContainer';

import PeopleContainer from './containers/people/peopleContainer';
import PersonNewContainer from './containers/people/personNewContainer';
import PersonEditContainer from './containers/people/personEditContainer';
import PersonDetailsContainer from './containers/people/personDetailsContainer';
import PersonDeviceNewContainer from './containers/people/personDeviceNewContainer';
import PersonDeviceEditContainer from './containers/people/personDeviceEditContainer';
import PersonDeviceDetailsContainer from './containers/people/personDeviceDetailsContainer';
import GatewaysContainer from './containers/places/gatewaysContainer';
import GatewayContainer from './containers/places/gatewayContainer';

import SearchContainer from './containers/search/searchContainer';
import DashboardContainer from './containers/dashboardContainer';
import SettingsContainer from './containers/settingsContainer.js';
import BulkUploadContainer from './containers/admin/bulkUploadContainer';

import MapUI from './modules/location/containers/mapNewContainer';
import MapsGridContainer from './modules/location/containers/mapGridContainer';
import MapEditContainer from './modules/location/containers/mapEditContainer';
import LocationsContainer from './modules/location/containers/locationsContainer';
import BreadcrumbsContainer from './modules/location/containers/breadcrumbsContainer';
import TriggerDashboardContainer from './containers/dashboard/triggerDashboardContainer';

import FavoritesContainer from './containers/favoritesContainer.js';

import GeoLocMapContainer from './containers/maps/geoLocMapContainer';
import { readOnlyCheck } from './utils';

import Layout from './components/layout/layout';
import { Loading } from './components';

import { ContactTracingContainer, ContactTracingDetailContainer } from './screens/contactTracing';
import { persistor, store, history } from './redux';

import * as loadingAnim from './animations/loading.json';

class App extends Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <PersistGate
            loading={<Loading animationData={loadingAnim.default} />}
            persistor={persistor}
          >
            <ConnectedRouter history={history}>
              <Switch>
                <Route exact path={`/logout`} component={LoginContainer} />
                <Route exact path={`/login`} component={LoginContainer} />
                <Route
                  exact
                  path={`/triggers/:triggerId/recipients/:triggerRecipientId/notifications/:triggerNotificationId`}
                  component={TriggerResponseContainer}
                />
                <Route
                  exact
                  path={`/triggeremailconfirmation`}
                  component={TriggerEmailConfirmationContainer}
                />
                <Route
                  path={`/`}
                  render={({ _match }) => {
                    return (
                      <Layout>
                        <Switch>
                          {/* Contact Tracing */}
                          <PrivateRoute
                            path="/contact-tracing-detail/:beaconId"
                            component={ContactTracingDetailContainer}
                          />
                          <PrivateRoute
                            path="/contact-tracing"
                            component={ContactTracingContainer}
                          />

                          <PrivateRoute path={`/favorites`} component={FavoritesContainer} />
                          <PrivateRoute path={`/triggers`} component={TriggerDashboardContainer} />
                          {readOnlyCheck() ? (
                            false
                          ) : (
                            <PrivateRoute path={`/maps/:mapId/edit`} component={MapEditContainer} />
                          )}
                          <PrivateRoute
                            path={`/maps/:mapId/locations`}
                            component={LocationsContainer}
                          />
                          {readOnlyCheck() ? (
                            false
                          ) : (
                            <PrivateRoute path={`/maps/new`} component={MapUI} />
                          )}
                          <PrivateRoute path={`/maps`} component={MapsGridContainer} />
                          {readOnlyCheck() ? (
                            false
                          ) : (
                            <PrivateRoute path={`/people/new`} component={PersonNewContainer} />
                          )}
                          {readOnlyCheck() ? (
                            false
                          ) : (
                            <PrivateRoute
                              exact={true}
                              path={`/people/:personId/devices/new`}
                              component={PersonDeviceNewContainer}
                            />
                          )}
                          {readOnlyCheck() ? (
                            false
                          ) : (
                            <PrivateRoute
                              exact={true}
                              path={`/people/:personId/devices/:deviceId/edit`}
                              component={PersonDeviceEditContainer}
                            />
                          )}
                          <PrivateRoute
                            path={`/people/:personId/devices/:deviceId`}
                            component={PersonDeviceDetailsContainer}
                          />

                          {readOnlyCheck() ? (
                            false
                          ) : (
                            <PrivateRoute
                              path={`/people/:personId/edit`}
                              component={PersonEditContainer}
                            />
                          )}
                          <PrivateRoute
                            path={`/people/:personId`}
                            component={PersonDetailsContainer}
                          />
                          <PrivateRoute path={`/people`} component={PeopleContainer} />
                          {readOnlyCheck() ? (
                            false
                          ) : (
                            <PrivateRoute path={`/places/new`} component={PlaceNewContainer} />
                          )}
                          {readOnlyCheck() ? (
                            false
                          ) : (
                            <PrivateRoute
                              path={`/places/:placeId/edit`}
                              component={PlaceEditContainer}
                            />
                          )}
                          <PrivateRoute
                            path={`/places/:placeId`}
                            component={PlaceDetailsContainer}
                            // component={PlaceContainer}
                          />
                          <PrivateRoute path={`/places`} component={PlacesContainer} />
                          {readOnlyCheck() ? (
                            false
                          ) : (
                            <PrivateRoute path={`/things/new`} component={ThingNewContainer} />
                          )}
                          <PrivateRoute
                            path={`/things/:thingId/maps`}
                            component={BreadcrumbsContainer}
                          />
                          {readOnlyCheck() ? (
                            false
                          ) : (
                            <PrivateRoute
                              path={`/things/:thingId/edit`}
                              component={ThingEditContainer}
                            />
                          )}
                          <PrivateRoute
                            path={`/things/:thingId`}
                            component={ThingDetailsContainer}
                          />
                          <PrivateRoute path={`/things`} component={ThingsContainer} />
                          <PrivateRoute path={`/reports/:reportId`} component={ReportContainer} />
                          <PrivateRoute path={`/search`} component={SearchContainer} />
                          <PrivateRoute
                            path={`/admin/bulkbeacons`}
                            component={BulkUploadContainer}
                          />
                          <PrivateRoute
                            path={`/admin/accountmanagement`}
                            component={AccountManagementContainer}
                          />
                          <PrivateRoute
                            path={`/admin/bulkgateways`}
                            component={BulkUploadContainer}
                          />
                          <PrivateRoute
                            path={`/admin/gateways/:registrationId`}
                            component={GatewayContainer}
                          />
                          <PrivateRoute path={`/admin/gateways`} component={GatewaysContainer} />
                          <PrivateRoute path={`/settings`} component={SettingsContainer} />
                          {readOnlyCheck() ? (
                            false
                          ) : (
                            <PrivateRoute path={`/pressure`} component={PosNegPressureContainer} />
                          )}
                          <PrivateRoute path={`/dev`} component={GeoLocMapContainer} />
                          <PrivateRoute path={`/`} component={DashboardContainer} />
                        </Switch>
                      </Layout>
                    );
                  }}
                />
              </Switch>
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;
