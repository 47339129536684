/**
 * Export Data
 * @typedef {object} ExportData
 * @property {Array} data data
 * @property {Array} headers headers
 */

/**
 * @desc Helper to convert complex array of data with nested objects to a flat
 * array of data with formatted values for csv use. The conversion happens
 * off of the provided column definitions for the table.
 * @param {Array} cdefs Column definitions (uses aggrid column definition)
 * @param {Array} data Array of data to convert to csv data for export
 * @returns {ExportData} export data
 */
export function getExportData(cdefs, data) {
  // get export headers from col defs
  const headers = cdefs.map((column) => ({
    label: column.headerName,
    key: column.csvKey || column.field,
  }));

  // map over complex nested data and convert to flat data
  const csvData = data.map((item) => {
    const dataPoint = {};

    cdefs.forEach((column, columnIndex) => {
      const fieldKeys = column.field.split('.');

      let value = { ...item };

      fieldKeys.forEach((fieldKey, fieldIndex) => {
        const isLast = fieldIndex === fieldKeys.length - 1;

        if (!value) {
          value = column.valueFormatter ? column.valueFormatter({ value: '' }) : '';
          return;
        }

        let newValue = value[fieldKey];
        if (isLast && column.valueFormatter) newValue = column.valueFormatter({ value: newValue });
        value = newValue;
      });

      dataPoint[headers[columnIndex].key] = value;
    });

    return dataPoint;
  });

  return { data: csvData, headers };
}
