import Api from './../config/api';
import { store } from '../redux/store/store';
import moment from 'moment';

let state;

// returns all things
const getAll = (page = 1, limit = 20, sort, order) => {
  state = store.getState();

  let queryString = `things?page=${page}&limit=${limit}`;

  if (sort) {
    queryString += `&sort=${sort}`;
  }

  if (order) {
    queryString += `&order=${order}`;
  }

  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/${queryString}`
  );
};

// returns all things
const getThingSummary = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/thingssummary`
  );
};

// puts a thing
const create = (thing) => {
  state = store.getState();
  return Api.post(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things`,
    thing
  );
};

// puts a thing
const update = (id, thing) => {
  state = store.getState();
  return Api.put(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${id}`,
    thing
  );
};

// returns a thing
const get = (id) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${id}`
  );
};

// returns a thing
const remove = (id) => {
  state = store.getState();
  return Api.delete(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${id}`
  );
};

const map = (id, mapId) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${id}/maps/${mapId}`
  );
};

const timeSeries = (id, mapId, startDate, endDate) => {
  state = store.getState();
  if (!startDate) {
    startDate = moment().startOf('day');
  }

  if (!endDate) {
    endDate = moment().endOf('day');
  }

  let request = `${process.env.REACT_APP_API_URL}/${
    state.navigation.currentApplication.appID
  }/things/${id}/maps/${mapId}/timeseries/movements?start=${moment(
    startDate
  ).toISOString()}&end=${moment(endDate).toISOString()}`;

  return Api.get(request);
};

const getLight = () => {
  state = store.getState();
  let queryString = `things/light`;
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/${queryString}`
  );
};

const tags = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/tags`
  );
};

const mapTags = (tags) => {
  return tags.map((tag, index) => {
    tag.isSelected = false;
    tag.key = index;
    return tag;
  });
};

export default {
  getAll,
  get,
  update,
  remove,
  create,
  getThingSummary,
  map,
  tags,
  mapTags,
  timeSeries,
  getLight,
};
