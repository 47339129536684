import React from 'react';
import PropTypes from 'prop-types';
import { isKeyboardKey } from '../utils';
import { KEYBOARD_KEYS } from '../constants';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      open: false,
      showInitial: true,
    };

    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.makeEditable = this.makeEditable.bind(this);
  }

  componentDidMount() {
    this.setState({
      showInitial: this.props.showInitial,
    });
  }

  handleChange = (event) => {
    this.setState(
      {
        filter: event.target.value,
      },
      () => {
        if (this.state.filter.length > 0) {
          this.props.onChange(this.state.filter);
        }
      }
    );
  };

  onBlur() {
    this.setState({ open: false });
  }

  onFocus() {
    this.setState({ open: true });
  }

  onSelect(item) {
    this.props.onSelect(item);
    this.setState({ open: false, showInitial: true });
  }

  makeEditable() {
    this.setState({ showInitial: false });
  }

  render() {
    return (
      <div>
        {this.state.showInitial && this.props.selected ? (
          <div
            style={{ cursor: 'pointer' }}
            className="d-flex justify-content-between"
            role="button"
            onClick={(event) => {
              event.preventDefault();
              this.makeEditable();
            }}
            onKeyDown={(e) =>
              isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => {
                e.preventDefault();
                this.makeEditable();
              })
            }
            tabIndex={0}
          >
            <div>{`${this.props.selected[this.props.title]}  ${
              this.props.selected[this.props.subTitle]
            }`}</div>
          </div>
        ) : (
          <div>
            <input
              className="form-control"
              placeholder="Search"
              value={this.state.filter}
              onChange={this.handleChange}
              onFocus={this.onFocus}
            />
            {this.state.open ? (
              <div
                style={{
                  overflowY: 'visible',
                  overflowX: 'visible',
                  height: 0,
                }}
              >
                <ul
                  className="list-group list-group-flush border-light"
                  style={{
                    minWidth: '200px',
                    maxHeight: '300px',
                    overflowX: 'visible',
                    overflowY: 'auto',
                    zIndex: '1000',
                    position: 'absolute',
                  }}
                >
                  {this.props.items.map((item) => {
                    if (this.props.exceptIds.includes(item.id)) {
                      return false;
                    }

                    return this.props.selectedId ? (
                      item.id === this.props.selectedId ? (
                        <li className="list-group-item" key={item.id}>
                          <span>{item[this.props.title]}</span>{' '}
                          <small>{item[this.props.subTitle]}</small>
                        </li>
                      ) : (
                        false
                      )
                    ) : (
                      <li
                        className="list-group-item"
                        key={item.id}
                        onClick={() => this.onSelect(item)}
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) =>
                          isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => this.onSelect(item))
                        }
                      >
                        <span>{item[this.props.title]}</span>{' '}
                        <small>{item[this.props.subTitle]}</small>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              false
            )}
          </div>
        )}
      </div>
    );
  }
}

Search.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.object,
  exceptIds: PropTypes.array,
  onSelect: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showInitial: PropTypes.bool,
};

export default Search;
