// imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
// locals
import TextField from './textField';
import { required } from '../../utils/validationRules';

class PlaceForm extends Component {
  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header d-flex align-items-center">
            <h3 className="h4">Edit Person</h3>
          </div>
          <div className="card-body">
            <form className="form-horizontal" onSubmit={this.props.handleSubmit}>
              <Field
                className="form-control"
                type="text"
                label="First Name"
                component={TextField}
                name="firstName"
                validate={[required]}
              />
              <Field
                className="form-control"
                type="text"
                label="Last Name"
                component={TextField}
                name="lastName"
                validate={[required]}
              />
              <Field
                className="form-control"
                type="checkbox"
                label="Sensoring User"
                component={TextField}
                name="isSensoringUser"
              />
              <div className="line">&nbsp;</div>
              <Field
                className="form-control"
                type="text"
                label="Person Keywords"
                component={TextField}
                name="keywords"
              />
              <div className="line">&nbsp;</div>
              <div className="form-group row">
                <div className="col-sm-4 offset-sm-3">
                  <button
                    type="button"
                    onClick={this.props.resetForm}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary" disabled={this.props.isLoading}>
                    Save changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

PlaceForm.propTypes = {
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.func,
};

const form = reduxForm({
  form: 'person',
  enableReinitialize: true,
})(PlaceForm);

export default form;
