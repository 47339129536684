import { PROGRESS_SET_PROGRESS, PROGRESS_END_PROGRESS, PROGRESS_START_PROGRESS } from '../types';

// https://stackoverflow.com/questions/30564053/how-can-i-synchronously-determine-a-javascript-promises-state
async function getPromiseState(promise) {
  const test = {};
  return Promise.race([promise, test]).then(
    (v) => (v === test ? 'pending' : 'fulfilled'),
    () => 'rejected'
  );
}

export function setProgress(progress) {
  return {
    type: PROGRESS_SET_PROGRESS,
    data: {
      progress,
    },
  };
}

export function startProgress() {
  return {
    type: PROGRESS_START_PROGRESS,
  };
}

export function endProgress() {
  return {
    type: PROGRESS_END_PROGRESS,
  };
}

let interval;

/**
 * @desc Redux thunk to set the progress based on array of promises.
 * @param {Array.<Promise>} promises Array of promises
 */
export function initiateProgress(promises) {
  return async (dispatch) => {
    let promisesAdded = 0; // tracks how many promises have been accounted for
    const numPromises = promises.length;
    const step = 100 / numPromises;

    clearInterval(interval);
    dispatch(startProgress());

    interval = setInterval(() => {
      promises.forEach(async (promise) => {
        const promiseState = await getPromiseState(promise);

        const isInDoneState = promiseState === 'fulfilled' || promiseState === 'rejected';
        if (isInDoneState && !promise.addedToProgress) {
          promise.addedToProgress = true;
          const newProgress = promisesAdded * step + step;
          dispatch(setProgress(newProgress));
          promisesAdded++;

          // check if this was last promise to be added
          if (promisesAdded === numPromises) {
            dispatch(endProgress());
          }
        }
      });
    }, 50);
  };
}
