import React, { Component } from 'react';
import CrumbsHeader from '../components/layout/crumbsHeader';
import FavoritesGrid from '../components/grids/favoritesGrid';
import { withRouter } from 'react-router-dom';
import userServices from '../services/userServices';
import thingService from '../services/thingService';
import placeService from '../services/placeService';

class FavoritesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favoritesArr: [],
      favorites: [],
    };
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  componentDidMount() {
    let currentApplication = localStorage.getItem('currentApplicationID');
    localStorage.setItem('searchLastUrl', '');
    userServices.getFavorites().then((res) => {
      this.setState({ favorites: res.data });
      if (res.data.length > 0) {
        for (let i = 0; i < res.data.length; i++) {
          if (currentApplication !== res.data[i].applicationId) {
            continue;
          }
          if (res.data[i].itemType === 'map') {
            continue;
          }
          if (res.data[i].itemType === 'thing') {
            thingService.get(res.data[i].itemId).then((res) => {
              let x = {
                type: 'thing',
                data: res.data,
              };
              let z = this.state.favoritesArr;
              z.push(x);
              this.setState({ favoritesArr: z });
            });
          }
          if (res.data[i].itemType === 'place') {
            placeService.get(res.data[i].itemId).then((res) => {
              let x = {
                type: 'place',
                data: res.data,
              };
              let z = this.state.favoritesArr;
              z.push(x);
              this.setState({ favoritesArr: z });
            });
          }
        }
        this.setState({ favorites: res.data });
      }
    });
  }

  handleRowClick(id, type) {
    const { history } = this.props;
    if (type === 'thing') {
      history.push(`/things/${id}`);
    }
    if (type === 'place') {
      history.push(`/places/${id}`);
    }
    if (type === 'map') {
      history.push(`/maps/${id}`);
    }
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Favorites'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/favorites', label: 'Favorites' },
          ]}
        />
        <section>
          <FavoritesGrid
            allowFilter={true}
            items={
              this.state.favoritesArr && this.state.favoritesArr.length > 0
                ? this.state.favoritesArr
                : []
            }
            onRowSelect={this.handleRowClick}
          ></FavoritesGrid>
        </section>
      </div>
    );
  }
}

export default withRouter(FavoritesContainer);
