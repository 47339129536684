import React from 'react';
import PropTypes from 'prop-types';

class ZoneEditRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.zone.name,
      translation: this.props.zone.translation,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  handleChange(event) {
    this.setState({
      name: event.target.value,
    });
  }

  onConfirm() {
    this.props.onEditCompleted('zones', {
      index: this.props.zone.index,
      id: this.props.zone.id,
      zone: this.props.zone,
      name: this.state.name,
      translation: this.state.translation,
      isVisible: true,
      boundary: this.props.zone.boundary,
    });
  }

  onCancel() {
    if (!this.props.zone.zone) {
      this.props.onAddCanceled('zones', this.props.zone.index);
    } else {
      this.props.onEditCompleted('zones', this.props.zone);
    }
  }

  render() {
    return (
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="form-group w-50">
          <input className="form-control" value={this.state.name} onChange={this.handleChange} />
        </div>

        <div className="w-25">
          <div className="btn btn-group float-right">
            <a href="# " className="mr-4" onClick={this.onConfirm} title="Confirm">
              <i className="fa fa-check-circle text-success" />
            </a>
            <a href="# " className="mr-4" onClick={this.onCancel} title="Cancel">
              <i className="fa fa-ban" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

ZoneEditRow.propTypes = {
  zone: PropTypes.object,
  onSearchFilterChanged: PropTypes.func,
  searchItems: PropTypes.array,
  excludedSearchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
};

export default ZoneEditRow;
