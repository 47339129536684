import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
// import { createLocationsLayerGroup } from '../../services/leafletLayerFactories/leafletLocationFactory';
import { createGatewaysLayerGroup } from '../../services/leafletLayerFactories/leafletGatewayFactory';
import { createBeaconsLayerGroup } from '../../services/leafletLayerFactories/leafletBeaconFactory';
import { createZonesLayerGroup } from '../../services/leafletLayerFactories/leafletZoneFactory';

let drawControl;
let zones;

class MapEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
    };

    this.onItemAdded = this.onItemAdded.bind(this);
    this.onItemMoved = this.onItemMoved.bind(this);
    this.submitZoneEdit = this.submitZoneEdit.bind(this);
  }

  drawCanvas(map) {
    if (!map) {
      return;
    }

    map.eachLayer(function (layer) {
      if (layer._url == null) {
        map.removeLayer(layer);
      }
    });

    zones = createZonesLayerGroup(this.props.map.zones);
    zones.addTo(map);
    const gateways = createGatewaysLayerGroup(this.props.map.gateways);
    gateways.addTo(map);
    const beacons = createBeaconsLayerGroup(this.props.map.beacons);
    beacons.addTo(map);

    if (this.props.editGateway) {
      gateways.eachLayer((gateway) => {
        if (gateway.gatewayIndex === this.props.editGateway) {
          gateway.options.editing = {};
          gateway.editing.enable();
        }
      });
    }

    if (this.props.addGateway) {
      new L.Draw.Marker(map, drawControl.options.marker).enable();
    }

    if (this.props.editZone) {
      zones.eachLayer((zone) => {
        if (zone.zoneIndex === this.props.editZone) {
          zone.options.editing = {};
          zone.editing.enable();
        }
      });
    }

    if (this.props.addZone) {
      new L.Draw.Polygon(map, { showLength: false }).enable();
    }

    if (this.props.editBeacon) {
      beacons.eachLayer((beacon) => {
        if (beacon.beaconIndex === this.props.editBeacon) {
          beacon.options.editing = {};
          beacon.editing.enable();
        }
      });
    }

    if (this.props.addBeacon) {
      new L.Draw.Marker(map, drawControl.options.marker).enable();
    }

    if (this.props.addMeasure) {
      new L.Draw.Polyline(map, { showLength: false }).enable();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { map } = this.state;
    this.drawCanvas(map);
  }

  componentDidMount() {
    let x = this.props.map.width;
    let y = this.props.map.height;
    let bounds = [
      [0, 0],
      [y, x],
    ];

    let map = L.map('map', {
      zoomControl: false,
      crs: L.CRS.Simple,
      maxBoundsViscosity: 0.8,
      maxBounds: bounds,
      attributionControl: false,
      minZoom: -5,
    });

    let drawPluginOptions = {
      draw: false,
      edit: false,
    };

    drawControl = new L.Control.Draw(drawPluginOptions);
    map.addControl(drawControl);

    L.imageOverlay(this.props.map.imageUrl, bounds).addTo(map);
    map.fitBounds(bounds);

    this.setState({ map: map, loaded: true });
    this.drawCanvas(map);
    map.on(L.Draw.Event.CREATED, this.onItemAdded);
    map.on(L.Draw.Event.EDITMOVE, this.onItemMoved);
  }

  render() {
    return (
      <div>
        <div
          id="map"
          className="w-100"
          style={{
            margin: '0',
            minHeight: '70vh',
            height: '100%',
          }}
        />
        {this.props.editZone ? (
          <div className="d-flex justify-content-center">
            <button onClick={this.submitZoneEdit} className="btn btn-primary">
              Save
            </button>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }

  submitZoneEdit() {
    zones.eachLayer((zone) => {
      if (zone.zoneIndex === this.props.editZone) {
        this.props.onZoneChanged(this.props.editZone, zone._latlngs[0]);
        return;
      }
    });
  }

  onItemMoved(event) {
    const layer = event.layer;
    if (this.props.editGateway) {
      this.props.onGatewayChanged(this.props.editGateway, layer._latlng);
    }
    if (this.props.editBeacon) {
      this.props.onBeaconChanged(this.props.editBeacon, layer._latlng);
    }
  }
  onItemAdded(event) {
    const layer = event.layer;
    if (this.props.addGateway) {
      this.props.onGatewayChanged(this.props.addGateway, layer._latlng);
    }
    if (this.props.addZone) {
      this.props.onZoneChanged(this.props.addZone, layer._latlngs[0]);
    }
    if (this.props.addBeacon) {
      this.props.onBeaconChanged(this.props.addBeacon, layer._latlng);
    }
    if (this.props.addMeasure) {
      console.log(layer._latlngs);
      let a = layer._latlngs[0].lat - layer._latlngs[1].lat;
      let b = layer._latlngs[0].lng - layer._latlngs[1].lng;
      console.log(a, b);
      let c = Math.sqrt(a * a + b * b);
      console.log(c);
      this.props.onMeasureAdded(c);
    }
  }
}

MapEdit.propTypes = {
  map: PropTypes.object,
  addGateway: PropTypes.number,
  editGateway: PropTypes.number,
  onGatewayChanged: PropTypes.func,
  editZone: PropTypes.number,
  addZone: PropTypes.number,
  onZoneChanged: PropTypes.func,
  editBeacon: PropTypes.number,
  addBeacon: PropTypes.number,
  onBeaconChanged: PropTypes.func,
};

export default MapEdit;
