import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class PersonDetails extends Component {
  render() {
    return (
      <div className={this.props.width}>
        <div className="card h-100">
          <div className="card-close">
            <div className="dropdown">
              <button
                type="button"
                id="closeCard1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <i className="fal fa-bars"></i>
              </button>
              <div
                aria-labelledby="closeCard1"
                className="dropdown-menu dropdown-menu-right has-shadow"
              >
                <Link className="dropdown-item edit" to={`/people/${this.props.person.id}/edit`}>
                  <i className="fal fa-edit" />
                  Edit
                </Link>
                <Link
                  className="dropdown-item edit"
                  to={`/people/${this.props.person.id}/devices/new`}
                >
                  <i className="fab fa-bluetooth-b"></i>
                  Add Device
                </Link>
                <a
                  className="dropdown-item edit"
                  href="#delete"
                  onClick={() => {
                    if (window.confirm('Are you sure? \nPerson will now be archived')) {
                      this.props.deletePerson();
                    }
                  }}
                >
                  <i className="fal fa-trash-alt"></i>
                  Delete
                </a>
              </div>
            </div>
          </div>

          <div className="card-header d-flex align-items-center">
            <h3 className="h4">Person Details</h3>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <strong>First Name</strong>
              </div>
              <div className="col-md-5">{this.props.person.firstName}</div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <strong>Last Name</strong>
              </div>
              <div className="col-md-5">{this.props.person.lastName}</div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <strong>Keywords</strong>
              </div>
            </div>
            {this.props.person ? (
              this.props.person.keywords ? (
                this.props.person.keywords.length > 0 ? (
                  <div>{this.props.person.keywords.join(', ')}</div>
                ) : (
                  <div>This person does not have any keywords</div>
                )
              ) : (
                <div>This person does not have any keywords</div>
              )
            ) : (
              <div>This person does not have any keywords</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PersonDetails.propTypes = {
  person: PropTypes.object.isRequired,
  width: PropTypes.string,
  deletePerson: PropTypes.func,
};

export default PersonDetails;
