import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { updateSearchString, updateSearchFilter } from '../../redux/actions/searchActions';
import searchService from './../../services/searchService';
import './globalSearch.css';
import { isKeyboardKey } from '../../utils';
import { KEYBOARD_KEYS } from '../../constants';

let width = window.innerWidth;

class GlobalSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      response: '',
      responseSorted: {
        people: [],
        places: [],
        things: [],
      },
      filterPeople: false,
      filterPlaces: false,
      filterThings: false,
      redirect: '',
      openResults: false,
      open: false,
      mobile: false,
      anchorEl: null,
    };
    this.closeResults = this.closeResults.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress = (fieldName) => (event) => {
    if (event.keyCode === 13) {
      if (fieldName === 'search') {
        this.props.history.push('/search');
      }
    }
  };

  handleInputChange(e) {
    updateSearchString(e);
    this.setState({ input: e }, () => {
      if (e.length > 3) {
        this.getResults(e);
      } else if (e.length <= 3 && e.length >= 1) {
        let val = e;
        if (val.length === 1) {
          val += '   ';
        } else if (val.length === 2) {
          val += '  ';
        } else if (val.length === 3) {
          val += ' ';
        }
        this.getResults(val);
      } else if (e.length === 0) {
        this.setState({ ready: false }, () =>
          this.setState({
            ready: false,
            loading: false,
            responseSorted: {
              people: [],
              places: [],
              things: [],
            },
            response: '',
          })
        );
      } else {
        this.setState({ ready: false }, () =>
          this.setState({
            ready: false,
            loading: false,
            responseSorted: {
              people: [],
              places: [],
              things: [],
            },
            response: '',
          })
        );
      }
    });
  }

  componentDidMount() {
    document.addEventListener('click', this.closeResults);
    document.addEventListener('keydown', this.closeResults);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeResults);
    document.removeEventListener('keydown', this.closeResults);
  }

  handleFilterChange(val) {
    let x = !this.state[val];
    this.setState({ [val]: x }, () => {
      this.getResults(this.state.input);
    });
  }

  getResults(val) {
    // val is the query string we need to look for
    this.setState({
      loading: true,
    });
    if (
      this.state.filterPeople === false &&
      this.state.filterPlaces === false &&
      this.state.filterThings === false
    ) {
      searchService.getSearchResults(val, '').then((res) => {
        this.setState({
          response: res,
          responseSorted: {
            people: [],
            places: [],
            things: [],
          },
        });
        this.sortResponse(res.data);
      });
    } else {
      let holder = [];
      if (this.state.filterPeople === true) holder.push('person');
      if (this.state.filterPlaces === true) holder.push('place');
      if (this.state.filterThings === true) holder.push('thing');
      let filter = holder.join();
      updateSearchFilter(filter);
      searchService.getSearchResults(val, filter).then((res) => {
        this.setState({
          response: res,
          responseSorted: {
            people: [],
            places: [],
            things: [],
          },
        });
        this.sortResponse(res.data);
      });
    }
  }

  isFiltered() {
    return this.state.filterPeople || this.state.filterPlaces || this.state.filterThings;
  }

  sortResponse(res) {
    for (let i = 0; i <= res.length; i++) {
      if (i === res.length && this.state.input.length > 3) {
        this.setState({ ready: true, loading: false, openResults: true });
        return;
      } else if (this.state.input.length < 4) {
        i = res.length;
      } else if (res[i].entityType === 'Person') {
        this.setState({ loading: true, openResults: false });
        this.state.responseSorted.people.push(res[i]);
      } else if (res[i].entityType === 'Place') {
        this.setState({ loading: true, openResults: false });
        this.state.responseSorted.places.push(res[i]);
      } else if (res[i].entityType === 'Thing') {
        this.setState({ loading: true, openResults: false });
        this.state.responseSorted.things.push(res[i]);
      }
    }
  }

  navigate(obj) {
    if (obj.entityType === 'Place') {
      this.setState({ redirect: `/places/${obj.id}` }, () => {
        this.setState({ redirect: '', ready: false, openResults: false });
      });
    } else if (obj.entityType === 'Person') {
      this.setState({ redirect: `/people/${obj.id}` }, () => {
        this.setState({ redirect: '', ready: false, openResults: false });
      });
    } else if (obj.entityType === 'Thing') {
      this.setState({ redirect: `/things/${obj.id}` }, () => {
        this.setState({ redirect: '', ready: false, openResults: false });
      });
    }

    // used to send user to details page of P/P/T
  }

  loading() {
    // this method is to be triggered when this.state.loading === true
  }

  closeResults(e) {
    if (e.target.id !== 'target-id-gloab-search') {
      this.setState({ openResults: false, mobile: false });
    } else if (e.keyCode === 27) {
      this.setState({ openResults: false });
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  renderThings() {
    if (!this.isFiltered() || (this.isFiltered() && this.state.filterThings)) {
      let results;
      var header = (
        <div id="target-id-gloab-search" className="searchTitle">
          Things:
        </div>
      );
      if (this.state.responseSorted.things.length > 0) {
        results = this.state.responseSorted.things.map((val, i) => {
          // Only show lastLocation prefix if the thing is online
          if (val.onlineStatus === 'Online') {
            if (val.lastLocation) {
              return (
                <div
                  id="target-id-gloab-search"
                  className="search-result-clickable"
                  key={val.id}
                  onClick={() => {
                    this.navigate(val);
                  }}
                  onKeyDown={(e) => isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => this.navigate(val))}
                  role="button"
                  tabIndex={0}
                >{`${val ? `[${val.lastLocation}] ${val.name}` : 'Nothing to display'}`}</div>
              );
            }
          }
          return (
            <div
              id="target-id-gloab-search"
              className="search-result-clickable"
              role="button"
              key={val.id}
              onClick={() => {
                this.navigate(val);
              }}
              onKeyDown={(e) => isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => this.navigate(val))}
              tabIndex={0}
            >{`${val ? `${val.name}` : 'Nothing to display'}`}</div>
          );
        });
      } else {
        results = <div id="target-id-gloab-search">Nothing to display</div>;
      }
      return (
        <div>
          {header}
          {results}
        </div>
      );
    }
  }

  renderPlaces() {
    if (!this.isFiltered() || (this.isFiltered() && this.state.filterPlaces)) {
      let results;
      var header = (
        <div id="target-id-gloab-search" className="searchTitle">
          Places:
        </div>
      );
      if (this.state.responseSorted.places.length > 0) {
        results = this.state.responseSorted.places.map((val, i) => {
          return (
            <div
              id="target-id-gloab-search"
              className="search-result-clickable"
              key={val.id}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.navigate(val);
              }}
              onKeyDown={(e) => isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => this.navigate(val))}
            >{`${val ? val.name : 'Nothing to display'}`}</div>
          );
        });
      } else {
        results = <div id="target-id-gloab-search">Nothing to display</div>;
      }
      return (
        <div>
          {header}
          {results}
        </div>
      );
    }
  }

  renderPeople() {
    if (!this.isFiltered() || (this.isFiltered() && this.state.filterPeople)) {
      let results;
      var header = (
        <div id="target-id-gloab-search" className="searchTitle">
          People:
        </div>
      );
      if (this.state.responseSorted.people.length > 0) {
        results = this.state.responseSorted.people.map((val, i) => {
          return (
            <div
              id="target-id-gloab-search"
              className="search-result-clickable"
              key={val.id}
              onClick={() => {
                this.navigate(val);
              }}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => this.navigate(val))}
            >{`${val ? val.name : 'Nothing to display'}`}</div>
          );
        });
      } else {
        results = <div id="target-id-gloab-search">Nothing to display</div>;
      }
      return (
        <div>
          {header}
          {results}
        </div>
      );
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleMobile() {
    this.setState({ mobile: !this.state.mobile });
  }

  render() {
    width = window.innerWidth;

    return (
      <div id="target-id-gloab-search">
        {this.state.redirect.length > 1 ? (
          <div>
            <Redirect to={{ pathname: '/', state: { redirect: this.state.redirect } }} />
          </div>
        ) : (
          false
        )}
        {width > 576 ? (
          <div>
            <div className="searchHeader" id="target-id-gloab-search">
              <input
                id="target-id-gloab-search"
                className="searchInputBox"
                onClick={() => {
                  this.handleInputChange(this.state.input);
                }}
                placeholder="Search"
                onChange={(e) => this.handleInputChange(e.target.value)}
                onKeyDown={this.handleKeyPress('search')}
              />
            </div>
            {this.state.ready === true && this.state.openResults === true ? (
              <div className="searchResults" id="target-id-gloab-search">
                {this.renderPeople()}
                &nbsp;
                {this.renderPlaces()}
                &nbsp;
                {this.renderThings()}
                <Link to="/search" id="seeAllResults">
                  <div>See All Results</div>
                </Link>
              </div>
            ) : (
              false
            )}
          </div>
        ) : (
          <div id="target-id-gloab-search">
            <i
              id="target-id-gloab-search"
              className="fa fa-search"
              role="button"
              tabIndex={0}
              onClick={() => {
                this.toggleMobile();
              }}
              onKeyDown={(e) => isKeyboardKey(e, KEYBOARD_KEYS.ENTER, this.toggleMobile)}
              style={{
                fontSize: '1.5em',
                marginLeft: '-20px',
                cursor: 'pointer',
              }}
            />
            {this.state.mobile ? (
              <div className="mobile-search" id="target-id-gloab-search">
                <div className="searchHeader" id="target-id-gloab-search">
                  <input
                    id="target-id-gloab-search"
                    className="searchInputBox"
                    onClick={() => {
                      this.handleInputChange(this.state.input);
                    }}
                    placeholder="Search"
                    onChange={(e) => this.handleInputChange(e.target.value)}
                  />
                </div>
                {this.state.ready === true && this.state.openResults === true ? (
                  <div className="searchResults" id="target-id-gloab-search">
                    {this.renderPeople()}
                    &nbsp;
                    {this.renderPlaces()}
                    &nbsp;
                    {this.renderThings()}
                    <Link to="/search" id="seeAllResults">
                      <div>See All Results</div>
                    </Link>
                  </div>
                ) : (
                  false
                )}
              </div>
            ) : (
              false
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => state;

export default withRouter(
  connect(mapStateToProps, { updateSearchFilter, updateSearchString })(GlobalSearch)
);
