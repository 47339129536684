import React from 'react';
import PropTypes from 'prop-types';

class ZoneEditRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'Measurement',
      meters: '0',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.changedDistance(event.target.value);
  };

  render() {
    return (
      <div
        style={{ margin: '10px' }}
        className="d-flex align-items-center justify-content-between w-100"
      >
        <div className="form-group w-25">Distance between points in meters</div>
        <div className="form-group w-25">
          <input
            className="form-control"
            value={this.state.meters}
            onChange={this.handleChange('meters')}
          />
        </div>

        <div className="w-25">
          <div className="btn btn-group float-right">
            <a
              href="# "
              className="mr-4"
              onClick={this.props.startMeasurement}
              title="Change location on map"
            >
              <i className="fa fa-map-pin" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

ZoneEditRow.propTypes = {
  startMeasurement: PropTypes.func,
  changedDistance: PropTypes.func,
};

export default ZoneEditRow;
