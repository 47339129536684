// imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
// locals
import TextField from './textField';

class GatewayConfigurationForm extends Component {
  getCommitHash() {
    if (!this.props.gateway) {
      return '';
    }

    if (this.props.gateway.hashException && this.props.gateway.hashException.commitHash !== '') {
      return this.props.gateway.hashException.commitHash;
    }

    return this.props.gateway.commitHash;
  }
  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header d-flex align-items-center">
            <h3 className="h4">Configure Gateway</h3>
          </div>
          <div className="card-body">
            <form className="form-horizontal" onSubmit={this.props.handleSubmit}>
              <div className="form-group row">
                <span className="col-sm-2 form-control-label">Current Hash</span>
                <div className="col-sm-6">{this.getCommitHash()}</div>
              </div>

              <Field
                className={'form-control'}
                type={'text'}
                label={'Hash Exception'}
                component={TextField}
                name={'hashException.commitHash'}
              />

              <div className="line">&nbsp;</div>
              <div className="form-group row">
                <div className="col-sm-6 offset-sm-3">
                  <button
                    type="button"
                    onClick={this.props.resetForm}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.props.resetHash}
                    disabled={this.props.isLoading}
                  >
                    Use Default
                  </button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary" disabled={this.props.isLoading}>
                    Save changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

GatewayConfigurationForm.propTypes = {
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  resetHash: PropTypes.func,
  gateway: PropTypes.object,
};

const form = reduxForm({
  form: 'GatewayConfigurationForm',
  enableReinitialize: true,
})(GatewayConfigurationForm);

export default form;
