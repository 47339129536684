import * as types from '../types';
import { store } from '../store/store';

export const updateSearchString = (val) => {
  store.dispatch({
    type: types.UPDATE_SEARCH_STRING,
    payload: val,
  });
};

export const updateSearchFilter = (val) => {
  store.dispatch({
    type: types.UPDATE_SEARCH_FILTER,
    payload: val,
  });
};
