import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import swal from '@sweetalert/with-react';
import thingService from './../../services/thingService';
import { convertCommaStringToArray } from './../../utils/stringUtils';

import toast from '../utils/toast';
import Dropzone from '../dropzones/dropzone';
import CloseModal from './closeModal';
import axios from 'axios';

/**
 * @desc Displays content to allow Admin to bulk import beacons via CSV
 */
export default class AddUsersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gateways: [],
      submitEnable: false,
    };
    this.buildObj = this.buildObj.bind(this);
  }

  buildObj = (obj) => {
    let newObj = { context: {} };
    if (obj.name) {
      newObj.name = obj.name;
    }
    if (obj.description) {
      newObj.description = obj.description;
    }
    if (obj.deviceId) {
      newObj.deviceId = obj.deviceId;
    }

    if (obj.deviceParser) {
      newObj.deviceType = {
        parser: { name: obj.deviceParser, version: 1 },
      };
    }
    if (obj.manufacturerName) {
      newObj.context.manufacturer = {};
      newObj.context.manufacturer.name = obj.manufacturerName;
    }

    if (obj.manufacturerModel) {
      if (!newObj.context.manufacturer) {
        newObj.context.manufacturer = {};
      }
      newObj.context.manufacturer.model = obj.manufacturerModel;
    }
    if (obj.manufacturerYear) {
      if (!newObj.context.manufacturer) {
        newObj.context.manufacturer = {};
      }
      newObj.context.manufacturer.year = obj.manufacturerYear;
    }
    if (obj.serialNumber) {
      newObj.context.serialNumber = obj.serialNumber;
    }
    if (obj.alternateId) {
      newObj.context.alternateIdentifier = obj.alternateId;
    }
    if (obj.notes) {
      newObj.context.notes = obj.notes;
    }
    if (obj.keywords) {
      newObj.keywords = obj.keywords;
    }

    console.log(newObj);
    return newObj;
  };

  getCsvHeaders = () => {
    return [
      { label: 'Device Name', key: 'name' },
      { label: 'Device ID', key: 'deviceId' },
      { label: 'Device Description', key: 'description' },
      { label: 'Parser', key: 'deviceParser' },
      { label: 'Notes', key: 'notes' },
      { label: 'Alternate ID', key: 'alternateId' },
      { label: 'Serial Number', key: 'serialNumber' },
      { label: 'Manufacturer', key: 'manufacturerName' },
      { label: 'Model', key: 'manufacturerModel' },
      { label: 'Year', key: 'manufacturerYear' },
      { label: 'Device Keywords', key: 'keywords' },
    ];
  };

  // display an example row for User downloading CSV
  getExampleCsvData = () => {
    return [
      {
        name: 'Example Beacon',
        deviceId: 'EX:AM:PL:E1:23:45',
        description: 'Example Beacon Description',
        parser: '',
        notes: '',
        alternateId: '',
        serialNumber: '',
        manufacturerName: '',
        manufacturerModel: '',
        manufacturerYear: '',
        keywords: 'example, keywords',
      },
    ];
  };

  onDropCsv = (file) => {
    this.setState({ submitEnable: true });
    const reader = new FileReader();

    reader.onload = (e) => {
      const gateways = e.target.result
        .split('\n')
        .slice(1) // remove headers
        .map((csvString) => {
          const [
            name,
            deviceId,
            description,
            parser,
            notes,
            alternateId,
            serialNumber,
            manufacturerName,
            manufacturerModel,
            manufacturerYear,
            keywords,
          ] = csvString.split(',');

          return {
            name: name ? name.trim().replace(/['"]+/g, '') : null,
            deviceId: deviceId ? deviceId.trim().replace(/['"]+/g, '') : null,
            description: description ? description.trim().replace(/['"]+/g, '') : null,
            parser: parser ? parser.trim().replace(/['"]+/g, '') : null,
            notes: notes ? notes.trim().replace(/['"]+/g, '') : null,
            alternateId: alternateId ? alternateId.trim().replace(/['"]+/g, '') : null,
            serialNumber: serialNumber ? serialNumber.trim().replace(/['"]+/g, '') : null,
            manufacturerName: manufacturerName
              ? manufacturerName.trim().replace(/['"]+/g, '')
              : null,
            manufacturerModel: manufacturerModel
              ? manufacturerModel.trim().replace(/['"]+/g, '')
              : null,
            manufacturerYear: manufacturerYear
              ? manufacturerYear.trim().replace(/['"]+/g, '')
              : null,
            keywords: keywords ? convertCommaStringToArray(keywords.replace(/['"]+/g, '')) : [],
          };
        });
      console.log(gateways);
      this.setState({ gateways });
    };

    reader.onerror = (e) => {
      toast.error('Failed to read the CSV file. Please try again.');
    };

    reader.readAsText(file);
  };

  onUploadUsers = () => {
    console.log(this.state.gateways);
    let requestArr = [];
    let successArr = [];
    let failedArr = [];
    for (let i = 0; i < this.state.gateways.length; i++) {
      if (this.state.gateways[i].deviceId && this.state.gateways[i].deviceId.length > 12) {
        requestArr.push(
          thingService
            .create(this.buildObj(this.state.gateways[i]))
            .then((res) => {
              successArr.push(res);
            })
            .catch(() => {
              failedArr.push({ item: this.state.gateways[i], index: i });
            })
        );
      } else {
        failedArr.push({
          message: 'Device ID is incorrect',
          item: this.state.gateways[i],
          index: i,
        });
      }
    }
    axios.all(requestArr).then(
      axios.spread((...responses) => {
        console.log('Completed');
        if (failedArr.length > 0) {
          if (failedArr.length > 1) {
            toast.warn(`Uh Oh! Failed to create ${failedArr.length} beacons.`);
          } else {
            if (failedArr[0].message) {
              toast.warn(
                `Uh Oh! Failed to create ${failedArr[0].item.name}. Device ID is incorrect`
              );
            } else {
              toast.warn(
                `Uh Oh! Failed to create ${failedArr[0].item.name}. Device ID is already active in Sonar.`
              );
            }
          }

          swal.close();
          this.props.getFailedFunc(failedArr);
        } else if (failedArr.length === 0) {
          window.location.assign('/');
          toast.success(`Successfully created ${successArr.length} beacons`);
        }
        console.log(failedArr);
        console.log('COMPLETED: ', successArr.length, 'FAILED: ', failedArr.length);
      })
    );

    console.log('GREEN: ', successArr.length, 'RED; ', failedArr.length);
  };

  closeSwal = (e) => {
    e.preventDefault();
    window.location.assign('/');
    swal.close();
  };

  render() {
    return (
      <div>
        <CloseModal />

        <div className="form-inline justify-content-center mb-2">
          <div className="mr-4">
            <i style={{ color: '#3f51b5' }} className="fas fa-3x mb-2 fa-tags" />
            <span>Add Beacons</span>
          </div>
        </div>

        <hr />

        <p>
          Download the CSV template to include the beacons you would like to add then upload the
          completed CSV
        </p>

        <CSVLink
          data={this.getExampleCsvData()}
          headers={this.getCsvHeaders()}
          filename="bulk-beacons.csv"
          className="btn btn-outline-primary mb-4"
        >
          Download CSV
        </CSVLink>

        <div className="d-flex mb-4 justify-content-center">
          <Dropzone
            onDrop={this.onDropCsv}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>

        <div className="d-flex flex-row justify-content-around">
          <button
            type="button"
            className="btn btn-outline-primary"
            title="Add Users"
            onClick={(e) => this.closeSwal(e)}
          >
            Cancel
          </button>

          {this.state.submitEnable ? (
            <button
              type="button"
              className="btn btn-primary"
              title="Add Users"
              onClick={this.onUploadUsers}
            >
              Upload
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              title="Add Users"
              onClick={this.onUploadUsers}
              disabled
            >
              Upload
            </button>
          )}
        </div>
      </div>
    );
  }
}
