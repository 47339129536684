import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
// import moment from 'moment';
import { Link } from 'react-router-dom';

import './grid.css';

class GatewaysGrid extends Component {
  render() {
    const columns = [
      {
        accessor: 'name',
        Header: 'Name',
      },
      {
        accessor: 'gatewayId',
        Header: 'Gateway ID',
      },
      {
        accessor: 'hasException',
        Header: 'Using Exception',
        Cell: (row) => (
          <div style={{ textAlign: '' }}>
            <span>{row.value ? 'Yes' : 'No'}</span>
          </div>
        ),
      },
      {
        accessor: 'commitHash',
        Header: 'Installed Hash',
      },
      {
        accessor: 'id',
        headerStyle: { textAlign: 'center' },
        Header: () => (
          <span>
            <Link to={`/places/new`} className="addHeader">
              <i className="fa fa-plus-square fa-lg" />
            </Link>
          </span>
        ),
        maxWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>
              <Link to={`/admin/gateways/${row.value}`}>EDIT</Link>
            </span>
          </div>
        ),
      },
    ];

    return (
      <div>
        <ReactTable
          data={this.props.gateways}
          columns={columns}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          loading={this.props.isLoading} // Display the loading overlay when we need it
          onFetchData={this.props.onTableChange} // Request new data when things change
          defaultPageSize={this.props.gateways.length ? this.props.gateways.length : 100}
          pageSizeOptions={[5, 10, 20, 25, 50]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

GatewaysGrid.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  gateways: PropTypes.array.isRequired,
  onTableChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number,
};

export default GatewaysGrid;
