// BaseService
import axios from 'axios';

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL_V2,
  timeout: 10000,
  transformResponse: [transformResponse],
});

// #region TRANSFORMS
function transformResponse(data) {
  let parsedData = JSON.parse(data);

  // transform list response
  if (parsedData.value) {
    const listResponse = {
      count: parsedData['@odata.count'],
      nextLink: parsedData['@odata.nextLink'],
      items: parsedData['value'],
    };

    return listResponse;
  }

  return parsedData;
}
// #endregion TRANSFORMS

// #region INTERCEPTORS
async function reqInterceptorSuccess(config) {
  // console.log('req config', config);
  return config;
}

async function reqInterceptorError(err) {
  console.error('req err', err);
  return Promise.reject(err);
}

async function resInterceptorSuccess(res) {
  // console.log(`res - ${res.config.url}`, res);
  return res;
}

async function resInterceptorError(err) {
  console.error('res err', err);
  return Promise.reject(err);
}
// #endregion INTERCEPTORS

Api.interceptors.request.use(reqInterceptorSuccess, reqInterceptorError);
Api.interceptors.response.use(resInterceptorSuccess, resInterceptorError);

export default Api;
