import React, { Component, Fragment } from 'react';
import ReactDropzone from 'react-dropzone';
import PropTypes from 'prop-types';

/**
 * @desc Customized Dropzone for files.
 */
class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: '',
      files: '',
    };
  }

  onDrop = (files) => {
    if (!files.length) return;

    // if multiple isnt specified, just pass the file for ease
    const _files = this.props.multiple ? files : files[0];

    this.setState({ className: 'dropZoneReady', files: _files }, () => {
      this.props.onDrop(_files);
    });
  };

  renderFileSelected = () => {
    return (
      <div>
        <i className="fas fa-check fa-6x" />

        {/* Display names of selected file(s) */}
        {this.props.multiple ? (
          <Fragment>
            <div>Selected Files:</div>
            {this.state.files.map((file, index) => (
              <div key={index}>{file.name}</div>
            ))}
          </Fragment>
        ) : (
          <div>Selected file: {this.state.files.name}</div>
        )}
      </div>
    );
  };

  renderFileNotSelected = () => {
    return (
      <div>
        <div>Drag and drop your CSV file</div>
        <div>or</div>
        <div>Select a file from your computer</div>
        <button type="button" className="btn btn-link" title="Choose File">
          Choose File
        </button>
      </div>
    );
  };

  render() {
    return (
      <ReactDropzone
        id="hoverZone"
        className={this.state.className}
        onDrop={this.onDrop}
        accept={this.props.accept}
        multiple={this.props.multiple}
        style={{
          width: '85%',
          border: '2px dotted #333333',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          padding: '8px',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          {this.state.className.length > 2
            ? this.renderFileSelected()
            : this.renderFileNotSelected()}
        </div>
      </ReactDropzone>
    );
  }
}

Dropzone.defaultProps = {
  multiple: false,
};

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,

  // defaults
  multiple: PropTypes.bool,
};

export default Dropzone;
