import Api from './../config/api';
import { store } from '../redux/store/store';

let state;

// returns all triggers within an application
const getAllApplicationTriggers = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/triggers`
  );
};

// returns all triggers belonging to a thing
const getAll = (thingId) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${thingId}/triggers`
  );
};

// returns trigger data
const getTrigger = (thingId, triggerId) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${thingId}/triggers/${triggerId}`
  );
};

// creates a new trigger
const newTrigger = (thingId, trigger) => {
  state = store.getState();
  return Api.put(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${thingId}/triggers`,
    trigger
  );
};

// updates a trigger
const updateTrigger = (thingId, trigger, triggerId) => {
  state = store.getState();
  return Api.patch(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${thingId}/triggers/${triggerId}`,
    trigger
  );
};

// deletes a trigger
const deleteTrigger = (thingId, triggerId) => {
  state = store.getState();
  return Api.delete(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${thingId}/triggers/${triggerId}`
  );
};

export const getZones = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/zones`
  );
};

//DASH

const getDashboardTriggers = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/dashboard/triggers`
  );
};

const snoozeDashboardTrigger = (trigger, recipient, time) => {
  state = store.getState();
  return Api.post(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/triggers/${trigger}/recipients/${recipient}/snooze/${time}`
  );
};

const resolveDashboardTrigger = (trigger, recipient, note) => {
  let comment2 = {};
  if (note) {
    comment2.comment = note;
  } else {
    comment2.comment = '';
  }
  console.log(comment2);
  console.log(note);
  state = store.getState();
  return Api.post(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/triggers/${trigger}/recipients/${recipient}/resolve`,
    comment2
  );
};

const resetDashboardTrigger = (trigger, recipient, comment) => {
  if (!comment) {
    comment = { comment: '' };
  }
  state = store.getState();
  return Api.post(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/triggers/${trigger}/recipients/${recipient}/reset`,
    comment
  );
};

export default {
  getAllApplicationTriggers,
  getAll,
  getTrigger,
  newTrigger,
  updateTrigger,
  deleteTrigger,
  getZones,
  getDashboardTriggers,
  snoozeDashboardTrigger,
  resolveDashboardTrigger,
  resetDashboardTrigger,
};
