import React, { Component } from 'react';
import moment from 'moment';
import { DateTimePicker } from 'material-ui-pickers';
import PropTypes from 'prop-types';

const styles = {
  label: {
    paddingBottom: '5px',
  },
};

class StartEndDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
    };
  }

  handleDateChange = (name, val) => {
    const newDate = moment(val);
    this.setState({ [name]: newDate }, () => {
      if (name === 'startDate') this.props.onChangeStartDate(newDate);
      else this.props.onChangeEndDate(newDate);
    });
  };

  render() {
    return (
      <div className="d-flex flex-row align-items-center flex-wrap">
        {/* <i className="fa fa-calendar-alt fa-lg mr-3" /> */}

        <div>
          <span style={styles.label}>From:&nbsp;</span>
          <DateTimePicker
            value={this.state.startDate}
            onChange={(e) => this.handleDateChange('startDate', e)}
            className="mr-4"
          />
        </div>

        <div>
          <span style={styles.label}>To:&nbsp;</span>
          <DateTimePicker
            value={this.state.endDate}
            onChange={(e) => this.handleDateChange('endDate', e)}
          />
        </div>
      </div>
    );
  }
}

StartEndDatePicker.defaultProps = {
  startDate: moment(),
  endDate: moment(),
};

StartEndDatePicker.propTypes = {
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,

  // defaults
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
};

export default StartEndDatePicker;
