// imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// locals

import { getUser, updateUser } from '../redux/actions/userActions';
import { changeLocation } from '../redux/actions/navigationActions';
import toast from './../components/utils/toast';
import cognitoActions from '../redux/actions/cognitoActions';

let obj = {};

class SettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      fName: '',
      lName: '',
      pName: '',
      company: '',
      pEmail: '',
      pAddName: '',
      pAddStreet1: '',
      pAddStreet2: '',
      pAddCity: '',
      pAddState: '',
      pAddZip: '',
      pPhoneNumber: '',
      currentPass: '',
      newPassOne: '',
      newPassTwo: '',
    };
  }
  componentDidMount() {
    let cb = (data) => {
      this.setState({
        data,
        ready: true,
        fName: data.firstName ? data.firstName : '',
        lName: data.lastName ? data.lastName : '',
        pName: data.preferredName ? data.preferredName : '',
        company: data.company ? data.company : '',
        pEmail: data.emailAddresses !== null ? data.emailAddresses[0].emailAddress : '',
        pAddName:
          data.addresses !== null ? data.addresses.length > 0 && data.addresses[0].name : '',
        pAddStreet1:
          data.addresses !== null ? data.addresses.length > 0 && data.addresses[0].street1 : '',
        pAddStreet2:
          data.addresses !== null ? data.addresses.length > 0 && data.addresses[0].street2 : '',
        pAddCity:
          data.addresses !== null ? data.addresses.length > 0 && data.addresses[0].city : '',
        pAddState:
          data.addresses !== null ? data.addresses.length > 0 && data.addresses[0].state : '',
        pAddZip:
          data.addresses !== null ? data.addresses.length > 0 && data.addresses[0].zipcode : '',
        pPhoneNumber:
          data.phoneNumbers !== null &&
          data.phoneNumbers !== undefined &&
          data.phoneNumbers[0].phoneNumber
            ? data.phoneNumbers[0].phoneNumber
            : '',
      });
    };
    getUser(cb);
  }

  buildObj = () => {
    let newObj = { context: {} };
    newObj.email = localStorage.getItem('email');
    newObj.addresses = [
      {
        name: this.state.pAddName,
        street1: this.state.pAddStreet1,
        street2: this.state.pAddStreet2,
        city: this.state.pAddCity,
        state: this.state.pAddState,
        zipcode: this.state.pAddZip,
      },
    ];
    newObj.firstName = this.state.fName;
    newObj.preferredName = this.state.pName;
    newObj.lastName = this.state.lName;
    newObj.company = this.state.company;
    newObj.emailAddresses = [
      {
        name: 'Primary Email',
        emailAddress: this.state.pEmail,
        receiveEmailNotifications: true,
      },
    ];
    newObj.phoneNumbers = [
      {
        name: 'Primary Phone Number',
        emailAddress: this.state.pPhoneNumber,
        receiveSMSNotifications: true,
      },
    ];

    return newObj;
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    obj[name] = true;
  };

  onUpdate() {
    toast.success();
  }

  submit() {
    if (
      this.state.newPassOne &&
      this.state.newPassTwo &&
      this.state.currentPass &&
      this.state.newPassTwo === this.state.newPassOne
    ) {
      cognitoActions.updatePassword(
        localStorage.getItem('email'),
        this.state.currentPass,
        this.state.newPassOne,
        this
      );
    }
    updateUser(this.buildObj())
      .then((_res) => {
        this.props.changeLocation('/');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div className="d-flex flex-wrap" style={{ marginTop: '15px' }}>
        <div className="col-xl-8 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">Personal Information</h3>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group">
                  <label className=" form-control-label">First Name</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.fName}
                      onChange={this.handleChange('fName')}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className=" form-control-label">Preferred Name</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.pName}
                      onChange={this.handleChange('pName')}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className=" form-control-label">Last Name</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.lName}
                      onChange={this.handleChange('lName')}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className=" form-control-label">Company</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.company}
                      onChange={this.handleChange('company')}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className=" form-control-label">Email</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.pEmail}
                      onChange={this.handleChange('pEmail')}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className=" form-control-label">Phone Number</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.pPhoneNumber}
                      onChange={this.handleChange('pPhoneNumber')}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">Change Password</h3>
            </div>
            <div className="card-body">
              <div className="col-sm-12">
                <p>
                  Enter a password 8 or more characters. Requirements: 1 Lowercase, 1 Uppercase, 1
                  Number & 1 Special character
                </p>
                <div className="form-horizontal">
                  <div className="form-group">
                    <label className=" form-control-label">Current Password</label>
                    <div className="">
                      <input
                        className="form-control"
                        value={this.state.currentPass}
                        onChange={this.handleChange('currentPass')}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className=" form-control-label">New Password</label>
                    <div className="">
                      <input
                        className="form-control"
                        value={this.state.newPassOne}
                        onChange={this.handleChange('newPassOne')}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className=" form-control-label">Verify New Password</label>
                    <div className="">
                      <input
                        className="form-control"
                        value={this.state.newPassTwo}
                        onChange={this.handleChange('newPassTwo')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">Address</h3>
            </div>
            <div className="card-body">
              <div className="form-horizontal">
                <div className="form-group">
                  <label className=" form-control-label">Address Name</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.pAddName}
                      onChange={this.handleChange('pAddName')}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className=" form-control-label">Street</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.pAddStreet1}
                      onChange={this.handleChange('pAddStreet1')}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className=" form-control-label">Unit/Apt/Suite</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.pAddStreet2}
                      onChange={this.handleChange('pAddStreet2')}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className=" form-control-label">City</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.pAddCity}
                      onChange={this.handleChange('pAddCity')}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className=" form-control-label">Texas</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.pAddState}
                      onChange={this.handleChange('pAddState')}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className=" form-control-label">Zip Code</label>
                  <div className="">
                    <input
                      className="form-control"
                      value={this.state.pAddZip}
                      onChange={this.handleChange('pAddZip')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group" style={{ margin: '0 auto' }}>
            <button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                this.submit();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SettingsContainer.propTypes = {
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  formHeader: PropTypes.string,
  close: PropTypes.func,
  changeLocation: PropTypes.func,
};

const mapStateToProps = (state) => ({
  // ...
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeLocation,
    },
    dispatch
  );

SettingsContainer = connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);

export default reduxForm({
  form: 'user', // a unique name for this form
})(SettingsContainer);

// <div>
//     {this.state.ready === true
//         ? this.state.data.addresses && this.state.data.addresses.length > 0
//             ? (hasAddresses = true)
//             : (hasAddresses = false)
//         : false}
//     {this.state.ready === true
//         ? this.state.data.phoneNumbers && this.state.data.phoneNumbers.length > 0
//             ? (hasPhoneNumbers = true)
//             : (hasPhoneNumbers = false)
//         : false}
//     {this.state.ready === true ? (
//         <div className="col-lg-12">
//             <div className="card" style={{ marginTop: 20 }}>
//                 <div className="card-header d-flex align-items-center">
//                     <h3 className="h4">Edit Details</h3>
//                 </div>
//                 <div className="card-body">
//                     <form className="form-horizontal">
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('fName', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="First Name"
//                             component={TextField}
//                             name="user.firstName"
//                             defaultValue={
//                                 this.state.data.firstName
//                                     ? this.state.data.firstName
//                                     : 'Enter your first name'
//                             }
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('pName', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="Preferred Name"
//                             component={TextField}
//                             name="user.preferredName"
//                             defaultValue={
//                                 this.state.data.preferredName
//                                     ? this.state.data.preferredName
//                                     : 'Enter your preferred name'
//                             }
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('lName', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="Last Name"
//                             component={TextField}
//                             name="user.lastName"
//                             defaultValue={
//                                 this.state.data.lastName
//                                     ? this.state.data.lastName
//                                     : 'Enter your last name'
//                             }
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('company', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="Company"
//                             component={TextField}
//                             name="user.company"
//                             defaultValue={
//                                 this.state.data.company
//                                     ? this.state.data.company
//                                     : 'Enter your company'
//                             }
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('pEmail', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="Primary Email"
//                             component={TextField}
//                             name="user.primaryEmail"
//                             defaultValue={
//                                 this.state.data.emailAddresses
//                                     ? this.state.data.emailAddresses[0].emailAddress
//                                     : 'Enter your primary email'
//                             }
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('pPhoneNumber', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="Primary Phone Number"
//                             component={TextField}
//                             name="user.primaryPhoneNumber"
//                             defaultValue={
//                                 hasPhoneNumbers === true
//                                     ? this.state.data.phoneNumbers[0].phoneNumber
//                                     : 'Enter your primary phone number'
//                             }
//                         />
//                         <div className="row">
//                             <div className="col-md-4">
//                                 <strong>Address</strong>
//                             </div>
//                         </div>
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('pAddName', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="Address Name"
//                             component={TextField}
//                             name="user.address.name"
//                             defaultValue={
//                                 hasAddresses === true
//                                     ? this.state.data.addresses[0].name
//                                     : 'Enter a name for your address'
//                             }
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('pAddStreet1', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="Street"
//                             component={TextField}
//                             name="user.address.street1"
//                             defaultValue={
//                                 hasAddresses === true
//                                     ? this.state.data.addresses[0].street1
//                                     : 'Enter street information'
//                             }
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('pAddStreet2', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="Unit/Apt/Floor"
//                             component={TextField}
//                             name="user.address.street2"
//                             defaultValue={
//                                 hasAddresses === true
//                                     ? this.state.data.addresses[0].street2
//                                     : 'Enter street information cont.'
//                             }
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('pAddCity', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="City"
//                             component={TextField}
//                             name="user.address.city"
//                             defaultValue={
//                                 hasAddresses === true
//                                     ? this.state.data.addresses[0].city
//                                     : 'Enter city'
//                             }
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('pAddState', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="State"
//                             component={TextField}
//                             name="user.address.state"
//                             defaultValue={
//                                 hasAddresses === true
//                                     ? this.state.data.addresses[0].state
//                                     : 'Enter state'
//                             }
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('pAddZip', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="Zip Code"
//                             component={TextField}
//                             name="user.address.zip"
//                             defaultValue={
//                                 hasAddresses === true
//                                     ? this.state.data.addresses[0].zipcode
//                                     : 'Enter zipcode'
//                             }
//                         />

//                         <div className="line">&nbsp;</div>
//                         <div className="form-group row">
//                             <div className="col-sm-4 offset-sm-3">
//                                 <button
//                                     className="btn btn-primary"
//                                     disabled={this.props.isLoading}
//                                     onClick={e => {
//                                         e.preventDefault();
//                                         this.submit();
//                                     }}
//                                 >
//                                     Submit
//                                 </button>
//                             </div>
//                         </div>
//                     </form>
//                     <div className="line">&nbsp;</div>
//                     <form className="form-horizontal">
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('currentPass', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="Current Password"
//                             component={TextField}
//                             name="currentPass"
//                             defaultValue=""
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('newPassOne', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="New Password"
//                             component={TextField}
//                             name="newPass1"
//                             defaultValue=""
//                         />
//                         <Field
//                             onChange={e => {
//                                 this.handleChange('newPassTwo', e.target.value);
//                             }}
//                             className="form-control"
//                             type="text"
//                             label="Verify New Password"
//                             component={TextField}
//                             name="newPass2"
//                             defaultValue=""
//                         />

//                         <div className="line">&nbsp;</div>
//                         <div className="form-group row">
//                             <div className="col-sm-4 offset-sm-3">
//                                 <button
//                                     className="btn btn-primary"
//                                     disabled={this.props.isLoading}
//                                     onClick={e => {
//                                         e.preventDefault();
//                                         this.changePassword;
//                                     }}
//                                 >
//                                     Submit Change
//                                 </button>
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     ) : (
//         false
//     )}
// </div>;
