// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// locals
import { changeLocation } from '../../../redux/actions/navigationActions';
import CrumbsHeader from '../../../components/layout/crumbsHeader';
import NewMapForm from '../../../components/forms/newMapForm';
import { createMap, clear } from '../../../redux/actions/mapActions';
import mapsDataContext from '../../../services/dataContexts/mapsDataContext';
import toast from './../../../components/utils/toast';

class MapsNewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      name: '',
      description: '',
      keywords: [],
      ready: false,
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.clear();
  }

  componentWillUnmount() {
    this.props.clear();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps &&
      nextProps.maps &&
      nextProps.maps.imageUrl &&
      prevState &&
      !prevState.imageUrl
    ) {
      return { imageUrl: nextProps.maps.imageUrl, ready: true };
    } else {
      return null;
    }
  }

  passStateUp(state, value) {
    this.setState({ [state]: value });
  }

  submit(img, name, description, keywords) {
    mapsDataContext
      .post(img, name, description, keywords.join(','))
      .then((x) => {
        toast.success();
        this.props.changeLocation(`/maps/${x.data.id}/edit`);
      })
      .catch(() => {
        toast.error('Failed to submit map, please try again.');
      });
  }
  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Maps'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/Maps', label: 'Maps' },
            { route: '/Maps/New', label: 'New' },
          ]}
        />
        <section>
          <div className="container-fluid">
            {!this.state.ready ? <NewMapForm onSubmit={this.submit} /> : false}
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeLocation, createMap, clear }, dispatch);

const mapStateToProps = (state) => {
  return {
    maps: state.maps,
  };
};

MapsNewContainer.propTypes = {
  maps: PropTypes.object,
  createMap: PropTypes.func,
  changeLocation: PropTypes.func,
  clear: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapsNewContainer);
