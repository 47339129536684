import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './dashboardCard.css';
import onlineStatusFunctions from '../../../utils/onlineStatusFunctions';
import { isKeyboardKey } from '../../../utils';
import { KEYBOARD_KEYS } from '../../../constants';
class DashboardCard extends Component {
  getIcon = (name) => {
    switch (name) {
      case 'battery':
        return <i className="far fa-battery-bolt"></i>;
      case 'humidity':
        return <i className="far fa-humidity"></i>;
      case 'thermometer':
        return <i className="far fa-thermometer-three-quarters"></i>;
      default:
        return null;
    }
  };

  renderIcons = () => {
    return this.props.icons.map((icon, index) => {
      return (
        <div className="d-flex flex-column p-2 text-center" key={index}>
          {this.getIcon(icon.name)}
          {icon.text}
        </div>
      );
    });
  };

  render() {
    return (
      <div
        className="dashboard-card-container m-2"
        role="button"
        tabIndex={0}
        onClick={this.props.onClick}
        onKeyDown={(e) => isKeyboardKey(e, KEYBOARD_KEYS.ENTER, this.props.onClick)}
      >
        {/* Online Status Indicator */}
        <div
          style={{
            height: '5px',
            borderRadius: '3px',
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
            backgroundImage: `linear-gradient(${onlineStatusFunctions.getHexColorByStatus(
              this.props.onlineStatus
            )} 100%, transparent 33%)`,
          }}
        />

        {/* Titles */}
        <div className="d-flex flex-column pt-2 pb-2 pl-2 mt-2 align-items-start">
          <div className="dashboard-card-title mb-2" title={this.props.title}>
            {this.props.title}
          </div>
          <div className="text-left text-wrap text-break">{this.props.subtitle}</div>
        </div>

        {/* Status Icons */}
        <div className="dashboard-card-icons">{this.renderIcons()}</div>
      </div>
    );
  }
}

DashboardCard.defaultProps = {
  icons: [],
};

DashboardCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onlineStatus: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,

  // defaults
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf(['battery', 'humidity', 'thermometer']).isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
};

export default DashboardCard;
