import axios from 'axios';
const userApi = `${process.env.REACT_APP_AUTH_API}/users`;

const getEmail = () => {
  return encodeURIComponent(localStorage.getItem('email'));
};

export const getUser = (cb) => {
  axios
    .get(`${userApi}?email=${getEmail()}`)
    .then((res) => {
      cb(res.data);
    })
    .catch((err) => console.log(err));
};

export const updateUser = (obj) => {
  return axios.patch(`${userApi}?email=${getEmail()}`, obj);
};
