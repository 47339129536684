import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Login from '../components/auth/login';
import Register from '../components/auth/register';
import Confirm from '../components/auth/confirm';
import RegistrationMessage from '../components/auth/registrationMessage';
import Forgot from '../components/auth/forgot';
import UpdatePassword from '../components/auth/updatePassword';

import '../components/auth/auth.css';

class LoginContainer extends Component {
  render() {
    return (
      <div className="page login-page">
        <div className="container d-flex align-items-center">
          <div className="form-holder">
            <div className="row">
              <div className="col-lg-6">
                <div className="logo" id="hidden-on-mobile" />
              </div>{' '}
              <div className="col-lg-6">
                {this.props.location.hash === '' ? <Login flow={'register'} /> : false}
                {this.props.location.hash === '#register' ? <Register /> : false}
                {this.props.location.hash === '#confirm' ? <Confirm /> : false}
                {this.props.location.hash === '#forgot' ? <Forgot /> : false}
                {this.props.location.hash === '#updatePassword' ? <UpdatePassword /> : false}
                {this.props.location.hash.startsWith('#confirmForgot') ? (
                  <Confirm flow={'confirmForgot'} />
                ) : (
                  false
                )}
                {this.props.location.hash.startsWith('#confirmRegistration') ? (
                  <Confirm flow={'confirmRegistration'} />
                ) : (
                  false
                )}
                {this.props.location.hash === '#registrationMessage' ? (
                  <RegistrationMessage flow={'registrationMessage'} />
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginContainer.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const mapStateToProps = (state) => {
  return {
    navigation: state.navigation,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
