import baseDataContext from './baseDataContext';
import { store } from '../../redux/store/store';
import Api from '../../config/api';

let state;
const version = 'v2';
const context = baseDataContext.context('maps', `${version}/`);

const all = (pageNumber, pageSize, sortField, sortOrder, filter) => {
  return context.all(pageNumber, pageSize, sortField, sortOrder, filter);
};

const get = (id) => {
  return context.get(id);
};

const post = function (img, name, description, keywords) {
  state = store.getState();
  let formBody = new FormData();
  formBody.set('name', name);
  formBody.set('description', description);
  formBody.set('keywords', keywords);
  formBody.append('files', img);
  return Api.post(
    `${process.env.REACT_APP_API_URL}/${version}/${state.navigation.currentApplication.appID}/maps`,
    formBody,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};

const patch = (id, patch) => {
  return context.patch(id, patch);
};

const put = (id, json) => {
  return context.put(id, json);
};

const remove = (id) => {
  return context.remove(id);
};

const upload = (img) => {};

export default {
  all,
  get,
  post,
  patch,
  remove,
  put,
  upload,
};
