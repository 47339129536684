import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CrumbsHeader from '../../components/layout/crumbsHeader';
import Details from '../../components/details/thingDetails';
import TemperatureChart from '../../components/charts/temperatureChart';
import MagnitudeChart from '../../components/charts/magnitudeChart';
import FrequencyChart from '../../components/charts/frequencyChart';
import RelativeHumidityChart from '../../components/charts/relativeHumidityChart';
import MoistureAlertChart from '../../components/charts/moistureAlertChart';
import VisibleLightChart from '../../components/charts/visibleLightChart';

import { getThing, deleteThing, clearThing } from '../../redux/actions/thingActions';
import { getPeripherals } from '../../redux/actions/peripheralActions';
import {
  getReadings,
  getLatestReading,
  getLatestReadings,
  clearReadings,
} from '../../redux/actions/readingsActions';
import NewTriggerWizard from '../../components/trigger/createTriggerWizard';
import EditTriggerWizard from '../../components/trigger/editTriggerWizard';
import {
  getTriggers,
  getTrigger,
  newTrigger,
  updateTrigger,
  deleteTrigger,
} from '../../redux/actions/triggerActions';
import mapService, { getZones, getPressure } from '../../services/mapService';
import { changeLocation } from '../../redux/actions/navigationActions';
import PressureCard from './../../components/cards/pressureCard';
import WeightChart from '../../components/charts/weightChart';
import LocationMap from '../../modules/location/components/locationMap';
import { Link } from 'react-router-dom';
import ThingDetailsCard from './../../components/cards/thingDetailsCard';
import GeoLocationMap from './../../modules/location/components/geoLocationMap';
import { readOnlyCheck, isKeyboardKey } from './../../utils';
import userServices from '../../services/userServices';
import { KEYBOARD_KEYS } from '../../constants';

let cancelInterval;

// eslint-disable-next-line no-extend-native
Date.prototype.addHours2 = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

class ThingDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTemperature: false,
      showHumidity: false,
      createNewTrigger: false,
      toggleEditTrigger: false,
      triggers: [],
      editTriggerObj: [],
      zones: [],
      pressure: [],
      showWeight: false,
      favorited: false,
    };

    this.handleDeleteThing = this.handleDeleteThing.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.editTrigger = this.editTrigger.bind(this);
    this.newTrigger = this.newTrigger.bind(this);
    this.handleNewTriggerSubmit = this.handleNewTriggerSubmit.bind(this);
    this.handleEditTriggerSubmit = this.handleEditTriggerSubmit.bind(this);
    this.handleDeleteTrigger = this.handleDeleteTrigger.bind(this);
    this.customReadingsCall = this.customReadingsCall.bind(this);
  }

  subtractHours(hours) {
    let hoursBefore = new Date();
    hoursBefore.setHours(hoursBefore.getHours() - hours);
    return hoursBefore;
  }

  componentDidMount() {
    this.props.getThing(this.props.match.params.thingId);
    localStorage.setItem('searchLastUrl', window.location.pathname);
    userServices.getFavorites().then((res) => {
      // scan for match
      if (typeof res.data === 'object' && res.data.length > 0) {
        let x = res.data.filter((val) => val.itemId === this.props.match.params.thingId);
        if (x.length > 0) {
          this.setState({ favorited: true });
        } else {
          this.setState({ favorited: false });
        }
      } else {
        this.setState({ favorited: false });
      }
    });

    let repeats = (count) => {
      this.props.getLatestReadings(this.props.match.params.thingId, 'batterylevel', 1);
      this.props.getLatestReadings(this.props.match.params.thingId, 'magnitudes', 1);
      this.props.getLatestReadings(this.props.match.params.thingId, 'weight', 10);
      this.props.getReadings(
        this.props.match.params.thingId,
        'magnitude',
        this.subtractHours(12).toISOString()
      );
      this.props.getReadings(
        this.props.match.params.thingId,
        'externaltemperature',
        this.subtractHours(12).toISOString()
      );
      this.props.getReadings(
        this.props.match.params.thingId,
        'relativehumidity',
        this.subtractHours(12).toISOString()
      );
      this.props.getReadings(
        this.props.match.params.thingId,
        'moisturealert',
        this.subtractHours(12).toISOString()
      );
      this.props.getReadings(
        this.props.match.params.thingId,
        'visiblelight',
        this.subtractHours(12).toISOString()
      );

      cancelInterval = setTimeout(
        () => {
          repeats(count + 1);
        },
        count > 60 ? 600000 : 15000
      ); // after 60 refresh calls, change refresh rate from 15 seconds to 10 minutes
    };

    repeats(1);

    this.props.getTriggers(this.props.match.params.thingId);

    getZones()
      .then((res) => {
        this.setState({ zones: res.data });
      })
      .catch((err) => {
        throw err;
      });

    getPressure(this.props.match.params.thingId).then((res) =>
      this.setState({ pressure: res.data })
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.thing &&
      this.props.thing.lastLocationCoordinates &&
      this.props.thing.lastLocationCoordinates.type === 1
    ) {
      return false;
    }
    if (this.props.thing.id !== prevProps.thing.id && this.props.thing.lastLocationMapId != null) {
      mapService.getNoTrack(this.props.thing.lastLocationMapId).then((x) => {
        this.setState({ map: x.data });
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(cancelInterval);
    this.props.clearReadings();
    this.props.clearThing();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps || !nextProps.thing || !nextProps.peripherals) {
      return null;
    }

    let stateUpdate = {
      showTemperature: false,
      showHumidity: false,
      showPressure: false,
      showWeight: false,
      showMagnitude: false,
      showFrequency: false,
      showMoistureAlert: false,
      showVisibleLight: false,
    };

    if (nextProps.peripherals && nextProps.peripherals[0]) {
      stateUpdate.showPressure = false;

      for (let peripheral of nextProps.peripherals) {
        if (peripheral && peripheral.reading.barometricPressure) {
          stateUpdate.showPressure = true;
        }
      }
    }

    if (nextProps.readings) {
      if (nextProps.readings.externaltemperature && nextProps.readings.externaltemperature.length) {
        stateUpdate.showTemperature = true;
      }

      if (nextProps.readings.relativehumidity && nextProps.readings.relativehumidity.length) {
        stateUpdate.showHumidity = true;
      }

      if (nextProps.readings.weight && nextProps.readings.weight.length) {
        stateUpdate.showWeight = true;
      }

      if (nextProps.readings.magnitude && nextProps.readings.magnitude.length) {
        stateUpdate.showMagnitude = true;
      }

      if (nextProps.readings.magnitudes && nextProps.readings.magnitudes.length) {
        stateUpdate.showFrequency = true;
      }

      if (nextProps.readings.moisturealert && nextProps.readings.moisturealert.length) {
        stateUpdate.showMoistureAlert = true;
      }

      if (nextProps.readings.visiblelight && nextProps.readings.visiblelight.length) {
        stateUpdate.showVisibleLight = true;
      }
    }

    return stateUpdate;
  }

  handleDeleteThing() {
    this.props.deleteThing(this.props.thing.id);
    this.props.changeLocation(`/things`);
  }

  handleClose() {
    this.setState({ createNewTrigger: false, edit: {}, toggleEditTrigger: false });
  }

  handleDeleteTrigger() {
    if (this.state.editTriggerObj) {
      // DELETE TRIGGER
      this.props.deleteTrigger(this.props.match.params.thingId, this.state.editTriggerObj.id);
      this.setState({
        createNewTrigger: false,
        editTriggerObj: {},
        toggleEditTrigger: false,
      });
    } else {
      this.setState({
        createNewTrigger: false,
        editTriggerObj: {},
        toggleEditTrigger: false,
      });
      return;
    }
  }

  handleNewTriggerSubmit(trigger) {
    this.props.newTrigger(this.props.match.params.thingId, trigger);
    this.setState({ createNewTrigger: false, editTriggerObj: {}, toggleEditTrigger: false });
  }

  handleEditTriggerSubmit(trigger) {
    this.props.updateTrigger(
      this.props.match.params.thingId,
      trigger,
      this.state.editTriggerObj.id
    );
    this.setState({ createNewTrigger: false, editTriggerObj: {}, toggleEditTrigger: false });
  }

  editTrigger(trigger) {
    if (this.state.toggleEditTrigger === false) {
      if (trigger) {
        this.setState({
          toggleEditTrigger: true,
          createNewTrigger: false,
          editTriggerObj: trigger,
        });
      }
    } else {
      this.setState({
        toggleEditTrigger: false,
        createNewTrigger: false,
        editTriggerObj: {},
      });
    }
  }

  newTrigger() {
    if (this.state.createNewTrigger === false) {
      this.setState({
        createNewTrigger: true,
        toggleEditTrigger: false,
      });
    } else {
      this.setState({ createNewTrigger: false, toggleEditTrigger: false });
    }
  }

  customReadingsCall(type, start, end) {
    clearTimeout(cancelInterval);
    if (end) {
      let today = new Date();
      let date = new Date(end);
      if (date.getTime() > today.getTime()) {
        if (type === 'externaltemperature') {
          this.props.getReadings(
            this.props.match.params.thingId,
            'externaltemperature',
            this.subtractHours(start).toISOString(),
            end
          );
        }
        if (type === 'relativehumidity') {
          this.props.getReadings(
            this.props.match.params.thingId,
            'relativehumidity',
            this.subtractHours(start).toISOString(),
            end
          );
        }
        if (type === 'weight') {
          this.props.getReadings(
            this.props.match.params.thingId,
            'weight',
            this.subtractHours(start).toISOString(),
            end
          );
        }
        if (type === 'magnitude') {
          this.props.getReadings(
            this.props.match.params.thingId,
            'magnitude',
            this.subtractHours(start).toISOString(),
            end
          );
        }
        if (type === 'moisturealert') {
          this.props.getReadings(
            this.props.match.params.thingId,
            'moisturealert',
            this.subtractHours(start).toISOString(),
            end
          );
        }
        if (type === 'visiblelight') {
          this.props.getReadings(
            this.props.match.params.thingId,
            'visiblelight',
            this.subtractHours(start).toISOString(),
            end
          );
        }
      }
      if (type === 'externaltemperature') {
        let final = new Date(end);

        final.addHours2(start);

        this.props.getReadings(
          this.props.match.params.thingId,
          'externaltemperature',
          end.toISOString(),
          final.toISOString()
        );
      }
      if (type === 'relativehumidity') {
        let final = new Date(end);

        final.addHours2(start);
        this.props.getReadings(
          this.props.match.params.thingId,
          'relativehumidity',
          end.toISOString(),
          final.toISOString()
        );
      }
      if (type === 'weight') {
        let final = new Date(end);

        final.addHours2(start);
        this.props.getReadings(
          this.props.match.params.thingId,
          'weight',
          end.toISOString(),
          final.toISOString()
        );
      }
      if (type === 'magnitude') {
        let final = new Date(end);
        final.addHours2(start);
        this.props.getReadings(
          this.props.match.params.thingId,
          'magnitude',
          end.toISOString(),
          final.toISOString()
        );
      }
      if (type === 'moisturealert') {
        let final = new Date(end);
        final.addHours2(start);
        this.props.getReadings(
          this.props.match.params.thingId,
          'moisturealert',
          end.toISOString(),
          final.toISOString()
        );
      }
      if (type === 'visiblelight') {
        let final = new Date(end);
        final.addHours2(start);
        this.props.getReadings(
          this.props.match.params.thingId,
          'visiblelight',
          end.toISOString(),
          final.toISOString()
        );
      }
    } else {
      if (type === 'externaltemperature') {
        this.props.getReadings(
          this.props.match.params.thingId,
          'externaltemperature',
          this.subtractHours(start).toISOString(),
          end
        );
      }
      if (type === 'relativehumidity') {
        this.props.getReadings(
          this.props.match.params.thingId,
          'relativehumidity',
          this.subtractHours(start).toISOString(),
          end
        );
      }
      if (type === 'weight') {
        this.props.getReadings(
          this.props.match.params.thingId,
          'weight',
          this.subtractHours(start).toISOString(),
          end
        );
      }
      if (type === 'magnitude') {
        this.props.getReadings(
          this.props.match.params.thingId,
          'magnitude',
          this.subtractHours(start).toISOString(),
          end
        );
      }
      if (type === 'moisturealert') {
        this.props.getReadings(
          this.props.match.params.thingId,
          'moisturealert',
          this.subtractHours(start).toISOString(),
          end
        );
      }
      if (type === 'visiblelight') {
        this.props.getReadings(
          this.props.match.params.thingId,
          'visiblelight',
          this.subtractHours(start).toISOString(),
          end
        );
      }
    }
  }

  favorite() {
    this.setState({ favorited: !this.state.favorited });
  }

  render() {
    if (!this.props.thing) {
      return <div />;
    }
    return (
      <div>
        <CrumbsHeader
          header={'Things'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/things', label: 'Things' },
            { route: '', label: this.props.thing.name },
          ]}
        />

        <section>
          <div className="container-fluid">
            {readOnlyCheck() ? null : (
              <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div
                  className="btn-group"
                  style={{
                    marginBottom: '10px',
                    marginLeft: '15px',
                    marginRight: '15px',
                  }}
                >
                  {this.state.favorited ? (
                    <button
                      type="button"
                      title="Remove favorite thing"
                      style={{ color: '#FFA500', fontSize: '2em' }}
                      onClick={(e) => {
                        e.preventDefault();
                        userServices.removeFavorite(this.props.thing.id, 'thing');
                        this.favorite();
                      }}
                    >
                      <i className="fas fa-star"></i>
                    </button>
                  ) : (
                    <button
                      type="button"
                      title="Favorite thing"
                      style={{ fontSize: '2em' }}
                      onClick={(e) => {
                        e.preventDefault();
                        userServices.newFavorite(this.props.thing.id, 'thing');
                        this.favorite();
                      }}
                    >
                      <i className="far fa-star"></i>
                    </button>
                  )}
                  <div className="dropdown">
                    <button
                      type="button"
                      id="closeCard1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ fontSize: '2em' }}
                    >
                      <i className="fas fa-ellipsis-h"></i>
                    </button>
                    <div
                      aria-labelledby="closeCard1"
                      className="dropdown-menu dropdown-menu-right has-shadow"
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'baseline',
                          paddingLeft: '1em',
                          fontSize: '1.25rem',
                        }}
                      >
                        <Link
                          title="Edit thing"
                          to={`/things/${this.props.thing.id}/edit`}
                          style={{
                            textDecoration: 'none',
                            color: 'black',
                          }}
                        >
                          <i className="fas fa-pencil"></i>
                          <span> Edit</span>
                        </Link>

                        <div
                          type="button"
                          role="button"
                          tabIndex={0}
                          title="Deactivate thing"
                          style={{
                            textDecoration: 'none',
                            color: '#F32013',
                            fontSize: '1.25rem',
                          }}
                          onClick={() => {
                            if (window.confirm('Are you sure? \nThing will now be archived')) {
                              this.handleDeleteThing(this.props.thing.id);
                            }
                          }}
                          onKeyDown={(event) =>
                            isKeyboardKey(event, KEYBOARD_KEYS.ENTER, () => {
                              if (window.confirm('Are you sure? \nThing will now be archived')) {
                                this.handleDeleteThing(this.props.thing.id);
                              }
                            })
                          }
                        >
                          <i className="fa fa-trash" />
                          <span> Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-12">
                <ThingDetailsCard
                  thing={this.props.thing ? this.props.thing : ''}
                  temperature={
                    this.props.readings
                      ? this.props.readings.externaltemperature
                        ? this.props.readings.externaltemperature
                        : ''
                      : ''
                  }
                  humidity={
                    this.props.readings
                      ? this.props.readings.relativehumidity
                        ? this.props.readings.relativehumidity
                        : ''
                      : ''
                  }
                  battery={
                    this.props.readings
                      ? this.props.readings.batterylevel
                        ? this.props.readings.batterylevel[0]
                          ? this.props.readings.batterylevel[0].mean
                            ? Math.round(this.props.readings.batterylevel[0].mean)
                            : ''
                          : ''
                        : ''
                      : ''
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Details
                  thing={this.props.thing}
                  triggers={this.props.triggers}
                  editUrl={`/things/${this.props.thing.id}/edit`}
                  toggleTriggerEdit={this.editTrigger}
                  toggleTriggerAdd={this.newTrigger}
                  deleteThing={this.handleDeleteThing}
                />
              </div>
              <div className="col-lg-6">
                {this.state.map ||
                (this.props.thing &&
                  this.props.thing.lastLocationCoordinates &&
                  this.props.thing.lastLocationCoordinates.type === 1) ? (
                  <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <h3 className="card-heading">Last Location</h3>
                      <div className="btn-group">
                        <Link
                          className="btn btn-outline-dark"
                          title="View Breadcrumbs"
                          to={`/things/${this.props.thing.id}/maps`}
                        >
                          <i className="fa fa-history" />
                        </Link>
                      </div>
                    </div>
                    <div className="card-body h-100">
                      {this.props.thing ? (
                        this.props.thing.lastLocationCoordinates ? (
                          this.props.thing.lastLocationCoordinates.type ? (
                            <GeoLocationMap
                              map={this.state.map}
                              marker={this.props.thing.lastLocationCoordinates}
                              zoom={false}
                              height="350px"
                            />
                          ) : (
                            <LocationMap
                              map={this.state.map}
                              marker={this.props.thing.lastLocationCoordinates}
                              zoom={false}
                              height="350px"
                            />
                          )
                        ) : (
                          false
                        )
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>
            {this.state.createNewTrigger === true ? (
              <div className="row" style={{ paddingTop: 20 }}>
                <NewTriggerWizard
                  style={{ height: '100%' }}
                  onSubmit={this.handleNewTriggerSubmit}
                  close={this.handleClose}
                  action={'Create Trigger'}
                  thing={this.props.thing}
                  zones={this.state.zones}
                />
              </div>
            ) : (
              false
            )}
            {this.state.toggleEditTrigger === true ? (
              <div className="row" style={{ paddingTop: 20 }}>
                <EditTriggerWizard
                  style={{ height: '100%' }}
                  onSubmit={this.handleEditTriggerSubmit}
                  close={this.handleClose}
                  action={'Edit Trigger'}
                  onDelete={this.handleDeleteTrigger}
                  thing={this.props.thing}
                  initialValues={this.state.editTriggerObj}
                  zones={this.state.zones}
                />
              </div>
            ) : (
              false
            )}
            <div className="row">
              {this.state.showTemperature && (
                <TemperatureChart
                  style={{ height: '100%' }}
                  readings={this.props.readings.externaltemperature}
                  changeTime={this.customReadingsCall}
                />
              )}
              {this.state.showHumidity && (
                <RelativeHumidityChart
                  style={{ height: '100%' }}
                  readings={this.props.readings.relativehumidity}
                  changeTime={this.customReadingsCall}
                />
              )}
              {this.state.pressure && (
                <PressureCard
                  style={{ height: '100%' }}
                  data={this.state.pressure}
                  changeTime={this.customReadingsCall}
                />
              )}
              {this.state.showWeight && (
                <WeightChart
                  style={{ height: '100%' }}
                  readings={this.props.readings.weight}
                  changeTime={this.customReadingsCall}
                />
              )}
              {this.state.showMagnitude && (
                <MagnitudeChart
                  style={{ height: '100%' }}
                  readings={this.props.readings.magnitude}
                  changeTime={this.customReadingsCall}
                />
              )}
              {this.state.showFrequency && (
                <FrequencyChart
                  style={{ height: '100%' }}
                  readings={this.props.readings.magnitudes}
                  changeTime={this.customReadingsCall}
                />
              )}
              {this.state.showMoistureAlert && (
                <MoistureAlertChart
                  style={{ height: '100%' }}
                  readings={this.props.readings.moisturealert}
                  changeTime={this.customReadingsCall}
                />
              )}
              {this.state.showVisibleLight && (
                <VisibleLightChart
                  style={{ height: '100%' }}
                  readings={this.props.readings.visiblelight}
                  changeTime={this.customReadingsCall}
                />
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getThing,
      getPeripherals,
      getReadings,
      getLatestReading,
      getLatestReadings,
      deleteThing,
      changeLocation,
      getTrigger,
      getTriggers,
      updateTrigger,
      newTrigger,
      deleteTrigger,
      getPressure,
      clearReadings,
      clearThing,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    thing: state.things.currentThing,
    peripherals: state.peripherals.items,
    reading: state.readings.reading,
    readings: state.readings.readings,
    isLoading: state.things.isLoading,
    triggers: state.trigger.thingTriggers,
    triggerDetails: state.trigger.triggerDetails,
  };
};

ThingDetailsContainer.propTypes = {
  isLoading: PropTypes.bool,
  thing: PropTypes.object,
  peripherals: PropTypes.array,
  getThing: PropTypes.func,
  getPeripherals: PropTypes.func,
  getReadings: PropTypes.func,
  getLatestReading: PropTypes.func,
  getLatestReadings: PropTypes.func,
  handleEditDetails: PropTypes.func,
  match: PropTypes.object,
  putTrigger: PropTypes.func,
  deleteThing: PropTypes.func,
  close: PropTypes.func,
  changeLocation: PropTypes.func,
  getTrigger: PropTypes.func,
  getTriggers: PropTypes.func,
  newTrigger: PropTypes.func,
  updateTrigger: PropTypes.func,
  deleteTrigger: PropTypes.func,
  triggers: PropTypes.array,
  triggerDetails: PropTypes.object,
  getZones: PropTypes.func,
  getPressure: PropTypes.func,
  clearThing: PropTypes.func,
  clearReadings: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThingDetailsContainer);
