import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import moment from 'moment';
import _ from 'lodash';

import './grid.css';

class TempAndHumidityGrid extends Component {
  render() {
    const columns = [
      {
        accessor: 'thingName',
        Header: 'Asset Name',
      },
      {
        accessor: 'triggerName',
        Header: 'Trigger Name',
      },
      {
        accessor: 'notificationTime',
        Header: 'Time Triggered',
        Cell: (row) => {
          if (row.value) {
            let x = moment(row.value).format('LLL');
            return <span className="pull-right">{x}</span>;
          } else {
            return <span className="pull-right">--</span>;
          }
        },
      },
      {
        accessor: 'recipientName',
        Header: 'Recipient',
      },
      {
        accessor: 'responseTime',
        Header: 'Response Time',
        Cell: (row) => {
          if (row.value) {
            let x = moment(row.value).format('LLL');
            return <span className="pull-right">{x}</span>;
          } else {
            return <span className="pull-right">--</span>;
          }
        },
      },
      {
        accessor: 'reportedValue',
        Header: 'Triggered Value',
        headerStyle: { textAlign: 'right' },
        Cell: (row) => {
          if (row.value) {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                {row.value}
              </span>
            );
          } else {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                --
              </span>
            );
          }
        },
      },
      {
        accessor: 'criteria',
        Header: 'Expected Value',
        headerStyle: { textAlign: 'right', paddingRight: '15px' },
        Cell: (row) => {
          if (row.value) {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                {row.value}
              </span>
            );
          } else {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                --
              </span>
            );
          }
        },
      },
      {
        accessor: 'comment',
        Header: 'Comment',
        headerStyle: { textAlign: 'right', paddingRight: '15px' },
        Cell: (row) => {
          if (row.value) {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                {row.value}
              </span>
            );
          } else {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                --
              </span>
            );
          }
        },
      },
    ];

    return (
      <div>
        <ReactTable
          data={
            this.props.data && _.isArray(this.props.data) && this.props.data.length > 0
              ? this.props.data
              : []
          }
          columns={columns}
          defaultPageSize={20}
          loading={this.props.isLoading} // Display the loading overlay when we need it
          showPageSizeOptions={false}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

TempAndHumidityGrid.propTypes = {
  data: PropTypes.array,
  onTableChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default TempAndHumidityGrid;
