import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { TOGGLE_NAVIGATION } from '../../redux';

const styles = {
  btn: {
    backgroundColor: 'transparent',
    alignSelf: 'flex-start',
  },
};

function NavToggle({ navigation, toggleNavigation }) {
  return (
    <button style={styles.btn} onClick={toggleNavigation} title="navigation-toggle">
      <i
        style={{ color: navigation.isOpen ? 'white' : 'black' }}
        className="fal fa-bars fa-lg ml-1"
      />
    </button>
  );
}

const mapStateToProps = (state) => {
  return {
    navigation: state.navigation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNavigation: () => dispatch({ type: TOGGLE_NAVIGATION }),
  };
};

NavToggle.propTypes = {
  navigation: PropTypes.object.isRequired,
  toggleNavigation: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavToggle);
