import * as types from '../types';
import triggerService from '../../services/triggerService';
import toast from '../../components/utils/toast';

export const startGetTriggers = () => {
  return {
    type: types.TRIGGER_THING_GET_STARTED,
  };
};

export function getTriggers(thingId) {
  return function (dispatch) {
    dispatch(startGetTriggers());
    triggerService
      .getAll(thingId)
      .then((res) => {
        dispatch({ type: types.TRIGGER_THING_GET_COMPLETED, triggers: res.data });
        return res.data;
      })
      .catch(() => {
        dispatch({ type: types.TRIGGER_THING_GET_FAILED });
      });
  };
}

export const startGetApplicationTriggers = () => {
  return {
    type: types.TRIGGER_APPLICATION_GET_STARTED,
  };
};

export function getApplicationTriggers() {
  return function (dispatch) {
    dispatch(startGetApplicationTriggers());
    triggerService
      .getAllApplicationTriggers()
      .then((res) => {
        dispatch({
          type: types.TRIGGER_APPLICATION_GET_COMPLETED,
          triggers: res.data,
        });
        return res.data;
      })
      .catch(() => {
        dispatch({ type: types.TRIGGER_APPLICATION_GET_FAILED });
      });
  };
}

export const startGetTrigger = () => {
  return {
    type: types.TRIGGER_GET_STARTED,
  };
};

export function getTrigger(thingId, triggerId) {
  return function (dispatch) {
    dispatch(startGetTrigger());
    triggerService
      .getTrigger(thingId, triggerId)
      .then((res) => {
        dispatch({
          type: types.TRIGGER_GET_COMPLETED,
          trigger: res.data,
        });
        return res.data;
      })
      .catch(() => {
        dispatch({ type: types.TRIGGER_GET_FAILED });
      });
  };
}

export const startNewTrigger = () => {
  return {
    type: types.TRIGGER_NEW_STARTED,
  };
};

export function newTrigger(thingId, trigger) {
  return function (dispatch) {
    dispatch(startNewTrigger());
    triggerService
      .newTrigger(thingId, trigger)
      .then((res) => {
        toast.success();
        dispatch({
          type: types.TRIGGER_NEW_COMPLETED,
        });
        dispatch(getTriggers(thingId));
      })
      .catch(() => {
        dispatch({ type: types.TRIGGER_NEW_FAILED });
      });
  };
}

export const startUpdateTrigger = () => {
  return {
    type: types.TRIGGER_UPDATE_STARTED,
  };
};

export function updateTrigger(thingId, trigger, triggerId) {
  return function (dispatch) {
    dispatch(startUpdateTrigger());
    triggerService
      .updateTrigger(thingId, trigger, triggerId)
      .then(() => {
        toast.success();
        dispatch({
          type: types.TRIGGER_UPDATE_COMPLETED,
        });
        dispatch(getTriggers(thingId));
      })
      .catch(() => {
        dispatch({ type: types.TRIGGER_UPDATE_FAILED });
      });
  };
}

export const startDeleteTrigger = () => {
  return {
    type: types.TRIGGER_DELETE_STARTED,
  };
};

export function deleteTrigger(thingId, triggerId) {
  return function (dispatch) {
    dispatch(startDeleteTrigger());
    triggerService
      .deleteTrigger(thingId, triggerId)
      .then(() => {
        toast.success();
        dispatch({
          type: types.TRIGGER_DELETE_COMPLETED,
        });
        dispatch(getTriggers(thingId));
      })
      .catch(() => {
        dispatch({ type: types.TRIGGER_DELETE_FAILED });
      });
  };
}
