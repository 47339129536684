import { UPDATE_SEARCH_FILTER, UPDATE_SEARCH_STRING } from '../types';

const search = (
  state = {
    searchString: '',
    searchFilter: '',
  },
  action
) => {
  switch (action.type) {
    case UPDATE_SEARCH_STRING:
      state = { ...state, searchString: action.payload };
      return state;

    case UPDATE_SEARCH_FILTER:
      state = { ...state, searchFilter: action.payload };
      return state;

    default:
      return state;
  }
};

export default search;
