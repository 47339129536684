import Api from '../config/api';
import { store } from '../redux/store/store';
import swal from 'sweetalert';

let state;

// returns all maps in an application
const getAll = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/maps`
  );
};

// creates a new map
const create = (map) => {
  state = store.getState();
  return Api.post(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/maps`,
    map
  );
};

// gets a specified map
const get = (id) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/v2/${state.navigation.currentApplication.appID}/maps/${id}`
  );
};

const getNoTrack = (id) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/v2/${state.navigation.currentApplication.appID}/maps/${id}?trackview=false`
  );
};

const locations = (id) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/maps/${id}/things`
  );
};

// updates a specified map
const update = (id, map) => {
  state = store.getState();
  return Api.put(
    `${process.env.REACT_APP_API_URL}/v2/${state.navigation.currentApplication.appID}/maps/${id}`,
    map
  );
};

// deletes a specified map
const remove = (id) => {
  state = store.getState();
  return Api.delete(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/maps/${id}`
  );
};

// get a preview of a specified map
const preview = (id) => {
  state = store.getState();
  Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/maps/${id}`
  )
    .then((val) => {
      return Api.post(
        `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/maps/preview`,
        val.data
      );
    })
    .catch((err) => {
      swal({
        title: 'An error occurred',
        text: 'We could not load the map image',
        icon: 'error',
        button: 'Close',
      });
    });
};

// get a preview of a specified map
const upload = (img) => {
  state = store.getState();
  return Api.post(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/mapupload`,
    img,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};

export const getZones = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/zones`
  );
};

export const getPressure = (thing) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${thing}/readings`
  );
};

export default {
  getAll,
  get,
  locations,
  update,
  create,
  remove,
  preview,
  upload,
  getPressure,
  getNoTrack,
};
