import axios from 'axios';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

import applicationService from '../../services/applicationService';

const cognitoApi = `${process.env.REACT_APP_AUTH_API}/authentication`;
const cognitoApiRegister = `${process.env.REACT_APP_AUTH_API}/registration`;

moment().format();

const login = (email, password, sender) => {
  email = email.toLowerCase();
  axios
    .post(`${cognitoApi}/login`, {
      userName: email,
      password,
    })
    .then(
      async (res) => {
        if (res.data.authenticated === true) {
          axios.defaults.headers.Authorization = 'Bearer ' + res.data.accessToken;
          localStorage.setItem('email', res.data.user.email);
          localStorage.setItem('applications', res.data.user.applications);
          localStorage.setItem('token', 'Bearer ' + res.data.accessToken);
          localStorage.setItem('tokenExpiration', res.data.tokenExpiration);
          localStorage.setItem('refreshTokenExpiration', res.data.refreshTokenExpiration);
          localStorage.setItem('refreshToken', res.data.refreshToken);
          sender.onLogin(null, res);

          // store app objects
          applicationService.getApplications();
        }
      },
      (reason) => {
        throw new Error(reason.response.data.message);
      }
    )
    .catch((res) => {
      sender.onLogin(res);
    });
};

const refreshSession = () => {
  let decoded = jwtDecode(localStorage.getItem('token'));
  let now = moment().unix();
  if (now > decoded.exp) {
    let refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      axios
        .post(`${cognitoApi}/refresh`, {
          token: refreshToken,
        })
        .then((res) => {
          axios.defaults.headers.Authorization = 'Bearer ' + res.data.accessToken;
          localStorage.setItem('token', 'Bearer ' + res.data.accessToken);
          localStorage.setItem('tokenExpiration', res.data.tokenExpiration);
          localStorage.setItem('refreshToken', res.data.refreshToken);
          localStorage.setItem('refreshTokenExpiration', res.data.refreshTokenExpiration);
          return 'Bearer ' + res.data.accessToken;
        })
        .catch(() => {
          localStorage.clear();
          logout();
          return false;
        });
    } else if (!refreshToken) {
      window.location.assign('/login');
    }
  } else {
    return false;
  }
};

const logout = () => {
  localStorage.clear();
  window.location.href = '/login';
  return {
    type: 'USER_LOGOUT',
  };
};

const register = (email, password, company, phone, sender) => {
  email = email.toLowerCase();
  axios
    .post(`${cognitoApiRegister}/register`, {
      password,
      userName: email,
      email,
      company,
      // avatar: '',
      // firstName: '',
      // lastName: '',
      // preferredName: '',
      // passwordHint: '',
      emailAddresses: [
        {
          name: 'Primary Email',
          emailAddress: email,
          receiveEmailNotifications: true,
        },
      ],
      phoneNumbers: [
        {
          name: 'Primary Phone Number',
          phoneNumber: phone,
          receiveSMSNotifications: true,
        },
      ],
      // addresses: [],
      // preferences: []
    })
    .then((res) => {
      sender.onRegister(null, res);
    })
    .catch((err) => {
      sender.onRegister(err, null);
    });
};

const confirmUser = (userId, token, sender) => {
  axios
    .post(`${cognitoApi}/confirmRegistration/${userId}?confirmationtoken=${token}`)
    .then((res) => {
      sender.onConfirm(null, res);
    })
    .catch((err) => {
      sender.onConfirm(err, null);
    });
};

const forgotPassword = (email) => {
  email = email.toLowerCase();
  return axios.post(`${cognitoApi}/forgotpassword`, {
    email,
  });
};

const confirmPasswordReset = (email, confirmationCode, password) => {
  email = email.toLowerCase();
  return axios.post(`${cognitoApi}/confirmforgot`, {
    email,
    password,
    confirmationCode,
  });
};

const updatePassword = (email, old, password, sender) => {
  email = email.toLowerCase();
  return axios
    .post(`${cognitoApi}/changepassword`, {
      accessToken: localStorage.getItem('token'),
      email: email,
      oldPassword: old,
      newPassword: password,
    })
    .then((res) => {
      sender.onUpdate(null, res);
    })
    .catch((err) => {
      sender.onUpdate(err, null);
    });
};

export default {
  refreshSession,
  login,
  logout,
  register,
  confirmUser,
  forgotPassword,
  confirmPasswordReset,
  updatePassword,
};
