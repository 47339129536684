import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import OnlineStatusCell from './onlineStatus';
import Pager from '../pager';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import userServices from '../../services/userServices';
// TODO: API NO LONGER WORKING / EXISTS IN V2?

const scrollStyle = {
  maxHeight: '60vh',
  overflow: 'auto',
};

const headerStyle = {
  cursor: 'pointer',
};

const rowStyle = {
  cursor: 'pointer',
};
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class ThingsGrid extends Component {
  constructor(props) {
    super(props);
    this.onlineStatus = this.onlineStatus.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateParent = this.updateParent.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.state = {
      limit: props.limit,
      page: props.page,
      sortOrder: props.sortOrder,
      sortField: props.sortField,
      filter: props.filter,
      onlineStatus: 1,
      items: [],
      changed: false,
    };
  }

  componentDidMount() {
    if (this.props.items) {
      this.setState({ items: this.props.items });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.items && nextProps.items.length > 0 && prevState.items.length === 0) {
      return {
        items: nextProps.items,
      };
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(this.state.items, result.source.index, result.destination.index);

    this.setState({
      items,
      changed: true,
    });

    // TODO: API CALL WITH UPDATE ITEMS LIST TO UPDATE FAVORITES SORT
    // userServices.updateFavorites(items);
  }

  handleSort(columnName) {
    let { sortField, sortOrder } = this.state;

    if (this.isSortedBy(columnName)) {
      sortOrder = sortOrder === 1 ? -1 : 1;
    } else {
      sortField = columnName;
      sortOrder = 1;
    }

    this.setState({ sortField: sortField, sortOrder: sortOrder }, () => this.updateParent());
  }

  handleChange(name, event) {
    if (this.state[name] !== event.target.value) {
      this.setState(
        {
          [name]: event.target.value,
        },
        () => this.updateParent()
      );
    }
  }

  handlePageChange(pageNumber) {
    let { page } = this.state;

    if (page !== pageNumber) {
      this.setState({ page: pageNumber }, () => this.updateParent());
    }
  }

  updateParent() {
    const { sortField, sortOrder, page, limit, filter } = this.state;
    if (page.length === 0 || limit.length === 0) {
      return;
    }
    this.props.onQueryChanged(page, limit, sortField, sortOrder, filter);
  }

  sortIcon(columnName) {
    let { sortDirection } = this.state;
    if (this.isSortedBy(columnName)) {
      return sortDirection === 1 ? (
        <i className="fa fa-caret-up" />
      ) : (
        <i className="fa fa-caret-down" />
      );
    }
  }

  isSortedBy(columnName) {
    let { sortField } = this.state;
    return sortField === columnName;
  }

  triggerStyle(hasTrigger) {
    if (hasTrigger) {
      return ' text-danger ';
    }
    return ' ';
  }

  readingCell(reading, icon) {
    if (reading.value) {
      return (
        <div>
          {Math.round(reading.value)}
          <i className={'ml-2 fa ' + icon + ' ' + this.triggerStyle(reading.activeTrigger)} />
        </div>
      );
    }

    return <div></div>;
  }

  batteryIcon(battery) {
    if (battery > 80) {
      return 'fa-battery-full';
    }
    if (battery > 60) {
      return 'fa-battery-three-quarters';
    }
    if (battery > 30) {
      return 'fa-battery-half';
    }
    if (battery > 10) {
      return 'fa-battery-quarter';
    }
    return 'fa-battery-empty';
  }

  onlineStatus(lastPing) {
    if (lastPing) {
      let now = new Date();
      let ping = new Date(lastPing);
      if (now - ping > 5 * 60 * 1000) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return 2;
    }
  }

  render() {
    console.log(this.state.items);
    return (
      <div>
        <div className="d-flex justify-content-between form-inline pl-4 pr-4">
          <h3>Dashboard View </h3>
          <div>
            <span>Viewing </span>
            <input
              style={{ width: '40px', textAlign: 'center' }}
              className="form-control form-control-sm"
              value={this.props.items.length}
              onChange={(e) => this.handleChange('limit', e)}
            />
            <span> of {this.props.items.length} favorites.</span>
          </div>
          <Pager page={1 + ''} pageCount={1 + ''} onChange={this.handlePageChange} />
        </div>

        <div style={scrollStyle}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <table className="table table-sm table-hover" ref={provided.innerRef}>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        onClick={() => this.handleSort('onlineStatus')}
                        style={headerStyle}
                        className={this.isSortedBy('onlineStatus') ? 'text-primary' : ''}
                      />
                      <th
                        scope="col"
                        onClick={() => this.handleSort('name')}
                        style={headerStyle}
                        className={this.isSortedBy('name') ? 'text-primary' : ''}
                      >
                        Name {this.sortIcon('name')}
                      </th>
                      <th
                        scope="col"
                        onClick={() => this.handleSort('type')}
                        style={headerStyle}
                        className={this.isSortedBy('type') ? 'text-primary' : ''}
                      >
                        Device Type {this.sortIcon('type')}
                      </th>
                      <th
                        scope="col"
                        onClick={() => this.handleSort('deviceId')}
                        style={headerStyle}
                        className={this.isSortedBy('deviceId') ? 'text-primary' : ''}
                      >
                        Device ID {this.sortIcon('deviceId')}
                      </th>
                      <th
                        scope="col"
                        onClick={() => this.handleSort('lastPing')}
                        style={headerStyle}
                        className={this.isSortedBy('lastPing') ? 'text-primary' : ''}
                      >
                        Last Checkin {this.sortIcon('lastPing')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.length > 0
                      ? this.state.items.map((item, index) => {
                          if (index > 7) {
                            return null;
                          }
                          let oS = this.onlineStatus(item.data.lastPing);
                          console.log(item);
                          return (
                            <Draggable
                              key={`${item.type}-${item.data.id}`}
                              draggableId={`${item.type}-${item.data.id}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <tr
                                  key={`${item.type}-${item.data.id}`}
                                  onClick={() => this.props.onRowSelect(item.data.id, item.type)}
                                  style={rowStyle}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <td style={{ width: '80px' }}>
                                    <div className="pl-4">
                                      <OnlineStatusCell onlineStatus={oS} />
                                    </div>
                                  </td>
                                  <td style={{ width: '180px' }}>{item.data.name}</td>
                                  <td style={{ width: '180px' }}>{item.type.toUpperCase()}</td>
                                  <td style={{ width: '180px' }}>{item.data.deviceId}</td>
                                  <td style={{ width: '180px' }}>
                                    {moment(item.data.lastPing).format('L LTS')}
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          );
                        })
                      : false}
                  </tbody>
                  {this.state.items && this.state.items.length > 7 ? (
                    <h3
                      style={{
                        padding: '5px',
                        paddingLeft: '25px',
                        paddingTop: '10px',
                      }}
                    >
                      More{' '}
                    </h3>
                  ) : (
                    <div></div>
                  )}
                  <tbody
                    style={{
                      borderTop: '4px solid #696969',
                    }}
                  >
                    {this.state.items.length > 7
                      ? this.state.items.map((item, index) => {
                          if (index < 8) {
                            return null;
                          }
                          let oS = this.onlineStatus(item.data.lastPing);
                          console.log(item);
                          return (
                            <Draggable
                              key={`${item.type}-${item.data.id}`}
                              draggableId={`${item.type}-${item.data.id}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <tr
                                  key={`${item.type}-${item.data.id}`}
                                  onClick={() => this.props.onRowSelect(item.data.id, item.type)}
                                  style={rowStyle}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <td style={{ width: '80px' }}>
                                    <div className="pl-4">
                                      <OnlineStatusCell onlineStatus={oS} />
                                    </div>
                                  </td>
                                  <td style={{ width: '180px' }}>{item.data.name}</td>
                                  <td style={{ width: '180px' }}>{item.type.toUpperCase()}</td>
                                  <td style={{ width: '180px' }}>{item.data.deviceId}</td>
                                  <td style={{ width: '180px' }}>
                                    {moment(item.data.lastPing).format('L LTS')}
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          );
                        })
                      : false}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  }
}

ThingsGrid.propTypes = {
  items: PropTypes.array,
  totalItems: PropTypes.string,
  pageCount: PropTypes.string,
  allowFilter: PropTypes.bool,
  sortField: PropTypes.string,
  sortOrder: PropTypes.number,
  limit: PropTypes.string,
  page: PropTypes.string,
  filter: PropTypes.string,
  onRowSelect: PropTypes.func,
  onQueryChanged: PropTypes.func,
};

export default ThingsGrid;
