import { push } from 'react-router-redux';
import { TOGGLE_NAVIGATION, TOGGLE_APPLICATION } from '../types';

export const toggleNavigation = () => {
  return { type: TOGGLE_NAVIGATION };
};

export const changeLocation = (newLocation) => {
  return push(newLocation);
};

export const toggleApplication = (val) => {
  localStorage.setItem('currentApplicationID', val.appID);
  localStorage.setItem('currentApplicationName', val.name);
  return { type: TOGGLE_APPLICATION, payload: val };
};
