import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { readOnlyCheck, isKeyboardKey } from '../../utils';

import userServices from '../../services/userServices';
import { KEYBOARD_KEYS } from '../../constants';

class PlaceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favorited: false,
    };
  }

  archive = () => {
    if (window.confirm('Are you sure? \nPlace will now be archived')) {
      this.props.deletePlace();
    }
  };

  favorite() {
    this.setState({ favorited: !this.state.favorited });
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card h-100">
          <div className="card-close"></div>
          <div
            className="card-header"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <h3 className="h4">Place Details</h3>
            <div>
              {readOnlyCheck() ? (
                false
              ) : (
                <div
                  className="btn-group"
                  style={{
                    float: 'right',
                  }}
                >
                  {this.state.favorited ? (
                    <button
                      type="button"
                      title="Remove favorite place"
                      style={{ color: '#FFA500', fontSize: '2em' }}
                      onClick={(e) => {
                        e.preventDefault();
                        userServices.removeFavorite(this.props.place.id, 'place');
                        this.favorite();
                      }}
                    >
                      <i className="fas fa-star"></i>
                    </button>
                  ) : (
                    <button
                      type="button"
                      title="Favorite place"
                      style={{ fontSize: '2em' }}
                      onClick={(e) => {
                        e.preventDefault();
                        userServices.newFavorite(this.props.place.id, 'place');
                        this.favorite();
                      }}
                    >
                      <i className="far fa-star"></i>
                    </button>
                  )}
                  <div className="dropdown">
                    <button
                      type="button"
                      id="closeCard1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ fontSize: '2em' }}
                    >
                      <i className="fas fa-ellipsis-h"></i>
                    </button>
                    <div
                      aria-labelledby="closeCard1"
                      className="dropdown-menu dropdown-menu-right has-shadow"
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'baseline',
                          paddingLeft: '1em',
                          fontSize: '1.25rem',
                        }}
                      >
                        <Link
                          title="Edit thing"
                          to={`/places/${this.props.place.id}/edit`}
                          style={{
                            textDecoration: 'none',
                            color: 'black',
                          }}
                        >
                          <i className="fas fa-pencil"></i>
                          <span> Edit</span>
                        </Link>

                        <div
                          type="button"
                          role="button"
                          title="Deactivate thing"
                          tabIndex={0}
                          style={{
                            textDecoration: 'none',
                            color: '#F32013',
                            fontSize: '1.25rem',
                          }}
                          onClick={this.archive}
                          onKeyDown={(e) => isKeyboardKey(e, KEYBOARD_KEYS.ENTER, this.archive)}
                        >
                          <i className="fa fa-trash" />
                          <span> Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-4">Name</div>
              <div className="col-md-5">{this.props.place.name}</div>
            </div>
            <div className="row">
              <div className="col-md-4">Description</div>
              <div className="col-md-5">{this.props.place.description}</div>
            </div>
            <div className="row">
              <div className="col-md-4">Device ID</div>
              <div className="col-md-5">{this.props.place.deviceId}</div>
            </div>
            <div className="row">
              <div className="col-md-4">Last Checkin</div>
              <div className="col-md-5">
                {typeof this.props.place.lastPing !== 'undefined'
                  ? moment(this.props.place.lastPing).format('MM/DD/YYYY hh:mm:ss A')
                  : 'This device has not checked in'}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <strong>Keywords</strong>
              </div>
            </div>
            {this.props.place ? (
              this.props.place.keywords ? (
                this.props.place.keywords.length > 0 ? (
                  <div>{this.props.place.keywords.join(', ')}</div>
                ) : (
                  <div>This place does not have any keywords</div>
                )
              ) : (
                <div>This place does not have any keywords</div>
              )
            ) : (
              <div>This place does not have any keywords</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PlaceDetails.propTypes = {
  place: PropTypes.object.isRequired,
  deletePlace: PropTypes.func,
};

export default PlaceDetails;
