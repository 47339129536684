import React, { Component } from 'react';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

class LocationsContainer extends Component {
  componentDidMount() {
    let map = L.map('map', {
      center: [32.966263, -96.726909],
      zoom: 13,
    }).setView([32.966263, -96.726909], 13);
    L.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          'pk.eyJ1IjoiaGF5ZGVuLXRoaW5hZXIiLCJhIjoiY2s3NHpjMGoxMHQ0bzNtcDdocGY5ZXZ3NiJ9.ftXGlq6a1nXfYrBHXBIEkA',
      }
    ).addTo(map);
    L.marker([32.966263, -96.726909]).addTo(map);
  }

  render() {
    return <div id="map" style={{ height: '100%' }}></div>;
  }
}

export default LocationsContainer;
