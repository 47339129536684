/* Utils for different events like onClick, onKeyPress, etc */

import noop from './noop';

/**
 * @desc Compare event key to desired key and run
 * optional function if they match.
 * @param {Object} event Event object
 * @param {string} key Keyboard key that is being checked
 * @param {Function} fn An optional function to run if key is true
 * @returns {boolean} Boolean If keys match
 */
export function isKeyboardKey(event, key = '', fn = noop) {
  if (!event.key || event.key !== key) return false;

  fn();
  return true;
}
