import React from 'react';
// const dateFormat = require('dateformat');
import PropTypes from 'prop-types';
import moment from 'moment';

class ScaleCard extends React.Component {
  render() {
    if (!this.props.weight) {
      return <div />;
    }

    return (
      <div className="col-lg-6">
        <div className="card h-100">
          <div className="card-header d-flex align-items-center">
            <h3 className="h4">Smart Scale</h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <i className="fa fa-clock-o" />
                &nbsp; Recorded
              </div>
              <div className="col-md-5">
                {moment(this.props.recordedAt).format('MM/DD/YYYY hh:mm:ss A')}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <i className="fa fa-medkit" />
                &nbsp; Last Weight
              </div>
              <div className="col-md-5">
                {this.props.weight} {this.props.units}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ScaleCard.propTypes = {
  weight: PropTypes.number,
  units: PropTypes.string,
  recordedAt: PropTypes.string,
};

export default ScaleCard;
