import Api from '../config/api';
import { store } from '../redux/store/store';

let state;

const getTempAndHumidity = (startDate, endDate, hour = 0, returnAll = 0, page = 1, limit = 20) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/reports/tempandhumidity?page=${page}&limit=${limit}&hour=${hour}&all=${returnAll}&startDate=${startDate}&endDate=${endDate}`,
    {}
  );
};

const getTriggerResponse = (startDate, endDate, hour = 0, returnAll = 0, page = 1, limit = 20) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/reports/triggerresponses?page=${page}&limit=${limit}&hour=${hour}&all=${returnAll}&startDate=${startDate}&endDate=${endDate}`,
    {}
  );
};

const getLocation = (page, limit, sort, order, assetTypeId, maintenanceDaysFrom) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/reports/assetlocation?page=${page}&limit=${limit}&sort=${sort}&order=${order}&assetTypeId=${assetTypeId}&maintenanceDaysFrom=${maintenanceDaysFrom}`,
    {}
  );
};

const getAssetTypes = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/assettypes`,
    {}
  );
};

export default {
  getTempAndHumidity,
  getLocation,
  getAssetTypes,
  getTriggerResponse,
};
