import Api from './../config/api';
import { store } from '../redux/store/store';

let state;

const getSearchResults = (query, filter) => {
  state = store.getState();

  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/search?q=${query}&limit=10&f=${filter}`
  );
};

const getAllSearchResults = (query, filter, page, pageSize) => {
  state = store.getState();

  return Api.get(
    `${process.env.REACT_APP_API_URL}/${
      state.navigation.currentApplication.appID
    }/search?q=${query}&limit=${1000}&page=${page}&f=${filter}`
  );
};

export default {
  getSearchResults,
  getAllSearchResults,
};
