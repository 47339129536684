import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import GlobalSearch from '../globalSearch/globalSearch';
import ApplicationSwitcher from '../applicationSwitcher/applicationSwitcher';
import SettingDropdown from '../settingDropdown/settingDropdown';
import CreateAsset from '../createAsset';
import { OPEN_NAV_WIDTH } from './utils';
import NavToggle from './NavToggle';
import './header.css';

function Header({ navigation }) {
  const logoDisplay = navigation.isOpen ? 'block' : 'none';

  return (
    <header className="header d-flex flex-row justify-content-between pt-3 pr-5 pb-2 w-100">
      {/* Logo & Hamburger icon */}
      <div
        className="header-left d-flex flex-row justify-content-between align-items-top"
        style={{ zIndex: 999, width: OPEN_NAV_WIDTH }}
      >
        <Link to="/dashboard" style={{ display: logoDisplay }}>
          <img
            style={{ height: navigation.logoHeight }}
            className="pl-3"
            src="/img/sonar-white.svg"
            alt="Sonar"
            title="Sonar"
          />
        </Link>

        <NavToggle />
      </div>

      {/* Nav actions */}
      <ul className="header-notifications quick-menu">
        <li>
          <GlobalSearch />
        </li>

        <li className="dropdown">
          <ApplicationSwitcher />
        </li>

        <li className="dropdown user-nav">
          <CreateAsset />
        </li>

        <li className="dropdown user-nav">
          <SettingDropdown />
        </li>
      </ul>
    </header>
  );
}

const mapStateToProps = (state) => {
  return {
    navigation: state.navigation,
  };
};

export default connect(mapStateToProps)(Header);
