// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
// locals
import CrumbsHeader from '../../components/layout/crumbsHeader';
import PlaceForm from '../../components/forms/placeForm';
import { createPlaceSuccess } from '../../redux/actions/placeActions';
import { changeLocation } from '../../redux/actions/navigationActions';
import placeService from './../../services/placeService';
import { convertCommaStringToArray } from '../../utils/stringUtils';

class PlaceNewContainer extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
  }

  submit(values) {
    values.deviceId = values.deviceId.trim();
    values.keywords = convertCommaStringToArray(values.keywords);
    return placeService
      .create(values)
      .then((e) => {
        this.props.createPlaceSuccess(e);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          throw new SubmissionError({
            deviceId: 'already in use',
            _error: 'Device ID is already in use',
          });
        }
      });
  }

  reset() {
    this.props.changeLocation(`/places`);
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Place'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/places', label: 'Places' },
            { route: '', label: 'New' },
          ]}
        />
        <section className="forms">
          <div className="container-fluid">
            <PlaceForm
              submit={this.submit}
              formHeader="Create Place"
              isLoading={this.props.isLoading}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  //toggleTodo: id => dispatch(toggleTodo(id))
  bindActionCreators(
    {
      createPlaceSuccess,
      changeLocation,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    isLoading: state.things.isLoading,
  };
};

PlaceNewContainer.propTypes = {
  isLoading: PropTypes.bool,
  changeLocation: PropTypes.func.isRequired,
  createPlaceSuccess: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceNewContainer);
