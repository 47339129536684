import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const PrivateRoute = ({ component: Component, ...props }) => {
  let token = localStorage.token;
  if (token && token.length > 1 && token !== 'undefined' && token !== 'null') {
    token = jwtDecode(token);
  }
  if (
    localStorage.token === undefined ||
    localStorage.token === null ||
    typeof localStorage.token !== 'string'
  ) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    );
  } else {
    return <Route {...props} render={(props) => <Component {...props} />} />;
  }
};

export default PrivateRoute;
