import React from 'react';

const selectAllOrNoneButton = (map, source) => {
  let items = map[source];
  for (let i = 0; i < items.length; i++) {
    if (!items[i].isVisible) {
      return (
        <button className="btn btn-sm" onClick={() => this.selectAll(source)}>
          {' '}
          Select All
        </button>
      );
    }
  }
  return (
    <button className="btn btn-sm" onClick={() => this.selectNone(source)}>
      {' '}
      Select None
    </button>
  );
};

const create = {
  gateway: (index) => {
    return {
      index: index + 1,
      gateway: null,
      gatewayId: null,
      influence: 1,
      location: null,
    };
  },
  zone: (index) => {
    return {
      index: index + 1,
      zoneId: null,
      name: 'New Zone',
      translation: '',
      boundary: null,
    };
  },
  beacon: (index) => {
    return {
      index: index + 1,
      beaconId: null,
      location: null,
      beacon: null,
    };
  },
};

const replace = (map, source, item) => {
  map[source] = map[source].map((g) => {
    if (g.index === item.index) {
      return item;
    }
    return g;
  });

  return map;
};

const remove = (map, source, index) => {
  let i = 0;
  let found = false;
  let list = map[source];
  for (i; i < list.length; i++) {
    if (list[i].index === index) {
      found = true;
      break;
    }
  }

  if (found) {
    list.splice(i, 1);
    map[source] = list;
  }
  return map;
};

const setGatewayLocation = (map, index, location) => {
  map.gateways = map.gateways.map((g) => {
    if (g.index === index) {
      g.location = {
        x: Math.round(location.lng),
        y: Math.round(location.lat),
      };
    }
    return g;
  });

  return map;
};

const setZoneBoundary = (map, index, boundary) => {
  map.zones = map.zones.map((z) => {
    if (z.index === index) {
      z.boundary = boundary.map((val) => {
        return {
          x: Math.round(val.lng),
          y: Math.round(val.lat),
        };
      });
    }
    return z;
  });

  return map;
};

const setBeaconLocation = (map, index, location) => {
  map.beacons = map.beacons.map((b) => {
    if (b.index === index) {
      b.location = {
        x: Math.round(location.lng),
        y: Math.round(location.lat),
      };
    }
    return b;
  });

  return map;
};

export default {
  create,
  replace,
  selectAllOrNoneButton,
  remove,
  setGatewayLocation,
  setZoneBoundary,
  setBeaconLocation,
};
