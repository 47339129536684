import moment from 'moment';

const timeSeriesDateFilter = (startDate, endDate, locations) => {
  return locations.map((l) => {
    l.isSelected = moment(startDate).isBefore(l.name) && moment(endDate).isAfter(l.name);
    return l;
  });
};

const assignPropsToTimeSeries = (timeSeries) => {
  if (!timeSeries || !timeSeries.map) {
    return [];
  }
  return timeSeries.map((ts, index) => {
    ts.index = index;
    ts.name = ts.timestamp;
    ts.isVisible = true;
    ts.isHighlighted = false;
    return ts;
  });
};

export default { timeSeriesDateFilter, assignPropsToTimeSeries };
