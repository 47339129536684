// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// locals
import CrumbsHeader from '../../components/layout/crumbsHeader';
import PlaceForm from '../../components/forms/placeForm';
import { getPlace, updatePlace } from '../../redux/actions/placeActions';
import { changeLocation } from '../../redux/actions/navigationActions';
import { convertCommaStringToArray } from '../../utils/stringUtils';

class PlaceEditContainer extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.getPlace(this.props.match.params.placeId);
  }

  submit(values) {
    if (values.keywords) {
      values.keywords = convertCommaStringToArray(values.keywords);
    }
    this.props.updatePlace(this.props.match.params.placeId, values);
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Place'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/places', label: 'Places' },
            { route: `/places/${this.props.place.id}`, label: this.props.place.name },
            { route: '', label: 'Edit' },
          ]}
        />
        <section className="forms">
          <div className="container-fluid">
            <PlaceForm
              submit={this.submit}
              initialValues={this.props.place}
              isLoading={this.props.isLoading}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  //toggleTodo: id => dispatch(toggleTodo(id))
  bindActionCreators(
    {
      getPlace,
      updatePlace,
      changeLocation,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    place: state.places.currentPlace,
    isLoading: state.places.isLoading,
  };
};

PlaceEditContainer.propTypes = {
  isLoading: PropTypes.bool,
  place: PropTypes.object.isRequired,
  getPlace: PropTypes.func.isRequired,
  updatePlace: PropTypes.func.isRequired,
  changeLocation: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceEditContainer);
