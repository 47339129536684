// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// locals
import { changeLocation } from '../../redux/actions/navigationActions';
import CrumbsHeader from '../../components/layout/crumbsHeader';
import Gateway from './../../components/bulkUpload/gateway';
import Beacon from './../../components/bulkUpload/beacon';

class BulkUploadContainer extends Component {
  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Maps'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/Admin', label: 'Admin' },
            { route: '/Admin/', label: 'Bulk Upload' },
          ]}
        />
        <section>
          <div className="container-fluid">
            {window.location.pathname.endsWith('gateways') ? <Gateway /> : <Beacon />}
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ changeLocation }, dispatch);

const mapStateToProps = (state) => {
  return {
    state,
  };
};

BulkUploadContainer.propTypes = {
  changeLocation: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadContainer);
