// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
// reports
import TempHumidityReport from '../../components/reports/tempHumidityReport';
import LocationReport from '../../components/reports/locationReport';
import TriggerResponseReport from './../../components/reports/triggerResolutionReport';

// locals

class ReportContainer extends Component {
  render() {
    if (this.props.match.params.reportId === '1') {
      return (
        <div>
          <TempHumidityReport />
        </div>
      );
    } else if (this.props.match.params.reportId === '2') {
      return (
        <div>
          <LocationReport />
        </div>
      );
    } else {
      return (
        <div>
          <TriggerResponseReport />
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const mapStateToProps = (_state) => {
  return {};
};

ReportContainer.propTypes = {
  match: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
