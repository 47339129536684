import React from 'react';
import PropTypes from 'prop-types';
import Search from '../../../../components/search';

class BeaconEditRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beacon: this.props.beacon,
    };

    this.onSearchItemSelected = this.onSearchItemSelected.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSearchItemSelected(item) {
    this.setState({ beacon: item });
  }

  onConfirm() {
    this.props.onEditCompleted('beacons', {
      index: this.props.beacon.index,
      beaconId: this.state.beacon.id,
      location: this.props.beacon.location,
      beacon: this.state.beacon,
      isVisible: true,
    });
  }

  onCancel() {
    if (!this.props.beacon.beacon) {
      this.props.onAddCanceled('beacons', this.props.beacon.index);
    } else {
      this.props.onEditCompleted('beacons', this.props.beacon);
    }
  }

  render() {
    return (
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="form-group w-50">
          <Search
            items={this.props.searchItems}
            onSelect={this.onSearchItemSelected}
            exceptIds={this.props.excludedSearchItems}
            onChange={this.props.onSearchFilterChanged}
            selected={this.state.beacon}
            title="deviceId"
            subTitle="name"
            showInitial={this.state.beacon.beaconId !== null}
          />
        </div>

        <div className="form-group w-25">
          {this.props.beacon.location &&
          this.props.beacon.location.x &&
          this.props.beacon.location.y ? (
            <a href="# " className="w-100">
              <span className="w-100 text-right">
                ({this.props.beacon.location.x}, {this.props.beacon.location.y})
              </span>
            </a>
          ) : (
            false
          )}
        </div>

        <div className="w-25">
          <div className="btn btn-group float-right">
            <a href="# " className="mr-4" onClick={this.onConfirm} title="Confirm">
              <i className="fa fa-check-circle text-success" />
            </a>
            <a c href="# " lassName="mr-4" onClick={this.onCancel} title="Cancel">
              <i className="fa fa-ban" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

BeaconEditRow.propTypes = {
  beacon: PropTypes.object,
  onSearchFilterChanged: PropTypes.func,
  searchItems: PropTypes.array,
  excludedSearchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
};

export default BeaconEditRow;
