import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar';

import './layout.css';
import Navigation from './navigation';
import Header from './header';
import Footer from './footer';
import Toast from './../utils/toastComponent';

function Layout({ children, progressBar, navigation }) {
  return (
    <Fragment>
      <div className="h-100 w-100">
        {/* Header */}
        <Header />
        <ProgressBar
          now={progressBar.progress}
          className="layout-progressbar"
          style={{ height: progressBar.inProgress ? '4px' : '0', marginLeft: navigation.navWidth }}
        />

        {/* Content */}
        <div className="layout-main-container">
          <div className="layout-inner-container">
            {/* Navigation */}
            <Navigation />

            {/* Main Content */}
            <main className="layout-main" style={{ marginLeft: navigation.navWidth }}>
              <div className="h-100 w-100">{children}</div>
            </main>
          </div>
          <Footer />
        </div>
      </div>

      {/* Absolutely positioned Toast component */}
      <Toast />
    </Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  progressBar: PropTypes.shape({
    progress: PropTypes.number.isRequired,
    inProgress: PropTypes.bool.isRequired,
  }).isRequired,
  navigation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    progressBar: state.progressBar,
    navigation: state.navigation,
  };
};

export default connect(mapStateToProps)(Layout);
