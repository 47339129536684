import L from 'leaflet';
import {} from 'leaflet.awesome-markers';
import onlineStatusFunctions from '../../../../utils/onlineStatusFunctions';

const createLocationMarker = (beacon, color) => {
  if (beacon.location) {
    let marker = L.circle([beacon.location.y, beacon.location.x], {
      radius: 6,
      color: color,
      fillColor: beacon.fillColor || color,
      fillOpacity: beacon.fillOpacity || 0.2,
      weight: 1,
    }).bindPopup(`<strong>${beacon.name}</strong>`);

    if (beacon.onmouseover) {
      marker.on('mouseover', (e) => {
        e.location = beacon;
        e.marker = marker;
        beacon.onmouseover(e);
      });
    }

    if (beacon.onmouseout) {
      marker.on('mouseout', (e) => {
        e.location = beacon;
        e.marker = marker;
        beacon.onmouseout(e);
      });
    }

    return marker;
  }
  return null;
};

const createGeoLocationMarker = (beacon, color) => {
  if (beacon.location) {
    let marker = L.circle([beacon.location.x, beacon.location.y], {
      radius: 20,
      color: color,
      fillColor: color,
      fillOpacity: 0.2,
      weight: 3,
    }).bindPopup(`<strong>${beacon.name}</strong>`);
    return marker;
  }
  return null;
};

const createHighlightedLocationMarker = (beacon, color) => {
  if (beacon.location) {
    let marker = L.marker([beacon.location.y, beacon.location.x], {
      icon: L.AwesomeMarkers.icon({ prefix: 'fa', icon: 'star', markerColor: 'green' }),
      title: beacon.name,
    });

    marker.beaconIndex = beacon.index;

    return marker;
  }
  return null;
};
const createGeoHighlightedLocationMarker = (beacon, color) => {
  if (beacon.location) {
    let marker = L.marker([beacon.location.x, beacon.location.y], {
      icon: L.AwesomeMarkers.icon({ prefix: 'fa', icon: 'star', markerColor: 'green' }),
      title: beacon.name,
    });

    marker.beaconIndex = beacon.index;

    return marker;
  }
  return null;
};

const createLocationCircle = (circle) => {
  return L.circle([circle.center.y, circle.center.x], {
    radius: circle.radius,
    opacity: 0.2,
    fillOpacity: 0.02,
  });
};

const createLocationsLayerGroup = (locations, index, mapId) => {
  let filteredLocations = locations.filter((val) => val.location.type === 0 && val.mapId === mapId);
  return new L.FeatureGroup(createLocationsLayerArray(filteredLocations, index, mapId));
};

const createGeoLocationsLayerGroup = (locations) => {
  let filteredLocations = locations.filter((val) => val.location.type === 1);
  return new L.FeatureGroup(createGeoLocationsLayerArray(filteredLocations));
};

const createLocationsLayerArray = (locations, index, mapId) => {
  if (!locations) {
    return [];
  }

  let locationsLayerGroup = [];

  const sorted = locations.concat().sort(function (a, b) {
    return a.isHighlighted - b.isHighlighted;
  });

  for (let location of sorted) {
    for (let layer of createLocationLayers(location)) {
      locationsLayerGroup.push(layer);
    }
  }
  return locationsLayerGroup;
};

const createGeoLocationsLayerArray = (locations) => {
  if (!locations) {
    return [];
  }

  let locationsLayerGroup = [];

  const sorted = locations.concat().sort(function (a, b) {
    return a.isHighlighted - b.isHighlighted;
  });

  for (let location of sorted) {
    for (let layer of createGeoLocationLayers(location)) {
      locationsLayerGroup.push(layer);
    }
  }
  return locationsLayerGroup;
};

const createLocationLayers = (location) => {
  if (!location.location) {
    location.location = {
      x: location.x,
      y: location.y,
    };
  }

  let layers = [];
  if (location.isVisible) {
    let layer = null;
    if (location.isHighlighted) {
      layer = createHighlightedLocationMarker(
        location,
        onlineStatusFunctions.getHexColorByStatus(location.onlineStatus)
      );

      if (layer != null) {
        layers.push(layer);
        if (location.circles) {
          for (let circle of location.circles) {
            layers.push(createLocationCircle(circle));
          }
        }
      }
    } else {
      layer = createLocationMarker(
        location,
        onlineStatusFunctions.getHexColorByStatus(location.onlineStatus)
      );
      if (layer != null) {
        layers.push(layer);
      }
    }
  }

  return layers;
};

const createGeoLocationLayers = (location) => {
  if (!location.location) {
    location.location = {
      x: location.x,
      y: location.y,
    };
  }

  let layers = [];
  if (location.isVisible) {
    let layer = null;
    if (location.isHighlighted) {
      layer = createGeoHighlightedLocationMarker(
        location,
        onlineStatusFunctions.getHexColorByStatus(location.onlineStatus)
      );

      if (layer != null) {
        layers.push(layer);
        if (location.circles) {
          for (let circle of location.circles) {
            layers.push(createLocationCircle(circle));
          }
        }
      }
    } else {
      layer = createGeoLocationMarker(
        location,
        onlineStatusFunctions.getHexColorByStatus(location.onlineStatus)
      );
      if (layer != null) {
        layers.push(layer);
      }
    }
  }

  return layers;
};

export {
  createLocationLayers,
  createLocationsLayerGroup,
  createLocationsLayerArray,
  createGeoLocationsLayerGroup,
  createGeoHighlightedLocationMarker,
  createGeoLocationsLayerArray,
};
