/* eslint-disable indent */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './navigation.css';
import { isAdmin } from '../../utils';
import { store } from '../../redux';

function Navigation() {
  const { navigation } = store.getState();

  function isActive(pathname) {
    return new RegExp(pathname).test(window.location.pathname);
  }

  function renderAdminLinks() {
    if (!isAdmin()) return null;

    return (
      <Fragment>
        <NavLinkHeader text="Admin" />

        <NavLink
          iconClassname="fas fa-tachometer"
          text="Account Management"
          to="/admin/accountmanagement"
          isActive={isActive('/admin/accountmanagement')}
          isAdminOnly
        />
      </Fragment>
    );
  }

  function renderInternalLinks() {
    const email = localStorage.getItem('email');
    if (!email) return null;
    if (!email.endsWith('thinaer.io')) return null;

    return (
      <Fragment>
        <NavLinkHeader text="Internal Tools" />

        <NavLink
          iconClassname="fas fa-tachometer"
          text="Gateway Management"
          to="/admin/gateways"
          isActive={isActive('/admin/gateways')}
        />

        <NavLink
          iconClassname="fas fa-tachometer"
          text="Bulk Beacon Upload"
          to="/admin/bulkbeacons"
          isActive={isActive('/admin/bulkbeacons')}
        />

        <NavLink
          iconClassname="fas fa-tachometer"
          text="Bulk Gateway Upload"
          to="/admin/bulkgateways"
          isActive={isActive('/admin/bulkgateways')}
        />
      </Fragment>
    );
  }
  // #endregion HELPER FUNCTIONS

  return (
    <nav data-testid="main-nav" className="sidenav" style={{ width: navigation.navWidth }}>
      {/* Links */}
      <ul className="nav-menu">
        {/* Main */}
        <NavLinkHeader text="Main" />

        <NavLink
          iconClassname="fas fa-tachometer"
          text="Home"
          to="/dashboard"
          isActive={isActive('/dashboard')}
        />

        <NavLink
          iconClassname="fas fa-users"
          text="People"
          to="/people"
          isActive={isActive('/people')}
        />

        <NavLink
          iconClassname="fas fa-home"
          text="Places"
          to="/places"
          isActive={isActive('/places')}
        />

        <NavLink
          iconClassname="fas fa-tags"
          text="Things"
          to="/things"
          isActive={isActive('/things')}
        />

        <NavLink
          iconClassname="fas fa-crosshairs"
          text="Triggers"
          to="/triggers"
          isActive={isActive('/triggers')}
        />

        <NavLink iconClassname="fas fa-map" text="Maps" to="/maps" isActive={isActive('/maps')} />

        <NavLink
          envRegexString="(dev|test)"
          iconClassname="fas fa-disease"
          text="Contact Tracing"
          to="/contact-tracing"
          isAdminOnly
          isActive={isActive('/contact-tracing')}
        />

        {/* Reports */}
        <NavLinkHeader text="Reports" />

        <NavLink
          iconClassname="fas fa-map-pin"
          text="Asset"
          to="/reports/2"
          isActive={isActive('/reports/2')}
        />

        <NavLink
          iconClassname="fas fa-thermometer-empty"
          text="Environmental"
          to="/reports/1"
          isActive={isActive('/reports/1')}
        />

        <NavLink
          iconClassname="fas fa-crosshairs"
          text="Trigger Resolution"
          to="/reports/3"
          isActive={isActive('/reports/3')}
        />

        {/* Admin */}
        {renderAdminLinks()}

        {/* Internal Tools */}
        {renderInternalLinks()}
      </ul>
    </nav>
  );
}

// Navigation.propTypes = {
// };

// #region HELPER COMPS
function NavLinkHeader({ text }) {
  return <li className="nav-header">{text}</li>;
}

NavLinkHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

function NavLink({ iconClassname, text, to, isAdminOnly, isActive, envRegexString }) {
  if (!new RegExp(envRegexString).test(process.env.REACT_APP_ENV)) return null;

  if (isAdminOnly && !isAdmin()) return null;

  const linkClassName = classnames('menu no-arrow', { open: isActive });

  return (
    <li className={linkClassName}>
      <Link to={to}>
        <i className={iconClassname} />
        <span className="nav-text">{text}</span>
      </Link>
    </li>
  );
}

NavLink.defaultProps = {
  isAdminOnly: false,
  isActive: false,
  isDevOnly: false,
  envRegexString: '(prod|stag|dev|test)',
};

NavLink.propTypes = {
  iconClassname: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,

  // defaults
  isAdminOnly: PropTypes.bool,
  isActive: PropTypes.bool,
  isDevOnly: PropTypes.bool,
  envRegexString: PropTypes.string,
};
// #endregion HELPER COMPS

export default Navigation;
