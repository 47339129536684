import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';

import { StartEndDatePicker, Export, AgGrid, Text } from '../../components';
import { contactTracingColumnDefs } from './columnDefs';
import NameCellRenderer from './NameCellRenderer';
import { noop } from '../../utils';

function ContactTracingScreen({
  data,
  nameFilter,
  count,
  page,
  pageCount,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  onChangeSearch,
  onChangeLimit,
  onChangePage,
  exportData,
  onRefetch,
}) {
  function getGridProps() {
    return {
      frameworkComponents: {
        nameCellRenderer: NameCellRenderer,
      },
    };
  }

  // TODO: move to custom Tooltip comp
  function renderDateTooltip() {
    return (
      <Tooltip>
        <Popover style={{ width: 200 }}>
          <Popover.Title as="h3">Date Range</Popover.Title>
          <Popover.Content>
            <Text as="div">
              This controls the date range of contact tracing data that is loaded once you select a
              beacon.
            </Text>
            <br />
            <Text>Note: Longer date ranges may increase loading time.</Text>
          </Popover.Content>
        </Popover>
      </Tooltip>
    );
  }

  return (
    <Container fluid className="h-100">
      <Row noGutters>
        <Col xs={12} lg={11}>
          <div className="d-flex flex-row align-items-center ml-4">
            <OverlayTrigger placement="bottom" overlay={renderDateTooltip()}>
              <i className="fa fa-question-circle fa-xs mr-1 pointer" />
            </OverlayTrigger>
            <StartEndDatePicker
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={onChangeStartDate}
              onChangeEndDate={onChangeEndDate}
            />
          </div>
        </Col>
        <Col xs={1} lg={1}>
          <Export
            data={exportData.data}
            headers={exportData.headers}
            filename={`ContactTracing-${startDate.format('lll')}-${endDate.format('lll')}${
              nameFilter ? `-${nameFilter}` : ''
            }`}
          />
        </Col>
      </Row>

      <Row className="h-90">
        <Col className="h-100">
          <AgGrid
            columnDefs={contactTracingColumnDefs}
            rowData={data}
            totalItems={count}
            page={page}
            pageCount={pageCount}
            onChangeSearch={onChangeSearch}
            onChangeLimit={onChangeLimit}
            onChangePage={onChangePage}
            gridProps={getGridProps()}
            searchDebounceTimeSec={1}
            limitDisabled
            onRefetch={onRefetch}
            defaultSearch={nameFilter}
          />
        </Col>
      </Row>
    </Container>
  );
}

ContactTracingScreen.defaultProps = {
  onChangeLimit: noop,
};

ContactTracingScreen.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  nameFilter: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  exportData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    headers: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
  onRefetch: PropTypes.func.isRequired,

  // defaults
  onChangeLimit: PropTypes.func,
};

export default ContactTracingScreen;
