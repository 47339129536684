import React from 'react';
import PropTypes from 'prop-types';
import Search from '../../../../components/search';

class GatewayEditRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      influence: this.props.gateway.influence,
      gateway: this.props.gateway.gateway,
    };

    this.onSearchItemSelected = this.onSearchItemSelected.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSearchItemSelected(item) {
    this.setState({ gateway: item });
  }

  handleChange(event) {
    this.setState({
      influence: event.target.value,
    });
  }

  onConfirm() {
    this.props.onEditCompleted('gateways', {
      index: this.props.gateway.index,
      gatewayId: this.state.gateway.id,
      location: this.props.gateway.location,
      influence: this.state.influence,
      gateway: this.state.gateway,
      isVisible: true,
    });
  }

  onCancel() {
    if (!this.props.gateway.gateway) {
      this.props.onAddCanceled('gateways', this.props.gateway.index);
    } else {
      this.props.onEditCompleted('gateways', this.props.gateway);
    }
  }

  render() {
    return (
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="form-group w-50">
          <Search
            items={this.props.searchItems}
            onSelect={this.onSearchItemSelected}
            exceptIds={this.props.excludedSearchItems}
            onChange={this.props.onSearchFilterChanged}
            selected={this.state.gateway}
            title="deviceId"
            subTitle="name"
            showInitial={this.state.gateway !== null}
          />
        </div>

        <div className="form-group w-25">
          {this.props.gateway.location ? (
            <a href="# " className="w-100">
              <span className="w-100 text-right">
                ({this.props.gateway.location.x}, {this.props.gateway.location.y})
              </span>
            </a>
          ) : (
            false
          )}
        </div>

        <div className="form-group w-25">
          <input
            className="form-control form-control-sm text-center float-right"
            value={this.state.influence}
            onChange={this.handleChange}
            style={{
              width: '60px',
            }}
          />
        </div>

        <div className="w-25">
          <div className="btn btn-group float-right">
            <a href="# " className="mr-4" onClick={this.onConfirm} title="Confirm">
              <i className="fa fa-check-circle text-success" />
            </a>
            <a href="# " className="mr-4" onClick={this.onCancel} title="Cancel">
              <i className="fa fa-ban" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

GatewayEditRow.propTypes = {
  gateway: PropTypes.object,
  onSearchFilterChanged: PropTypes.func,
  searchItems: PropTypes.array,
  excludedSearchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
};

export default GatewayEditRow;
