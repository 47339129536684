import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import {
  createGeoLocationsLayerGroup,
  createGeoLocationsLayerArray,
} from '../services/leafletLayerFactories/leafletLocationFactory';
import { createGeoPath } from '../services/leafletLayerFactories/lineLayerFactory]';

let locations;

// let userZoomed = false;

// TODO: Improvement, cache the already rendered dots and lines to an array and just do push operations for new renders

class LocationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      selectedIndex: null,
    };
  }

  drawCanvas(map) {
    if (!map) {
      return;
    }
    map.eachLayer(function (layer) {
      if (layer._url == null) {
        map.removeLayer(layer);
      }
    });

    if (this.props.selectedIndex) {
      if (
        this.props.locations[this.props.selectedIndex].location &&
        this.props.locations[this.props.selectedIndex].location.type === 1
      ) {
        let lat = this.props.locations[this.props.selectedIndex].location.x;
        let long = this.props.locations[this.props.selectedIndex].location.y;

        L.marker([lat, long]).addTo(map);
        map.setView([lat, long]);
      }
      if (this.props.locations && this.props.locations.length > 0) {
        if (this.props.playback) {
          if (this.props.selectedIndex) {
            // USER IS CONTROLLING WHAT THEY SEE AND THE PACE
            if (this.props.displayType === 'lines') {
              locations = createGeoPath(this.props.locations);
              for (let i = 0; i < this.props.selectedIndex; i++) {
                if (this.props.locations[i].location.type === 1) {
                  if (!map || !locations[i]) {
                    continue;
                  }
                  locations[i].addTo(map);
                }
              }
            } else {
              locations = createGeoLocationsLayerArray(this.props.locations);
              for (let i = 0; i < this.props.selectedIndex; i++) {
                if (this.props.locations[i].location.type === 1) {
                  locations[i].addTo(map);
                }
              }
            }
          } else if (this.props.paused) {
            // USER IS CONTROLLING WHAT THEY SEE AND THE PACE
            if (this.props.displayType === 'lines') {
              locations = createGeoPath(this.props.locations);
              for (let i = 0; i < this.props.selectedIndex; i++) {
                if (this.props.locations[i].location.type === 1) {
                  if (!map || !locations[i]) {
                    continue;
                  }
                  locations[i].addTo(map);
                }
              }
            } else {
              locations = createGeoLocationsLayerArray(this.props.locations);
              for (let i = 0; i < this.props.selectedIndex; i++) {
                if (this.props.locations[i].location.type === 1) {
                  locations[i].addTo(map);
                }
              }
            }
          } else {
            // TODO: What is this code doing??
            // BREADCRUMBS ARE IN AUTO PLAY MODE
            if (this.props.displayType === 'lines') {
              locations = createGeoPath(this.props.locations);
            } else {
              locations = createGeoLocationsLayerArray(this.props.locations);
            }
          }
        } else {
          if (this.props.displayType === 'lines') {
            locations = createGeoPath(this.props.locations);
            for (let i = 0; i < this.props.locations.length; i++) {
              if (this.props.locations[i].location.type === 1) {
                if (!map || !locations[i]) {
                  continue;
                }
                locations[i].addTo(map);
              }
            }
          } else {
            locations = createGeoLocationsLayerGroup(this.props.locations);
            locations.addTo(map);
          }
        }
      }
    } else if (this.props.selectedIndex === null) {
      // LOAD DEFAULT VALUES TO MAP
      if (this.props.marker) {
        let lat = this.props.marker.x;
        let long = this.props.marker.y;
        L.marker([lat, long]).addTo(map);
      }
    } else if (this.props.selectedIndex === 0) {
      if (this.props.displayType === 'lines') {
        locations = createGeoPath(this.props.locations);
        for (let i = 0; i < this.props.locations.length; i++) {
          if (this.props.locations[i].location.type === 1) {
            if (!map || !locations[i]) {
              continue;
            }
            locations[i].addTo(map);
          }
        }
      } else {
        locations = createGeoLocationsLayerGroup(this.props.locations);
        locations.addTo(map);
      }
    }
  }

  componentDidMount() {
    let lat = 32.7767;
    let long = -96.797;
    if (this.props.marker && this.props.marker.type === 1) {
      lat = this.props.marker.x;
      long = this.props.marker.y;
    } else if (
      this.props.locations[this.props.selectedIndex].location &&
      this.props.locations[this.props.selectedIndex].location.type === 1
    ) {
      lat = this.props.locations[this.props.selectedIndex].location.x;
      long = this.props.locations[this.props.selectedIndex].location.y;
    }
    let map = L.map('map', {
      center: [lat, long],
      zoom: 13,
    }).setView([lat, long]);
    L.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          'pk.eyJ1IjoiaGF5ZGVuLXRoaW5hZXIiLCJhIjoiY2s3NHpjMGoxMHQ0bzNtcDdocGY5ZXZ3NiJ9.ftXGlq6a1nXfYrBHXBIEkA',
      }
    ).addTo(map);

    this.setState({ map: map, loaded: true });
    this.drawCanvas(map);

    // map.on('zoomend', function () {
    //   userZoomed = true;
    // });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { map } = this.state;
    this.drawCanvas(map);
  }

  render() {
    return <div id="map" style={{ height: this.props.height }} />;
  }
}

LocationMap.propTypes = {
  map: PropTypes.object,
  locations: PropTypes.array,
  zones: PropTypes.array,
  gateways: PropTypes.array,
  beacons: PropTypes.array,
  height: PropTypes.string,
  playback: PropTypes.bool,
  displayType: PropTypes.string,
  marker: PropTypes.object,
  reading: PropTypes.string,
  selectedIndex: PropTypes.number,
  pace: PropTypes.number,
  paused: PropTypes.bool,
};

export default LocationMap;
