// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import SearchResultsGrid from '../../components/grids/searchResultsGrid';
import CrumbsHeader from '../../components/layout/crumbsHeader';
import searchService from '../../services/searchService';

class SearchContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageCount: 0,
      totalResults: 0,
      pageSize: 20,
      currentPage: 1,
      results: [],
      ready: false,
      sendToDashboard: false,
      searchString: '',
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    this.getData(1, 20, this.props.searchFilter, this.props.searchString);
    this.setState({ searchString: this.props.searchString });
  }

  getData(page, pageSize, filter, search) {
    if (this.props.searchString.length < 3) {
      this.setState({ sendToDashboard: true });
      return;
    } else {
      searchService.getAllSearchResults(search, filter, page, pageSize).then((res) => {
        this.setState({
          totalResults: res.data.length,
          pageCount: 1,
          results: res.data,
          ready: true,
          sendToDashboard: false,
        });
      });
    }
  }

  handleTableChange(table, _instance) {
    this.getData(table.page + 1, table.pageSize, this.props.searchFilter, this.props.searchString);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.searchFilter !== nextProps.searchFilter) {
      this.getData(1, 20, nextProps.searchFilter, nextProps.searchString);
    } else if (this.props.searchString !== nextProps.searchString) {
      this.getData(1, 20, nextProps.searchFilter, nextProps.searchString);
    }
  }

  render() {
    return (
      <div>
        <CrumbsHeader header={'Search Results'} crumbs={[{ route: '', label: 'Results' }]} />

        {this.state.ready !== false ? (
          <section>
            <div className="container-fluid">
              <div className="card h-100">
                <SearchResultsGrid
                  totalPages={this.state.pageCount}
                  results={this.state.results}
                  isLoading={this.state.isLoading}
                  onTableChange={this.handleTableChange}
                  onRowClick={this.handleRowClick}
                />
              </div>
            </div>
          </section>
        ) : (
          false
        )}
        {this.state.sendToDashboard !== false ? <Redirect to="/" /> : false}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentApplication: state.navigation.currentApplication,
    searchString: state.search.searchString,
    searchFilter: state.search.searchFilter,
  };
};

SearchContainer.propTypes = {
  currentApplication: PropTypes.object,
  searchString: PropTypes.string,
  searchFilter: PropTypes.string,
};

export default connect(mapStateToProps)(SearchContainer);
