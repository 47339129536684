import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { changeLocation } from '../../redux/actions/navigationActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

const obj = {};

class TextFields extends React.Component {
  state = {
    name: '',
    description: '',
    deviceId: '',
    keywords: '',
    commitHash: '',
    editCheck: false,
  };

  buildObj = () => {
    let newObj = {};

    if (obj.name) {
      newObj.name = this.state.name;
    }
    if (obj.description) {
      newObj.description = this.state.description;
    }
    if (obj.deviceId) {
      newObj.deviceId = this.state.deviceId;
    }
    if (obj.keywords) {
      newObj.keywords = this.state.keywords;
    }
    if (obj.commitHash) {
      newObj.commitHash = this.state.commitHash;
    }

    return newObj;
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    obj[name] = true;
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let newObj = {};

    if (
      nextProps.initialValues &&
      prevState.deviceId.length === 0 &&
      prevState.editCheck === false
    ) {
      if (nextProps.initialValues && nextProps.initialValues.name) {
        newObj.name = nextProps.initialValues.name;
      }

      if (nextProps.initialValues && nextProps.initialValues.description) {
        newObj.description = nextProps.initialValues.description;
      }

      if (nextProps.initialValues && nextProps.initialValues.deviceId) {
        newObj.deviceId = nextProps.initialValues.deviceId;
        newObj.editCheck = true;
      }

      if (nextProps.initialValues && nextProps.initialValues.keywords) {
        newObj.keywords = nextProps.initialValues.keywords.toString();
      }

      return newObj;
    }

    return null;
  }

  render() {
    return (
      <div className="d-flex flex-wrap">
        <div className="col-xl-8 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">{this.props.formHeader ? this.props.formHeader : `Edit Place`}</h3>
            </div>
            <div className="card-body">
              <div className="col-sm-12">
                <p></p>
                <div className="form-horizontal">
                  <div className="form-group">
                    <label className=" form-control-label" htmlFor="device-name">
                      Device Name
                    </label>
                    <div className="">
                      <input
                        id="device-name"
                        className="form-control"
                        value={this.state.name}
                        onChange={this.handleChange('name')}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className=" form-control-label" htmlFor="device-description">
                      Device Description
                    </label>
                    <div className="">
                      <input
                        id="device-description"
                        className="form-control"
                        value={this.state.description}
                        onChange={this.handleChange('description')}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className=" form-control-label" htmlFor="device-id">
                      Device ID
                    </label>
                    <div className="">
                      <input
                        id="device-id"
                        className="form-control"
                        value={this.state.deviceId}
                        onChange={this.handleChange('deviceId')}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className=" form-control-label" htmlFor="device-keywords">
                      Device Keywords
                    </label>
                    <div className="">
                      <input
                        id="device-keywords"
                        className="form-control"
                        value={this.state.keywords}
                        onChange={this.handleChange('keywords')}
                      />
                    </div>
                  </div>
                  {this.props.formHeader === 'Create Place' ? (
                    <div className="form-group">
                      <label className=" form-control-label" htmlFor="device-hash">
                        Commit Hash
                      </label>
                      <div className="">
                        <input
                          id="device-hash"
                          className="form-control"
                          value={this.state.commitHash}
                          onChange={this.handleChange('commitHash')}
                        />
                      </div>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group" style={{ margin: '0 auto' }}>
            <button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                this.props.submit(this.buildObj());
              }}
            >
              Submit
            </button>
            <button
              className="btn btn-default"
              onClick={() => {
                this.props.changeLocation('/things');
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeLocation,
    },
    dispatch
  );

TextFields.propTypes = {
  classes: PropTypes.object.isRequired,
  submit: PropTypes.func,
  formHeader: PropTypes.string,
  changeLocation: PropTypes.func,
  initialValues: PropTypes.object, // if Editing this will be the place object
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(TextFields));

// <div className="col-lg-12">
//     <div className="card">
//         <div className="card-header d-flex align-items-center">
//             <h3 className="h4">{this.props.formHeader ? this.props.formHeader : `Edit Place`}</h3>
//         </div>
//         <div className="card-body">
//             <form className={classes.container} noValidate autoComplete="off">
//                 <TextField
//                     id="device-name"
//                     label="Device Name"
//                     className={classes.textField}
//                     value={this.state.name}
//                     onChange={this.handleChange('name')}
//                     margin="normal"
//                 />
//                 <TextField
//                     id="device-description"
//                     label="Device Description"
//                     className={classes.textField}
//                     value={this.state.description}
//                     onChange={this.handleChange('description')}
//                     margin="normal"
//                 />
//                 <TextField
//                     id="device-id"
//                     label="Device ID"
//                     className={classes.textField}
//                     value={this.state.deviceId}
//                     onChange={this.handleChange('deviceId')}
//                     margin="normal"
//                 />

//                 <TextField
//                     id="device-keywords"
//                     label="Device Keywords"
//                     className={classes.textField}
//                     value={this.state.keywords}
//                     onChange={this.handleChange('keywords')}
//                     margin="normal"
//                     helperText="Comma delimited keywords"
//                 />
//                 {this.props.formHeader === 'Create Place' ? (
//                     <TextField
//                         id="commit-hash"
//                         label="Commit Hash"
//                         className={classes.textField}
//                         value={this.state.commitHash}
//                         onChange={this.handleChange('commitHash')}
//                         margin="normal"
//                         helperText="Assign Commit Hash"
//                     />
//                 ) : (
//                     false
//                 )}
//                 <div className="line">&nbsp;</div>
//                 <div className="form-group row" style={{ margin: '0 auto' }}>
//                     <Button
//                         variant="contained"
//                         className={classes.button}
//                         onClick={() => {
//                             this.props.changeLocation('/places');
//                         }}
//                     >
//                         Cancel
//                     </Button>
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         className={classes.button}
//                         onClick={() => {
//                             this.props.submit(this.buildObj());
//                         }}
//                     >
//                         Submit
//                     </Button>
//                 </div>
//             </form>
//         </div>
//     </div>
// </div>;
