import Api from '../config/api';
import { store } from '../redux/store/store';

let state;

const getLatestReading = (id, ability) => {
  state = store.getState();

  let endpoint = `readings/${id}/latestreading`;

  let queryString = `?ability=${ability}`;

  let url = `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/${endpoint}${queryString}`;

  return Api.get(url);
};

const getLatestReadings = (id, ability, count) => {
  state = store.getState();

  let endpoint = `readings/${id}/latestreadings`;

  let queryString = `?ability=${ability}&count=${count}`;

  let url = `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/${endpoint}${queryString}`;

  return Api.get(url);
};

const getReadingsRange = (id, ability, start, end) => {
  state = store.getState();

  let endpoint = `readings/${id}/readingsrange`;

  let queryString = `?ability=${ability}`;

  if (start) {
    queryString += `&start=${start}`;
  }

  if (end) {
    queryString += `&end=${end}`;
  }

  let url = `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/${endpoint}/${queryString}`;

  return Api.get(url);
};

const getLastReadingofReadingType = (id, ability) => {
  state = store.getState();

  let endpoint = `readings/${id}/latestreadings`;

  let queryString = `?count=1&ability=${ability}`;

  let url = `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/${endpoint}/${queryString}`;

  return Api.get(url);
};

export default {
  getReadingsRange,
  getLastReadingofReadingType,
  getLatestReading,
  getLatestReadings,
};
