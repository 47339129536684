import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { readOnlyCheck } from '../../../utils';

class MapCard extends Component {
  render() {
    return (
      <div
        className={'card pt-0 ' + this.props.classes}
        style={{ overflow: 'hidden', minWidth: '300px' }}
      >
        <img
          src={this.props.map.imageUrl}
          className="card-img-top"
          alt="..."
          style={{ height: '24rem', width: '24rem' }}
        />
        <div className="card-body m-0 pl-4 pr-4 pb-0 pt-0">
          <h5 className="">{this.props.map.name}</h5>
          <p className="card-text">{this.props.map.description}</p>
          <div className="d-flex d-flex-row">
            <div className="w-25">
              <h6 className="text-center">{this.props.map.gateways}</h6>
              <h6 className="text-center font-weight-light">Gateways</h6>
            </div>

            <div className="w-25">
              <h6 className="text-center">{this.props.map.zones}</h6>
              <h6 className="text-center font-weight-light">Zones</h6>
            </div>

            <div className="w-25">
              <h6 className="text-center">{this.props.map.beacons}</h6>
              <h6 className="text-center font-weight-light">Beacons</h6>
            </div>

            <div className="w-25">
              <h6 className="text-center">{this.props.map.pixelsPerMeter}</h6>
              <h6 className="text-center  font-weight-light">px/m</h6>
            </div>
          </div>
        </div>
        <div className="card-footer pl-4 pr-4">
          <div className="d-flex justify-content-between">
            {readOnlyCheck() ? (
              <div> </div>
            ) : (
              <Link to={`/maps/${this.props.map.id}/edit`} className="card-link">
                <i className="fal fa-edit" />
                View/Edit Map
              </Link>
            )}

            <Link to={`/maps/${this.props.map.id}/locations`} className="card-link">
              <i className="fal fa-map-marked" />
              View Locations
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

MapCard.propTypes = {
  map: PropTypes.object.isRequired,
  classes: PropTypes.string,
};

export default MapCard;
