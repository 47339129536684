import React from 'react';
import { connect } from 'react-redux';

import { initiateProgress, endProgress } from '../../redux/actions';

/**
 * @desc HOC to give wrapped Comp access to Progress Bar
 * @param {React.Component} Comp Component
 */
function withProgressBar(Comp) {
  class WithProgressBar extends React.Component {
    render() {
      return (
        <Comp
          {...this.props}
          startProgress={this.props.startProgress}
          endProgress={this.props.endProgress}
        />
      );
    }

    componentWillUnmount() {
      this.props.endProgress();
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      startProgress: (promises) => dispatch(initiateProgress(promises)),
      endProgress: () => dispatch(endProgress()),
    };
  };

  const ConnectedWithProgressBar = connect(null, mapDispatchToProps)(WithProgressBar);
  return ConnectedWithProgressBar;
}

export default withProgressBar;
