import React, { Component } from 'react';
import userServices from './../../../services/userServices';
import toast from './../../../components/utils/toast';

export default class DeleteButton extends Component {
  render() {
    return (
      <span>
        {this.props.value ? (
          <div>YES</div>
        ) : (
          <div>
            <span style={{ float: 'left' }}>NO</span>

            <button
              style={{
                float: 'right',
                color: '#6214a7',
                fontWeight: '500',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                userServices
                  .inviteUser(this.props.data.userName, this.props.data.userRole)
                  .then(() => {
                    console.log('yes');
                    toast.success(`Invitation sent to: ${this.props.data.userName}`);
                  })
                  .catch(() => console.log('no'));
              }}
            >
              Resend Invite
            </button>
          </div>
        )}
      </span>
    );
  }
}
