// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// locals
import CrumbsHeader from '../../components/layout/crumbsHeader';
import ThingForm from '../../components/forms/thingForm';
import { updatePersonDevice, getPerson, getPersonDevice } from '../../redux/actions/personActions';
import { changeLocation } from '../../redux/actions/navigationActions';

class PersonDeviceEditContainer extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
    this.state = {
      device: {},
    };
  }

  submit(values) {
    this.props.updatePersonDevice(
      this.props.match.params.personId,
      this.props.match.params.deviceId,
      values
    );
  }

  reset() {
    this.props.changeLocation(`/people/${this.props.match.params.personId}`);
  }

  componentDidMount() {
    this.props.getPerson(this.props.match.params.personId);
    this.props.getPersonDevice(this.props.match.params.personId, this.props.match.params.deviceId);
    // this.props.getPeripherals(this.props.match.params.thingId);
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Person'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/people', label: 'People' },
            {
              route: `/people/${this.props.person.id}`,
              label: `${this.props.person.firstName} ${this.props.person.lastName}`,
            },
            { route: '', label: 'Devices' },
            { route: '', label: 'New' },
          ]}
        />
        <section className="forms">
          <div className="container-fluid">
            <ThingForm
              formHeader="New Device"
              onSubmit={this.submit}
              resetForm={this.reset}
              initialValues={this.props.device}
              isLoading={this.props.isLoading}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  //toggleTodo: id => dispatch(toggleTodo(id))
  bindActionCreators(
    {
      updatePersonDevice,
      getPerson,
      getPersonDevice,
      changeLocation,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    isLoading: state.people.isLoading,
    person: state.people.currentPerson,
    device: state.people.currentDevice,
  };
};

PersonDeviceEditContainer.propTypes = {
  // pages: PropTypes.number,
  isLoading: PropTypes.bool,
  person: PropTypes.object,
  // sees: PropTypes.array,
  // getPerson: PropTypes.func,
  // getSees: PropTypes.func,
  updatePersonDevice: PropTypes.func,
  getPerson: PropTypes.func,
  handleTableChange: PropTypes.func,
  handleEditDetails: PropTypes.func,
  getPersonDevice: PropTypes.func,
  changeLocation: PropTypes.func,
  match: PropTypes.object,
  device: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonDeviceEditContainer);
