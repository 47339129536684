import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllThings } from '../redux/actions/thingActions';
import CrumbsHeader from '../components/layout/crumbsHeader';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Api from './../config/api';
import { store } from '../redux/store/store';
import { Redirect } from 'react-router-dom';
import toast from './../components/utils/toast';

let state;

const guid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});
class posNegPressureContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thing: '',
      response: '',
      error: '',
      redirect: false,
    };
  }

  componentDidMount() {
    // get all enviro things
    this.props.getAllThings(1, 1000);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  submit() {
    this.setState({ error: '' });
    if (this.state.response && this.state.thing) {
      state = store.getState();
      if (this.state.response === 'true') {
        Api.post(
          `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${this.state.thing}/positive`
        ).then((res) => {
          toast.success();
          this.setState({ redirect: true });
        });
      } else {
        Api.post(
          `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/things/${this.state.thing}/negative`
        ).then((res) => {
          toast.success();
          this.setState({ redirect: true });
        });
      }
    } else {
      this.setState({
        error: 'Please select a value for both fields',
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <CrumbsHeader
          header={'Positive Negative Air Pressure'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/things', label: 'Things' },
            { route: '', label: 'Positive Negative Air Pressure' },
          ]}
        />
        {this.state.redirect === true ? <Redirect push to="/reports/1" /> : false}

        <section>
          <div className="container-fluid">
            <div className="col-lg-6">
              <div className="card h-100">
                <div className="card-header d-flex align-items-center">
                  <h3 className="h4">Positive/Negative Pressure Reporting</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div style={{ margin: '0 auto' }}>
                      <TextField
                        id="thing"
                        select
                        label="Thing"
                        className={classes.textField}
                        value={this.state.thing}
                        onChange={this.handleChange('thing')}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        helperText="Please select the thing you want to record air pressure for"
                        margin="normal"
                      >
                        <option value={''} />
                        {this.props.things
                          ? this.props.things.map((option) => (
                              <option key={guid()} value={option.id}>
                                {option.name}
                              </option>
                            ))
                          : false}
                      </TextField>
                      <TextField
                        id="response"
                        select
                        label="Pressure"
                        className={classes.textField}
                        value={this.state.response}
                        onChange={this.handleChange('response')}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        helperText="Please select the correct air pressure"
                        margin="normal"
                      >
                        <option value={''} />
                        <option value="true">Positive</option>
                        <option value="false">Negative</option>
                      </TextField>
                    </div>
                  </div>
                  <div className="form-group row" style={{ margin: '0 auto' }}>
                    <Button
                      style={{ margin: '0 auto' }}
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => {
                        this.submit();
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                  <div className="row">
                    <div style={{ margin: '0 auto', color: 'red' }}>{this.state.error}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllThings,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return { things: state.things.items };
};

posNegPressureContainer.propTypes = {
  match: PropTypes.object,
  getAllThings: PropTypes.func,
  things: PropTypes.array,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(posNegPressureContainer));
