import L from 'leaflet';
import {} from 'leaflet.awesome-markers/dist/leaflet.awesome-markers';
import onlineStatusFunctions from '../../../../utils/onlineStatusFunctions';

const createGatewayMarker = (gateway, color) => {
  if (gateway.location) {
    let marker = L.marker([gateway.location.y, gateway.location.x], {
      icon: L.AwesomeMarkers.icon({
        prefix: 'fa',
        icon: 'router',
        markerColor: 'blue',
      }),
      title: gateway.gateway.name,
    });
    marker.gatewayIndex = gateway.index;
    return marker;
  }
  return null;
};

const createGatewayLayers = (gateway) => {
  const layers = [];
  if (gateway.isVisible) {
    let layer = createGatewayMarker(
      gateway,
      onlineStatusFunctions.getHexColorByStatus(gateway.onlineStatus)
    );

    if (layer != null) {
      layers.push(layer);
    }
  }

  return layers;
};

const createGatewaysLayerGroup = (gateways) => {
  return L.featureGroup(createGatewaysLayerArray(gateways));
};

const createGatewaysLayerArray = (gateways) => {
  if (!gateways) {
    return [];
  }

  const layers = [];
  for (let gateway of gateways) {
    const gatewayLayers = createGatewayLayers(gateway);
    for (let layer of gatewayLayers) {
      layers.push(layer);
    }
  }

  return layers;
};

export { createGatewaysLayerGroup };
