// library imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
// local imports
import TempAndHumidityGrid from '../grids/tempHumidityGrid';
import CrumbsHeader from '../layout/crumbsHeader';
import { getTempAndHumidity } from '../../redux/actions/reportActions';
import { Link } from 'react-router-dom';
// style imports
import 'react-day-picker/lib/style.css';
import './reports.css';

import { formatDate, parseDate } from 'react-day-picker/moment';
import { readOnlyCheck } from '../../utils';

const paddingTop = {
  paddingTop: '8px',
};

const userTimezone = new Date().getTimezoneOffset() / 60;

class TempHumidityReport extends Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.handleHourChange = this.handleHourChange.bind(this);
    this.handleUpdateReport = this.handleUpdateReport.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);

    // sets up the start and end parameters
    let endDate = new Date();
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    this.state = {
      from: startDate,
      to: endDate,
      hour: 0, // in user's timezone
      data: [],
      rawData: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newData = [];
    if (
      nextProps.data !== prevState.rawData &&
      _.isArray(nextProps.data) &&
      nextProps.data.length > 0
    ) {
      nextProps.data.map((val) => {
        let obj = {};
        obj.recordedAt = moment.utc(val.recordedAt).local().format('MM/DD/YYYY @ hh:mm A');
        obj.name = val.name;
        obj.deviceId = val.deviceId;
        if (typeof val.averageTemperature === 'number') {
          obj.averageTemperature = val.averageTemperature.toFixed(2);
        } else {
          obj.averageTemperature = '';
        }
        if (typeof val.averageRelativeHumidity === 'number') {
          obj.averageRelativeHumidity = val.averageRelativeHumidity.toFixed(2);
        } else {
          obj.averageRelativeHumidity = '';
        }
        if (typeof val.averageBarometricPressure === 'number') {
          obj.averageBarometricPressure = val.averageBarometricPressure.toFixed(2);
        } else {
          obj.averageBarometricPressure = '';
        }
        if (typeof val.positiveNegativeReading === 'string') {
          obj.positiveNegativeReading = val.positiveNegativeReading.toUpperCase();
        } else {
          obj.positiveNegativeReading = '';
        }

        if (typeof val.averageCelsiusAndFahrenheit === 'string') {
          obj.averageCelsiusAndFahrenheit = val.averageCelsiusAndFahrenheit.toUpperCase();
        }

        newData.push(obj);
        return val;
      });
    }
    if (newData.length > 0) {
      return { rawData: nextProps.data, data: newData };
    }
    return null;
  }

  handleTableChange(table, _instance) {
    this.updateReportData(table.page + 1, table.pageSize);
  }

  // handles when a component un mounts
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  focusTo() {
    // Focus to `to` field. A timeout is required here because the overlays
    // already set timeouts to work well with input fields
    this.timeout = setTimeout(() => this.to.getInput().focus(), 0);
  }

  // formats and returns the correct data from the input
  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  // when update button is pressed, handles the update
  handleUpdateReport(event) {
    this.updateReportData();
  }

  // when the from input value changes
  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  // when the to input value changes
  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }

  // when the hour input value changes
  handleHourChange(event) {
    this.setState({ hour: event.target.value });
  }
  // POSITIVE IS NEGATIVE AND NEGATIVE IS POSITIVE
  // UTC -11 == 11
  // UTC -5 == 5
  // UTC 1 == -1
  // UTC 6 == -6
  // updates the reports data by refreshing triggering an action
  updateReportData(page = 1, limit = 10) {
    let endFormatted = moment(this.state.to);
    let startFormatted = moment(this.state.from);
    let hour;
    if (userTimezone === 0) {
      hour = parseInt(this.state.hour, 10);
    } else {
      hour = parseInt(this.state.hour, 10) + userTimezone;
      if (hour < 0) {
        // UTC is behind the user's time when adjusting for the hour
        hour = 24 + hour;
        startFormatted = moment(startFormatted, 'DD-MM-YYYY').subtract(1, 'day');
        endFormatted = moment(endFormatted, 'DD-MM-YYYY').subtract(1, 'day');
      } else if (hour > 23) {
        // UTC is ahead of the user's time when adjusting for the hour
        let overage = hour - 24;
        hour = 0 + overage;
        startFormatted = moment(startFormatted, 'DD-MM-YYYY').add(1, 'day');
        endFormatted = moment(endFormatted, 'DD-MM-YYYY').add(1, 'day');
      }
    }
    this.props.getTempAndHumidity(
      startFormatted.format('MM/DD/YYYY'),
      endFormatted.format('MM/DD/YYYY'),
      hour,
      0,
      page,
      limit
    );
  }

  // when the component loads, refresh the data
  componentDidMount() {
    this.updateReportData();
  }

  // general render method
  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    return (
      <div>
        <CrumbsHeader
          header={'Reports'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/reports', label: 'Reports' },
            { route: '', label: 'Temperature & Humidity Report' },
          ]}
        />
        <section>
          <div className="container-fluid">
            <div className="card h-100">
              <div className="card-header d-flex ">
                <div className="col-8">
                  <h4 className="h4">
                    Customize Your Report &nbsp;&nbsp;
                    <a
                      className=""
                      data-toggle="collapse"
                      href="#collapseExample"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <i className="fa fa-filter"> </i>
                    </a>
                  </h4>
                </div>

                <div className="col-4">
                  <div className="pull-right" style={{ float: 'right' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                      }}
                    >
                      {readOnlyCheck() ? (
                        false
                      ) : (
                        <Link to="/pressure">
                          <div style={{ marginRight: '15px' }}>Submit Pressure Recording</div>
                        </Link>
                      )}

                      <CSVLink
                        style={{
                          marginLeft: '15px',
                          textDecoration: 'none',
                        }}
                        filename={'TemperatureAndHumidity.csv'}
                        data={this.state.data}
                      >
                        <i className="fa fa-download"> Export</i>
                      </CSVLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse" id="collapseExample">
                <div className="card-body">
                  <div className="row">
                    <label className="col-sm-2 form-control-label">Filter by Date</label>

                    <div className="col-5">
                      <div className="InputFromTo">
                        <DayPickerInput
                          value={from}
                          placeholder="From"
                          format="LL"
                          formatDate={formatDate}
                          parseDate={parseDate}
                          dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { after: to },
                            toMonth: to,
                            modifiers,
                            numberOfMonths: 2,
                            onDayClick: () => this.to.getInput().focus(),
                          }}
                          onDayChange={this.handleFromChange}
                        />{' '}
                        —{' '}
                        <span className="InputFromTo-to">
                          <DayPickerInput
                            ref={(el) => (this.to = el)}
                            value={to}
                            placeholder="To"
                            format="LL"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              selectedDays: [from, { from, to }],
                              disabledDays: { before: from },
                              modifiers,
                              month: from,
                              fromMonth: from,
                              numberOfMonths: 2,
                            }}
                            onDayChange={this.handleToChange}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={paddingTop}>
                    <label className="col-sm-2 form-control-label">Filter by Time</label>

                    <div className="col-5">
                      <select name={'hour'} onChange={this.handleHourChange}>
                        <option value="0">12:00 AM</option>
                        <option value="1">1:00 AM</option>
                        <option value="2">2:00 AM</option>
                        <option value="3">3:00 AM</option>
                        <option value="4">4:00 AM</option>
                        <option value="5">5:00 AM</option>
                        <option value="6">6:00 AM</option>
                        <option value="7">7:00 AM</option>
                        <option value="8">8:00 AM</option>
                        <option value="9">9:00 AM</option>
                        <option value="10">10:00 AM</option>
                        <option value="11">11:00 AM</option>
                        <option value="12">12:00 PM</option>
                        <option value="13">1:00 PM</option>
                        <option value="14">2:00 PM</option>
                        <option value="15">3:00 PM</option>
                        <option value="16">4:00 PM</option>
                        <option value="17">5:00 PM</option>
                        <option value="18">6:00 PM</option>
                        <option value="19">7:00 PM</option>
                        <option value="20">8:00 PM</option>
                        <option value="21">9:00 PM</option>
                        <option value="22">10:00 PM</option>
                        <option value="23">11:00 PM</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2"> </div>
                    <div className="col-5">
                      <button
                        type="button"
                        onClick={this.handleUpdateReport}
                        className="btn btn-primary"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card h-100">
              <TempAndHumidityGrid
                data={this.state.data}
                totalPages={this.props.pages}
                isLoading={this.props.isLoading}
                onTableChange={this.handleTableChange}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTempAndHumidity,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    getTempAndHumidity: PropTypes.func.isRequired,
    data: state.reports.items,
    total: state.reports.total,
    pages: state.reports.pages,
    isLoading: state.reports.isLoading,
  };
};

TempHumidityReport.propTypes = {
  getTempAndHumidity: PropTypes.func.isRequired,
  data: PropTypes.array,
  total: PropTypes.number,
  pages: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(TempHumidityReport);
