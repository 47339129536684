import React, { Component } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import cognitoActions from '../../redux/actions/cognitoActions';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import ErrorOutlined from '@material-ui/icons/ErrorOutlined';

import './auth.css';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 1,
  },
});

class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      validationMessage: '',
      validated: false,
    };
    this.submit = this.submit.bind(this);
  }

  validateForm() {
    if (this.state.email.length === 0) {
      this.setState({ validationMessage: 'Please enter your email address' });
      return false;
    }

    return true;
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      validationMessage: '',
    });
  };

  submit(event) {
    event.preventDefault();
    if (!this.validateForm()) {
      return;
    }

    cognitoActions
      .forgotPassword(this.state.email)
      .then((_res) => {
        this.setState({ validated: true });
      })
      .catch((e) => {
        this.setState({ validationMessage: e.response.data.message });
      });
  }

  render() {
    const { classes } = this.props;
    if (this.state.validated) {
      return <Redirect to="/" />;
    }
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <ErrorOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recover Account
          </Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <Typography align="center" className={classes.submit}>
                Enter your email address used to sign up for Sonar. We will send an email link you
                will follow to reset your password.
              </Typography>
              <TextField
                autoComplete="email"
                type="email"
                id="email"
                label="Email Address"
                className={classes.textField}
                margin="normal"
                helperText="Enter your email address"
                onChange={this.handleChange('email')}
              />
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.submit}
            >
              Send Recovery Email
            </Button>
            <Typography align="center" className={classes.submit}>
              Trying to login? <Link to="login">Login Here</Link>
            </Typography>
            {this.state.validationMessage ? (
              <div className="alert alert-danger">{this.state.validationMessage}</div>
            ) : (
              false
            )}
          </form>
        </Paper>
      </main>
    );
  }
}

export default withRouter(withStyles(styles)(Forgot));

// <div className="form d-flex align-items-center">
//     <div className="content">
//         <form onSubmit={this.handleSubmit}>
//             <div className="form-group">
//                 <h2>Confirm your email</h2>
//                 <p>
//                     Enter the email address you used to sign up for Sonar. We’ll send you a recovery
//                     confirmation by email.
//                 </p>
//                 <label className="label-material">Email</label>
//                 <input
//                     id="email"
//                     type="text"
//                     name="loginEmail"
//                     required=""
//                     className="input-material"
//                     value={this.state.email}
//                     onChange={this.handleChange}
//                 />
//             </div>
//             <div className="validation-message">{this.validationMessage()}</div>
//             <input type="submit" value="Send Confirmation" className="btn btn-primary" />
//         </form>
//     </div>
// </div>;
