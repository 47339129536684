import React, { Fragment } from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';

function Loading({ animationData, options, height, width, isStopped, isPaused }) {
  options.animationData = animationData;

  return (
    <Fragment>
      <Lottie
        options={options}
        width={width}
        height={height}
        isStopped={isStopped}
        isPaused={isPaused}
      />
    </Fragment>
  );
}

Loading.defaultProps = {
  options: {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
  height: 500,
  width: 500,
  isStopped: false,
  isPaused: false,
};

Loading.propTypes = {
  animationData: PropTypes.any.isRequired,

  // defaults
  options: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  isStopped: PropTypes.bool,
  isPaused: PropTypes.bool,
};

export default Loading;
