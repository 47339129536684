import React from 'react';
import PropTypes from 'prop-types';
import BeaconEditRow from './beaconEditRow';
import readingService from './../../../../services/readingsService';

class BeaconList extends React.Component {
  render() {
    return (
      <div>
        <div className="btn-group btn-group-sm">
          <button
            className="btn btn-sm ml-4 mt-2 mb-2"
            type="button"
            onClick={this.props.onAdd}
            disabled={this.props.editing}
          >
            Add Stationary Beacon
          </button>
        </div>

        <ul className="list-group list-group-flush">
          <li className="d-flex list-group-item list-group-item-action p-1 justify-content-between align-items-center">
            <div className="w-25">Beacon</div>
            <div className="w-25">
              <span className="text-right w-100">Coordinates</span>
            </div>
            <div className="w-25">
              <span className="text-right w-100">Environment</span>
            </div>
            <div className="w-25"></div>
          </li>
          {this.props.beacons.map((b, i) => {
            let temp, humidity;
            if (b.beaconId) {
              readingService
                .getLastReadingofReadingType(b.beaconId, 'externaltemperature')
                .then((res) => {
                  temp = res.data[0];
                });
              readingService
                .getLastReadingofReadingType(b.beaconId, 'relativehumidity')
                .then((res) => {
                  humidity = res.data[0];
                });
            }

            return (
              <li className="list-group-item list-group-item-action p-1" key={i}>
                {this.props.editing && b.index === this.props.editing.index ? (
                  <BeaconEditRow
                    beacon={b}
                    excludedSearchItems={this.props.excludedSearchItems}
                    onSearchFilterChanged={this.props.onSearchFilterChanged}
                    searchItems={this.props.searchItems}
                    onEditCompleted={this.props.onEditCompleted}
                    onAddCanceled={this.props.onAddCanceled}
                  />
                ) : (
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <a
                      href="# "
                      className="w-25"
                      onClick={() => this.props.onSelect('beacons', 'index', b.index)}
                    >
                      {b.isVisible ? (
                        <strong>{b.beacon.name}</strong>
                      ) : (
                        <span>{b.beacon.name}</span>
                      )}
                    </a>

                    <div className="w-25">
                      {b.location ? (
                        <span className="text-right w-100">
                          ({b.location.x}, {b.location.y})
                        </span>
                      ) : (
                        false
                      )}
                    </div>

                    <div className="w-25">
                      {temp && humidity ? (
                        <span className="text-right w-100">{`${temp}F - ${humidity}%`}</span>
                      ) : (
                        false
                      )}
                    </div>

                    <div className="w-25">
                      <div className="btn btn-group float-right">
                        <a
                          href="# "
                          className="btn flat-btn btn-default p-2"
                          onClick={() => this.props.onEdit(b)}
                          title="Edit Properties"
                        >
                          <i className="fas fa-edit" />
                        </a>
                        <a
                          href="# "
                          className="btn flat-btn btn-default p-2"
                          onClick={() => this.props.onEditMap(b)}
                          title="Change location on map"
                        >
                          <i className="fas fa-map-pin" />
                        </a>
                        <a
                          href="# "
                          onClick={() => this.props.onRemove('beacons', b.index)}
                          className="btn flat-btn btn-default p-2"
                          title="Remove from map."
                        >
                          <i className="fas fa-trash text-danger" />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

BeaconList.propTypes = {
  editing: PropTypes.object,
  beacons: PropTypes.array,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onEditMap: PropTypes.func,
  onSearchFilterChanged: PropTypes.func,
  excludedSearchItems: PropTypes.array,
  searchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
};

export default BeaconList;
