import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

class FrequencyChart extends Component {
  constructor(props) {
    super();

    this.state = {
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                stepSize: 10,
              },
            },
          ],
        },
      },
      data: {
        labels: [],
        datasets: [{}],
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.readings || !nextProps.readings.length) {
      return null;
    }

    let readings = JSON.parse(nextProps.readings[0].reading);
    let values = [];
    let labels = [];

    let frequencyStep = 1600 / readings.length;

    let lowest = 0;
    let highest = 0;

    readings.forEach((reading, i) => {
      if (frequencyStep * i >= 25) {
        values.push(reading);
        labels.push(frequencyStep * i + 'Hz');

        let value = reading;
        if (value < lowest) lowest = value;
        if (value > highest) highest = value;
      }
    });

    let range = highest - lowest;
    let minTick = 0;
    let maxTick = Math.max(100, highest + range * 0.25);
    let tickRange = maxTick - minTick;

    return {
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                min: minTick,
                max: maxTick,
                stepSize: tickRange / 4,
              },
            },
          ],
        },
      },
      data: {
        labels: labels,
        datasets: [
          {
            data: values,
            label: 'Amplitude',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            borderWidth: 1,
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 0,
            pointHoverRadius: 2,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 2,
          },
        ],
      },
    };
  }

  render() {
    return (
      <div className="col-lg-12" style={{ marginTop: '20px' }}>
        <div className=" card h-100">
          <div className="card-close" />
          <div className="card-header d-flex align-items-center">
            <h3 className="h4">Frequency</h3>
          </div>
          <div className="card-body">
            <Line data={this.state.data} options={this.state.options} />
          </div>
        </div>
      </div>
    );
  }
}

FrequencyChart.propTypes = {
  readings: PropTypes.array,
};

export default FrequencyChart;
