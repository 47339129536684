import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import {
  createLocationsLayerGroup,
  createLocationsLayerArray,
} from '../services/leafletLayerFactories/leafletLocationFactory';
import { createGatewaysLayerGroup } from '../services/leafletLayerFactories/leafletGatewayFactory';
import { createZonesLayerGroup } from '../services/leafletLayerFactories/leafletZoneFactory';
import { createPath } from '../services/leafletLayerFactories/lineLayerFactory]';
import { createBeaconsLayerGroup } from '../services/leafletLayerFactories/leafletBeaconFactory';

let locations;
let image;

// TODO: REDO THIS ENTIRE FILE
class LocationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      selectedIndex: null,
    };
  }

  onClickBeacon = (e) => {
    console.log('clicked beacon', e);
  };

  drawCanvas(map) {
    if (!map) {
      return;
    }

    map.eachLayer(function (layer) {
      if (layer._url == null) {
        map.removeLayer(layer);
      }
    });

    const zones = createZonesLayerGroup(this.props.zones);
    zones.addTo(map);
    const gateways = createGatewaysLayerGroup(this.props.gateways);
    gateways.addTo(map);
    const beacons = createBeaconsLayerGroup(
      this.props.beacons,
      this.props.reading,
      this.onClickBeacon
    );
    beacons.addTo(map);

    // NOT THIS
    if (this.props.marker) {
      let marker = new L.Marker([this.props.marker.y, this.props.marker.x]);
      marker.addTo(map);
    }

    if (this.props.static === true) {
      locations = createLocationsLayerGroup(this.props.locations);
      locations.addTo(map);
    }

    if (this.props.locations && this.props.locations.length > 0) {
      if (this.props.playback) {
        if (this.props.selectedIndex) {
          // USER IS CONTROLLING WHAT THEY SEE AND THE PACE
          if (this.props.displayType === 'lines') {
            locations = createPath(
              this.props.locations,
              this.props.selectedIndex,
              this.props.map.id
            );
            for (let i = 0; i < this.props.selectedIndex; i++) {
              if (this.props.locations[i].location.type === 0) {
                if (!map || !locations[i]) {
                  continue;
                }
                locations[i].addTo(map);
              }
            }
          } else {
            locations = createLocationsLayerArray(
              this.props.locations,
              this.props.selectedIndex,
              this.props.map.id
            );

            for (let i = 0; i < this.props.selectedIndex; i++) {
              if (this.props.locations[i].location.type === 0) {
                locations[i].addTo(map);
              }
            }
            for (let i = this.props.locations.length - 1; i < locations.length; i++) {
              locations[i].addTo(map);
            }
          }
        } else if (this.props.paused) {
          // USER IS CONTROLLING WHAT THEY SEE AND THE PACE
          if (this.props.displayType === 'lines') {
            locations = createPath(
              this.props.locations,
              this.props.selectedIndex,
              this.props.map.id
            );
            for (let i = 0; i < this.props.selectedIndex; i++) {
              if (this.props.locations[i].location.type === 0) {
                if (!map || !locations[i]) {
                  continue;
                }
                locations[i].addTo(map);
              }
            }
          } else {
            locations = createLocationsLayerArray(
              this.props.locations,
              this.props.selectedIndex,
              this.props.map.id
            );
            for (let i = 0; i < this.props.selectedIndex; i++) {
              if (this.props.locations[i].location.type === 0) {
                locations[i].addTo(map);
              }
            }
            for (let i = this.props.locations.length - 1; i < locations.length; i++) {
              locations[i].addTo(map);
            }
          }
        } else {
          //TODO: What is this code doing??
          // BREADCRUMBS ARE IN AUTO PLAY MODE
          if (this.props.displayType === 'lines') {
            locations = createPath(
              this.props.locations,
              this.props.selectedIndex,
              this.props.map.id
            );
          } else {
            locations = createLocationsLayerArray(
              this.props.locations,
              this.props.selectedIndex,
              this.props.map.id
            );
          }
        }
      } else {
        // INITIAL RUN
        if (this.props.displayType === 'lines') {
          locations = createPath(this.props.locations, this.props.selectedIndex, this.props.map.id);

          for (let i = 0; i < this.props.locations.length; i++) {
            if (this.props.locations[i].location.type === 0) {
              if (!map || !locations[i]) {
                continue;
              }
              locations[i].addTo(map);
            }
          }
        } else {
          locations = createLocationsLayerGroup(
            this.props.locations,
            this.props.selectedIndex,
            this.props.map.id
          );
          locations.addTo(map);
        }
      }
    } else if (this.props.selectedIndex === 0) {
      if (this.props.displayType === 'lines') {
        locations = createPath(this.props.locations);
        for (let i = 0; i < this.props.locations.length; i++) {
          if (this.props.locations[i].location.type === 1) {
            if (!map || !locations[i]) {
              continue;
            }
            locations[i].addTo(map);
          }
        }
      } else {
        locations = createLocationsLayerGroup(this.props.locations);
        locations.addTo(map);
      }
    }
  }

  componentDidMount() {
    let x = this.props.map.width;
    let y = this.props.map.height;
    let bounds = [
      [0, 0],
      [y, x],
    ];

    let map = L.map(this.props.id, {
      scrollWheelZoom: true,
      zoomControl: true,
      crs: L.CRS.Simple,
      maxBoundsViscosity: 0.8,
      maxBounds: bounds,
      attributionControl: false,
      minZoom: -5,
      zoomDelta: 0.1,
      zoomSnap: 0.1,
    });

    image = L.imageOverlay(this.props.map.imageUrl, bounds);
    map.addLayer(image);
    map.fitBounds(bounds);

    this.setState({ map: map, loaded: true });
    this.drawCanvas(map);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { map } = this.state;
    if (this.props.map.id !== prevProps.map.id) {
      if (map.hasLayer(image)) {
        map.removeLayer(image);
        let x = this.props.map.width;
        let y = this.props.map.height;
        let bounds = [
          [0, 0],
          [y, x],
        ];
        image = L.imageOverlay(this.props.map.imageUrl, bounds);
        map.addLayer(image);
        map.fitBounds(bounds);
      }
    }

    this.drawCanvas(map);
  }

  render() {
    return <div id={this.props.id} style={{ height: this.props.height }} />;
  }
}

LocationMap.defaultProps = {
  id: 'map',
};

LocationMap.propTypes = {
  id: PropTypes.string,
  map: PropTypes.object,
  locations: PropTypes.array,
  zones: PropTypes.array,
  gateways: PropTypes.array,
  beacons: PropTypes.array,
  height: PropTypes.string,
  playback: PropTypes.bool,
  displayType: PropTypes.string,
  marker: PropTypes.object,
  reading: PropTypes.string,
  selectedIndex: PropTypes.number,
  pace: PropTypes.number,
  paused: PropTypes.bool,
};

export default LocationMap;
