import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { changeLocation } from '../../redux/actions/navigationActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const guid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

const parsers = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Pebble',
    label: 'Pebble',
  },
  {
    value: 'TICK',
    label: 'Tick',
  },
  {
    value: 'Kontakt',
    label: 'Kontakt',
  },
  {
    value: 'minew',
    label: 'Minew',
  },

  {
    value: 'pebble',
    label: 'Tempo',
  },
  {
    value: 'inkbird',
    label: 'Inkbird',
  },
  {
    value: 'kaipule',
    label: 'Kaipule',
  },
  {
    value: 'miscale',
    label: 'Xiaomi MiScale',
  },
  {
    value: 'viatom-checkme-pro',
    label: 'Viatom Checkme Pro',
  },
  {
    value: 'wired-vibration',
    label: 'Hardwired Vibration',
  },
  {
    value: 'generic',
    label: 'Generic Parser',
  },
];

const obj = {};

class TextFields extends React.Component {
  state = {
    name: '',
    description: '',
    deviceId: '',
    deviceParser: '',
    manufacturerName: '',
    manufacturerModel: '',
    manufacturerYear: '',
    serialNumber: '',
    altId: '',
    keywords: '',
    humidityOffset: '',
    temperatureOffset: '',
    editCheck: false,
    notes: '',
    movementThreshold: '',
    deviceMAC: true,
  };

  buildObj = () => {
    let newObj = { context: {} };
    if (obj.name) {
      newObj.name = this.state.name;
    }
    if (obj.description) {
      newObj.description = this.state.description;
    }
    if (obj.deviceId) {
      newObj.deviceId = this.state.deviceId;
    }
    if (obj.healthFrequency) {
      newObj.healthFrequency = this.state.healthFrequency;
    }

    if (obj.deviceParser) {
      newObj.deviceType = {
        parser: { name: this.state.deviceParser, version: 1 },
      };
    }
    if (obj.manufacturerName) {
      newObj.context.manufacturer = {};
      newObj.context.manufacturer.name = this.state.manufacturerName;
    }

    if (obj.manufacturerModel) {
      if (!newObj.context.manufacturer) {
        newObj.context.manufacturer = {};
      }
      newObj.context.manufacturer.model = this.state.manufacturerModel;
    }
    if (obj.manufacturerYear) {
      if (!newObj.context.manufacturer) {
        newObj.context.manufacturer = {};
      }
      newObj.context.manufacturer.year = this.state.manufacturerYear;
    }
    if (obj.serialNumber) {
      newObj.context.serialNumber = this.state.serialNumber;
    }
    if (obj.altId) {
      newObj.context.alternateIdentifier = this.state.altId;
    }
    if (obj.notes) {
      newObj.context.notes = this.state.notes;
    }
    if (obj.keywords) {
      newObj.keywords = this.state.keywords;
    }
    if (obj.humidityOffset) {
      newObj.humidityOffset = this.state.humidityOffset;
    }
    if (obj.temperatureOffset) {
      newObj.temperatureOffset = this.state.temperatureOffset;
    }
    if (obj.movementThreshold) {
      newObj.movementThreshold = this.state.movementThreshold;
    }
    return newObj;
  };

  formatMAC = (e) => {
    if (!this.state.deviceMAC) {
      return e.target.value;
    }
    if (e.target.value === '') {
      return '';
    }
    let r = /([a-f0-9]{2})([a-f0-9]{2})/i,
      str = e.target.value.replace(/[^a-f0-9]/gi, '');

    while (r.test(str)) {
      str = str.replace(r, `${`$1`}:${`$2`}`).toUpperCase();
    }
    return str.slice(0, 17).toUpperCase();
  };

  handleChange = (name) => (event) => {
    if (name === 'deviceId') {
      let formatted = this.formatMAC(event);
      this.setState({ [name]: formatted });
      obj[name] = true;
      return;
    }
    if (name === 'deviceMAC') {
      this.setState({ [name]: !this.state[name] });
      return;
    }
    this.setState({
      [name]: event.target.value,
    });
    obj[name] = true;
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let newObj = {};

    if (
      nextProps.initialValues &&
      prevState.deviceId.length === 0 &&
      prevState.editCheck === false
    ) {
      if (nextProps.initialValues && nextProps.initialValues.name) {
        newObj.name = nextProps.initialValues.name;
      }

      if (nextProps.initialValues && nextProps.initialValues.description) {
        newObj.description = nextProps.initialValues.description;
      }

      if (nextProps.initialValues && nextProps.initialValues.deviceId) {
        newObj.deviceId = nextProps.initialValues.deviceId;
        newObj.editCheck = true;
      }

      if (nextProps.initialValues && nextProps.initialValues.healthFrequency) {
        newObj.healthFrequency = nextProps.initialValues.healthFrequency;
      }

      if (nextProps.initialValues && nextProps.initialValues.locationFrequency) {
        newObj.locationFrequency = nextProps.initialValues.locationFrequency;
      }

      if (
        nextProps.initialValues &&
        nextProps.initialValues.deviceType &&
        nextProps.initialValues.deviceType.parser &&
        nextProps.initialValues.deviceType.parser.name
      ) {
        newObj.deviceParser = nextProps.initialValues.deviceType.parser.name;
      }

      if (
        nextProps.initialValues &&
        nextProps.initialValues.context &&
        nextProps.initialValues.context.manufacturer &&
        nextProps.initialValues.context.manufacturer.name
      ) {
        newObj.manufacturerName = nextProps.initialValues.context.manufacturer.name;
      }

      if (
        nextProps.initialValues &&
        nextProps.initialValues.context &&
        nextProps.initialValues.context.manufacturer &&
        nextProps.initialValues.context.manufacturer.model
      ) {
        newObj.manufacturerModel = nextProps.initialValues.context.manufacturer.model;
      }

      if (
        nextProps.initialValues &&
        nextProps.initialValues.context &&
        nextProps.initialValues.context.manufacturer &&
        nextProps.initialValues.context.manufacturer.year
      ) {
        newObj.manufacturerYear = nextProps.initialValues.context.manufacturer.year;
      }

      if (
        nextProps.initialValues &&
        nextProps.initialValues.context &&
        nextProps.initialValues.context.serialNumber
      ) {
        newObj.serialNumber = nextProps.initialValues.context.serialNumber;
      }

      if (
        nextProps.initialValues &&
        nextProps.initialValues.context &&
        nextProps.initialValues.context.alternateIdentifier
      ) {
        newObj.altId = nextProps.initialValues.context.alternateIdentifier;
      }

      if (
        nextProps.initialValues &&
        nextProps.initialValues.context &&
        nextProps.initialValues.context.notes
      ) {
        newObj.notes = nextProps.initialValues.context.notes;
      }

      if (nextProps.initialValues && nextProps.initialValues.keywords) {
        newObj.keywords = nextProps.initialValues.keywords.toString();
      }
      if (nextProps.initialValues && nextProps.initialValues.humidityOffset) {
        newObj.humidityOffset = nextProps.initialValues.humidityOffset;
      }

      if (nextProps.initialValues && nextProps.initialValues.temperatureOffset) {
        newObj.temperatureOffset = nextProps.initialValues.temperatureOffset;
      }

      if (nextProps.initialValues && nextProps.initialValues.movementThreshold) {
        newObj.movementThreshold = nextProps.initialValues.movementThreshold;
      }

      return newObj;
    }

    return null;
  }

  render() {
    return (
      <div className="d-flex flex-wrap">
        <div className="col-xl-8 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">Beacon Information</h3>
            </div>
            <div className="card-body">
              <p>
                Enter information about this beacon, which parser to use and how it will be
                displayed in Sonar.
              </p>
              <div className="form-horizontal">
                <div className="form-group">
                  <label htmlFor="device-name" className=" form-control-label">
                    Name
                  </label>
                  <div className="">
                    <input
                      id="device-name"
                      className="form-control"
                      value={this.state.name}
                      onChange={this.handleChange('name')}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="device-id" className=" form-control-label">
                    Device ID
                  </label>
                  <div className="">
                    <input
                      id="device-id"
                      className="form-control"
                      value={this.state.deviceId}
                      onChange={this.handleChange('deviceId')}
                    />
                    <small>Enter the device ID exactly as you see it on your device.</small>
                  </div>
                </div>
                <div className="form-group form-check">
                  <div className="">
                    <input
                      id="device-ismac"
                      className="form-check-input"
                      onChange={this.handleChange('deviceMAC')}
                      type="checkbox"
                      checked={this.state.deviceMAC}
                    />{' '}
                    <label htmlFor="device-ismac" className=" form-check-label">
                      Is MAC Address
                    </label>
                    <br />
                    <small>
                      If your device ID is something other than a MAC Address please deselect the
                      checkbox.
                    </small>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="device-description" className=" form-control-label">
                    Description
                  </label>
                  <div className="">
                    <textarea
                      id="device-description"
                      className="form-control"
                      value={this.state.description}
                      onChange={this.handleChange('description')}
                      rows="2"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="device-parser" className=" form-control-label">
                    Parser
                  </label>
                  <div className="">
                    <select
                      id="device-parser"
                      className="form-control"
                      value={this.state.deviceParser}
                      onChange={this.handleChange('deviceParser')}
                    >
                      {parsers.map((option) => (
                        <option key={guid()} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="keywords" className=" form-control-label">
                    Keywords
                  </label>
                  <div className="">
                    <textarea
                      id="keywords"
                      className="form-control"
                      value={this.state.keywords}
                      onChange={this.handleChange('keywords')}
                      rows="2"
                    />
                    <small>
                      Enter as many keywords, or tags to use in searching for this device. Separate
                      each keyword with a comma.
                    </small>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="thingFormNotes" className=" form-control-label">
                    Notes
                  </label>
                  <div className="">
                    <textarea
                      id="thingFormNotes"
                      className="form-control"
                      value={this.state.notes}
                      onChange={this.handleChange('notes')}
                      rows="2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">Equipment Information</h3>
            </div>
            <div className="card-body">
              <div className="col-sm-12">
                <p>Enter information about the equipment this beacon is attached to.</p>
                <div className="form-horizontal">
                  <div className="form-group">
                    <label htmlFor="alt-id" className=" form-control-label">
                      Alternate ID
                    </label>
                    <div className="">
                      <input
                        id="alt-id"
                        className="form-control"
                        value={this.state.altId}
                        onChange={this.handleChange('altId')}
                      />
                      <small>ex. Crest ID</small>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="thingFormSerialNumber" className=" form-control-label">
                      Serial #
                    </label>
                    <div className="">
                      <input
                        id="thingFormSerialNumber"
                        className="form-control"
                        value={this.state.serialNumber}
                        onChange={this.handleChange('serialNumber')}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="manufacturer-name" className=" form-control-label">
                      Manufacturer
                    </label>
                    <div className="">
                      <input
                        id="manufacturer-name"
                        className="form-control"
                        value={this.state.manufacturerName}
                        onChange={this.handleChange('manufacturerName')}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="manufacturer-model" className=" form-control-label">
                      Model
                    </label>
                    <div className="">
                      <input
                        id="manufacturer-model"
                        className="form-control"
                        value={this.state.manufacturerModel}
                        onChange={this.handleChange('manufacturerModel')}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="manufacturer-year" className="form-control-label">
                      Year
                    </label>
                    <div className="">
                      <input
                        id="manufacturer-year"
                        className="form-control"
                        value={this.state.manufacturerYear}
                        onChange={this.handleChange('manufacturerYear')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">Calibration/Offset</h3>
            </div>
            <div className="card-body">
              <div className="col-sm-12">
                <p>
                  Set the offset values to be reported for temperature and humidity. This can be
                  used to calibrate the devices.
                </p>
                <div className="form-horizontal">
                  <div className="form-group">
                    <label htmlFor="temperature-offset" className=" form-control-label">
                      Temperature
                    </label>
                    <div className="">
                      <input
                        id="temperature-offset"
                        className="form-control"
                        value={this.state.temperatureOffset}
                        onChange={this.handleChange('temperatureOffset')}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="humidity-offset" className=" form-control-label">
                      Humidity
                    </label>
                    <div className="">
                      <input
                        id="humidity-offset"
                        className="form-control"
                        value={this.state.humidityOffset}
                        onChange={this.handleChange('humidityOffset')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">Location Configuration</h3>
            </div>
            <div className="card-body">
              <p>Set the movement threshold and zone buffer for this device.</p>
              <div className="form-group">
                <label htmlFor="movement-threshold" className=" form-control-label">
                  Movement Threshold
                </label>
                <div className="">
                  <input
                    id="movement-threshold"
                    className="form-control"
                    value={this.state.movementThreshold}
                    onChange={this.handleChange('movementThreshold')}
                  />
                  <small>
                    This controls how far the device must move inside a zone before being logged as
                    a movement. Enter 0 to only log movements between zones.
                  </small>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="zone-buffer" className=" form-control-label">
                  Zone Buffer
                </label>
                <div className="">
                  <input
                    id="zone-buffer"
                    className="form-control"
                    value={this.state.movementThreshold}
                    onChange={this.handleChange('zoneBuffer')}
                  />
                  <small>
                    This control how far the device must leave a zone before being logged as a
                    movement.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group" style={{ margin: '0 auto' }}>
            <button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                this.props.submit(this.buildObj());
              }}
            >
              Submit
            </button>
            <button
              className="btn btn-default"
              onClick={() => {
                this.props.changeLocation('/things');
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeLocation,
    },
    dispatch
  );

TextFields.propTypes = {
  classes: PropTypes.object.isRequired,
  submit: PropTypes.func,
  formHeader: PropTypes.string,
  changeLocation: PropTypes.func,
  initialValues: PropTypes.object, // if Editing this will be the thing object
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(TextFields));
