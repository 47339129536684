import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

import infraApp from '../reducers/index';
// import logger from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import { history } from './browserHistory';
import { isDev } from '../../utils';

function initializeStore() {
  // Build the middleware for intercepting and dispatching navigation actions
  const middleware = routerMiddleware(history);
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    infraApp,
    composeEnhancers(
      applyMiddleware(thunk),
      // applyMiddleware(logger),
      applyMiddleware(middleware)
    )
  );

  const persistor = persistStore(store);

  return { store, persistor };
}

const { store, persistor } = initializeStore();

if (isDev()) {
  window.store = store;
}

export { store, persistor };
