import { toast } from 'react-toastify';

export default {
  warn(msg) {
    return toast.warn(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'toast-warn',
    });
  },
  error(msg) {
    return toast.error(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'toast-failure',
    });
  },
  success(msg = '') {
    return toast.success(msg || 'Success', {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'toast-success',
    });
  },
  info(msg) {
    return toast.info(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: false,
      className: 'toast-info',
    });
  },
};
