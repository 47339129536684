import React from 'react';

import './footer.css';

function Footer() {
  return (
    <footer className="t-footer">
      <img
        src="/img/footer.png"
        alt={`Thinaer \u00a9 2018-${new Date().getFullYear()}`}
        title={`Thinaer \u00a9 2018-${new Date().getFullYear()}`}
        style={{ height: '20px' }}
      />
    </footer>
  );
}

export default Footer;
