import React, { Component } from 'react';
import swal from '@sweetalert/with-react';
import BulkModal from './beaconBulk';

const guid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

const headerStyle = {
  cursor: 'pointer',
};

const rowStyle = {
  cursor: 'pointer',
};

class GatewayBulkUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failedArr: [],
    };
    this.getFailed = this.getFailed.bind(this);
    this.renderModal = this.renderModal.bind(this);
  }
  componentDidMount() {
    setTimeout(this.renderModal, 200);
  }

  getFailed = (data) => {
    this.setState({ failedArr: data });
  };

  renderModal() {
    swal(<BulkModal getFailedFunc={this.getFailed} />, { buttons: false });
  }
  render() {
    return (
      <div>
        {this.state.failedArr.length > 0 ? (
          <div>
            <div style={{ textAlign: 'center' }}>
              Failed to create Beacons for the following entries
            </div>
            <table className="table table-sm table-hover">
              <thead>
                <tr>
                  <th scope="col" style={headerStyle}>
                    Row Index
                  </th>
                  <th scope="col" style={headerStyle}>
                    Name
                  </th>
                  <th scope="col" style={headerStyle}>
                    Device ID
                  </th>
                  <th scope="col" style={headerStyle}>
                    Message
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.failedArr.length > 0
                  ? this.state.failedArr.map((item) => {
                      console.log(item);
                      return (
                        <tr key={guid()} style={rowStyle}>
                          <td>
                            {(item && item.index) || item.index === 0 ? item.index + 1 : 'N/A'}
                          </td>
                          <td>{item && item.item && item.item.name ? item.item.name : ''}</td>
                          <td>
                            {item && item.item && item.item.deviceId ? item.item.deviceId : ''}
                          </td>
                          <td>{item && item.message ? item.message : ''}</td>
                        </tr>
                      );
                    })
                  : ''}
              </tbody>
            </table>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default GatewayBulkUpload;
