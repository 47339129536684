import { push } from 'react-router-redux';
import * as types from '../types';
import thingService from '../../services/thingService';
import toast from '../../components/utils/toast';

export const requestThingSummmyary = () => {
  return {
    type: types.THING_SUMMARY_REQUEST,
  };
};
/* 
    Update a thing initiation
*/
export const startUpdateThing = () => {
  return {
    type: types.THING_PATCH_STARTED,
  };
};

/* 
    Post a thing initiation
*/
export const startCreateThing = () => {
  return {
    type: types.THING_POST_STARTED,
  };
};

/* 
    Delete a thing initiation
*/
export const startDeleteThing = () => {
  return {
    type: types.THING_DELETE_STARTED,
  };
};

/* 
    Request all things initiation
*/
export const requestThings = () => {
  return {
    type: types.THINGS_REQUEST,
  };
};

/* 
    Request a thing initiation
*/
export const requestThing = () => {
  return {
    type: types.THING_REQUEST,
  };
};

/* 
    Post a Thing
*/
export function createThing(thing) {
  return function (dispatch) {
    dispatch(startCreateThing());

    thingService
      .create(thing)
      .then((res) => {
        toast.success();
        dispatch({
          type: types.THING_POST_COMPLETED,
          thing: res.data,
        });
        dispatch(push(`/things`));
      })
      .catch((res) =>
        dispatch({
          type: types.THING_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

/* 
    Post a Thing
*/
export function createThingSuccess(res) {
  return function (dispatch) {
    dispatch({
      type: types.THING_POST_COMPLETED,
      thing: res.data,
    });
    toast.success();
    dispatch(push(`/things`));
  };
}

/* 
    Update a Thing
*/
export function updateThing(id, thing) {
  return function (dispatch) {
    dispatch(startUpdateThing());

    thingService
      .update(id, thing)
      .then((res) => {
        toast.success();
        dispatch({
          type: types.THING_PATCH_COMPLETED,
          //                    thing: res.data
        });
        dispatch(push(`/things/${id}`));
      })
      .catch((res) =>
        dispatch({
          type: types.THING_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

/* 
    Update a Thing
*/
export function deleteThing(id) {
  return function (dispatch) {
    dispatch(startDeleteThing());

    thingService
      .remove(id)
      .then((res) => {
        toast.success();
        dispatch({
          type: types.THING_DELETE_COMPLETED,
          thing: res.data,
        });
        dispatch(getAllThings());
      })
      .catch((res) =>
        dispatch({
          type: types.THING_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

/* 
    Individual thing action 
*/
export function getThing(id) {
  return function (dispatch) {
    dispatch(requestThing());

    thingService
      .get(id)
      .then((res) => {
        dispatch({
          type: types.THING_RECEIVED,
          thing: res.data,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.THING_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export function clearThing() {
  return function (dispatch) {
    dispatch({
      type: types.THING_CLEAR,
    });
  };
}

export function getThingSummary() {
  return function (dispatch) {
    dispatch(requestThingSummmyary());

    thingService
      .getThingSummary()
      .then((res) => {
        return dispatch({
          type: types.THING_SUMMARY_RECEIVED,
          online: res.data.online,
          offline: res.data.offline,
          provisioned: res.data.provisioned,
          total: res.data.total,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.THING_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

/*
    All things action
*/
export function getAllThings(page, limit, sort, order) {
  return function (dispatch) {
    dispatch(requestThings());

    thingService
      .getAll(page, limit, sort, order)
      .then((res) => {
        return dispatch({
          type: types.THINGS_RECEIVED,
          things: res.data.results,
          pages: res.data.pageCount,
          total: res.data.total,
          currentPage: page,
          currentOrder: order,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.THING_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}
