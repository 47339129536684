import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Refresh from '@material-ui/icons/Refresh';

import './auth.css';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 1,
  },
});
class RegistrationMessage extends Component {
  render() {
    const { classes } = this.props;
    /*if (this.state.authenticated) {
            return <Redirect to="/" />;
        }*/
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            {this.props.flow === 'confirm' ? <Refresh /> : <CheckCircle />}
          </Avatar>
          <Typography component="h1" variant="h5">
            {this.props.flow === 'confirm' ? 'Reset Password' : 'Confirm Registration'}
          </Typography>

          <Typography component="h1" align="center">
            <br />
          </Typography>

          <Typography component="h1" align="center">
            You will receive an email confirmation containing a link.
          </Typography>

          <Typography component="h2" align="center">
            Please follow the link, afterwards you may login.
          </Typography>

          <Typography component="h1" align="center">
            <br />
          </Typography>

          <Typography align="center" className={classes.submit}>
            Trying to login? <Link to="login">Login Here</Link>
          </Typography>
        </Paper>
      </main>
    );
  }
}

RegistrationMessage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  flow: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RegistrationMessage);
