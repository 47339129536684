import Api from './../config/api';
import { store } from '../redux/store/store';

let state;

const getPreferences = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/users/preferences`
  );
};
const getFavorites = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/users/preferences/favorites`
  );
};
const getRecentlyViewed = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/users/preferences/recentlyviewed`
  );
};

const newFavorite = (id, type) => {
  state = store.getState();
  return Api.post(`${process.env.REACT_APP_API_URL}/users/preferences/favorites`, {
    applicationId: state.navigation.currentApplication.appID,
    itemId: id,
    itemType: type,
  });
};

const updateFavorites = (arr) => {
  state = store.getState();
  return Api.post(`${process.env.REACT_APP_API_URL}/users/preferences/favorites/all`, arr);
};

const removeFavorite = (id) => {
  state = store.getState();
  return Api.delete(`${process.env.REACT_APP_API_URL}/users/preferences/favorites/${id}`);
};

const listUsers = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/accountmanagement/users`
  );
};

const updateUserRole = (email, role) => {
  state = store.getState();
  return Api.post(`${process.env.REACT_APP_API_URL}/users/${email}/role/${role}`);
};

const deleteUser = (userId) => {
  state = store.getState();
  return Api.delete(`${process.env.REACT_APP_API_URL}/users/${userId}`);
};

const updateUserPhone = (email, phone) => {
  state = store.getState();
  return Api.post(`${process.env.REACT_APP_API_URL}/users/${email}/phoneNumber/${phone}`);
};

const inviteUser = (email, role) => {
  state = store.getState();
  return Api.post(`${process.env.REACT_APP_API_URL}/accountmanagement/invitation`, [
    { username: email, role, applicationId: state.navigation.currentApplication.appID },
  ]);
};

export default {
  getPreferences,
  getFavorites,
  getRecentlyViewed,
  newFavorite,
  removeFavorite,
  updateFavorites,
  listUsers,
  updateUserRole,
  deleteUser,
  updateUserPhone,
  inviteUser,
};
