import Api from '../config/api';
import { STORAGE_KEYS } from '../constants';
import toast from '../components/utils/toast';

/**
 * @desc Gets Application objects and also stores in localStorage
 * @returns {Array.<{id: String, name: String}>} applications
 */
async function getApplications() {
  try {
    const appsRes = await Api.get(`${process.env.REACT_APP_API_URL}/applications`);
    localStorage.setItem(STORAGE_KEYS.APP_OBJECTS, JSON.stringify(appsRes.data));
    return appsRes.data;
  } catch (err) {
    toast.error('Failed to retrieve Applications');
  }
}

export default {
  getApplications,
};
