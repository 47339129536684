import Api from './../config/api';
import { store } from '../redux/store/store';

let state;

const getAll = (page = 1, limit = 20, sort = 'name', order = 'asc') => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/people?page=${page}&limit=${limit}&sort=${sort}&order=${order}`
  );
};

const create = (person) => {
  state = store.getState();
  return Api.post(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/people`,
    person
  );
};

const createPersonDevice = (id, device) => {
  state = store.getState();
  return Api.post(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/people/${id}/devices`,
    device
  );
};

// patches a place
const updatePersonDevice = (id, deviceId, device) => {
  state = store.getState();
  return Api.put(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/people/${id}/devices/${deviceId}`,
    device
  );
};

// gets last reading
const getPersonDeviceLastReading = (id, deviceId) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/people/${id}/devices/${deviceId}/lastreading`
  );
};

// patches a place
const deletePersonDevice = (id, deviceId) => {
  state = store.getState();
  return Api.delete(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/people/${id}/devices/${deviceId}`
  );
};

// patches a place
const getPersonDevices = (id) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/people/${id}/devices/`
  );
};

const getPersonDevice = (id, deviceId) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/people/${id}/devices/${deviceId}`
  );
};

// patches a person
const update = (id, place) => {
  state = store.getState();
  return Api.put(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/people/${id}`,
    place
  );
};

// patches a place
const remove = (id) => {
  state = store.getState();
  return Api.delete(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/people/${id}`
  );
};

// returns a place
const get = (id) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${state.navigation.currentApplication.appID}/people/${id}`
  );
};

export default {
  getAll,
  create,
  update,
  remove,
  createPersonDevice,
  updatePersonDevice,
  getPersonDevice,
  getPersonDevices,
  deletePersonDevice,
  getPersonDeviceLastReading,
  get,
};
