import React, { Component } from 'react';
import PropTypes from 'prop-types';

const centerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

class StatusCard extends Component {
  getCardColor() {
    if (this.props.cardColor) {
      return {
        color: this.props.cardColor,
      };
    }
  }

  getCardTypeIcon() {
    switch (this.props.cardType) {
      case 'online':
        return <i className="fa fa-signal fa-2x"> </i>;
      case 'offline':
        return <i className="fa fa-plug fa-2x"> </i>;
      case 'provisioned':
        return <i className="fa fa-certificate fa-2x"> </i>;
      case 'thing':
        return <i className="fa fa-tags fa-2x"> </i>;
      case 'place':
        return <i className="fa fa-home fa-2x"> </i>;
      default:
        return '';
    }
  }

  render() {
    return (
      <div className="container-fluid statistic has-shadow">
        <div className="row">
          <div className="col-3" style={centerStyle}>
            {this.getCardTypeIcon()}
          </div>
          <div className="col" style={{ padding: 0 }}>
            <div className="col">
              <small>{this.props.cardTitle}</small>
            </div>
            <div className="col">
              <strong style={this.getCardColor()}>{this.props.cardCount}</strong>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StatusCard.propTypes = {
  cardTitle: PropTypes.string,
  cardCount: PropTypes.number,
  cardType: PropTypes.string,
  cardColor: PropTypes.string,
};

export default StatusCard;
