import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class AdvancedHealthCard extends React.Component {
  render() {
    if (!this.props.recordedAt) {
      return <div />;
    }

    return (
      <div className="col-lg-6">
        <div className="card h-100">
          <div className="card-header d-flex align-items-center">
            <h3 className="h4">Vitals</h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <i className="fa fa-clock-o" />
                &nbsp; Recorded
              </div>
              <div className="col-md-5">
                {moment(this.props.recordedAt).format('MM/DD/YYYY hh:mm:ss A')}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <i className="fa fa-battery-three-quarters" />
                &nbsp; Battery Level
              </div>
              <div className="col-md-5">{this.props.batteryLevel}</div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <i className="fa fa-heartbeat" />
                &nbsp; Heart rate
              </div>
              <div className="col-md-5">{this.props.heartRate}</div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <i className="fa fa-medkit" />
                &nbsp; Pulse Ox (SpO2)
              </div>
              <div className="col-md-5">{this.props.spO2}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdvancedHealthCard.propTypes = {
  heartRate: PropTypes.number,
  spO2: PropTypes.number,
  batteryLevel: PropTypes.number,
  recordedAt: PropTypes.string,
};

export default AdvancedHealthCard;
