import React from 'react';
import PropTypes from 'prop-types';

class Pager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: this.props.page,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleIncrement = this.handleIncrement.bind(this);
  }

  componentDidUpdate(prevProps) {
    const page = parseInt(this.props.page, 10);
    const pageCount = parseInt(this.props.pageCount, 10);
    if (page > pageCount) {
      this.resetPager();
    }
  }

  handleChange(event) {
    const newPage = Number(event.target.value);

    if (Number.isNaN(newPage)) return; // dont allow inputs that arent a number
    if (newPage > Number(this.props.pageCount)) return; // dont allow inputs greater than pageCount

    const { page } = this.state;
    if (page !== newPage) {
      this.setState({ page: newPage });
      if (newPage > 0) this.props.onChange(newPage + '');
    }
  }

  handleIncrement(count) {
    let { page } = this.state;
    page = page * 1 + count;

    this.setState({ page: page });
    this.props.onChange(page + '');
  }

  resetPager = () => {
    this.handleChange({
      target: {
        value: '1',
      },
    });
  };

  render() {
    return (
      <div className="input-group input-group-sm">
        <span>Page</span>
        <div className="input-group-prepend">
          <button
            className="btn"
            disabled={this.state.page === 1}
            onClick={() => this.handleIncrement(-1)}
          >
            <i className="fa fa-caret-left" />
          </button>
        </div>

        <input
          className="form-control"
          value={this.state.page}
          onChange={(e) => this.handleChange(e)}
          style={{ width: '40px', textAlign: 'center' }}
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn"
            disabled={this.state.page + '' === this.props.pageCount + ''}
            onClick={() => this.handleIncrement(1)}
          >
            <i className="fa fa-caret-right" />
          </button>
        </div>
        <span>of {this.props.pageCount}</span>
      </div>
    );
  }
}

Pager.defaultProps = {
  page: '1',
};

Pager.propTypes = {
  pageCount: PropTypes.string,
  onChange: PropTypes.func,
};

export default Pager;
