// grid column definitions
export default [
  {
    headerName: 'Email',
    field: 'userName',
    comparator(valueA, valueB) {
      return valueA.toUpperCase().localeCompare(valueB.toUpperCase());
    },
    suppressKeyboardEvent(params) {
      return true;
    },
  },
  {
    headerName: 'Phone',
    field: 'phoneNumber',
    valueFormatter(params) {
      if (!params.value) return 'N/A';
    },
    editable: true,
    suppressKeyboardEvent(params) {
      return true;
    },
  },
  {
    headerName: 'Role',
    field: 'userRole',
    editable: true,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['Admin', 'User', 'ReadOnly'],
    },
    suppressKeyboardEvent(params) {
      return true;
    },
  },
  {
    headerName: 'Email Confirmed',
    field: 'emailConfirmed',
    cellRenderer: 'resendInviteRenderer',
    suppressKeyboardEvent(params) {
      return true;
    },
  },
  {
    headerName: 'Locked Out',
    field: 'lockoutEnd',
    suppressKeyboardEvent(params) {
      return true;
    },
  },
  {
    headerName: '',
    cellRenderer: 'deleteButtonRenderer',
    suppressKeyboardEvent(params) {
      return true;
    },
    width: '125',
    suppressCellSelection: true,
  },
];
