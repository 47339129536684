// modules
import React, { Component } from 'react';
import _ from 'lodash';
// import PropTypes from 'prop-types';

// locals
import CrumbsHeader from '../../components/layout/crumbsHeader';
import GatewaysGrid from '../../components/grids/gatewaysGrid';
import placeService from '../../services/placeService';

class GatewaysContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSize: 20,
      currentPage: 1,
      gateways: [],
      isLoading: false,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  handleRowClick(place) {
    //        this.props.deletePlace(place);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    placeService
      .getGateways()
      .then((res) => {
        if (_.isArray(res.data)) {
          this.setState({ gateways: res.data, isLoading: false });
        } else {
          this.setState({ gateways: [], isLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
  }

  handleTableChange(table, _instance) {
    // let sort;
    // let order;
    // if (table.sorted.length === 1) {
    //     sort = table.sorted[0].id;
    //     order = table.sorted[0].desc ? 'desc' : 'asc';
    // }
    // this.props.getAllPlaces(table.page + 1, table.pageSize, sort, order);
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.currentApplication.appID !== this.props.currentApplication.appID) {
    //     this.props.getAllPlaces(1, 20);
    //     this.props.getPlaceSummary();
    // }
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Gateways'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/Gateways', label: 'Gateways' },
          ]}
        />
        <section>
          <div className="container-fluid">
            <div className="card h-100">
              <GatewaysGrid
                gateways={this.state.gateways}
                isLoading={this.state.isLoading}
                onTableChange={this.handleTableChange}
                onRowClick={this.handleRowClick}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

GatewaysContainer.propTypes = {};

export default GatewaysContainer;
