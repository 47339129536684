import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextField extends Component {
  renderRowError() {
    return this.props.meta.error ? 'has-danger' : '';
  }

  render() {
    let rowClassName = 'form-group row ';
    let textClassName = 'form-control ';

    if (this.props.meta.touched && this.props.meta.error) {
      rowClassName += 'has-danger';
      textClassName += 'is-invalid';
    }

    return (
      <div className={rowClassName}>
        <span className="col-sm-2 form-control-label">{this.props.label}</span>
        <div className="col-sm-6">
          <input
            className={textClassName}
            {...this.props.input}
            placeholder={this.props.label}
            type={this.props.type}
          />
          {this.props.meta.touched && this.props.meta.error && (
            <div className="invalid-feedback">
              {this.props.label} is {this.props.meta.error}
            </div>
          )}
        </div>
      </div>
    );
  }
}

TextField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  type: PropTypes.string,
  meta: PropTypes.object,
};

export default TextField;
