import React, { Component } from 'react';
import CloseModal from './../../containers/admin/accountManagement/CloseModal';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import swal from '@sweetalert/with-react';

const guid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

const snoozeTimes = [
  { name: '1 Hour', id: guid(), value: '3600' },
  { name: '2 Hours', id: guid(), value: '7200' },
  { name: '3 Hours', id: guid(), value: '10800' },
  { name: '6 Hours', id: guid(), value: '21600' },
  { name: '12 Hours', id: guid(), value: '43200' },
  { name: '1 Day', id: guid(), value: '86400' },
  { name: '3 Days', id: guid(), value: '259200' },
  { name: '7 Days', id: guid(), value: '604800' },
];

// const trueFalse = [
//   { name: 'No', id: guid(), value: false },
//   { name: 'Yes', id: guid(), value: true },
// ];

export default class TriggerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snoozeLength: '3600',
      notifyUsers: false,
      notes: '',
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      validationMessage: '',
    });
  };
  handleSubmit() {
    if (this.props.action) {
      if (this.props.action === 'snooze') {
        //snooze func
        this.props.snoozeFunc(
          this.props.trigger.triggerId,
          localStorage.getItem('email'),
          this.state.snoozeLength
        );
        swal.close();
      } else {
        //resolve func
        this.props.resolveFunc(
          this.props.trigger.triggerId,
          localStorage.getItem('email'),
          this.state.notes
        );
        swal.close();
      }
    }
  }

  renderButtons() {
    return (
      <div>
        <Button
          style={{ color: '#8500C5', borderColor: '#8500C5' }}
          variant="outline-primary"
          onClick={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          Skip
        </Button>
        <Button
          style={{ backgroundColor: '#8500C5', borderColor: '#8500C5' }}
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          Submit
        </Button>
      </div>
    );
  }

  renderResolve() {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <div>Resolution Notes: </div>{' '}
          <input
            type="notes"
            placeholder="Enter notes about this trigger"
            className="form-control col-sm-6 mr-1"
            value={this.state.notes}
            onChange={this.handleChange('notes')}
            style={{ width: '50%' }}
          />
        </div>
        <div>&nbsp;</div>
      </div>
    );
  }

  renderSnooze() {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <div>Snooze Time: </div>{' '}
          <select
            className="form-control"
            onChange={this.handleChange('snoozeLength')}
            value={this.state.snoozeLength}
            style={{ width: '50%' }}
          >
            {snoozeTimes.map((val) => {
              return (
                <option key={val.id} value={val.value}>
                  {val.name}
                </option>
              );
            })}
          </select>
        </div>
        <div>&nbsp;</div>
      </div>
    );
  }

  render() {
    let sent = this.props.trigger.sent;
    let next = this.props.trigger.nextTrigger;
    let thingName = this.props.trigger.thingName;
    let expectedValue = this.props.trigger.expectedValue;
    let triggerName = this.props.trigger.triggerName;

    let formattedSent = moment(sent).format('dddd, h:mm a, MMMM Do ');
    let formattedSend2 = moment(sent).format('MMMM Do, YYYY h:mm a');
    let formattedNext = moment(next).format('MMMM Do, YYYY h:mm a');
    console.log(this.props.trigger);
    return (
      <div>
        <CloseModal />
        <div className="form-inline justify-content-center mb-2">
          <div className="mr-4">
            {this.props.action ? (
              this.props.action === 'snooze' ? (
                <i style={{ color: '#c476e3' }} className="fa fa-3x mb-2 fa-alarm-clock" />
              ) : (
                <i style={{ color: '#83C0B4' }} className="fas fa-3x mb-2 fa-check" />
              )
            ) : (
              ''
            )}
          </div>
          <div>
            <div>
              {this.props.action
                ? this.props.action === 'snooze'
                  ? 'Snooze Trigger For'
                  : 'Resolve Trigger For'
                : 'Handle Trigger For'}
            </div>
            <div style={{ fontWeight: '500' }}>{thingName ? thingName : triggerName}</div>
          </div>
        </div>
        <div>&nbsp;</div>
        <div style={{ fontSize: '.8em' }}>
          <span style={{ fontWeight: '500' }}>{thingName}</span>{' '}
          <span style={{ color: '#C62e49' }}>{expectedValue}</span> on {formattedSent}
        </div>
        <div>&nbsp;</div>
        <div>
          <div>Last Sent: {formattedSend2}</div>
          <div>Next Trigger: {formattedNext}</div>
        </div>
        <div>&nbsp;</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '7%',
            paddingLeft: '7%',
          }}
        >
          {this.props.trigger
            ? this.props.trigger.triggerType.startsWith('movement')
              ? 'Current Location: '
              : 'Current Value: '
            : ''}
          <div>
            <span style={{ color: '#C62e49' }}>{this.props.trigger.currentValue}</span>
            <span>
              {' '}
              {this.props.trigger.triggerType.endsWith('temperature') ? 'Degrees Fahrenheit' : ''}
            </span>
          </div>
        </div>
        <div>&nbsp;</div>
        {this.props.action
          ? this.props.action === 'snooze'
            ? this.renderSnooze()
            : this.renderResolve()
          : ''}
        <div>&nbsp;</div>
        {this.renderButtons()}
      </div>
    );
  }
}

// ***** RESOLVE
//    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
//           <div>Notify Users: </div>{' '}
//           <select
//             className="form-control"
//             onChange={this.handleChange('notifyUsers')}
//             value={this.state.notifyUsers}
//             style={{ width: '50%' }}
//           >
//             {trueFalse.map((val) => {
//               return (
//                 <option key={val.id} value={val.value}>
//                   {val.name}
//                 </option>
//               );
//             })}
//           </select>
//         </div>

// ***** SNOOZE
// <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
//   <div>Notify Users: </div>{' '}
//   <select
//     className="form-control"
//     onChange={this.handleChange('notifyUsers')}
//     value={this.state.notifyUsers}
//     style={{ width: '50%' }}
//   >
//     {trueFalse.map((val) => {
//       return (
//         <option key={val.id} value={val.value}>
//           {val.name}
//         </option>
//       );
//     })}
//   </select>
// </div>
