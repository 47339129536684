import Api from '../../config/api';
import { store } from '../../redux/store/store';

let state;

const context = (controller, version = '') => {
  const baseUrl = () => {
    state = store.getState();
    return `${process.env.REACT_APP_API_URL}/${version}${state.navigation.currentApplication.appID}`;
  };

  const controllerUrl = () => {
    return `${baseUrl()}/${controller}`;
  };

  const all = (pageNumber, pageSize, sortField, sortOrder, filter) => {
    state = store.getState();
    return Api.get(
      `${controllerUrl()}?page=${pageNumber}&limit=${pageSize}&sort=${sortField}&order=${sortOrder}&filter=${filter}`
    );
  };

  const get = (id) => {
    state = store.getState();
    return Api.get(`${controllerUrl()}/${id}`);
  };

  const post = (json) => {
    state = store.getState();
    return Api.post(`${controllerUrl()}`, json);
  };

  const patch = (id, patch) => {
    state = store.getState();
    return Api.patch(`${controllerUrl()}/${id}`, patch);
  };

  const put = (id, json) => {
    state = store.getState();
    return Api.put(`${controllerUrl()}/${id}`, json);
  };

  const remove = (id) => {
    state = store.getState();
    return Api.delete(`${controllerUrl()}/${id}`);
  };

  return {
    baseUrl,
    all,
    get,
    post,
    put,
    patch,
    remove,
  };
};

export default { context };
