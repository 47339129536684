import baseDataContext from './baseDataContext';
import Api from '../../config/api';
import moment from 'moment';
const context = baseDataContext.context('', 'v2/');

const getTimeSeries = (
  thingId,
  mapId,
  startDate,
  endDate,
  pageNumber,
  pageSize,
  sortOrder,
  token
) => {
  if (!startDate) {
    startDate = moment().startOf('day');
  }

  if (!endDate) {
    endDate = moment().endOf('day');
  }
  return Api.get(
    `${context.baseUrl()}/things/${thingId}/maps/${mapId}/locations?start=${moment(
      startDate
    ).toISOString()}&end=${moment(
      endDate
    ).toISOString()}&limit=${pageSize}&page=${pageNumber}&order=${sortOrder}`,
    { cancelToken: token }
  );
};
const getGeoTimeSeries = (thingId, startDate, endDate, pageNumber, pageSize, sortOrder) => {
  if (!startDate) {
    startDate = moment().startOf('day');
  }

  if (!endDate) {
    endDate = moment().endOf('day');
  }
  return Api.get(
    `${context.baseUrl()}/things/${thingId}/locations/viewmodel?start=${moment(
      startDate
    ).toISOString()}&end=${moment(
      endDate
    ).toISOString()}&limit=${pageSize}&page=${pageNumber}&order=${sortOrder}`
  );
};

const getLastLocations = (mapId, page, limit, sortField, sortOrder, filter, cancellationToken) => {
  return Api.get(
    `${context.baseUrl()}/maps/${mapId}/locations/viewmodel?page=${page}&limit=${limit}&sort=${sortField}&order=${sortOrder}&filter=${filter}`,
    { cancelToken: cancellationToken.token }
  );
};

export default {
  getTimeSeries,
  getGeoTimeSeries,
  getLastLocations,
};
