import * as types from '../types';
import reportService from '../../services/reportService';

export const requestTempAndHumidity = () => {
  return {
    type: types.TEMP_HUMIDITY_REQUEST,
  };
};

export const requestAssetTypes = () => {
  return {
    type: types.ASSET_TYPES_REQUEST,
  };
};

export const requestLocationReport = () => {
  return {
    type: types.LOCATION_REQUEST,
  };
};

/*
    Get Temp and Humidity
*/
export function getTempAndHumidity(startDate, endDate, hour, returnAll, page, limit) {
  return function (dispatch) {
    dispatch(requestTempAndHumidity());

    //getTempAndHumidity = (startDate, endDate, hour = 0, returnAll = 0, page = 1, limit = 20) => {
    reportService
      .getTempAndHumidity(startDate, endDate, hour, returnAll, page, limit)
      .then((res) => {
        return dispatch({
          type: types.TEMP_HUMIDITY_RECEIVED,
          items: res.data.items,
          pages: res.data.totalPages,
          total: res.data.total,
          currentPage: page,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.REPORT_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

/*
    Get Temp and Humidity
*/
export function getTriggerResponse(startDate, endDate, hour, returnAll, page, limit) {
  return function (dispatch) {
    dispatch(requestTempAndHumidity());

    //getTempAndHumidity = (startDate, endDate, hour = 0, returnAll = 0, page = 1, limit = 20) => {
    reportService
      .getTriggerResponse(startDate, endDate, hour, returnAll, page, limit)
      .then((res) => {
        return dispatch({
          type: types.TEMP_HUMIDITY_RECEIVED,
          items: res.data.items,
          pages: res.data.totalPages,
          total: res.data.total,
          currentPage: page,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.REPORT_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export function getLocationReport(
  page = 1,
  limit = 10,
  sort,
  order,
  assetTypeId,
  maintenanceDaysFrom
) {
  return function (dispatch) {
    dispatch(requestLocationReport());

    //getTempAndHumidity = (startDate, endDate, hour = 0, returnAll = 0, page = 1, limit = 20) => {
    reportService
      .getLocation(page, limit, sort, order, assetTypeId, maintenanceDaysFrom)
      .then((res) => {
        return dispatch({
          type: types.LOCATION_RECEIVED,
          items: res.data.items,
          pages: res.data.totalPages,
          total: res.data.total,
          currentPage: page,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.REPORT_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export function getAssetTypes() {
  return function (dispatch) {
    dispatch(requestLocationReport());

    //getTempAndHumidity = (startDate, endDate, hour = 0, returnAll = 0, page = 1, limit = 20) => {
    reportService
      .getAssetTypes()
      .then((res) => {
        return dispatch({
          type: types.ASSET_TYPES_RECEIVED,
          items: res.data,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.REPORT_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}
