import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import swal from 'sweetalert';
import KeywordForm from './keywordForm';

class NewMapForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: '',
      file: '',
      name: '',
      description: '',
      keywords: [],
    };
    this.onDrop = this.onDrop.bind(this);
    this.addKeyword = this.addKeyword.bind(this);
    this.removeKeyword = this.removeKeyword.bind(this);
  }

  onDrop(acceptedFiles) {
    if (
      acceptedFiles &&
      acceptedFiles[0] &&
      acceptedFiles[0].type &&
      acceptedFiles[0].type.startsWith('image')
    ) {
      this.setState({ file: acceptedFiles, style: 'dropZoneReady' });
    } else {
      swal({
        title: 'Incorrect File Type',
        text: 'Your file was rejected, please verify you file is an image and try again.',
        icon: 'error',
        button: 'Ok',
      });
      this.setState({ style: '', file: '' });
    }
  }

  handleInputChange(type, val) {
    this.setState({ [type]: val });
  }

  addKeyword(value) {
    let keywords = this.state.keywords;
    if (!keywords.includes(value)) {
      keywords.push(value);
      this.setState({ keywords: keywords });
    }
  }

  removeKeyword(value) {
    let keywords = this.state.keywords;
    if (keywords.includes(value)) {
      keywords.splice(keywords.indexOf(value), 1);
      this.setState({ keywords: keywords });
    }
  }

  handleSubmit() {
    if (this.state.file && this.state.file[0] && this.state.name) {
      this.props.onSubmit(
        this.state.file[0],
        this.state.name,
        this.state.description,
        this.state.keywords
      );
    } else {
      swal({
        title: 'Missing Information',
        text: 'Please verify that you have named your map and upload a map image.',
        icon: 'error',
        button: 'Ok',
      });
    }
  }

  render() {
    return (
      <div>
        <div className="card h-100">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label htmlFor="newMapFormName">Name</label>
                  <input
                    id="newMapFormName"
                    className="form-control"
                    type="text"
                    value={this.state.name}
                    onChange={(e) => this.handleInputChange('name', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="newMapFormDescription">Description</label>
                  <textarea
                    id="newMapFormDescription"
                    rows="3"
                    className="form-control"
                    value={this.state.description}
                    onChange={(e) => this.handleInputChange('description', e.target.value)}
                  />
                </div>

                <KeywordForm
                  keywords={this.state.keywords}
                  addKeyword={this.addKeyword}
                  removeKeyword={this.removeKeyword}
                />
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <label htmlFor="hoverZone">Map Image</label>

                  <Dropzone
                    id="hoverZone"
                    className={this.state.style}
                    onDrop={this.onDrop}
                    accept="image/*"
                    style={{
                      width: '15vw',
                      height: '15vh',
                      border: '2px dotted #333333',
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      {this.state.style.length > 2 ? (
                        <i className="fas fa-check fa-6x" />
                      ) : (
                        <div style={{ margin: '3px' }}>
                          Drop files here to upload or click to choose a file
                        </div>
                      )}
                    </div>
                  </Dropzone>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                this.handleSubmit();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

NewMapForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default NewMapForm;
