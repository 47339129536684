import onlineStatusFunctions from '../../utils/onlineStatusFunctions';
import React from 'react';

const OnlineStatusCell = ({ onlineStatus }) => {
  return (
    <span style={{ color: onlineStatusFunctions.getHexColorByStatus(onlineStatus) }}>
      <i className="fa fa-circle" />
    </span>
  );
};

export default OnlineStatusCell;
