// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// locals
import CrumbsHeader from '../../components/layout/crumbsHeader';
import ThingForm from '../../components/forms/thingForm';
import { createPersonDevice } from '../../redux/actions/personActions';
import { changeLocation } from '../../redux/actions/navigationActions';

class PersonDeviceNewContainer extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
  }

  submit(values) {
    this.props.createPersonDevice(this.props.match.params.personId, values);
  }

  reset() {
    this.props.changeLocation(`/people/${this.props.match.params.personId}`);
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Person'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/people', label: 'People' },
            {
              route: `/people/${this.props.person.id}`,
              label: `${this.props.person.firstName} ${this.props.person.lastName}`,
            },
            { route: '', label: 'Devices' },
            { route: '', label: 'New' },
          ]}
        />
        <section className="forms">
          <div className="container-fluid">
            <ThingForm
              formHeader="New Device"
              submit={this.submit}
              resetForm={this.reset}
              initialValues={{}}
              isLoading={this.props.isLoading}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  //toggleTodo: id => dispatch(toggleTodo(id))
  bindActionCreators(
    {
      createPersonDevice,
      changeLocation,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    isLoading: state.people.isLoading,
    person: state.people.currentPerson,
  };
};

PersonDeviceNewContainer.propTypes = {
  // pages: PropTypes.number,
  isLoading: PropTypes.bool,
  person: PropTypes.object,
  // sees: PropTypes.array,
  // getPerson: PropTypes.func,
  // getSees: PropTypes.func,
  createPersonDevice: PropTypes.func,
  handleTableChange: PropTypes.func,
  handleEditDetails: PropTypes.func,
  changeLocation: PropTypes.func,
  match: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonDeviceNewContainer);
