// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// locals
import CrumbsHeader from '../../components/layout/crumbsHeader';
import PersonForm from '../../components/forms/personForm';
import { getPerson, updatePerson } from '../../redux/actions/personActions';
import { changeLocation } from '../../redux/actions/navigationActions';
import { convertCommaStringToArray } from '../../utils/stringUtils';

class PersonEditContainer extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    this.props.getPerson(this.props.match.params.personId);
  }

  submit(values) {
    values.keywords = convertCommaStringToArray(values.keywords);
    if (typeof values.keywords === 'object') {
      this.props.updatePerson(this.props.match.params.personId, values);
    }
  }

  reset() {
    this.props.changeLocation(`/people/${this.props.match.params.personId}`);
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Person'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/people', label: 'People' },
            {
              route: `/people/${this.props.person.id}`,
              label: `${this.props.person.firstName} ${this.props.person.lastName}`,
            },
            { route: '', label: 'Edit' },
          ]}
        />
        <section className="forms">
          <div className="container-fluid">
            <PersonForm
              onSubmit={this.submit}
              resetForm={this.reset}
              initialValues={this.props.person}
              isLoading={this.props.isLoading}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  //toggleTodo: id => dispatch(toggleTodo(id))
  bindActionCreators(
    {
      getPerson,
      updatePerson,
      changeLocation,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    person: state.people.currentPerson,
    isLoading: state.people.isLoading,
  };
};

PersonEditContainer.propTypes = {
  isLoading: PropTypes.bool,
  person: PropTypes.object,
  changeLocation: PropTypes.func.isRequired,
  updatePerson: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  getPerson: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonEditContainer);
