/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';

import './NameCellRenderer.css';
import { Text } from '../../components';

class NameCellRenderer extends Component {
  onClick = () => {
    const { id } = this.props.data;
    this.props.history.push(`/contact-tracing-detail/${id}`);
  };

  render() {
    return (
      <Button variant="link" className="w-100 text-left" onClick={this.onClick}>
        <Text>{this.props.value}</Text>
      </Button>
    );
  }
}

const mapStateToProps = ({ contactTracing }) => {
  return {
    contactTracing,
  };
};

export default compose(withRouter, connect(mapStateToProps))(NameCellRenderer);
