import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { STORAGE_KEYS } from '../../../constants';
import toast from '../../../components/utils/toast';

/**
 * @desc Dropdown that allows User to select from list of applications.
 */
export default class AppSelector extends Component {
  constructor(props) {
    super(props);

    const apps = JSON.parse(localStorage.getItem(STORAGE_KEYS.APP_OBJECTS) || '[]');
    if (!apps.length) {
      toast.error("There are no Applications to assign Users to. This feature won't work");
    }

    this.state = {
      apps,
      selectedAppId: localStorage.getItem('currentApplicationID'),
    };
  }

  componentDidMount() {
    this.props.onChange(this.getSelectedAppObj(this.state.selectedAppId));
  }

  /**
   * @desc Get application object given an app id
   */
  getSelectedAppObj = (appId) => this.state.apps.find((app) => app.id === appId);

  onChange = (e) => {
    const appId = e.target.value;
    this.setState({ selectedAppId: appId }, () => {
      const app = this.getSelectedAppObj(appId);
      this.props.onChange(app);
    });
  };

  render() {
    return (
      <select className="form-control" value={this.state.selectedAppId} onChange={this.onChange}>
        {this.state.apps.map((app) => {
          return (
            <option key={app.id} value={app.id}>
              {app.name}
            </option>
          );
        })}
      </select>
    );
  }
}

AppSelector.propTypes = {
  onChange: PropTypes.func.isRequired, // function with application object as arg { id, name }
};
