import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateTimePicker } from 'material-ui-pickers';

const scrollStyle = {
  maxHeight: '20vh',
  overflow: 'auto',
};

const headerStyle = {
  cursor: 'pointer',
};
const rowStyle = {
  cursor: 'pointer',
};

// TODO: MAKE SURE THAT DISTANCE MOVED DEFAULTS TO UNKNOWN OR 0 FOR GPS LOCATIONS THAT WE DONT CALCULATE MOVEMENT ON
class BreadcrumbsTable extends Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateParent = this.updateParent.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);

    this.state = {
      limit: props.limit,
      page: props.page,
      sortOrder: props.sortOrder,
      sortField: props.sortField,
      startDate: props.startDate,
      endDate: props.endDate,
      locations: props.locations,
      currentlySelected: props.current,
      start: 0,
      end: 1000,
      arr: [],
    };
  }

  handleSort(columnName) {
    let { sortField, sortOrder } = this.state;

    if (this.isSortedBy(columnName)) {
      sortOrder = sortOrder === 1 ? -1 : 1;
    } else {
      sortField = columnName;
      sortOrder = 1;
    }

    this.setState({ sortField: sortField, sortOrder: sortOrder }, () => this.updateParent());
  }

  handleChange(name, event) {
    this.setState(
      {
        [name]: event.target.value,
      },
      this.updateParent
    );
  }

  updateParent() {
    const { startDate, endDate, sortOrder, page, limit } = this.state;
    this.props.onQueryChanged(page, limit, sortOrder, startDate, endDate);
  }

  sortIcon(columnName) {
    let { sortDirection } = this.state;
    if (this.isSortedBy(columnName)) {
      return sortDirection === 1 ? (
        <i className="fa fa-caret-up" />
      ) : (
        <i className="fa fa-caret-down" />
      );
    }
    return;
  }

  isSortedBy(columnName) {
    let { sortField } = this.state;
    return sortField === columnName;
  }

  handleDateChange(name, val) {
    this.setState({ [name]: moment(val) }, () => this.updateParent());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.current) {
      return {
        currentlySelected: nextProps.current,
      };
    } else {
      return {
        currentlySelected: nextProps.current,
        start: 0,
        end: nextProps.locations.length,
        arr: nextProps.locations,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.current !== this.props.current) {
      let start = 0;
      let end = this.props.locations.length - 1;
      if (this.props.current) {
        let count = Math.floor(this.props.current / 50);
        start = count * 50;
        end = (count + 1) * 50 - 1;
        if (end > this.props.locations.length - 1) {
          end = this.props.locations.length - 1;
        }
      }
      let arr = this.props.locations.slice(start, end);
      if (start !== prevState.start && end !== prevState.end) {
        this.setState({
          start,
          end,
          arr,
        });
      }
    }
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between form-inline">
          <div>
            <i className="fa fa-calendar mr-2" />

            <span>From:</span>
            <DateTimePicker
              value={this.state.startDate}
              onChange={(e) => this.handleDateChange('startDate', e)}
              className="form-control ml-2 mr-4"
            />
            <span>To:</span>
            <DateTimePicker
              value={this.state.endDate}
              onChange={(e) => this.handleDateChange('endDate', e)}
              className="form-control ml-2"
            />
          </div>
          <div>
            <span>Viewing </span>
            <input
              style={{ width: '60px', textAlign: 'center' }}
              className="form-control form-control-sm"
              value={this.state.limit}
              onChange={(e) => this.handleChange('limit', e)}
            />
            <span> of {this.props.totalItems} tags.</span>
          </div>
        </div>
        <div style={scrollStyle}>
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th
                  scope="col"
                  onClick={() => this.handleSort('timestamp')}
                  style={headerStyle}
                  className={this.isSortedBy('timestamp') ? 'text-primary' : ''}
                >
                  Timestamp {this.sortIcon('timestamp')}
                </th>
                <th
                  scope="col"
                  onClick={() => this.handleSort('distanceMoved')}
                  style={headerStyle}
                  className={this.isSortedBy('distanceMoved') ? 'text-primary' : ''}
                >
                  Distance Moved {this.sortIcon('distanceMoved')}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.locations.length > 0
                ? this.state.arr.map((location, index) => {
                    return (
                      <tr
                        key={`${location.index} + ${this.state.current}`}
                        onClick={() => this.props.onRowSelect(location.index)}
                        className={location.isHighlighted ? 'table-primary' : 'table-default'}
                        style={rowStyle}
                      >
                        <td>{moment(location.timestamp).format('L LTS')}</td>
                        <td>{Math.round(location.distanceMoved)} ft</td>
                      </tr>
                    );
                  })
                : false}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

BreadcrumbsTable.propTypes = {
  locations: PropTypes.array,
  totalItems: PropTypes.string,
  pageCount: PropTypes.string,
  allowFilter: PropTypes.bool,
  sortField: PropTypes.string,
  sortOrder: PropTypes.number,
  limit: PropTypes.string,
  page: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onRowSelect: PropTypes.func,
  onQueryChanged: PropTypes.func,
};

export default BreadcrumbsTable;

// <div>
//     <span>Page </span>
//     <input
//         className="form-control form-control-sm"
//         value={this.state.page}
//         onChange={e => this.handleChange('page', e)}
//         style={{ width: '40px', textAlign: 'center' }}
//     />
//     <span> of {this.props.pageCount} pages</span>
// </div>;
