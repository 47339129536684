import React from 'react';
import PropTypes from 'prop-types';
import { isKeyboardKey } from '../../utils';
import { KEYBOARD_KEYS } from '../../constants';

class KeywordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      open: false,
      selected: '',
    };

    this.add = this.add.bind(this);
    this.onBadgeClick = this.onBadgeClick.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.open = this.open.bind(this);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    document[name] = event.target.value;
  };

  add = () => {
    this.props.addKeyword(this.state.keyword);
    this.setState({
      open: false,
      keyword: '',
    });
  };

  open = () => {
    this.setState({ open: true });
  };

  onBadgeClick = (keyword) => {
    const selected = this.state.selected;
    if (selected === keyword) {
      keyword = '';
    }
    this.setState({ selected: keyword });
  };

  onDelete = (keyword) => {
    this.setState({ selected: '' });
    this.props.removeKeyword(keyword);
  };

  render() {
    return (
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3 className="card-heading">Keywords</h3>
          <button className="btn btn-xs flat-btn btn-primary" onClick={this.open}>
            <i className="fa fa-plus-circle" />
          </button>
        </div>
        <div className={'card-body'}>
          <p>Keywords are used to help search and filter assets.</p>
          {this.state.open ? (
            <div className="d-flex justify-content-between">
              <input
                id="current-keyword"
                className="form-control"
                value={this.state.keyword}
                placeholder="Keyword/Tag"
                onChange={this.handleChange('keyword')}
              />

              <button
                type="button"
                disabled={this.state.keyword.length < 3}
                className="btn flat-btn"
                onClick={this.add}
              >
                Add
              </button>
            </div>
          ) : (
            false
          )}
          <div className="badge-list mt-2">
            {this.props.keywords.map((tag, i) => {
              return this.state.selected === tag ? (
                <h1 className="d-flex justify-content-between">
                  <span
                    className="badge badge-danger"
                    onClick={() => this.onBadgeClick(tag)}
                    role="button"
                    onKeyDown={(e) =>
                      isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => this.onBadgeClick(tag))
                    }
                    tabIndex={0}
                  >
                    {tag}
                  </span>
                  <span
                    onClick={() => this.onDelete(tag)}
                    role="button"
                    onKeyDown={(e) =>
                      isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => this.onDelete(tag))
                    }
                    tabIndex={0}
                  >
                    <i className="fa fa-trash text-danger" />
                  </span>
                </h1>
              ) : (
                <h1>
                  <span
                    className="badge badge-light"
                    onClick={() => this.onBadgeClick(tag)}
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) =>
                      isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => this.onBadgeClick(tag))
                    }
                  >
                    {tag}
                  </span>
                </h1>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

KeywordForm.propTypes = {
  keywords: PropTypes.array,
  addKeyword: PropTypes.func,
  removeKeyword: PropTypes.func,
};

export default KeywordForm;
