import React, { Component } from 'react';
import swal from '@sweetalert/with-react';
import toast from './../../../components/utils/toast';
import mapService from './../../../services/mapService';

/**
 * @desc Displays content to allow Admin to invite Users to App via CSV
 */
export default class AddUsersModal extends Component {
  constructor(props) {
    super(props);
    this.onDeleteMap = this.onDeleteMap.bind(this);
  }

  onDeleteMap = (event) => {
    event.preventDefault();
    mapService.remove(this.props.mapId).then(() => {
      toast.success();
      window.location.assign('/maps');
      swal.close();
    });
  };

  render() {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginLeft: '3vh',
            marginRight: '3vh',
          }}
        >
          <i
            style={{ color: '#9700C7', fontSize: '4rem' }}
            className="fas fa-exclamation-circle"
          ></i>
          <p style={{ margin: 0, marginLeft: '2vw' }}>
            Are you sure you would like to delete map{' '}
            <span style={{ fontWeight: '600' }}>
              {this.props.mapName ? this.props.mapName : ''}
            </span>
          </p>
        </div>

        <div className="d-flex flex-row justify-content-around mt-4">
          <button
            type="button"
            className="btn btn-outline-primary"
            style={{
              backgroundColor: 'transparent',
              color: '#9700C7',
              borderColor: '#9700C7',
            }}
            title="Cancel"
            onClick={() => swal.close()}
          >
            Continue Editing
          </button>

          <button
            type="button"
            className="btn btn-primary"
            title="Delete Map"
            style={{
              backgroundColor: '#9700C7',
              borderColor: '#9700C7',
            }}
            onClick={(e) => this.onDeleteMap(e)}
          >
            Delete Map
          </button>
        </div>
      </div>
    );
  }
}
