import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { readOnlyCheck } from '../../utils';

const guid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

class ThingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false,
    };
  }

  render() {
    return (
      <div>
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h3 className="card-heading">Keywords</h3>
          </div>
          <div className="card-body">
            {this.props.thing &&
            this.props.thing.keywords &&
            this.props.thing.keywords.length > 0 ? (
              this.props.thing.keywords.map((val, i) => {
                return (
                  <span
                    style={{ fontSize: '1rem' }}
                    className="badge badge-pill badge-primary"
                    key={guid()}
                  >
                    {val}
                  </span>
                );
              })
            ) : (
              <div>This thing does not have any keywords</div>
            )}
          </div>
        </div>
        {readOnlyCheck() ? null : (
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h3 className="card-heading">Triggers</h3>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  title="Create a new trigger"
                  onClick={() => {
                    this.props.toggleTriggerAdd();
                  }}
                >
                  <i className="fa fa-bell-plus" /> Create
                </button>
              </div>
            </div>

            <div className="card-body">
              {this.props.triggers &&
              typeof this.props.triggers === 'object' &&
              this.props.triggers.length > 0 ? (
                this.props.triggers.map((val, i) => {
                  return (
                    <div key={i} className="row">
                      <div className="col-md-8">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => {
                            this.props.toggleTriggerEdit(val);
                          }}
                        >
                          {val.triggerName}
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>This device has no triggers</div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

ThingDetails.propTypes = {
  thing: PropTypes.object,
  triggers: PropTypes.array,
  editUrl: PropTypes.string,
  toggleTriggerEdit: PropTypes.func,
  toggleTriggerAdd: PropTypes.func,
  deleteThing: PropTypes.func,
};

export default ThingDetails;
