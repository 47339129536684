import Api from './Api';

class BaseService {
  /**
   * @desc Get a query string
   * @param {import('./typedefs').QueryOptions} queryOptions Options to control query
   * @returns {string} querystring
   */
  getQS = ({ filter = '', orderby = '', expand = '', top = 0, skip = 0, useCount = true } = {}) => {
    let qs = '?';

    if (filter) qs += `$filter=${filter}&`;
    if (orderby) qs += `$orderby=${orderby}&`;
    if (expand) qs += `$expand=${expand}&`;
    if (top > 0) qs += `$top=${top}&`;
    if (skip >= 0) qs += `$skip=${skip}&`;
    if (useCount) qs += `$count=true&`;

    if (qs === '?') qs = '';
    if (qs.length > 0) qs = qs.slice(0, qs.length - 1); // remove last &

    // console.log(qs);
    return qs;
  };

  /**
   *
   * @param {string} path api path
   * @param {QueryOptions} queryOpts Query String Options
   */
  get = async (path, queryOpts) => {
    if (queryOpts.isGetOperation) {
      queryOpts.skip = -1;
      queryOpts.useCount = false;
    }

    const qs = this.getQS(queryOpts);
    const pathWithQs = path + qs;
    return Api.get(process.env.REACT_APP_BASE_API_URL_V2 + pathWithQs);
  };
}

export default BaseService;
