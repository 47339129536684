import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

class MagnitudeChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                stepSize: 10,
              },
            },
          ],
        },
      },
      data: {
        labels: [],
        datasets: [{}],
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.readings || nextProps.readings === '') {
      return null;
    }

    let readings = nextProps.readings;

    let data = [];
    let labels = [];

    let filteredReadings = readings;

    let lowest = filteredReadings[0].max;
    let highest = filteredReadings[0].max;

    filteredReadings.forEach((reading) => {
      data.push(reading.max);
      labels.push(moment.utc(reading.timestamp).local().format('MM/DD hh:mm:ss A'));

      let value = reading.max;
      if (value < lowest) lowest = value;
      if (value > highest) highest = value;
    });

    let range = Math.max(highest - lowest, 1); // bigger number -> bigger spread (tiny variations don't take up the whole graph)
    let minTick = lowest - range * 0.25;
    let maxTick = Math.max(highest + range * 0.25, 0.05);
    let tickRange = maxTick - minTick;

    return {
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                min: minTick,
                max: maxTick,
                stepSize: tickRange / 4,
              },
            },
          ],
        },
      },
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            label: 'Max (g)',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
          },
        ],
      },
    };
  }

  render() {
    return (
      <div className="col-lg-6" style={{ marginTop: '20px' }}>
        <div className=" card h-100">
          <div className="card-close" />
          <div
            className="card-header"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h3 className="h4">Magnitude</h3>
            <div className="btn-group float-right">
              <button
                type="button"
                className="btn btn-outline-dark btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.changeTime('magnitude', 12);
                }}
              >
                12H
              </button>
              <button
                type="button"
                className="btn btn-outline-dark btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.changeTime('magnitude', 24);
                }}
              >
                1D
              </button>
              <button
                type="button"
                className="btn btn-outline-dark btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.changeTime('magnitude', 72);
                }}
              >
                3D
              </button>
              <button
                type="button"
                className="btn btn-outline-dark btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.changeTime('magnitude', 168);
                }}
              >
                7D
              </button>
            </div>
          </div>
          <div className="card-body">
            <Line data={this.state.data} options={this.state.options} />
          </div>
        </div>
      </div>
    );
  }
}

MagnitudeChart.propTypes = {
  readings: PropTypes.array,
  changeTime: PropTypes.func,
};

export default MagnitudeChart;
