import React from 'react';
import PropTypes from 'prop-types';
import GatewayEditRow from './gatewayEditRow';

class GatewayList extends React.Component {
  render() {
    return (
      <div>
        <div className="btn-group btn-group-sm">
          <button
            className="btn btn-sm ml-4 mt-2 mb-2"
            type="button"
            onClick={this.props.onAdd}
            disabled={this.props.editing}
          >
            Add Gateway
          </button>
        </div>

        <ul className="list-group list-group-flush">
          <li className="d-flex list-group-item p-1 justify-content-between align-items-center pl-4 pr-4">
            <div className="w-50">Gateway</div>
            <div className="w-25">
              <span className="float-right">Coordinates</span>
            </div>
            <div className="w-25">
              <span className="float-right">Influence</span>
            </div>
            <div className="w-25"></div>
          </li>
          {this.props.gateways.map((g, i) => {
            return (
              <li className="list-group-item list-group-item-action pl-4 pr-4 pt-1 pb-1" key={i}>
                {this.props.editing && g.index === this.props.editing.index ? (
                  <GatewayEditRow
                    gateway={g}
                    excludedSearchItems={[]}
                    onSearchFilterChanged={this.props.onSearchFilterChanged}
                    searchItems={this.props.searchItems}
                    onEditCompleted={this.props.onEditCompleted}
                    onAddCanceled={this.props.onAddCanceled}
                  />
                ) : (
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <a
                      href="# "
                      className="w-50"
                      onClick={() => this.props.onSelect('gateways', 'index', g.index)}
                    >
                      {g.isVisible ? (
                        <strong>{g.gateway.name}</strong>
                      ) : (
                        <span>{g.gateway.name}</span>
                      )}
                    </a>

                    <div className="w-25">
                      {g.location ? (
                        <span className="float-right">
                          ({g.location.x}, {g.location.y})
                        </span>
                      ) : (
                        false
                      )}
                    </div>
                    <div className="w-25">
                      <span className="float-right">{g.influence}</span>
                    </div>
                    <div className="w-25">
                      <div className="btn-group float-right">
                        <a
                          href="# "
                          style={{ cursor: 'pointer' }}
                          className="btn flat-btn btn-default p-2"
                          onClick={() => this.props.onEdit(g)}
                          title="Edit Properties"
                        >
                          <i className="fas fa-edit" />
                        </a>
                        <a
                          href="# "
                          style={{ cursor: 'pointer' }}
                          className="btn flat-btn btn-default p-2"
                          onClick={() => this.props.onEditMap(g)}
                          title="Change location on map"
                        >
                          <i className="fas fa-map-pin" />
                        </a>
                        <a
                          href="# "
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.props.onRemove('gateways', g.index)}
                          className="btn flat-btn btn-default p-2"
                          title="Remove from map."
                        >
                          <i className="fas fa-trash text-danger" />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

GatewayList.propTypes = {
  editing: PropTypes.object,
  gateways: PropTypes.array,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onEditMap: PropTypes.func,
  onSearchFilterChanged: PropTypes.func,
  searchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
};

export default GatewayList;
