import { push } from 'react-router-redux';
import * as types from '../types';
import placeService from '../../services/placeService';
import toast from '../../components/utils/toast';

export const requestPlaceSummary = () => {
  return {
    type: types.PLACE_SUMMARY_RECEIVED,
  };
};

// Initiate a sees request
export const requestSees = () => {
  return {
    type: types.PLACE_SEES_REQUEST,
  };
};

export const startDeletePlace = () => {
  return {
    type: types.PLACE_DELETE_STARTED,
  };
};

/* 
    Post a thing initiation
*/
export const startCreatePlace = () => {
  return {
    type: types.PLACE_POST_STARTED,
  };
};

/* 
    Update a place initiation
*/
export const startUpdateThing = () => {
  return {
    type: types.PLACE_PATCH_STARTED,
  };
};

/* 
    Request a place initiation
*/
export const requestPlace = () => {
  return {
    type: types.PLACE_REQUEST,
  };
};

export const requestPlaces = () => {
  return {
    type: types.PLACES_REQUEST,
  };
};

export function getPlaceSummary() {
  return function (dispatch) {
    dispatch(requestPlaceSummary());

    placeService
      .getPlaceSummary()
      .then((res) => {
        return dispatch({
          type: types.PLACE_SUMMARY_RECEIVED,
          online: res.data.online,
          offline: res.data.offline,
          provisioned: res.data.provisioned,
          total: res.data.total,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.PLACES_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

/* 
    Post a Place
*/
export function createPlace(place) {
  return function (dispatch) {
    dispatch(startCreatePlace());

    placeService
      .create(place)
      .then((res) => {
        toast.success();
        dispatch({
          type: types.PLACE_POST_COMPLETED,
          place: res.data,
        });
        dispatch(push(`/places`));
      })
      .catch((res) =>
        dispatch({
          type: types.PLACES_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export function createPlaceSuccess(res) {
  return function (dispatch) {
    toast.success();
    dispatch({
      type: types.PLACE_POST_COMPLETED,
      place: res.data,
    });
    dispatch(push(`/places`));
  };
}

/* 
    Update a Place
*/
export function updatePlace(id, place) {
  return function (dispatch) {
    dispatch(startUpdateThing());

    placeService
      .update(id, place)
      .then((res) => {
        toast.success();
        dispatch({
          type: types.PLACE_PATCH_COMPLETED,
          thing: res.data,
        });
        dispatch(push(`/places/${id}`));
      })
      .catch((res) =>
        dispatch({
          type: types.PLACES_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

/* 
    Individual thing action 
*/
export function getPlace(id) {
  return function (dispatch) {
    dispatch(requestPlace());

    placeService
      .get(id)
      .then((res) => {
        dispatch({
          type: types.PLACE_RECEIVED,
          place: res.data,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.PLACES_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

/* 
    Individual thing action 
*/
export function deletePlace(id) {
  return function (dispatch) {
    dispatch(startDeletePlace());

    placeService
      .remove(id)
      .then((_res) => {
        toast.success();
        dispatch({
          type: types.PLACE_DELETE_COMPLETED,
        });
        dispatch(getAllPlaces());
      })
      .catch((res) =>
        dispatch({
          type: types.PLACES_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export function getSees(id, page, order) {
  return function (dispatch) {
    dispatch(requestSees());

    placeService
      .getSees(id, page, order)
      .then((res) => {
        dispatch({
          type: types.PLACE_SEES_RECEIVED,
          sees: res.data.docs,
          pages: res.data.pages,
          total: res.data.total,
          currentPage: page,
          currentOrder: order,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.PLACES_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export function getAllPlaces(page, limit, sort, order) {
  return function (dispatch) {
    dispatch(requestPlaces());

    placeService
      .getAll(page, limit, sort, order)
      .then((res) => {
        return dispatch({
          type: types.PLACES_RECEIVED,
          places: res.data.places,
          pages: res.data.pageCount,
          total: res.data.totalPlaces,
          currentPage: page,
          currentOrder: order,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.PLACES_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}
