// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// locals
import CrumbsHeader from '../../components/layout/crumbsHeader';
import ThingForm from '../../components/forms/thingForm';
import { getThing, updateThing } from '../../redux/actions/thingActions';
import { changeLocation } from '../../redux/actions/navigationActions';
import { convertCommaStringToArray } from '../../utils/stringUtils';

class ThingEditContainer extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.getThing(this.props.match.params.thingId);
  }

  submit(values) {
    if (values.keywords) {
      values.keywords = convertCommaStringToArray(values.keywords);
    }
    this.props.updateThing(this.props.match.params.thingId, values);
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Thing'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/things', label: 'Things' },
            { route: `/things/${this.props.thing.id}`, label: this.props.thing.name },
            { route: '', label: 'Edit' },
          ]}
        />
        <section className="forms">
          <div className="container-fluid">
            <ThingForm
              submit={this.submit}
              initialValues={this.props.thing}
              isLoading={this.props.isLoading}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  //toggleTodo: id => dispatch(toggleTodo(id))
  bindActionCreators(
    {
      getThing,
      updateThing,
      changeLocation,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    thing: state.things.currentThing,
    isLoading: state.things.isLoading,
  };
};

ThingEditContainer.propTypes = {
  isLoading: PropTypes.bool,
  thing: PropTypes.object,
  getThing: PropTypes.func.isRequired,
  updateThing: PropTypes.func.isRequired,
  changeLocation: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThingEditContainer);
