import React, { Component } from 'react';
import swal from '@sweetalert/with-react';

import { AgGrid, NavCrumbs, toast } from '../../../components';
import userService from '../../../services/userServices';
import { STORAGE_KEYS } from '../../../constants';
import applicationService from '../../../services/applicationService';
import columnDefs from './columnDefs';
import AddUsersModal from './AddUsersModal';
import { emitter, EVENTS } from './utils';
import DeleteButtonRenderer from './deleteButton';
import ResendInviteRenderer from './resendInvite';
import axios from 'axios';

/**
 * @desc Grid of Users for current application.
 */
class AccountManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs,
      rowData: [],
      gridFilter: '',
      query: {
        limit: 20,
        page: 1,
      },
      totalItems: 0,
      pageCount: 1,
      phoneUpdates: [],
      roleUpdates: [],
    };

    this.gridApi = null;
    this.columnApi = null;
    this.onSubmitChanges = this.onSubmitChanges.bind(this);
    this.getUsers = this.getUsers.bind(this);
  }

  componentDidMount() {
    this.getUsers();

    if (!localStorage.getItem(STORAGE_KEYS.APP_OBJECTS)) {
      applicationService.getApplications();
    }

    emitter.on(EVENTS.REFETCH_USERS, () => {
      this.getUsers();
    });
  }

  componentWillUnmount() {
    emitter.removeListener(EVENTS.REFETCH_USERS);
  }

  setGridFilter = (gridFilter) => {
    this.setState({ gridFilter });
    this.stopEditing();
  };

  /**
   * @desc reset all custom delete buttons and stop any editing that may have been taking place
   */
  stopEditing = () => {
    emitter.emit(EVENTS.EDIT_USER, '');
    this.gridApi.stopEditing();
  };

  setLimit = (limit) => {
    this.queryChange(this.state.query.page, limit);
  };

  onChangePage = (page) => {
    this.queryChange(page, this.state.query.limit);
  };

  queryChange = (page, limit) => {
    this.setState(
      {
        query: {
          page,
          limit,
        },
      },
      () => {
        this.getUsers();
      }
    );
  };

  getUsers = async () => {
    try {
      const { data } = await userService.listUsers();

      this.setState({
        rowData: data,
        totalItems: data.length,
        pageCount: 1,
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  onChangeRole = ({ email, role, oldValue }) => {
    userService.updateUserRole(email, role);
    // if (role === undefined || role === 'N/A' || role === oldValue) {
    //   return;
    // }
    // let x = this.state.roleUpdates;
    // x.push({ email, role });
    // this.setState({ roleUpdates: x });
  };

  onChangePhone = ({ email, phone, oldValue }) => {
    if (phone === undefined || phone === 'N/A' || phone === oldValue) {
      return;
    }
    userService.updateUserPhone(email, phone);
    // let x = this.state.phoneUpdates;
    // x.push({ email, phone });
    // this.setState({ phoneUpdates: x });
  };

  onSubmitChanges() {
    let arrChange = [];
    let requestArr = [];
    for (let i = 0; i < this.state.roleUpdates.length; i++) {
      requestArr.push(
        userService.updateUserRole(this.state.roleUpdates[i].email, this.state.roleUpdates[i].role)
      );
      arrChange.push(
        `Updating role of ${this.state.roleUpdates[i].email} to ${this.state.roleUpdates[i].role}`
      );
    }
    for (let i = 0; i < this.state.phoneUpdates.length; i++) {
      requestArr.push(
        userService.updateUserPhone(
          this.state.phoneUpdates[i].email,
          this.state.phoneUpdates[i].phone
        )
      );
      arrChange.push(
        `Updating phone number of ${this.state.phoneUpdates[i].email} to ${this.state.phoneUpdates[i].phone}`
      );
    }
    if (
      window.confirm(
        arrChange.map((val, i) => {
          return `\n ${i + 1}: ${val}`;
        })
      )
    ) {
      axios.all(requestArr).then(() => {
        toast.success('All Changes Saved');
        this.getUsers();
      });
    } else {
      console.log('NO');
    }
  }

  onCellValueChanged = (params) => {
    console.log(params);
    switch (params.colDef.field) {
      case 'userRole':
        return this.onChangeRole({
          email: params.data.userName,
          role: params.data.userRole,
          oldValue: params.oldValue,
        });
      case 'phoneNumber':
        return this.onChangePhone({
          email: params.data.userName,
          phone: params.data.phoneNumber,
          oldValue: params.oldValue,
        });
      default:
        return null;
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  };

  getGridProps = () => {
    return {
      onCellValueChanged: this.onCellValueChanged,
      getRowNodeId(data) {
        return data.userId;
      },
      frameworkComponents: {
        deleteButtonRenderer: DeleteButtonRenderer,
        resendInviteRenderer: ResendInviteRenderer,
      },
      editType: 'fullRow',
      suppressClickEdit: true,
      onSortChanged: this.stopEditing,
    };
  };

  onClickAddUser = () => {
    swal(<AddUsersModal />, { buttons: false, closeOnClickOutside: false });
  };

  render() {
    return (
      <div className="h-75 w-100 p-4">
        <NavCrumbs
          header={'Account Management'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/Admin', label: 'Admin' },
            { route: '/Admin/AccountManagement2', label: 'Account Management' },
          ]}
        />
        <div className="d-flex justify-content-end mr-4">
          <button
            type="button"
            className="btn btn-outline-primary"
            title="Add Users"
            onClick={this.onClickAddUser}
          >
            Add Users&nbsp;
            <i className="fa fa-user-plus" />
          </button>
        </div>
        <AgGrid
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          onGridReady={this.onGridReady}
          onChangeSearch={this.setGridFilter}
          onChangeLimit={this.setLimit}
          onChangePage={this.onChangePage}
          totalItems={this.state.totalItems}
          pageCount={this.state.pageCount}
          gridProps={this.getGridProps()}
          defaultLimit={this.state.totalItems + ''}
          limitDisabled
        />
      </div>
    );
  }
}

export default AccountManagement;

// <div className="d-flex justify-content-end mr-4" style={{ paddingTop: '60px' }}>
//   <button
//     type="button"
//     className="btn btn-outline-primary"
//     title="Add Users"
//     onClick={this.onSubmitChanges}
//   >
//     Save Changes&nbsp;
//   </button>
// </div>;
