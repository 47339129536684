import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ContactTracingDetailScreen from './ContactTracingDetailScreen';
import { BeaconService, MapService } from '../../services';
import { toast } from '../../components';
import { getExportData } from '../../utils';
import { contactTracingDetailColumnDefs } from './columnDefs';
import { contactTracingSetStartDateAction, contactTracingSetEndDateAction } from '../../redux';
import { colorMap, getExposureColor } from './utils';

class ContactTracingDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      beacon: {},
      map: null,
      contactTracingData: {
        contactBeacons: [],
        places: [],
      },
      exportData: {
        data: [],
        headers: [],
      },
      colorMap,
      zones: [],
    };

    this.beaconId = parseInt(props.match.params.beaconId, 10);
    this.beaconService = new BeaconService();
    this.mapService = new MapService();
  }

  async componentDidMount() {
    await this.queryData();
    this.setState({ loading: false });
  }

  queryData = async () => {
    try {
      await Promise.all([this.queryContactTracing(), this.queryBeacon()]);
    } catch (err) {
      toast.error('Failed to retrieve contact tracing data.');
      console.error(err);
    }
  };

  onRefetch = async () => {
    this.setState({ loading: true });
    await this.queryData();
    this.setState({ loading: false });
  };

  queryContactTracing = async () => {
    const { data: contactTracingRes } = await this.beaconService.listContactTracing(
      {
        id: this.beaconId,
        from: this.props.contactTracing.startDate.toISOString(),
        to: this.props.contactTracing.endDate.toISOString(),
      },
      {
        expand:
          'beacon,contactBeacons($expand=contactBeacon),places($expand=place),details($expand=place,contactBeacon)',
      }
    );

    const contactTracingData = contactTracingRes.items[0];

    // map places to contact tracing beacons
    contactTracingData.contactBeacons.forEach((beacon) => {
      contactTracingData.details.forEach((detail) => {
        if (detail.contactBeaconId === beacon.contactBeaconId) {
          beacon.place = detail.place;
        }
      });
    });

    const zones = this.getZones(contactTracingData);

    this.setState({ contactTracingData, zones });
  };

  queryMap = async (beacon) => {
    if (!beacon.locations || !beacon.locations[0]) {
      throw new Error('Can not find map for current beacon');
    }

    return this.mapService.getMap(beacon.locations[0].mapId);
  };

  queryBeacon = async () => {
    const { data: beacon } = await this.beaconService.getBeacon(this.beaconId, {
      expand: 'locations($top=1;$orderby=timestamp desc;)',
    });
    const { data: map } = await this.queryMap(beacon);
    this.setState({ beacon, map });
  };

  onChangeStartDate = (startDate) => {
    this.props.setStartDate(startDate);
  };

  onChangeEndDate = (endDate) => {
    this.props.setEndDate(endDate);
  };

  toggleZoneById = (id) => {
    this.state.zones.map((zone) => {
      if (zone.id === id) zone.isVisible = !zone.isVisible;
      return zone;
    });

    this.setState({
      zones: this.state.zones,
    });
  };

  getLocations = () => {
    const locations = this.state.contactTracingData.contactBeacons.map((beacon) => {
      const location = {};
      location.id = beacon.contactBeaconId;
      location.location = { ...beacon.details.coordinates, type: 0 };
      location.mapId = this.state.map && this.state.map.id;
      location.isVisible = true;
      location.name = `${beacon.contactBeacon.name} - ${beacon.details.exposureRating}`;
      location.fillColor = getExposureColor(beacon.details.exposureRating);
      location.fillOpacity = 1;
      return location;
    });

    return locations;
  };

  getZones = (contactTracingData) => {
    const zones = contactTracingData.places.map((placeObj) => {
      const zone = {};
      zone.id = placeObj.placeId;
      zone.name = `${placeObj.place.name} - ${placeObj.details.exposureRating}`;
      zone.index = 1;
      zone.boundary = placeObj.place.boundary.coordinates.map((coord) => ({
        ...coord,
        type: 0,
      }));
      zone.isVisible = true;
      zone.fillOpacity = 0.7;
      zone.fillColor = getExposureColor(placeObj.details.exposureRating);
      return zone;
    });

    return zones;
  };

  render() {
    const exportData = getExportData(
      contactTracingDetailColumnDefs,
      this.state.contactTracingData.contactBeacons
    );

    return (
      <ContactTracingDetailScreen
        loading={this.state.loading}
        title={this.state.beacon.name || ''}
        contactTracingData={this.state.contactTracingData.contactBeacons}
        startDate={this.props.contactTracing.startDate}
        endDate={this.props.contactTracing.endDate}
        onChangeStartDate={this.onChangeStartDate}
        onChangeEndDate={this.onChangeEndDate}
        onRefetch={this.onRefetch}
        exportData={exportData}
        map={this.state.map}
        locations={this.getLocations()}
        zones={this.state.zones}
        colorMap={colorMap}
        toggleZoneById={this.toggleZoneById}
      />
    );
  }
}

const mapStateToProps = ({ contactTracing }) => {
  return {
    contactTracing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStartDate: (startDate) => dispatch(contactTracingSetStartDateAction(startDate)),
    setEndDate: (endDate) => dispatch(contactTracingSetEndDateAction(endDate)),
  };
};

ContactTracingDetailContainer.propTypes = {
  match: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactTracingDetailContainer);
