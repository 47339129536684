import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { contactTracingColumnDefs } from './columnDefs';
import ContactTracingScreen from './ContactTracingScreen';
import { withProgressBar } from '../../react/hocs';
import { BeaconService } from '../../services';
import { getExportData } from '../../utils';
import {
  contactTracingSetStartDateAction,
  contactTracingSetEndDateAction,
  contactTracingSetPage,
  contactTracingSetSearch,
} from '../../redux';

const initData = { items: [], count: 0 };

class ContactTracingContainer extends Component {
  constructor(props) {
    super(props);

    const { contactTracing } = props;
    const limit = 20;

    this.state = {
      data: initData,
      loading: true,
      queryOpts: {
        filter: this.getFilter(contactTracing.search),
        skip: (parseInt(contactTracing.page, 10) - 1) * limit,
        // TODO: uncomment when this is fixed in backend
        // expand: 'LastLocation($expand=place($select=name,id))',
      },
      pager: {
        limit,
        count: 0,
      },
      exportData: {
        data: [],
        headers: [],
      },
    };

    this.beaconService = new BeaconService();
  }

  componentDidMount() {
    this.queryBeacons();
  }

  queryBeacons = async () => {
    this.setState({ loading: true, data: initData });

    let { data } = await this.beaconService.listBeacons(this.state.queryOpts);

    const pager = {
      ...this.state.pager,
      count: Math.ceil(data.count / this.state.pager.limit),
    };

    this.setState({ data, pager, loading: false });
  };

  onRefetch = () => {
    this.queryBeacons();
  };

  onChangeStartDate = (startDate) => {
    this.props.setStartDate(startDate);
  };

  onChangeEndDate = (endDate) => {
    this.props.setEndDate(endDate);
  };

  getFilter = (search) => {
    return search ? `contains(tolower(Name), '${search.toLowerCase()}')` : '';
  };

  /**
   * @desc Called when User inputs text into search bar. This refetches beacons based off input.
   * @param {string} search Search input
   */
  onChangeSearch = (search) => {
    const filter = this.getFilter(search);

    this.props.setSearch(search);

    this.setState(
      {
        queryOpts: {
          ...this.state.queryOpts,
          filter,
        },
      },
      () => {
        this.queryBeacons();
      }
    );
  };

  /**
   *
   * @param {number} page Page changed to
   */
  onChangePage = (page) => {
    const skip = (parseInt(page, 10) - 1) * this.state.pager.limit;

    this.props.setPage(page);

    this.setState(
      {
        queryOpts: {
          ...this.state.queryOpts,
          skip,
        },
      },
      () => {
        this.queryBeacons();
      }
    );
  };

  render() {
    const exportData = getExportData(contactTracingColumnDefs, this.state.data.items);

    return (
      <ContactTracingScreen
        loading={this.state.loading}
        data={this.state.data.items}
        count={this.state.data.count}
        page={this.props.contactTracing.page}
        pageCount={this.state.pager.count}
        nameFilter={this.props.contactTracing.search}
        startDate={this.props.contactTracing.startDate}
        endDate={this.props.contactTracing.endDate}
        onChangeStartDate={this.onChangeStartDate}
        onChangeEndDate={this.onChangeEndDate}
        onChangeSearch={this.onChangeSearch}
        onChangePage={this.onChangePage}
        exportData={exportData}
        onRefetch={this.onRefetch}
      />
    );
  }
}

ContactTracingContainer.propTypes = {
  startProgress: PropTypes.func.isRequired,
};

const mapStateToProps = ({ contactTracing }) => {
  return {
    contactTracing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStartDate: (startDate) => dispatch(contactTracingSetStartDateAction(startDate)),
    setEndDate: (endDate) => dispatch(contactTracingSetEndDateAction(endDate)),
    setSearch: (search) => dispatch(contactTracingSetSearch(search)),
    setPage: (page) => dispatch(contactTracingSetPage(page)),
  };
};

export default compose(
  withProgressBar,
  connect(mapStateToProps, mapDispatchToProps)
)(ContactTracingContainer);
