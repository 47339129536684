/**
 * color: max exposure value
 */
export const colorMap = {
  blue: 20,
  cyan: 40,
  green: 60,
  yellow: 80,
  red: 100,
};

/**
 * @desc Get a color from a color map given an exposure between 0-100
 * @param {number} exposureRating Exposure 0-100
 */
export function getExposureColor(exposureRating) {
  const colorEntries = Object.entries(colorMap);

  for (let index = 0; index < colorEntries.length; index++) {
    const [color, maxValue] = colorEntries[index];

    if (exposureRating <= maxValue) return color;
  }
}
