import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './grid.css';

class SearchResultsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ results: this.props.results });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.results !== nextProps.results) {
      this.setState({ results: nextProps.results });
    }
  }

  render() {
    const columns = [
      {
        accessor: 'onlineStatus',
        maxWidth: 24,
        Cell: (row) => {
          switch (row.value) {
            case 'Online':
              return <span className={'online'}> </span>;
            case 'Offline':
              return <span className={'offline'}> </span>;
            case 'Provisioned':
            default:
              return <span className={'provisioned'}> </span>;
          }
        },
      },
      {
        accessor: 'entityType',
        maxWidth: 24,
        sortable: true,
        Cell: (row) => {
          if (row.value === 'Person') {
            return (
              <div style={{ textAlign: 'center' }}>
                <i className="fa fa-users" />
              </div>
            );
          }

          if (row.value === 'Thing') {
            return (
              <div style={{ textAlign: 'center' }}>
                <i className="fa fa-podcast" />
              </div>
            );
          }

          if (row.value === 'Place') {
            return (
              <div style={{ textAlign: 'center' }}>
                <i className="fa fa-home" />
              </div>
            );
          }
        },
      },

      {
        accessor: 'name',
        Header: 'Name',
      },
      {
        accessor: 'description',
        Header: 'Description',
      },
      {
        accessor: 'lastLocation',
        Header: 'Last Location',
        Cell: (row) => {
          switch (row.value) {
            case null:
              if (
                row.original.entityType !== 'Thing' ||
                row.original.onlineStatus === 'Provisioned'
              ) {
                return <span> -- </span>;
              }
              return <span>Unknown</span>;
            case undefined:
              if (
                row.original.entityType !== 'Thing' ||
                row.original.onlineStatus === 'Provisioned'
              ) {
                return <span> -- </span>;
              }
              return <span>Unknown</span>;
            case '':
              if (
                row.original.entityType !== 'Thing' ||
                row.original.onlineStatus === 'Provisioned'
              ) {
                return <span> -- </span>;
              }
              return <span>Unknown</span>;
            default:
              if (
                row.original.onlineStatus === 'Online' &&
                (row.original.lastLocationMapId !== null ||
                  row.original.lastLocationMapId !== undefined ||
                  row.original.lastLocationMapId !== '')
              ) {
                return (
                  <span>
                    <Link to={`/things/${row.original.id}/maps/${row.original.lastLocationMapId}`}>
                      {row.value}
                    </Link>
                  </span>
                );
              } else {
                return <span>{row.value}</span>;
              }
          }
        },
      },
      {
        accessor: 'lastPing',
        Header: 'Last Checkin',
        Cell: (row) => {
          if (row.value) {
            return <span>{moment(row.value).format('MM/DD/YYYY hh:mm:ss A')} </span>;
          } else {
            return <span>Not checked in</span>;
          }
        },
      },
      {
        accessor: 'id',
        maxWidth: 100,
        Cell: (row) => {
          if (row.original.entityType === 'Person') {
            return (
              <div style={{ textAlign: 'center' }}>
                <span>
                  <Link to={`/people/${row.value}`}>VIEW</Link>
                </span>
              </div>
            );
          }

          if (row.original.entityType === 'Thing') {
            return (
              <div style={{ textAlign: 'center' }}>
                <span>
                  <Link to={`/things/${row.value}`}>VIEW</Link>
                </span>
              </div>
            );
          }

          if (row.original.entityType === 'Place') {
            return (
              <div style={{ textAlign: 'center' }}>
                <span>
                  <Link to={`/places/${row.value}`}>VIEW</Link>
                </span>
              </div>
            );
          }
        },
      },
    ];

    return (
      <div>
        <ReactTable
          data={this.state.results}
          columns={columns}
          pages={this.props.totalPages} // Display the total number of pages
          loading={this.props.isLoading} // Display the loading overlay when we need it
          defaultSorted={[
            { id: 'onlineStatus', asc: true },
            { id: 'name', asc: true },
          ]}
          defaultPageSize={this.props.results.length ? this.props.results.length : 100}
          showPageSizeOptions={false}
          pageSizeOptions={[5, 10, 20, 25, 50]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

SearchResultsGrid.propTypes = {
  results: PropTypes.array,
  totalPages: PropTypes.number,
  isLoading: PropTypes.bool,
  onTableChange: PropTypes.func.isRequired,
};

export default SearchResultsGrid;
