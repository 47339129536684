import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Refresh from '@material-ui/icons/Refresh';
import cognitoActions from '../../redux/actions/cognitoActions';

import './auth.css';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 1,
  },
});
class Confirm extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      authenticated: false,
      email: '',
      verification: '',
      password: '',
      validationMessage: '',
      confirmForgot: false,
    };

    if (this.props.flow === 'confirmForgot') {
      let params = window.location.hash.replace('#', '').split('/');
      this.state.confirmForgot = true;
      this.state.email = params[1];
      this.state.verification = params[2];
    }

    if (this.props.flow === 'confirmRegistration') {
      let params = window.location.hash.replace('#', '').split('/');
      params = params[1].split('?confirmationtoken=');

      cognitoActions.confirmUser(params[0], params[1], this);

      this.state.authenticated = false;
    }
  }

  validateForm() {
    if (this.state.confirmForgot) {
      return this.state.password.length > 7;
    }

    return (
      this.state.email.length > 0 &&
      this.state.verification.length > 0 &&
      this.state.password.length > 7
    );
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      validationMessage: '',
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    if (!this.validateForm()) {
      if (this.state.password.length < 8) {
        this.setState({ validationMessage: 'The email or password is incorrect' });
        return;
      }
      this.setState({ validationMessage: 'The information you entered is incorrect' });
      return;
    }

    if (typeof this.props.flow === 'undefined' || this.props.flow === 'register') {
      cognitoActions.confirmUser(
        this.state.email,
        this.state.verification,
        this.state.password,
        this
      );
    } else {
      cognitoActions
        .confirmPasswordReset(this.state.email, this.state.verification, this.state.password)
        .then(() => {
          cognitoActions.login(this.state.email, this.state.password, this);
        })
        .catch((err) => {
          this.setState({ validationMessage: err.response.datamessage });
          return;
        });
    }
  }

  onConfirm = (err, result) => {
    if (err) {
      this.setState({ validationMessage: err.response.data.message });
      return;
    }

    cognitoActions.login(this.state.email, this.state.password, this);
  };

  onLogin = (err, result) => {
    if (err) {
      this.setState({ validationMessage: err.message });
      return;
    }

    this.setState({ authenticated: true });
  };

  render() {
    const { classes } = this.props;
    if (this.props.flow === 'confirmRegistration') {
      localStorage.clear();
      return <Redirect to="/" />;
    }
    if (this.state.authenticated) {
      return <Redirect to="/" />;
    }
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            {this.props.flow === 'confirmForgot' ? <Refresh /> : <CheckCircle />}
          </Avatar>
          <Typography component="h1" variant="h5">
            {this.props.flow === 'confirmForgot' ? 'Reset Password' : 'Confirm Registration'}
          </Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              {!this.state.confirmForgot && (
                <TextField
                  autoComplete="email"
                  type="email"
                  id="email"
                  label="Email Address"
                  className={classes.textField}
                  margin="normal"
                  helperText="Enter your email address"
                  onChange={this.handleChange('email')}
                />
              )}
              {!this.state.confirmForgot && (
                <TextField
                  type="verification"
                  id="verification"
                  label="Verification Code"
                  className={classes.textField}
                  margin="normal"
                  helperText="Enter your verification code"
                  onChange={this.handleChange('verification')}
                />
              )}

              <TextField
                autoComplete="password"
                type="password"
                id="password"
                label={this.props.flow === 'confirmForgot' ? 'New Password' : 'Password'}
                className={classes.textField}
                margin="normal"
                helperText={
                  this.props.flow === 'confirmForgot'
                    ? 'Enter a new password'
                    : 'Enter your password'
                }
                onChange={this.handleChange('password')}
              />
              <TextField
                autoComplete="password"
                type="password"
                id="password"
                label={this.props.flow === 'confirmForgot' ? 'New Password' : 'Password'}
                className={classes.textField}
                margin="normal"
                helperText={
                  this.props.flow === 'confirmForgot'
                    ? 'Confirm your new password'
                    : 'Confirm your password'
                }
              />
              <Typography align="center" className={classes.submit}>
                By logging in or signing up you accept the terms of our{' '}
                <a href="https://www.thinaer.io/eula/" target={'_blank'} rel="noreferrer">
                  End User License Agreement
                </a>
              </Typography>
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSubmit}
            >
              Verify
            </Button>
            <Typography align="center" className={classes.submit}>
              Trying to login? <Link to="login">Login Here</Link>
            </Typography>
            <Typography align="center">
              Didn&apos;t receive verification email? <a href="mailto:help@thinaer.io">Help</a>
            </Typography>
            {this.state.validationMessage ? (
              <div className="alert alert-danger">{this.state.validationMessage}</div>
            ) : (
              false
            )}
          </form>
        </Paper>
      </main>
    );
  }
}

Confirm.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  flow: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Confirm);

// <div className="form d-flex align-items-center">
//     <div className="content">
//         <form onSubmit={this.handleSubmit}>
//             <div className="form-group">
//                 <label className="label-material">Email</label>
//                 <input
//                     id="email"
//                     type="text"
//                     name="loginEmail"
//                     required=""
//                     className="input-material"
//                     value={this.state.email}
//                     onChange={this.handleChange}
//                 />
//             </div>
//             <div className="form-group">
//                 <label className="label-material">Verification Code</label>
//                 <input
//                     id="verification"
//                     type="text"
//                     name="loginVerification"
//                     required=""
//                     className="input-material"
//                     value={this.state.verification}
//                     onChange={this.handleChange}
//                 />
//             </div>
//             <div className="form-group">
//                 <label className="label-material">Password</label>
//                 <input
//                     id="password"
//                     type="password"
//                     name="loginPassword"
//                     required=""
//                     className="input-material"
//                     value={this.state.password}
//                     onChange={this.handleChange}
//                 />
//             </div>
//             <div className="validation-message">{this.validationMessage()}</div>
//             <input type="submit" value="Verify" className="btn btn-primary" />
//         </form>
//     </div>
// </div>
