import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import moment from 'moment';

import './grid.css';

class PeopleGrid extends Component {
  lastPingFormat(cell, _row) {
    return moment(cell).format('MM/DD/YYYY hh:mm:ss A');
  }

  render() {
    const columns = [
      {
        accessor: 'firstName',
        Header: 'First Name',
        Cell: (row) => <span>{row.value}</span>,
      },
      {
        accessor: 'lastName',
        Header: 'Last Name',
      },
      {
        headerStyle: { textAlign: 'center' },
        sortable: false,
        Header: () => (
          <span>
            <Link to={`/people/new`} className="addHeader">
              <i className="fa fa-plus-square fa-lg" />
            </Link>
          </span>
        ),
        maxWidth: 80,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>
              <Link to={`/people/${row.original.id}`}>VIEW</Link>
            </span>
          </div>
        ),
      },
    ];

    return (
      <div>
        <ReactTable
          data={this.props.people}
          columns={columns}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          pages={this.props.totalPages} // Display the total number of pages
          loading={this.props.isLoading} // Display the loading overlay when we need it
          onFetchData={this.props.onTableChange} // Request new data when things change
          defaultPageSize={20}
          pageSizeOptions={[5, 10, 20, 25, 50]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

PeopleGrid.propTypes = {
  people: PropTypes.array.isRequired,
  onTableChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PeopleGrid;
