import moment from 'moment';

import { getExposureColor } from './utils';

export const contactTracingColumnDefs = [
  {
    headerName: 'Name',
    field: 'name',
    cellRenderer: 'nameCellRenderer',
  },
  {
    headerName: 'Device ID',
    field: 'deviceId',
  },
  {
    headerName: 'Last Location',
    field: 'lastLocation.place.name',
    csvKey: 'lastLocation',
    valueFormatter(params) {
      return params.value || 'Pending';
    },
  },
  {
    headerName: 'Last Checkin',
    field: 'lastLocation.timestamp',
    csvKey: 'timestamp',
    valueFormatter(params) {
      if (!params.value) return 'Pending';
      return moment(params.value).format('lll');
    },
  },
];

export const contactTracingDetailColumnDefs = [
  {
    headerName: 'Name',
    field: 'contactBeacon.name',
    csvKey: 'name',
    headerTooltip: 'Name',
    tooltipField: 'contactBeacon.name',
    tooltipComponent: 'customTooltip',
  },
  {
    headerName: 'Exposure Rating',
    field: 'details.exposureRating',
    csvKey: 'exposureRating',
    headerTooltip: 'Exposure Rating',
    tooltipField: 'details.exposureRating',
    cellStyle(params) {
      return { backgroundColor: getExposureColor(params.data.details.exposureRating) };
    },
  },
  {
    headerName: 'Time in Contact',
    field: 'details.timeInContact',
    csvKey: 'timeInContact',
    headerTooltip: 'Time in Contact',
    tooltipField: 'details.timeInContact',
    valueFormatter(params) {
      if (!params.value) return 'Pending';
      return moment.duration(params.value).humanize();
    },
  },
  {
    headerName: 'Current Location',
    field: 'place.name',
    csvKey: 'location',
    headerTooltip: 'Current Location',
    tooltipField: 'place.name',
    valueFormatter(params) {
      return params.value || 'Pending';
    },
  },
];
