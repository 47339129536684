// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
// locals
import CrumbsHeader from '../../components/layout/crumbsHeader';
import ThingForm from '../../components/forms/thingForm';
import { createThingSuccess } from '../../redux/actions/thingActions';
import { changeLocation } from '../../redux/actions/navigationActions';
import thingService from './../../services/thingService';
import { convertCommaStringToArray } from '../../utils/stringUtils';

class ThingNewContainer extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
  }

  submit(values) {
    values.deviceId = values.deviceId.trim();
    values.keywords = convertCommaStringToArray(values.keywords);

    return thingService
      .create(values)
      .then((e) => {
        if (typeof e !== 'undefined') {
          this.props.createThingSuccess(e);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          throw new SubmissionError({
            deviceId: 'already in use',
            _error: 'Device ID is already in use',
          });
        }
      });
  }

  reset() {
    this.props.changeLocation(`/things`);
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Thing'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/things', label: 'Things' },
            { route: '', label: 'New' },
          ]}
        />
        <section className="forms">
          <div className="container-fluid">
            <ThingForm
              submit={this.submit}
              isLoading={this.props.isLoading}
              formHeader="Create Thing"
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  //toggleTodo: id => dispatch(toggleTodo(id))
  bindActionCreators(
    {
      createThingSuccess,
      changeLocation,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    isLoading: state.things.isLoading,
  };
};

ThingNewContainer.propTypes = {
  isLoading: PropTypes.bool,
  changeLocation: PropTypes.func.isRequired,
  createThingSuccess: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThingNewContainer);
