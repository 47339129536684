import React, { Component } from 'react';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import {
  createLocationsLayerGroup,
  createLocationsLayerArray,
} from '../services/leafletLayerFactories/leafletLocationFactory';
import { createGatewaysLayerGroup } from '../services/leafletLayerFactories/leafletGatewayFactory';
import { createZonesLayerGroup } from '../services/leafletLayerFactories/leafletZoneFactory';
import { createPath } from '../services/leafletLayerFactories/lineLayerFactory]';
import { createBeaconsLayerGroup } from '../services/leafletLayerFactories/leafletBeaconFactory';

let locations;

class LocationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      selectedIndex: null,
    };
  }

  drawCanvas(map) {
    if (!map) {
      return;
    }

    map.eachLayer(function (layer) {
      if (layer._url == null) {
        map.removeLayer(layer);
      }
    });

    const zones = createZonesLayerGroup(this.props.zones);
    zones.addTo(map);
    const gateways = createGatewaysLayerGroup(this.props.gateways);
    gateways.addTo(map);
    const beacons = createBeaconsLayerGroup(this.props.beacons, this.props.reading);
    beacons.addTo(map);

    if (this.props.marker) {
      console.log('HIT');
      let marker = new L.Marker([this.props.marker.y, this.props.marker.x]);
      marker.addTo(map);
    }

    if (this.props.locations && this.props.locations.length > 0) {
      if (this.props.playback) {
        if (this.props.selectedIndex) {
          // USER IS CONTROLLING WHAT THEY SEE AND THE PACE
          if (this.props.displayType === 'lines') {
            locations = createPath(this.props.locations);
            for (let i = 0; i < this.props.selectedIndex; i++) {
              if (locations[i]) {
                locations[i].addTo(map);
              }
            }
          } else {
            locations = createLocationsLayerArray(this.props.locations);
            for (let i = 0; i < this.props.selectedIndex; i++) {
              locations[i].addTo(map);
            }
          }

          // this.mediaControl(map, locations, this.props.selectedIndex, this.props.pace);
        } else if (this.props.paused) {
          // USER IS CONTROLLING WHAT THEY SEE AND THE PACE
          if (this.props.displayType === 'lines') {
            locations = createPath(this.props.locations);
            for (let i = 0; i < this.props.selectedIndex; i++) {
              if (locations[i]) {
                locations[i].addTo(map);
              }
            }
          } else {
            locations = createLocationsLayerArray(this.props.locations);
            for (let i = 0; i < this.props.selectedIndex; i++) {
              locations[i].addTo(map);
            }
          }
        } else {
          // BREADCRUMBS ARE IN AUTO PLAY MODE
          if (this.props.displayType === 'lines') {
            locations = createPath(this.props.locations);
          } else {
            locations = createLocationsLayerArray(this.props.locations);
          }

          // this.mediaControl(map, locations, this.props.selectedIndex, this.props.pace);
        }
      } else {
        if (this.props.displayType === 'lines') {
          locations = createPath(this.props.locations);
          locations.forEach((l) => {
            l.addTo(map);
          });
        } else {
          locations = createLocationsLayerGroup(this.props.locations);
          locations.addTo(map);
        }
      }
    }
  }

  componentDidMount() {
    if (!this.props.marker) {
      if (!this.props.locations) {
        return false;
      }
    }
    let lat;

    let long;

    if (this.props.marker) {
      lat = this.props.marker.x;
      long = this.props.marker.y;
    } else if (this.props.locations.length > 0) {
      lat = this.props.locations[0].location.x;
      long = this.props.locations[0].location.y;
    }
    if (!lat && !long) {
      return false;
    }
    let map = L.map('map', {
      center: [lat, long],
      zoom: 13,
    }).setView([lat, long], 13);
    L.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          'pk.eyJ1IjoiaGF5ZGVuLXRoaW5hZXIiLCJhIjoiY2s3NHpjMGoxMHQ0bzNtcDdocGY5ZXZ3NiJ9.ftXGlq6a1nXfYrBHXBIEkA',
      }
    ).addTo(map);
    L.marker([lat, long]).addTo(map);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { map } = this.state;
    this.drawCanvas(map);
  }

  render() {
    return <div id="map" style={{ height: this.props.height }}></div>;
  }
}

export default LocationsContainer;

//map == map info
//marker == last location coords
//zoom == wether or not to allow zoom
//height == height of map
