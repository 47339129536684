import * as types from '../types';

const things = (
  state = {
    isLoading: false,
    items: [],
    pages: 0,
    total: 0,
    currentPage: 1,
    currentOrder: 'asc',
    currentThing: {},
    onlineThings: 0,
    offlineThings: 0,
    provisionedThings: 0,
    totalThings: 0,
  },
  action
) => {
  switch (action.type) {
    case types.THING_CLEAR:
      return Object.assign({}, state, {
        isLoading: false,
        currentThing: {},
      });
    case types.THING_DELETE_COMPLETED: // delete request has completed
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.THING_DELETE_STARTED: // initiating a delete request
      return Object.assign({}, state, {
        isLoading: true,
      });

    case types.THING_SUMMARY_REQUEST: // initiating a delete request
      return Object.assign({}, state, {
        isLoading: true,
      });

    case types.THING_SUMMARY_RECEIVED: // initiating a delete request
      return Object.assign({}, state, {
        isLoading: false,
        onlineThings: action.online,
        offlineThings: action.offline,
        provisionedThings: action.provisioned,
        totalThings: action.total,
      });

    case types.THING_PATCH_COMPLETED: // update request has completed
      return Object.assign({}, state, {
        isLoading: false,
        // currentThing: action.thing
      });
    case types.THING_PATCH_STARTED: // update request completed
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.THINGS_REQUEST: // get all request started
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.THING_REQUEST: // get single thing request
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.THING_RECEIVED: // single thing request completed
      return Object.assign({}, state, {
        isLoading: false,
        currentThing: action.thing,
      });
    case types.THINGS_RECEIVED: // things request completed
      return Object.assign({}, state, {
        isLoading: false,
        items: action.things,
        pages: action.pages,
        total: action.total,
        currentPage: action.currentPage,
        currentOrder: action.currentOrder,
        currentThing: {},
      });
    case types.THING_REQUEST_FAILURE: // general thing api failure
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
};

export default things;
