import L from 'leaflet';
import {} from '../leaflet-icons';

import { noop } from '../../../../utils';

const createBeaconMarker = (beacon, reading, onClick) => {
  if (beacon.location) {
    let marker = L.marker([beacon.location.y, beacon.location.x], {
      icon: L.AwesomeMarkers.icon({
        prefix: 'fa',
        icon: 'tags',
        markerColor: reading && beacon.beacon[reading].activeTrigger ? 'red' : 'cadetblue',
        value: reading ? Math.round(beacon.beacon[reading].value) : null,
      }),
      title: beacon.beacon.name,
    }).on('click', onClick);

    marker.beaconIndex = beacon.index;

    return marker;
  }
  return null;
};

const createBeaconLayers = (beacon, reading, onClick) => {
  const layers = [];
  if (beacon.isVisible) {
    let layer = createBeaconMarker(beacon, reading, onClick);

    if (layer != null) {
      layers.push(layer);
    }
  }

  return layers;
};

const createBeaconsLayerGroup = (beacons, reading, onClick = noop) => {
  return new L.FeatureGroup(createBeaconsLayerArray(beacons, reading, onClick));
};

const createBeaconsLayerArray = (beacons, reading, onClick) => {
  if (!beacons) {
    return [];
  }

  const layers = [];
  for (let beacon of beacons) {
    const beaconLayers = createBeaconLayers(beacon, reading, onClick);
    for (let layer of beaconLayers) {
      layers.push(layer);
    }
  }

  return layers;
};

export { createBeaconsLayerGroup };
