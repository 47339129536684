import baseDataContext from './baseDataContext';
import Api from '../../config/api';
import { store } from '../../redux/store/store';

let state;
const context = baseDataContext.context('beacons', 'v2/');
const version = 'v2';

const all = (pageNumber, pageSize, sortField, sortOrder, filter) => {
  return context.all(pageNumber, pageSize, sortField, sortOrder, filter);
};

const summary = () => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${version}/${state.navigation.currentApplication.appID}/beacons/summary`
  );
};

const details = (id) => {
  state = store.getState();
  return Api.get(
    `${process.env.REACT_APP_API_URL}/${version}/${state.navigation.currentApplication.appID}/beacons/${id}/details`
  );
};

const get = (id) => {
  return context.get(id);
};

const post = (json) => {
  return context.post(json);
};

const patch = (id, patch) => {
  return context.patch(id, patch);
};

const put = (id, json) => {
  return context.put(id, json);
};

const remove = (id) => {
  return context.remove(id);
};

export default {
  all,
  summary,
  get,
  details,
  post,
  patch,
  remove,
  put,
};
