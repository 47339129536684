import React, { Component } from 'react';

import CrumbsHeader from '../../components/layout/crumbsHeader';
import TriggerCard from './../../components/cards/triggerCard';
import triggerService from './../../services/triggerService';
import toast from './../../components/utils/toast';

class TriggerDashboardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      error: false,
    };
  }

  componentDidMount() {
    this.getTriggers();
  }

  getTriggers = async () => {
    try {
      const { data } = await triggerService.getDashboardTriggers();
      this.setState({ data });
    } catch (error) {
      this.setState({ error });
    }
  };

  snoozeTrigger = async (trigger, recipient, time) => {
    try {
      await triggerService.snoozeDashboardTrigger(trigger, recipient, time);
      toast.success();
      this.getTriggers();
    } catch (error) {
      toast.error('Failed to snooze trigger, please refresh the page and try again.');
    }
  };

  resolveTrigger = async (trigger, recipient, comment) => {
    try {
      await triggerService.resolveDashboardTrigger(trigger, recipient, comment);
      toast.success();
      this.getTriggers();
    } catch (error) {
      toast.error('Failed to resolve trigger, please refresh the page and try again.');
    }
  };

  resetTrigger = async (trigger, recipient, comment) => {
    try {
      await triggerService.resetDashboardTrigger(trigger, recipient, comment);
      toast.success();
      this.getTriggers();
    } catch (error) {
      toast.error('Failed to reset trigger, please refresh the page and try again.');
    }
  };

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Triggers'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/triggers', label: 'Triggers' },
          ]}
        />

        <div className="card m-4">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-content-center">
                <span className="user-avatar border-0 size-50 bg-danger">
                  <i className="fas fa-exclamation text-white" />
                </span>

                <div className="ml-3">
                  <h1 className="m-0 p-0">
                    {this.state.data
                      ? this.state.data.filter((val) => val.triggerStatus.toLowerCase() === 'met')
                          .length
                      : ' '}
                  </h1>

                  <p>Active</p>
                </div>
              </div>

              <div className="d-flex">
                <span className="user-avatar border-0 size-50 bg-warning">
                  <i className="fas fa-bed text-white" />
                </span>

                <div className="ml-3">
                  <h1 className="m-0 p-0">
                    {this.state.data
                      ? this.state.data.filter(
                          (val) => val.triggerStatus.toLowerCase() === 'snoozed'
                        ).length
                      : ' '}
                  </h1>
                  <p>Snoozed</p>
                </div>
              </div>

              <div className="d-flex align-content-center">
                <span className="user-avatar border-0 size-50 bg-light-green">
                  <i className="fas fa-check text-white" />
                </span>

                <div className="ml-3">
                  <h1 className="m-0 p-0">
                    {this.state.data
                      ? this.state.data.filter(
                          (val) => val.triggerStatus.toLowerCase() === 'resolved'
                        ).length
                      : ' '}
                  </h1>
                  <p>Resolved</p>
                </div>
              </div>

              <div className="d-flex align-content-center">
                <span className="user-avatar border-0 size-50 bg-blue-grey">
                  <i className="fas fa-crosshairs text-white" />
                </span>

                <div className="ml-3">
                  <h1 className="m-0 p-0">{this.state.data ? this.state.data.length : ' '}</h1>
                  <p>Total</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-wrap">
          {this.state.data.map((val, i) => {
            return (
              <TriggerCard
                data={val}
                key={val.triggerId}
                snooze={this.snoozeTrigger}
                resolve={this.resolveTrigger}
                reset={this.resetTrigger}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default TriggerDashboardContainer;
