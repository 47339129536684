import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import swal from '@sweetalert/with-react';
import placeService from './../../services/placeService';
import { convertCommaStringToArray } from './../../utils/stringUtils';

import toast from '../utils/toast';
import Dropzone from '../dropzones/dropzone';
import CloseModal from './closeModal';
import axios from 'axios';

/**
 * @desc Displays content to allow Admin to bulk import gateways via CSV
 */
export default class AddUsersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gateways: [],
      submitEnable: false,
    };
  }

  getCsvHeaders = () => {
    return [
      { label: 'Device Name', key: 'name' },
      { label: 'Device Description', key: 'description' },
      { label: 'Device ID', key: 'deviceId' },
      { label: 'Commit Hash', key: 'commitHash' },
      { label: 'Device Keywords', key: 'keywords' },
    ];
  };

  // display an example row for User downloading CSV
  getExampleCsvData = () => {
    return [
      {
        name: 'Example Gateway',
        description: 'This gateway is an example',
        deviceId: 'EX:AM:PL:E1:23:45',
        keywords: 'example tag, example tag 2',
        commitHash: '',
      },
    ];
  };

  onDropCsv = (file) => {
    this.setState({ submitEnable: true });
    const reader = new FileReader();
    reader.onload = (e) => {
      const gateways = e.target.result
        .split('\n')
        .slice(1) // remove headers
        .map((csvString) => {
          const [name, description, deviceId, commitHash, keywords] = csvString.split(',');
          if (commitHash && commitHash.trim().length > 3) {
            return {
              name: name ? name.trim().replace(/['"]+/g, '') : null,
              description: description ? description.trim().replace(/['"]+/g, '') : null,
              deviceId: deviceId ? deviceId.trim().replace(/['"]+/g, '') : null,
              keywords: keywords ? convertCommaStringToArray(keywords.replace(/['"]+/g, '')) : [],
              commitHash: commitHash ? commitHash.trim().replace(/['"]+/g, '') : null,
            };
          } else {
            return {
              name: name ? name.trim().replace(/['"]+/g, '') : null,
              description: description ? description.trim().replace(/['"]+/g, '') : null,
              deviceId: deviceId ? deviceId.trim().replace(/['"]+/g, '') : null,
              keywords: keywords ? convertCommaStringToArray(keywords.replace(/['"]+/g, '')) : [],
            };
          }
        });
      console.log(gateways);
      this.setState({ gateways });
    };

    reader.onerror = (e) => {
      toast.error('Failed to read the CSV file. Please try again.');
    };

    reader.readAsText(file);
  };

  onUploadUsers = () => {
    console.log(this.state.gateways);
    let requestArr = [];
    let successArr = [];
    let failedArr = [];
    for (let i = 0; i < this.state.gateways.length; i++) {
      if (this.state.gateways[i].deviceId && this.state.gateways[i].deviceId.length > 12) {
        requestArr.push(
          placeService
            .create(this.state.gateways[i])
            .then((res) => {
              successArr.push(res);
            })
            .catch(() => {
              failedArr.push({ item: this.state.gateways[i], index: i });
            })
        );
      } else {
        failedArr.push({
          message: 'Device ID is incorrect',
          item: this.state.gateways[i],
          index: i,
        });
      }
    }
    axios.all(requestArr).then(
      axios.spread((...responses) => {
        console.log('Completed');
        if (failedArr.length > 0) {
          if (failedArr.length > 1) {
            toast.warn(`Uh Oh! Failed to create ${failedArr.length} beacons.`);
          } else {
            if (failedArr[0].message) {
              toast.warn(
                `Uh Oh! Failed to create ${failedArr[0].item.name}. Device ID is incorrect`
              );
            } else {
              toast.warn(
                `Uh Oh! Failed to create ${failedArr[0].item.name}. Device ID is already active in Sonar.`
              );
            }
          }
          swal.close();
          this.props.getFailedFunc(failedArr);
        } else if (failedArr.length === 0) {
          window.location.assign('/');
          toast.success(`Successfully created ${successArr.length} gateways`);
        }
        console.log(failedArr);
        console.log('COMPLETED: ', successArr.length, 'FAILED: ', failedArr.length);
      })
    );
  };

  closeSwal = (e) => {
    e.preventDefault();
    window.location.assign('/');
    swal.close();
  };

  render() {
    return (
      <div>
        <CloseModal />
        <div className="form-inline justify-content-center mb-2">
          <div className="mr-4">
            <i style={{ color: '#3f51b5' }} className="fas fa-3x mb-2 fa-home" />
            <span>Add Gateways</span>
          </div>
        </div>
        <hr />
        <p>
          Download the CSV template to include the gateways you would like to add then upload the
          completed CSV
        </p>

        <CSVLink
          data={this.getExampleCsvData()}
          headers={this.getCsvHeaders()}
          filename="bulk-gateways.csv"
          className="btn btn-outline-primary mb-4"
        >
          Download CSV
        </CSVLink>

        <div className="d-flex mb-4 justify-content-center">
          <Dropzone
            onDrop={this.onDropCsv}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>

        <div className="d-flex flex-row justify-content-around">
          <button
            type="button"
            className="btn btn-outline-primary"
            title="Add Users"
            onClick={(e) => this.closeSwal(e)}
          >
            Cancel
          </button>
          {this.state.submitEnable ? (
            <button
              type="button"
              className="btn btn-primary"
              title="Add Users"
              onClick={this.onUploadUsers}
            >
              Upload
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              title="Add Users"
              onClick={this.onUploadUsers}
              disabled
            >
              Upload
            </button>
          )}
        </div>
      </div>
    );
  }
}
