import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class CrumbsHeader extends Component {
  buildNav() {
    const rowLen = this.props.crumbs.length;

    return this.props.crumbs.map((link, i) => {
      let c = '';
      if (rowLen === i + 1) {
        c = (
          <span key={i} className="breadcrumb-item active">
            {link.label}
          </span>
        );
      } else {
        c = (
          <Link key={i} className="breadcrumb-item" to={`${link.route}`}>
            {link.label}
          </Link>
        );
      }

      return c;
    });
  }

  render() {
    return <nav className="breadcrumb mb-2 mt-2">{this.buildNav()}</nav>;
  }
}

CrumbsHeader.propTypes = {
  header: PropTypes.string,
  crumbs: PropTypes.array,
};

export default CrumbsHeader;
