export const convertCommaStringToArray = (input) => {
  // if the input is already an array, just return it
  // this means there were no edits made to the keywords
  if (Array.isArray(input)) {
    return input;
  }

  let result = [];
  if (typeof input === 'string') {
    if (input.length > 0) {
      let temp = input.split(',');
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].trim() !== '') {
          result[i] = temp[i].trim();
        }
      }
    }
  }

  return result;
};
