import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { readOnlyCheck } from './../../utils';

class AdvancedHealthCard extends React.Component {
  render() {
    if (!this.props.data[0]) {
      return <div />;
    }

    return (
      <div className="col-lg-6" style={{ marginTop: '20px' }}>
        <div className="card h-100">
          <div className="card-header d-flex align-items-center">
            <h3 className="h4">Manual Air Pressure</h3>
          </div>
          <div className="card-close">
            {readOnlyCheck() ? (
              false
            ) : (
              <div className="dropdown">
                <button
                  type="button"
                  id="closeCard1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-ellipsis-v" />
                </button>
                <div
                  aria-labelledby="closeCard1"
                  className="dropdown-menu dropdown-menu-right has-shadow"
                >
                  <Link className="dropdown-item edit" to={'/pressure'}>
                    <i className="fa fa-plus" />
                    Record Reading
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="card-body">
            {this.props.data.map((val, i) => {
              return (
                <div className="row" key={i}>
                  <div className="col-md-6">
                    <i className="fa fa-clock-o" /> &nbsp;
                    {moment(val.timeStamp).format('LLL')}
                  </div>
                  <div className="col-md-5">
                    {val.reading.charAt(0).toUpperCase() + val.reading.slice(1)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

AdvancedHealthCard.propTypes = {
  heartRate: PropTypes.number,
  spO2: PropTypes.number,
  batteryLevel: PropTypes.number,
  recordedAt: PropTypes.string,
  data: PropTypes.array,
};

export default AdvancedHealthCard;
