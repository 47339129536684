import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { Redirect } from 'react-router-dom';
import '../components/auth/auth.css';
import { isKeyboardKey } from '../utils';
import { KEYBOARD_KEYS } from '../constants';
import './dashboard/newDash.css';

const guid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

const frequency = [
  {
    value: '',
    label: '',
  },
  {
    value: '-1',
    label: 'One Time',
  },
  {
    value: '300',
    label: '5 Minutes',
  },
  {
    value: '900',
    label: '15 Minutes',
  },
  {
    value: '1800',
    label: '30 Minutes',
  },
  {
    value: '2700',
    label: '45 Minutes',
  },
  {
    value: '3600',
    label: '1 Hour',
  },
  {
    value: '7200',
    label: '2 Hours',
  },
  {
    value: '21600',
    label: '6 Hours',
  },
  {
    value: '43200',
    label: '12 Hours',
  },
  {
    value: '86400',
    label: '1 Day',
  },
];

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 1,
  },
  button: {
    color: '#9700C7',
  },
});

class TriggerResponseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snoozeTime: 3600,
      trigger: '',
      thing: '',
      recipient: '',
      notification: '',
      showReset: false,
      showResolve: false,
      showInterval: false,
      resolveNotes: '',
      resetNotes: '',
      confirmation: false,
      resolveError: false,
    };
    this.snoozeTrigger = this.snoozeTrigger.bind(this);
    this.resolveTrigger = this.resolveTrigger.bind(this);
    this.resetTrigger = this.resetTrigger.bind(this);
    this.revealInterval = this.revealInterval.bind(this);
    this.revealResetNotes = this.revealResetNotes.bind(this);
    this.revealResolveNotes = this.revealResolveNotes.bind(this);
  }
  componentDidMount() {
    this.setState({
      triggerId: this.props.match.params.triggerId,
      triggerRecipientId: this.props.match.params.triggerRecipientId,
      triggerNotificationId: this.props.match.params.triggerNotificationId,
    });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/triggers/${this.props.match.params.triggerId}/recipients/${this.props.match.params.triggerRecipientId}/notifications/${this.props.match.params.triggerNotificationId}`
      )
      .then((res) => {
        this.setState({
          recipient: res.data.recipient,
          thing: res.data.thing,
          trigger: res.data.trigger,
          notification: res.data.notification,
        });
      })
      .catch((err) => {
        // alert('An error has occurred please try loading the page again.');
        this.setState({ error: 'Failed to get trigger information' });
      });
  }

  snoozeTrigger() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/triggers/${this.state.trigger.id}/recipients/${this.state.triggerRecipientId}/notifications/${this.state.triggerNotificationId}/snooze/${this.state.snoozeTime}`
      )
      .then((res) => {
        this.setState({
          snoozeTime: 3600,
          trigger: '',
          thing: '',
          recipient: '',
          notification: '',
          showReset: false,
          showResolve: false,
          showInterval: false,
          resolveNotes: '',
          resetNotes: '',
          confirmation: true,
        });
      })
      .catch((err) => {
        // alert('An error occurred when trying to snooze this trigger');
        this.setState({ error: 'Failed to snooze trigger' });
      });
  }

  resolveTrigger() {
    if (this.state.resolveNotes) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/triggers/${this.state.trigger.id}/recipients/${this.state.triggerRecipientId}/notifications/${this.state.triggerNotificationId}/resolve`,
          { comment: this.state.resolveNotes }
        )
        .then((res) => {
          this.setState({
            snoozeTime: 3600,
            trigger: '',
            thing: '',
            recipient: '',
            notification: '',
            showReset: false,
            showResolve: false,
            showInterval: false,
            resolveNotes: '',
            resetNotes: '',
            confirmation: true,
            resolveError: false,
          });
        })
        .catch((err) => {
          // alert('An error occurred when trying to resolve this trigger');
          this.setState({ error: 'Failed to resolve trigger' });
        });
    } else {
      this.setState({ resolveError: true });
    }
  }

  resetTrigger() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/triggers/${this.state.trigger.id}/recipients/${this.state.triggerRecipientId}/notifications/${this.state.triggerNotificationId}/reset`,
        { comment: this.state.resetNotes }
      )
      .then((res) => {
        this.setState({
          snoozeTime: 3600,
          trigger: '',
          thing: '',
          recipient: '',
          notification: '',
          showReset: false,
          showResolve: false,
          showInterval: false,
          resolveNotes: '',
          resetNotes: '',
          confirmation: true,
        });
      })
      .catch((err) => {
        // alert('An error occurred when trying to reset this trigger');
        this.setState({ error: 'Failed to reset trigger' });
      });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  revealResetNotes() {
    this.setState({
      showReset: !this.state.showReset,
      showResolve: false,
      showInterval: false,
    });
  }

  revealResolveNotes() {
    this.setState({
      showResolve: !this.state.showResolve,
      showReset: false,
      showInterval: false,
    });
  }

  revealInterval() {
    this.setState({
      showInterval: !this.state.showInterval,
      showResolve: false,
      showReset: false,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="confirmationPage">
        <div
          className="container d-flex align-items-center"
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'stretch',
          }}
        >
          <div className="form-holder">
            {this.state.confirmation ? <Redirect to="/triggeremailconfirmation" /> : false}
            {this.state.trigger ? (
              <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                  <Typography component="h1" variant="h5" align="center">
                    Take action on your trigger <br />(
                    {this.state.trigger && this.state.trigger.triggerName
                      ? `${this.state.trigger.triggerName}`
                      : 'NO TRIGGER NAME'}
                    ) for{' '}
                    {this.state.trigger && this.state.thing.name
                      ? `${this.state.thing.name}`
                      : 'THING NAME'}
                  </Typography>

                  <form className={classes.form}>
                    <Typography align="center" component="h3" variant="h6">
                      How would you like to proceed?
                    </Typography>
                    <FormControl margin="normal" required fullWidth>
                      {this.state.showInterval ? (
                        <div
                          style={{
                            marginTop: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            flexWrap: 'nowrap',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            alignContent: 'stretch',
                          }}
                        >
                          <TextField
                            id="notif-freq"
                            select
                            label={`Snooze for`}
                            className={classes.textField}
                            value={this.state.snoozeTime}
                            onChange={this.handleChange('snoozeTime')}
                            SelectProps={{
                              native: true,
                              MenuProps: {
                                className: classes.menu,
                              },
                            }}
                            helperText="How long would you like to snooze this trigger for?"
                            margin="normal"
                          >
                            {frequency.map((option) => (
                              <option key={guid()} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </div>
                      ) : (
                        false
                      )}
                      <Button
                        fullWidth
                        variant="contained"
                        style={{
                          backgroundColor: '#9700C7',
                          color: 'white',
                        }}
                        className={classes.submit}
                        onClick={this.snoozeTrigger}
                      >
                        Snooze Trigger
                      </Button>
                      <Typography align="center">
                        Snooze your trigger so it alerts again in {this.state.snoozeTime / 60}{' '}
                        Minutes.
                        <span
                          role="button"
                          tabIndex={0}
                          onKeyDown={(event) =>
                            isKeyboardKey(event, KEYBOARD_KEYS.ENTER, this.revealInterval)
                          }
                          onClick={this.revealInterval}
                          style={{ cursor: 'pointer', color: '#1ea18e' }}
                        >
                          {' '}
                          Change snooze interval
                        </span>
                      </Typography>
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        id={`resolution-field`}
                        label="Resolution Notes"
                        className={classes.textField}
                        value={this.state.resolveNotes}
                        onChange={this.handleChange('resolveNotes')}
                        margin="normal"
                        helperText="Enter notes about this trigger resolution"
                        multiline
                        rows="3"
                        error={this.state.resolveError}
                      />

                      <Button
                        fullWidth
                        variant="contained"
                        style={{
                          backgroundColor: '#9700C7',
                          color: 'white',
                        }}
                        className={classes.submit}
                        onClick={this.resolveTrigger}
                      >
                        Resolve Trigger
                      </Button>
                      <Typography align="center">
                        Resolve your trigger so that it doesn&apos;t alert you again for this
                        incident.
                      </Typography>
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                      {this.state.showReset ? (
                        <TextField
                          id={`reset-field`}
                          label="Reset Notes"
                          className={classes.textField}
                          value={this.state.resetNotes}
                          onChange={this.handleChange('resetNotes')}
                          margin="normal"
                          helperText="Enter any notes about this trigger reset"
                          multiline
                          rows="3"
                        />
                      ) : (
                        false
                      )}

                      <Button
                        fullWidth
                        variant="contained"
                        style={{
                          backgroundColor: '#9700C7',
                          color: 'white',
                        }}
                        className={classes.submit}
                        onClick={this.resetTrigger}
                      >
                        Reset Trigger
                      </Button>
                      <Typography align="center">
                        Reset your trigger to new so that it can start detecting new incidents.{' '}
                        <span
                          role="button"
                          tabIndex={0}
                          onKeyDown={(event) =>
                            isKeyboardKey(event, KEYBOARD_KEYS.ENTER, this.revealResetNotes)
                          }
                          style={{ cursor: 'pointer', color: '#1ea18e' }}
                          onClick={this.revealResetNotes}
                        >
                          Document a reset reason
                        </span>
                      </Typography>
                    </FormControl>
                  </form>
                </Paper>
              </main>
            ) : this.state.error ? (
              <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                  <Typography align="center">
                    Trigger information unavailable, please log in to resolve trigger.
                  </Typography>
                  <Button
                    href="/"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: '#9700C7',
                      color: 'white',
                    }}
                    className={classes.submit}
                    onClick={this.resetTrigger}
                  >
                    Login
                  </Button>
                </Paper>
              </main>
            ) : !this.state.confirmation ? (
              <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                  <Typography align="center">Loading trigger information</Typography>
                </Paper>
              </main>
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    );
  }
}

TriggerResponseContainer.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const mapStateToProps = (state) => {
  return {
    navigation: state.navigation,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TriggerResponseContainer)
);

// <Typography
//     align="center"
//     variant="subtitle2"
//     style={{ fontSize: '1.2em' }}
// >
//     {this.state.trigger &&
//         (this.state.trigger.temperatureMax ||
//             this.state.trigger.temperatureMin ||
//             this.state.trigger.humidityMax ||
//             this.state.trigger.humidityMin)
//         ? `Trigger was met because it was outside its allowed
//                                         parameters of
//                                         ${
//         this.state.trigger &&
//             (this.state.trigger.temperatureMax ||
//                 this.state.trigger.temperatureMin ||
//                 this.state.trigger.humidityMax ||
//                 this.state.trigger.humidityMin)
//             ? `Temperature: ${this.state.trigger
//                 .temperatureMin || 'N/A'} - ${this.state
//                     .trigger.temperatureMax ||
//                 'N/A'} or Humidity: ${this.state.trigger
//                     .humidityMin || 'N/A'} - ${this.state.trigger
//                         .humidityMax || 'N/A'}`
//             : false
//         }`
//         : `Trigger was met because it was outside its allowed
//                                         zones`}
// </Typography>
