// general utils

export function noop() {}

export async function wait(time) {
  return new Promise((res) => {
    setTimeout(() => {
      res('done');
    }, time * 1000);
  });
}

export function getWaitPromises({ numPromises, maxWaitTime }) {
  return new Array(numPromises).fill('').map((o) => wait(Math.floor(Math.random() * maxWaitTime)));
}

/**
 * @desc Helper to sort an array of objects.
 * @param {Array} arr array to sort
 * @param {string} key Key of object in array to sort by
 * @param {boolean} asc Sort in ascending order
 */
export function sortArrayObjsByKey(arr, key, asc = true) {
  const sortedCopy = [...arr];
  sortedCopy.sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA < valueB) return asc ? -1 : 1;
    if (valueA > valueB) return asc ? 1 : -1;
    return 0;
  });
  return sortedCopy;
}
