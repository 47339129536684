import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Api from './../../config/api';
import moment from 'moment';
import { toggleApplication, changeLocation } from '../../redux/actions/navigationActions';
import { Link } from 'react-router-dom';
import './appSwitcher.css';

moment().format();

const iconStyle = {
  fontSize: '1.6em',
};

class ApplicationSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      allApplications: [],
      myApplications: [],
      currentApplicationName: 'My Applications',
      anchorEl: null,
    };
  }

  componentDidMount() {
    if (
      localStorage.token !== undefined &&
      localStorage.token !== null &&
      localStorage.token !== 'undefined' &&
      localStorage.token !== 'null' &&
      typeof localStorage.token === 'string'
    ) {
      this.getApplications();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalID);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getApplications = () => {
    if (localStorage.currentApplicationID) {
      let now = {
        appID: localStorage.currentApplicationID,
        name: localStorage.currentApplicationName,
      };
      this.toggleApplication(now);
    }
    Api.get(`${process.env.REACT_APP_API_URL}/applications`)
      .then((res) => {
        let data = res.data.map((val, i) => {
          return { name: val.name, appID: val.id };
        });
        this.setState({ allApplications: data });
        let myApplications = localStorage.getItem('applications');
        let myAppArr = myApplications.split(',');
        this.setState({ myApplications: myAppArr });
        let combinedApps = this.state.myApplications.map((val, i) => {
          let arr = this.state.allApplications;
          for (i = 0; i < arr.length; i++) {
            if (arr[i].appID === val) {
              return { appID: val, name: arr[i].name };
            }
          }
          return null;
        });
        this.setState({ myApplications: combinedApps });
        if (!localStorage.currentApplicationID) {
          if (this.state.myApplications.length >= 1) {
            let first = this.state.myApplications[0];
            this.toggleApplication(first);
          }
        }
      })
      .catch(() => {
        return;
      });
  };

  toggleApplication(val) {
    this.props.toggleApplication(val);
    this.setState({ currentApplicationName: val.name });
    this.setState({ currentApplicationID: val.appID });
    this.handleClose();
  }

  render() {
    return (
      <Fragment>
        <span
          className="dropdown-toggle no-arrow d-inline-block pointer"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fas fa-caret-down" style={iconStyle}></i>
        </span>

        <div className="dropdown-menu dropdown-menu-right">
          {this.state.allApplications
            ? this.state.myApplications.map((val, i) => {
                if (
                  !val ||
                  !this.state.currentApplicationName ||
                  !this.state.currentApplicationID
                ) {
                  return false;
                }
                if (val.name === this.state.currentApplicationName) {
                  return (
                    <Link
                      to="/"
                      className="dropdown-item"
                      key={i}
                      onClick={() => {
                        this.toggleApplication(val);
                        this.props.changeLocation('/');
                      }}
                      style={{ fontWeight: 800 }}
                    >
                      <span>{val.name}</span>
                    </Link>
                  );
                }
                return (
                  <Link
                    to="/"
                    className="dropdown-item"
                    key={i}
                    onClick={() => {
                      this.toggleApplication(val);
                      this.props.changeLocation('/');
                    }}
                  >
                    <span>{val.name}</span>
                  </Link>
                );
              })
            : false}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, { toggleApplication, changeLocation })(ApplicationSwitcher);

// <div className="appSwitcher">
//     <div className="appSwitcher" style={{ cursor: 'pointer' }} onClick={this.handleClick}>
//         <span className="text">{applicationName}</span>
//         <span className="fa fa-caret-down fa-lg carrat" />
//     </div>
//     <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
//         {this.state.allApplications
//             ? this.state.myApplications.map((val, i) => {
//                   return (
//                       <MenuItem
//                           key={i}
//                           onClick={() => {
//                               this.toggleApplication(val);
//                               this.props.changeLocation('/');
//                           }}
//                       >{`${val ? val.name : ''}`}</MenuItem>
//                   );
//               })
//             : () => <MenuItem primaryText="Loading" />}
//     </Menu>
// </div>;
