import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import moment from 'moment';

import './CustomTooltip.css';

class CustomTooltip extends Component {
  getReactContainerClasses() {
    return ['custom-tooltip'];
  }

  render() {
    const { data } = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex) || {};
    console.log(data);
    if (!data) return null;

    return (
      <Card bg={'dark'} text={'white'}>
        <Card.Header>{data.contactBeacon ? data.contactBeacon.name : 'Pending'}</Card.Header>
        <Card.Body>
          <Card.Text>{`Exposure Rating: ${
            data.details ? data.details.exposureRating : 'Pending'
          }`}</Card.Text>
          <Card.Text>{`Time in Contact: ${moment
            .duration(data.timeInContact)
            .humanize()}`}</Card.Text>
          <Card.Text>{`Current Location: ${data.place ? data.place.name : 'Pending'}`}</Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

export default CustomTooltip;
