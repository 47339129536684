/* Utilities for Account Management */
import EventEmitter from 'eventemitter3';
import swal from '@sweetalert/with-react';

import Api from '../../../config/api';
import toast from '../../../components/utils/toast';

export const emitter = new EventEmitter();

export const EVENTS = {
  INVITE_USERS_CLOSED: 'INVITE_USERS_CLOSED',
  REFETCH_USERS: 'REFETCH_USERS',
  EDIT_USER: 'EDIT_USER',
};

export const ROLES = ['ReadOnly', 'User', 'Admin'];

export function validateRoles(invitees) {
  const invalidRoles = invitees.filter((invitee) => !ROLES.includes(invitee.role));
  if (!invalidRoles.length) return true; // we are good

  // alert user to the invalid roles
  let invalidRolesAlert = '';
  invalidRoles.forEach((invitee, index) => {
    invalidRolesAlert += `${index + 1}. Please fix the role for ${
      invitee.username
    } to one of (ReadOnly | User | Admin). It is currently "${invitee.role}"\n\n`;
  });
  alert(invalidRolesAlert);
  return false;
}

/**
 *
 * @param {Object} param0
 * @param {Array.<{username:String, role:String, applicationid:String}>} param0.invitees
 * @param {Object} param0.app
 * @param {String} param0.app.id
 * @param {String} param0.app.name
 */
export async function inviteUsers({ invitees, app }) {
  // error handling
  if (!validateRoles(invitees)) return;
  if (!invitees.length) return toast.error('Please add a User to invite');

  const prefix = `Inviting Users to Application ${app.name}:\n`;
  const confirmString =
    prefix +
    invitees
      .map((invitee, index) => `${index + 1}. ${invitee.username} - ${invitee.role}`)
      .join('\n');
  if (!window.confirm(confirmString)) return;

  try {
    await Api.post(`${process.env.REACT_APP_API_URL}/accountmanagement/invitation`, invitees);
    toast.success('Successfully invited Users.');
    emitter.emit(EVENTS.REFETCH_USERS);
    swal.close();
  } catch (err) {
    toast.error(err.message);
  }
}

/**
 * @desc Creates a new blank invitee for User to fill out
 * @returns {Object} Black invitee with email, role that defaults to READ_ONLY, hasFocused for UI logic
 */
export function getNewInvitee() {
  return { email: '', role: ROLES[0], hasFocused: false };
}
