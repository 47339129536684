import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import cognitoActions from '../../redux/actions/cognitoActions';
import { connect } from 'react-redux';

const iconStyle = {
  fontSize: '1.6em',
};

class SettingDropdown extends Component {
  render() {
    return (
      <Fragment>
        <span
          className="dropdown-toggle no-arrow d-inline-block pointer"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-cog" style={iconStyle} />
        </span>

        <div className="dropdown-menu dropdown-menu-right pb-0">
          <Link className="dropdown-item" to="/favorites">
            <span>Favorites</span>
          </Link>
          <Link className="dropdown-item" to="/settings">
            <span>Settings</span>
          </Link>
          <Link className="dropdown-item" to="/logout" onClick={this.props.onLogoutClick}>
            <span>Logout</span>
          </Link>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogoutClick: () => {
      dispatch(cognitoActions.logout());
    },
  };
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingDropdown);
