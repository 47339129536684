import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import swal from '@sweetalert/with-react';

import toast from '../../../components/utils/toast';
import Dropzone from '../../../components/dropzones/dropzone';
import { inviteUsers, emitter, EVENTS } from './utils';
import CloseModal from './CloseModal';
import AppSelector from './AppSelector';
import InviteUsersModal from './InviteUsersModal';

/**
 * @desc Displays content to allow Admin to invite Users to App via CSV
 */
export default class AddUsersModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedApp: null,
      invitees: [], // { username, role, applicationid }
    };
  }

  getCsvHeaders = () => {
    return [
      { label: 'Email', key: 'email' },
      { label: 'Role (ReadOnly | User | Admin)', key: 'role' },
    ];
  };

  // display an example row for User downloading CSV
  getExampleCsvData = () => {
    return [{ email: 'replacethis@email.com', role: 'ReadOnly' }];
  };

  onDropCsv = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const invitees = e.target.result
        .split('\n')
        .slice(1) // remove headers
        .map((csvString) => {
          const [username, role] = csvString.split(',');
          return {
            username: username.trim(),
            role: role.trim(),
            applicationid: this.state.selectedApp.id,
          };
        });
      this.setState({ invitees });
    };

    reader.onerror = (e) => {
      toast.error('Failed to read the CSV file. Please try again.');
    };

    reader.readAsText(file);
  };

  onUploadUsers = () => {
    inviteUsers({ invitees: this.state.invitees, app: this.state.selectedApp });
  };

  render() {
    return (
      <div>
        <CloseModal />

        <div className="form-inline justify-content-center mb-2">
          <div className="mr-4">
            <i style={{ color: '#3f51b5' }} className="fa fa-3x mb-2 fa-user-plus" />
            <span>Add Users</span>
          </div>
          <AppSelector onChange={(app) => this.setState({ selectedApp: app })} />
        </div>

        <hr />

        <p>
          Download the CSV template to include the users you would like to add, and then upload the
          completed CSV
        </p>

        <CSVLink
          data={this.getExampleCsvData()}
          headers={this.getCsvHeaders()}
          filename="Invite-Users-Template.csv"
          className="btn btn-outline-primary mb-4"
        >
          Download CSV
        </CSVLink>

        <div className="d-flex mb-4 justify-content-center">
          <Dropzone
            onDrop={this.onDropCsv}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>

        <div className="d-flex flex-row justify-content-around">
          <button
            type="button"
            className="btn btn-outline-primary"
            title="Add Users"
            onClick={() => swal.close()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            title="Add Users"
            onClick={this.onUploadUsers}
          >
            Upload
          </button>
        </div>

        <div className="d-flex flex-row justify-content-end">
          <button
            type="button"
            className="btn btn-link"
            title="Invite New Users"
            onClick={() =>
              swal(<InviteUsersModal />, {
                buttons: false,
                className: 'h-auto',
                closeOnClickOutside: false,
              }).then(() => {
                emitter.emit(EVENTS.INVITE_USERS_CLOSED);
              })
            }
          >
            Invite New Users via Email
          </button>
        </div>
      </div>
    );
  }
}
