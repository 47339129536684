// modules
import React, { Component } from 'react';
import swal from '@sweetalert/with-react';
import mapService from '../../../services/mapService';
import CrumbsHeader from '../../../components/layout/crumbsHeader';
import MapEdit from '../components/editMap/mapEdit';
import PropTypes from 'prop-types';
import gatewaysDataContext from '../../../services/dataContexts/gatewaysDataContext';
import beaconsDataContext from '../../../services/dataContexts/beaconsDataContext';
import editMapFunctions from '../components/editMap/editMapFunctions';
import GatewayList from '../components/editMap/gatewayList';
import ZoneList from '../components/editMap/zoneList';
import BeaconList from '../components/editMap/beaconList';

import mapFunctions from '../services/mapFunctions';
import toast from './../../../components/utils/toast';
import MeasurementRow from './../components/editMap/measurementRow';
import DeleteMapSwal from './../components/deleteMapSwal';

class MapEditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: {},
      search: {
        gateways: [],
        beacons: [],
      },
      editingGateway: null,
      editingZone: null,
      editingBeacon: null,
      editingMeasurement: false,
      ready: false,
      maps: {
        editGateway: null,
        addGateway: null,
        editZone: null,
        addZone: null,
        editBeacon: null,
        addBeacon: null,
      },
      staticMeasurements: {
        pixels: '0',
        lengthInMeters: '0',
      },
    };
    this.handleUpdate = this.handleUpdate.bind(this);

    this.getGateways = this.getGateways.bind(this);
    this.onGatewayEdit = this.onGatewayEdit.bind(this);
    this.onClickDeleteMap = this.onClickDeleteMap.bind(this);
    this.onGatewayAdd = this.onGatewayAdd.bind(this);
    this.onGatewayEditMap = this.onGatewayEditMap.bind(this);
    this.onGatewayEditMapCompleted = this.onGatewayEditMapCompleted.bind(this);

    this.onZoneEdit = this.onZoneEdit.bind(this);
    this.onZoneAdd = this.onZoneAdd.bind(this);
    this.onZoneEditMap = this.onZoneEditMap.bind(this);
    this.onZoneEditMapCompleted = this.onZoneEditMapCompleted.bind(this);

    this.getBeacons = this.getBeacons.bind(this);
    this.onBeaconEdit = this.onBeaconEdit.bind(this);
    this.onBeaconAdd = this.onBeaconAdd.bind(this);
    this.onBeaconEditMap = this.onBeaconEditMap.bind(this);
    this.onBeaconEditMapCompleted = this.onBeaconEditMapCompleted.bind(this);

    this.selectButton = this.selectButton.bind(this);
    this.changeVisibility = this.changeVisibility.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.selectNone = this.selectNone.bind(this);

    this.onRemove = this.onRemove.bind(this);
    this.onEditCompleted = this.onEditCompleted.bind(this);
    this.onAddCanceled = this.onAddCanceled.bind(this);
    this.onMeasurementAdd = this.onMeasurementAdd.bind(this);
    this.onMeasurementDistanceChange = this.onMeasurementDistanceChange.bind(this);
  }

  componentDidMount() {
    this.getMap();
  }

  getMap() {
    let mapId = this.props.match.params.mapId;
    mapService.get(mapId).then((res) => {
      this.setState(
        {
          map: mapFunctions.assignPropsToMap(res.data),
        },
        () => {
          if (this.state.map) {
            this.setState({ ready: true });
          }
        }
      );
    });
  }

  getGateways(filter) {
    gatewaysDataContext.all(1, 20, 'name', 'ascending', filter).then((x) => {
      this.setState({
        search: {
          beacons: [],
          gateways: x.data.results,
        },
      });
    });
  }

  getBeacons(filter) {
    beaconsDataContext.all(1, 20, 'name', 'ascending', filter).then((x) => {
      this.setState({
        search: {
          beacons: x.data.results,
          gateways: [],
        },
      });
    });
  }

  handleUpdate() {
    let deleteZones = [];
    this.state.map.zones.filter((obj, i) => {
      if (obj.boundary === null) {
        deleteZones.push(i);
        return obj;
      }
      return null;
    });
    for (let i = deleteZones.length - 1; i >= 0; i--) {
      this.state.map.zones.splice(deleteZones[i], 1);
    }
    if (this.state.staticMeasurements.pixels && this.state.staticMeasurements.lengthInMeters) {
      console.log(this.state.staticMeasurements);
      let x = this.state.map;
      x.pixelsPerMeter = Math.floor(
        this.state.staticMeasurements.pixels / Number(this.state.staticMeasurements.lengthInMeters)
      );
      console.log(x.pixelsPerMeter);
      this.setState({ map: x });
    }

    mapService
      .update(this.state.map.id, this.state.map)
      .then(() => {
        toast.success();
        window.location.assign('/maps');
      })
      .catch(() => {
        toast.error('Failed to submit update, please try again.');
      });
  }

  changeVisibility = (source, field, id) => {
    let map = this.state.map;
    map[source] = map[source].map((i) => {
      if (i[field] === id) {
        i.isVisible = !i.isVisible;
      }
      return i;
    });
    this.setState({ map: map });
  };

  selectAll = (source) => {
    let map = this.state.map;
    map[source] = map[source].map((i) => {
      i.isVisible = true;
      return i;
    });
    this.setState({ map: map });
  };

  selectNone = (source) => {
    let map = this.state.map;
    map[source] = map[source].map((i) => {
      i.isVisible = false;
      return i;
    });
    this.setState({ map: map });
  };

  selectButton = (map, source) => {
    return editMapFunctions.selectAllOrNoneButton(map, source);
  };

  onRemove = (source, index) => {
    let map = this.state.map;
    map = editMapFunctions.remove(map, source, index);
    this.setState({ map: map });
  };

  onGatewayAdd = () => {
    const map = this.state.map;
    const gateway = editMapFunctions.create.gateway(this.state.map.gateways.length);
    map.gateways.unshift(gateway);

    this.setState({
      map: map,
      editingGateway: gateway,
    });
  };

  onGatewayEdit = (mapGateway) => {
    this.setState({
      editingGateway: mapGateway,
    });
  };

  onAddCanceled = (source, index) => {
    let map = editMapFunctions.remove(this.state.map, source, index);
    this.setState({
      map: map,
      editingGateway: null,
      editingBeacon: null,
      editingZone: null,
      addBeacon: null,
      editBeacon: null,
    });
  };

  onEditCompleted = (source, item) => {
    let map = editMapFunctions.replace(this.state.map, source, item);
    this.setState({
      map: map,
      editingGateway: null,
      editingBeacon: null,
      editingZone: null,
      addBeacon: null,
      editBeacon: null,
    });
  };

  onGatewayEditMap = (gateway) => {
    if (gateway.location) {
      this.setState({
        maps: {
          addGateway: null,
          editGateway: gateway.index,
          addZone: false,
          editZone: null,
          addBeacon: null,
          editBeacon: null,
        },
      });
      return;
    }

    this.setState({
      maps: {
        addGateway: gateway.index,
        editGateway: null,
        addZone: false,
        editZone: null,
        addBeacon: null,
        editBeacon: null,
      },
    });
  };

  onGatewayEditMapCompleted = (gatewayIndex, location) => {
    let map = this.state.map;
    map = editMapFunctions.setGatewayLocation(map, gatewayIndex, location);
    this.setState({
      map,
      maps: {
        addGateway: null,
        editGateway: null,
        addZone: null,
        editZone: null,
        addBeacon: null,
        editBeacon: null,
      },
    });
  };

  onZoneAdd = () => {
    const map = this.state.map;
    const zone = editMapFunctions.create.zone(this.state.map.zones.length);
    map.zones.unshift(zone);

    this.setState({
      map: map,
      editingZone: zone,
    });
  };

  onZoneEdit = (zone) => {
    this.setState({
      editingZone: zone,
    });
  };

  onZoneEditMap = (zone) => {
    if (zone.boundary && zone.boundary.length > 0) {
      this.setState({
        maps: {
          addGateway: null,
          editGateway: null,
          addZone: null,
          editZone: zone.index,
          addBeacon: null,
          editBeacon: null,
        },
      });
      return;
    }

    this.setState({
      maps: {
        addGateway: null,
        editGateway: null,
        addZone: zone.index,
        editZone: null,
        addBeacon: null,
        editBeacon: null,
      },
    });
  };

  onZoneEditMapCompleted = (zoneIndex, boundary) => {
    let map = this.state.map;
    map = editMapFunctions.setZoneBoundary(map, zoneIndex, boundary);
    this.setState({
      map,
      maps: {
        addGateway: null,
        editGateway: null,
        addZone: null,
        editZone: null,
        addBeacon: null,
        editBeacon: null,
      },
    });
  };

  onBeaconAdd = () => {
    const map = this.state.map;
    const beacon = editMapFunctions.create.beacon(
      this.state.map.beacons ? this.state.map.beacons.length : 0
    );
    map.beacons.unshift(beacon);

    this.setState({
      map: map,
      editingBeacon: beacon,
    });
  };

  onMeasurementAdd = () => {
    this.setState({
      editingMeasurement: !this.state.editingMeasurement,
    });
  };

  onMeasurementAdded = (distance) => {
    let x = this.state.staticMeasurements;
    x.pixels = distance;
    console.log(distance);
    this.setState({
      staticMeasurement: x,
      editingMeasurement: !this.state.editingMeasurement,
    });
  };

  onMeasurementDistanceChange = (distance) => {
    let x = this.state.staticMeasurements;
    x.lengthInMeters = distance;
    this.setState({
      staticMeasurement: x,
    });
  };

  onBeaconEdit = (beacon) => {
    this.setState({
      editingBeacon: beacon,
    });
  };

  onBeaconEditMap = (beacon) => {
    if (beacon.location) {
      this.setState({
        maps: {
          addGateway: null,
          editGateway: null,
          addZone: null,
          editZone: null,
          addBeacon: null,
          editBeacon: beacon.index,
        },
      });
      return;
    }

    this.setState({
      maps: {
        addGateway: null,
        editGateway: null,
        addZone: null,
        editZone: null,
        addBeacon: beacon.index,
        editBeacon: null,
      },
    });
  };

  onBeaconEditMapCompleted = (beaconIndex, location) => {
    let map = this.state.map;
    map = editMapFunctions.setBeaconLocation(map, beaconIndex, location);
    this.setState({
      map,
      maps: {
        addGateway: null,
        editGateway: null,
        addZone: null,
        editZone: null,
        addBeacon: null,
        editBeacon: null,
      },
    });
  };

  onClickDeleteMap = () => {
    //swal
    swal(<DeleteMapSwal mapName={this.state.map.name} mapId={this.state.map.id} />, {
      buttons: false,
      closeOnClickOutside: false,
    });
  };

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Things'}
          crumbs={[
            {
              route: '/',
              label: 'Dashboard',
            },
            {
              route: '/maps',
              label: 'Maps',
            },
            {
              route: `/maps/${this.state.map.id}`,
              label: this.state.map.name,
            },
            {
              route: `/maps/${this.state.map.id}/edit`,
              label: 'Edit',
            },
          ]}
        />

        {this.state.map.gateways ? (
          <div className="row">
            <div className="col-xl-4 col-lg-12">
              <div id="accordion">
                <div className="card m-0 p-0">
                  <div className="card-header p-0 m-0" id="headingOne">
                    <h5 className="mb-0">
                      <a
                        href="# "
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Gateways
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body p-0" style={{ height: '60vh', overflowY: 'auto' }}>
                      <GatewayList
                        gateways={this.state.map.gateways}
                        onSelect={this.changeVisibility}
                        onAdd={this.onGatewayAdd}
                        onEdit={this.onGatewayEdit}
                        exceptIds={this.state.map.gateways.map((g) => g.gatewayId)}
                        searchItems={this.state.search.gateways}
                        onSearchFilterChanged={this.getGateways}
                        editing={this.state.editingGateway}
                        onEditMap={this.onGatewayEditMap}
                        onRemove={this.onRemove}
                        onEditCompleted={this.onEditCompleted}
                        onAddCanceled={this.onAddCanceled}
                      />
                    </div>
                  </div>
                </div>
                <div className="card m-0 p-0">
                  <div className="card-header p-0 m-0" id="headingTwo">
                    <h5 className="mb-0">
                      <a
                        href="# "
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Zones
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body p-0" style={{ height: '60vh', overflowY: 'auto' }}>
                      <ZoneList
                        zones={this.state.map.zones}
                        onAdd={this.onZoneAdd}
                        onEdit={this.onZoneEdit}
                        editing={this.state.editingZone}
                        onEditMap={this.onZoneEditMap}
                        onSelect={this.changeVisibility}
                        onRemove={this.onRemove}
                        onEditCompleted={this.onEditCompleted}
                        onAddCanceled={this.onAddCanceled}
                      />
                    </div>
                  </div>
                </div>
                <div className="card m-0 p-0">
                  <div className="card-header p-0 m-0" id="headingThree">
                    <h5 className="mb-0">
                      <a
                        href="# "
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        Beacons
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body p-0" style={{ height: '60vh', overflowY: 'auto' }}>
                      <BeaconList
                        beacons={this.state.map.beacons}
                        onSelect={this.changeVisibility}
                        onAdd={this.onBeaconAdd}
                        onEdit={this.onBeaconEdit}
                        excludedSearchItems={this.state.map.beacons.map((b) => b.beaconId)}
                        searchItems={this.state.search.beacons}
                        onSearchFilterChanged={this.getBeacons}
                        editing={this.state.editingBeacon}
                        onEditMap={this.onBeaconEditMap}
                        onRemove={this.onRemove}
                        onEditCompleted={this.onEditCompleted}
                        onAddCanceled={this.onAddCanceled}
                      />
                    </div>
                  </div>
                </div>
                <div className="card m-0 p-0">
                  <div className="card-header p-0 m-0" id="headingFour">
                    <h5 className="mb-0">
                      <a
                        href="# "
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="true"
                        aria-controls="collapseFour"
                      >
                        Static Measurement
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordion"
                  >
                    <div className="card-body p-0" style={{ height: '60vh', overflowY: 'auto' }}>
                      <MeasurementRow
                        startMeasurement={this.onMeasurementAdd}
                        changedDistance={this.onMeasurementDistanceChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <button onClick={this.handleUpdate} className="btn btn-primary mt-2">
                  Save Changes
                </button>
              </div>
            </div>

            <div className="col-xl-8 col-lg-12">
              <div className="d-flex justify-content-end">
                <button
                  style={{
                    backgroundColor: 'transparent',
                    color: '#9700C7',
                    borderColor: '#9700C7',
                  }}
                  onClick={this.onClickDeleteMap}
                  className="btn btn-primary"
                >
                  Delete Map
                </button>
              </div>
              <div className="card">
                <div className="card-body">
                  <MapEdit
                    map={this.state.map}
                    updateMap={this.updateMap}
                    editGateway={this.state.maps.editGateway}
                    addGateway={this.state.maps.addGateway}
                    onGatewayChanged={this.onGatewayEditMapCompleted}
                    editZone={this.state.maps.editZone}
                    addZone={this.state.maps.addZone}
                    onZoneAdded={this.onZoneAddToMapCompleted}
                    onZoneChanged={this.onZoneEditMapCompleted}
                    editBeacon={this.state.maps.editBeacon}
                    addBeacon={this.state.maps.addBeacon}
                    onBeaconChanged={this.onBeaconEditMapCompleted}
                    addMeasure={this.state.editingMeasurement}
                    onMeasureAdded={this.onMeasurementAdded}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

MapEditContainer.propTypes = {
  match: PropTypes.object,
};

export default MapEditContainer;
