import React, { createRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Button from 'react-bootstrap/Button';

import Pager from '../pager';
import { SearchInput } from '../inputs';
import { noop } from '../../utils';
import { Text } from '../../components';

/**
 * @extends {React.Component<{columnDefs:array.isRequired, rowData:array.isRequired, onChangeSearch:Function, searchDebounceTimeSec:number, onChangeLimit:Function, totalItems:number, pageCount:number, onChangePage:Function, defaultLimit:number, gridProps:object, onGridReady:Function, onCellKeyPress:Function, limitDisabled:boolean, renderHeader:Function}>}
 */
class AgGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      limit: props.defaultLimit,
    };
    this.api = null;
    this.columnApi = null;
    this.pagerRef = createRef();
    this.agGridRef = createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.sizeColumnsToFit);
  }

  componentDidUpdate(prevProps) {
    // defaultLimit prop could possibly change due to value being passed after api call
    if (this.props.defaultLimit !== prevProps.defaultLimit) {
      this.setState({
        limit: this.props.defaultLimit,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.sizeColumnsToFit);
  }

  onChangeSearch = (search) => {
    this.setState({ search }, () => {
      this.props.onChangeSearch(search);
    });
  };

  onChangeFilter = (e) => {
    const newFilter = e.target.value;
    this.setState({ filter: newFilter }, () => {
      this.props.onChangeFilter(newFilter);
    });
  };

  onChangeLimit = (e) => {
    this.setState({ limit: e.target.value });
    this.debouncedOnChangeLimit();
  };

  debouncedOnChangeLimit = debounce(() => {
    this.props.onChangeLimit(this.state.limit);
  }, 300);

  /**
   * @desc Sizes grid columns to be as wide as column with longest value.
   * This won't truncate items within the grid and will usually have horizontal scroll.
   * @param {boolean} skipHeader Auto size headers?
   */
  autoSizeColumns = (skipHeader = false) => {
    var allColumnIds = [];
    this.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  /**
   * @desc Sizes grid columns to shrink so they all fit within grid window
   * with no horizontal scroll. This may truncates items within grid.
   */
  sizeColumnsToFit = () => {
    this.api.sizeColumnsToFit();
  };

  onCellKeyPress = (e) => {
    var keyPressed = e.event.key;
    switch (keyPressed) {
      case 's':
        this.sizeColumnsToFit();
        break;
      case 'a':
        this.autoSizeColumns();
        break;
      default:
        break;
    }

    this.props.onCellKeyPress(e);
  };

  onGridReady = (params) => {
    this.api = params.api;
    this.columnApi = params.columnApi;

    this.api.sizeColumnsToFit();

    this.props.onGridReady(params);
  };

  getDefaultColDef = () => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      // floatingFilter: true,
      singleClickEdit: true,
      filterParams: {
        resetButton: true,
      },
    };
  };

  renderHeader = () => {
    // use custom renderHeader
    if (this.props.renderHeader) return this.props.renderHeader();

    const limit =
      this.props.rowData.length >= this.state.limit ? this.state.limit : this.props.rowData.length;

    // use default renderHeader - preferred
    return (
      <div className="d-flex justify-content-between form-inline pl-4 pr-4 m-2">
        <SearchInput
          defaultText={this.props.defaultSearch}
          placeholder="Quick Search"
          onChange={this.onChangeSearch}
          debounceTimeSec={this.props.searchDebounceTimeSec}
        />

        <div>
          <span>Viewing </span>
          <input
            type="number"
            step="10"
            min="10"
            max={this.props.totalItems}
            style={{ width: '70px', textAlign: 'center' }}
            className="form-control form-control-sm"
            value={limit}
            onChange={this.onChangeLimit}
            disabled={this.props.limitDisabled}
          />
          <span> of {this.props.totalItems} items.</span>
        </div>

        <Pager
          page={this.props.page}
          pageCount={this.props.pageCount + ''}
          onChange={this.props.onChangePage}
          ref={this.pagerRef}
        />

        {this.props.onRefetch && (
          <Button size="sm" variant="outline-primary" onClick={this.props.onRefetch}>
            <Text>Refresh</Text>
            <i className="fa fa-sync" />
          </Button>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className="h-100">
        {this.renderHeader()}

        <div className="ag-theme-material h-100 w-100">
          <AgGridReact
            ref={this.agGridRef}
            rowData={this.props.rowData}
            columnDefs={this.props.columnDefs}
            quickFilterText={this.state.search}
            onGridReady={this.onGridReady}
            onCellKeyPress={this.onCellKeyPress}
            defaultColDef={this.getDefaultColDef()}
            scrollbarWidth={10}
            animateRows
            // pass other ag-grid props in a `gridProps` object unless it will widely be used by all grids to keep api clean
            {...this.props.gridProps}
          />
        </div>
      </div>
    );
  }
}

AgGrid.defaultProps = {
  onChangeSearch: noop,
  searchDebounceTimeSec: 0,
  onChangeLimit: noop,
  totalItems: 0,
  pageCount: 1,
  onChangePage: noop,
  defaultLimit: 20,
  gridProps: {},
  onGridReady: noop,
  onCellKeyPress: noop,
  limitDisabled: true,
  renderHeader: null,
  onRefetch: null,
  defaultSearch: '',
};

AgGrid.propTypes = {
  columnDefs: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,

  // defaults
  onChangeSearch: PropTypes.func,
  searchDebounceTimeSec: PropTypes.number,
  onChangeLimit: PropTypes.func,
  totalItems: PropTypes.number,
  pageCount: PropTypes.number,
  onChangePage: PropTypes.func,
  defaultLimit: PropTypes.number,
  gridProps: PropTypes.object,
  onGridReady: PropTypes.func,
  onCellKeyPress: PropTypes.func,
  limitDisabled: PropTypes.bool,
  renderHeader: PropTypes.func,
  onRefetch: PropTypes.func,
  defaultSearch: PropTypes.string,
};

export default AgGrid;
