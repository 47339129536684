import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

class ThingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false,
    };
  }

  render() {
    let thingName = this.props.thing ? (this.props.thing.name ? this.props.thing.name : '') : '';
    let deviceId = this.props.thing
      ? this.props.thing.deviceId
        ? this.props.thing.deviceId
        : ''
      : '';
    let lastLocation = this.props.thing
      ? this.props.thing.lastLocation
        ? this.props.thing.lastLocation
        : ''
      : '';
    let lastPing = this.props.thing
      ? this.props.thing.lastPing
        ? this.props.thing.lastPing
        : ''
      : '';
    let temperature = this.props.temperature
      ? this.props.temperature[this.props.temperature.length - 1]
        ? this.props.temperature[this.props.temperature.length - 1].reading
          ? Math.round(
              this.props.temperature[this.props.temperature.length - 1].reading * (9 / 5) + 32
            )
          : ''
        : ''
      : '';

    let temperatureTimestamp = this.props.temperature
      ? this.props.temperature[this.props.temperature.length - 1]
        ? this.props.temperature[this.props.temperature.length - 1].timestamp
          ? this.props.temperature[this.props.temperature.length - 1].timestamp
          : null
        : null
      : null;
    let humidity = this.props.humidity
      ? this.props.humidity[this.props.humidity.length - 1]
        ? this.props.humidity[this.props.humidity.length - 1].reading
          ? Math.round(this.props.humidity[this.props.humidity.length - 1].reading)
          : ''
        : ''
      : '';
    let humidityTimestamp = this.props.humidity
      ? this.props.humidity[this.props.humidity.length - 1]
        ? this.props.humidity[this.props.humidity.length - 1].timestamp
          ? this.props.humidity[this.props.humidity.length - 1].timestamp
          : null
        : null
      : null;

    let battery = this.props.battery ? this.props.battery : '';
    let batteryTimestamp = battery ? (lastPing ? lastPing : '') : '';
    let lastLocationTimestamp = this.props.thing
      ? this.props.thing.lastLocationCheckin
        ? this.props.thing.lastLocationCheckin
        : ''
      : '';

    return (
      <div>
        <div className="card">
          <div className="card-body d-flex align-items-center justify-content-around">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>Name</div>
              <div style={{ fontWeight: '500' }}>{thingName}</div>
              <div>{deviceId}</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>Current Location</div>
              <Link style={{ fontWeight: 500 }} to={`/things/${this.props.thing.id}/maps`}>
                {lastLocation ? lastLocation : ' '}
              </Link>
              <div>
                {lastLocationTimestamp
                  ? moment(lastLocationTimestamp).format('hh:mma M/D/YY')
                  : ' '}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>Temperature</div>
              <div style={{ fontWeight: '500' }}>{temperature ? `${temperature}F` : 'N/A'}</div>
              <div>
                {temperatureTimestamp ? moment(temperatureTimestamp).format('hh:mma M/D/YY') : ' '}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>Humidity</div>
              <div style={{ fontWeight: '500' }}>{humidity ? `${humidity}%` : 'N/A'}</div>
              <div>
                {humidityTimestamp ? moment(humidityTimestamp).format('hh:mma M/D/YY') : ' '}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>Battery</div>
              <div style={{ fontWeight: '500' }}>{battery ? `${battery}%` : 'N/A'}</div>
              <div>{batteryTimestamp ? moment(batteryTimestamp).format('hh:mma M/D/YY') : ' '}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ThingDetails.propTypes = {
  thing: PropTypes.object,
  triggers: PropTypes.array,
  editUrl: PropTypes.string,
  toggleTriggerEdit: PropTypes.func,
  toggleTriggerAdd: PropTypes.func,
  deleteThing: PropTypes.func,
};

export default ThingDetails;
