import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

// leaflet imports
import 'leaflet';

import 'bootstrap/dist/css/bootstrap.min.css';

import './index.scss';

import App from './App';
import { unregister } from './registerServiceWorker';
import { isDev } from './utils';

// only use Sentry in non dev envs
if (!isDev()) {
  Sentry.init({
    dsn: 'https://c168b1674a16482b948344ac7910b67e@o402895.ingest.sentry.io/5264761',
    environment: process.env.REACT_APP_ENV || 'n/a',
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
