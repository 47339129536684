import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Wizard, Steps, Step } from 'react-albus';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Line } from 'rc-progress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { changeLocation } from '../../redux/actions/navigationActions';

const guid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

const bool = [
  {
    value: '',
    label: '',
  },
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

const frequency = [
  {
    value: '',
    label: '',
  },
  {
    value: '-1',
    label: 'One Time',
  },
  {
    value: '900',
    label: '15 Minutes',
  },
  {
    value: '1800',
    label: '30 Minutes',
  },
  {
    value: '2700',
    label: '45 Minutes',
  },
  {
    value: '3600',
    label: 'Hour',
  },
  {
    value: '7200',
    label: '2 Hours',
  },
  {
    value: '21600',
    label: '6 Hours',
  },
  {
    value: '43200',
    label: '12 Hours',
  },
  {
    value: '86400',
    label: 'Day',
  },
];

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class TriggerWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [{ val: '', key: guid() }],
      phoneNumbers: [{ val: '', key: guid() }],
      temperatureHigh: '',
      temperatureLow: '',
      humidityHigh: '',
      humidityLow: '',
      movementEnters: [{ val: '', key: guid() }],
      movementExits: [{ val: '', key: guid() }],
      globalBattery: '',
      globalOffline: '',
      notificationFrequency: '',
      delay: '',
      triggerName: '',
      triggerType: '',
    };
  }

  componentDidMount() {
    let zoneTemp = [{ value: '', label: '' }];
    this.props.zones.map((val, i) => {
      this.props.zones[i].map((val) => {
        zoneTemp.push({
          value: `${val.name},${val.mapId}`,
          label: val.name,
        });
        return val;
      });
      return val;
    });
    this.setState({ zones: zoneTemp });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeForArr = (name, index) => (event) => {
    let val = _.map(this.state[name], _.clone);
    val[index].val = event.target.value;

    for (let i = val.length - 1; i > 0; i--) {
      if (val[i].val === '') {
        if (val.length === 1) {
          // arr of one
          continue;
        } else if (
          val[0].val.length === 0 &&
          val[val.length - 1].val.length === 0 &&
          val.length > 2
        ) {
          // first element is empty and the last element is empty
          val.splice(0, 1);
        } else if (i === val.length - 1 && val[i - 1].val.length > 0) {
          // last element and the one before it has text
          continue;
        } else if (i > 0 && i < val.length - 1 && val[i].val.length === 0) {
          // any element that is not 0 or last that is empty
          val.splice(i, 1);
        } else if (val.length === 2 && val[0].val.length === 0 && val[1].val.length === 0) {
          // the arr is only 2 elements and both are empty
          val.splice(1, 1);
        }
      }
    }
    if (val.length < 9 && val[val.length - 1].val.length > 0) {
      val.push({ val: '', key: guid() });
    }
    this.setState({
      [name]: val,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let newObj = {};

    if (nextProps.initialValues && !prevState.triggerType) {
      if (nextProps.initialValues.triggerName) {
        newObj.triggerName = nextProps.initialValues.triggerName;
      }

      if (nextProps.initialValues.notificationFrequency) {
        newObj.notificationFrequency = nextProps.initialValues.notificationFrequency;
      }

      if (nextProps.initialValues.delay) {
        newObj.delay = Math.floor(nextProps.initialValues.delay / 60.0);
      }

      if (nextProps.initialValues.globalOffline) {
        newObj.globalOffline = nextProps.initialValues.globalOffline;
        newObj.triggerType = 'global/offline';
      }

      if (nextProps.initialValues.batteryFallsBelow) {
        newObj.globalBattery = nextProps.initialValues.batteryFallsBelow;
        newObj.triggerType = 'global/battery';
      }

      if (nextProps.initialValues.movementEnters) {
        let moen = [];
        nextProps.initialValues.movementEnters.map((val) => {
          moen.push({ val: `${val.zone},${val.mapId}`, key: guid() });
          return val;
        });
        moen.push({ val: '', key: guid() });
        newObj.movementEnters = moen;
        newObj.triggerType = 'movement';
      }

      if (nextProps.initialValues.movementExits) {
        let moex = [];
        nextProps.initialValues.movementExits.map((val) => {
          moex.push({ val: `${val.zone},${val.mapId}`, key: guid() });
          return val;
        });
        moex.push({ val: '', key: guid() });
        newObj.movementExits = moex;
        newObj.triggerType = 'movement';
      }

      if (nextProps.initialValues.humidityFallsBelow) {
        newObj.humidityLow = nextProps.initialValues.humidityFallsBelow;
        newObj.triggerType = 'environmental/humidity';
      }

      if (nextProps.initialValues.humidityRisesAbove) {
        newObj.humidityHigh = nextProps.initialValues.humidityRisesAbove;
        newObj.triggerType = 'environmental/humidity';
      }

      if (nextProps.initialValues.temperatureFallsBelow) {
        newObj.temperatureLow = nextProps.initialValues.temperatureFallsBelow;
        newObj.triggerType = 'environmental/temperature';
      }

      if (nextProps.initialValues.temperatureRisesAbove) {
        newObj.temperatureHigh = nextProps.initialValues.temperatureRisesAbove;
        newObj.triggerType = 'environmental/temperature';
      }

      if (nextProps.initialValues.phoneNumbers) {
        newObj.phoneNumbers = nextProps.initialValues.phoneNumbers.map((val) => {
          return { val, key: guid() };
        });
      }

      if (nextProps.initialValues.emails) {
        newObj.emails = nextProps.initialValues.emails.map((val) => {
          return { val, key: guid() };
        });
      }
      if (
        newObj.phoneNumbers[newObj.phoneNumbers.length - 1] &&
        newObj.phoneNumbers[newObj.phoneNumbers.length - 1].val &&
        newObj.phoneNumbers[newObj.phoneNumbers.length - 1].val.length > 0
      ) {
        newObj.phoneNumbers.push({ val: '', key: guid() });
      }
      if (
        newObj.emails[newObj.emails.length - 1] &&
        newObj.emails[newObj.emails.length - 1].val &&
        newObj.emails[newObj.emails.length - 1].val.length > 0
      ) {
        newObj.emails.push({ val: '', key: guid() });
      }

      if (newObj.phoneNumbers.length === 0) {
        newObj.phoneNumbers.push({ val: '', key: guid() });
      }

      if (newObj.emails.length === 0) {
        newObj.emails.push({ val: '', key: guid() });
      }
      if (nextProps.initialValues.triggerType) {
        newObj.triggerType = nextProps.initialValues.triggerType;
      }

      return newObj;
    }

    return null;
  }

  buildObj() {
    let obj = {}; // obj being submitted
    let em = []; // emails
    let ph = []; // phone numbers
    let moen = []; // movement enters
    let moex = []; // movement exits
    if (this.state.emails.length > 0) {
      this.state.emails.map((val, i) => {
        if (val.val !== '') {
          em.push(val.val);
        }
        if (i === this.state.emails.length - 1) {
          obj.emails = em;
        }
        return null;
      });
    }
    if (this.state.phoneNumbers.length > 0) {
      this.state.phoneNumbers.map((val, i) => {
        if (val.val !== '') {
          ph.push(val.val);
        }
        if (i === this.state.phoneNumbers.length - 1) {
          obj.phoneNumbers = ph;
        }
        return null;
      });
    }
    if (this.state.movementEnters.length > 0) {
      this.state.movementEnters.map((val, i) => {
        if (val.val !== '') {
          let ids = val.val.split(',');
          moen.push({ zone: ids[0], mapId: ids[1] });
        }
        if (i === this.state.movementEnters.length - 1) {
          obj.movementEnters = moen;
        }
        return null;
      });
    }
    if (this.state.movementExits.length > 0) {
      this.state.movementExits.map((val, i) => {
        if (val.val !== '') {
          let ids = val.val.split(',');
          moex.push({ zone: ids[0], mapId: ids[1] });
        }

        if (i === this.state.movementExits.length - 1) {
          obj.movementExits = moex;
        }
        return null;
      });
    }
    if (this.state.temperatureHigh) {
      obj.temperatureRisesAbove = this.state.temperatureHigh;
    }
    if (this.state.temperatureLow) {
      obj.temperatureFallsBelow = this.state.temperatureLow;
    }
    if (this.state.humidityHigh) {
      obj.humidityRisesAbove = this.state.humidityHigh;
    }
    if (this.state.humidityLow) {
      obj.humidityFallsBelow = this.state.humidityLow;
    }
    if (this.state.globalBattery) {
      obj.batteryFallsBelow = this.state.globalBattery;
    }
    if (this.state.globalOffline) {
      obj.globalOffline = this.state.globalOffline;
    }
    if (this.state.notificationFrequency) {
      obj.notificationFrequency = this.state.notificationFrequency;
    }
    if (this.state.triggerName) {
      obj.triggerName = this.state.triggerName;
    }
    if (this.state.triggerType) {
      obj.triggerType = this.state.triggerType;
    }
    if (this.state.delay) {
      obj.delay = Math.floor(this.state.delay) * 60;
    }

    obj.id = this.props.initialValues.id;
    return obj;
  }

  renderEnviroTempStepTwo() {
    const { classes } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'stretch',
        }}
      >
        <TextField
          id="temp-below"
          label="Temperature falls below"
          className={classes.textField}
          value={this.state.temperatureLow}
          onChange={this.handleChange('temperatureLow')}
          margin="normal"
          helperText="Receive alerts if the temperature is lower than what you specify"
        />
        <TextField
          id="temp-above"
          label="Temperature rises above"
          className={classes.textField}
          value={this.state.temperatureHigh}
          onChange={this.handleChange('temperatureHigh')}
          margin="normal"
          helperText="Receive alerts if the temperature is higher than what you specify"
        />
      </div>
    );
  }

  renderEnviroHumidityStepTwo() {
    const { classes } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'stretch',
        }}
      >
        <TextField
          id="hum-below"
          label="Humidity falls below"
          className={classes.textField}
          value={this.state.humidityLow}
          onChange={this.handleChange('humidityLow')}
          margin="normal"
          helperText="Receive alerts if the humidity is lower than what you specify"
        />
        <TextField
          id="hum-above"
          label="Humidity rises above"
          className={classes.textField}
          value={this.state.humidityHigh}
          onChange={this.handleChange('humidityHigh')}
          margin="normal"
          helperText="Receive alerts if the humidity is higher than what you specify"
        />
      </div>
    );
  }

  renderMovementStepTwo() {
    const { classes } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'stretch',
          alignContent: 'stretch',
        }}
      >
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'stretch',
          }}
        >
          {this.state.movementEnters.map((val, i) => (
            <TextField
              key={val.key}
              id="movement-enters"
              select
              label={`Enters`}
              className={classes.textField}
              value={this.state.movementEnters[i].val}
              onChange={this.handleChangeForArr('movementEnters', i)}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu,
                },
              }}
              helperText="Receive alerts if this enters the specified place"
              margin="normal"
            >
              {this.state.zones.map((option) => (
                <option key={guid()} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          ))}
        </div>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'stretch',
          }}
        >
          {this.state.movementExits.map((val, i) => (
            <TextField
              key={val.key}
              id="movement-exits"
              select
              label={`Exits`}
              className={classes.textField}
              value={this.state.movementExits[i].val}
              onChange={this.handleChangeForArr('movementExits', i)}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu,
                },
              }}
              helperText="Receive alerts if this exits the specified place"
              margin="normal"
            >
              {this.state.zones.map((option) => (
                <option key={guid()} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          ))}
        </div>
      </div>
    );
  }

  renderGlobalBatteryStepTwo() {
    const { classes } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'stretch',
        }}
      >
        <TextField
          id="battery-below"
          label="Battery falls below"
          className={classes.textField}
          value={this.state.globalBattery}
          onChange={this.handleChange('globalBattery')}
          margin="normal"
          helperText="Receive alerts if the battery is lower than what you specify"
        />
      </div>
    );
  }

  renderGlobalOfflineStepTwo() {
    const { classes } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'stretch',
        }}
      >
        <TextField
          id="offline"
          select
          label={`Goes offline`}
          className={classes.textField}
          value={this.state.globalOffline}
          onChange={this.handleChange('globalOffline')}
          SelectProps={{
            native: true,
            MenuProps: {
              className: classes.menu,
            },
          }}
          helperText="Receive alerts if this beacon goes offline"
          margin="normal"
        >
          {bool.map((option) => (
            <option key={guid()} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </div>
    );
  }

  renderStepThree() {
    const { classes } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'stretch',
          alignContent: 'stretch',
        }}
      >
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'stretch',
          }}
        >
          {/* EMAILS - NEED TO BE ABLE TO SUBMIT AS MANY EMAILS AS I WANT - NEEDS TO HAVE SIMPLE EMAIL VALIDATION */}

          {this.state.emails.map((val, i) => (
            <TextField
              key={val.key}
              id={`email-field`}
              label="Email"
              className={classes.textField}
              value={this.state.emails[i].val}
              onChange={this.handleChangeForArr('emails', i)}
              margin="normal"
              helperText="Enter an email you want to receive this trigger alert"
            />
          ))}
        </div>

        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'stretch',
          }}
        >
          {this.state.phoneNumbers.map((val, i) => (
            <TextField
              key={val.key}
              id={`email-field`}
              label="Phone Number"
              className={classes.textField}
              value={this.state.phoneNumbers[i].val}
              onChange={this.handleChangeForArr('phoneNumbers', i)}
              margin="normal"
              helperText="Enter an email you want to receive this trigger alert"
            />
          ))}
        </div>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'stretch',
          }}
        >
          <TextField
            id="notif-freq"
            select
            label={`Notify me every`}
            className={classes.textField}
            value={this.state.notificationFrequency}
            onChange={this.handleChange('notificationFrequency')}
            SelectProps={{
              native: true,
              MenuProps: {
                className: classes.menu,
              },
            }}
            helperText="How often would you like to receive alerts about this trigger"
            margin="normal"
          >
            {frequency.map((option) => (
              <option key={guid()} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'stretch',
          }}
        >
          <TextField
            id="temp-delay"
            label="Delay Minutes"
            className={classes.textField}
            value={this.state.delay}
            onChange={this.handleChange('delay')}
            margin="normal"
            helperText="How many minutes must the trigger be met before you are notified"
          />
        </div>
      </div>
    );
  }

  renderStepFour() {
    const { classes } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'stretch',
        }}
      >
        <TextField
          id="trigger-name"
          label="Trigger Name"
          className={classes.textField}
          value={this.state.triggerName}
          onChange={this.handleChange('triggerName')}
          margin="normal"
          helperText="What would you like to name your trigger"
        />
      </div>
    );
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header d-flex align-items-center">
            <h3 className="h4">
              {this.state.triggerName
                ? `Editing "${this.props.initialValues.triggerName}"`
                : 'Edit Trigger'}
            </h3>
          </div>
          <div className="card-body">
            <Wizard>
              <Steps>
                <Step
                  id="trigger"
                  render={({ next }) => (
                    <div>
                      <Line strokeColor="#008598" percent={33} className="prog-bar" />
                      <div style={{ margin: '10px auto' }}>
                        <Typography variant="h5" align="center">
                          Notify me when {this.props.thing.name}:
                        </Typography>
                        {this.state.triggerType === 'environmental/temperature'
                          ? this.renderEnviroTempStepTwo()
                          : this.state.triggerType === 'movement'
                          ? this.renderMovementStepTwo()
                          : this.state.triggerType === 'global/battery'
                          ? this.renderGlobalBatteryStepTwo()
                          : this.state.triggerType === 'environmental/humidity'
                          ? this.renderEnviroHumidityStepTwo()
                          : this.state.triggerType === 'global/offline'
                          ? this.renderGlobalOfflineStepTwo()
                          : false}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignContent: 'stretch',
                        }}
                      >
                        <Button color="secondary" size="medium" onClick={this.props.onDelete}>
                          Delete Trigger
                        </Button>
                        <Button color="primary" size="medium" onClick={next}>
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
                />
                <Step
                  id="who"
                  render={({ next, previous }) => (
                    <div>
                      <Line strokeColor="#008598" percent={66} className="prog-bar" />
                      <div style={{ margin: '10px auto' }}>
                        <Typography variant="h5" align="center">
                          Notify me by...
                        </Typography>
                        {this.renderStepThree()}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignContent: 'stretch',
                        }}
                      >
                        <Button color="secondary" size="medium" onClick={previous}>
                          Previous
                        </Button>
                        <Button color="primary" size="medium" onClick={next}>
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
                />
                <Step
                  id="save"
                  render={({ previous }) => (
                    <div>
                      <Line strokeColor="#008598" percent={100} className="prog-bar" />
                      <div style={{ margin: '10px auto' }}>
                        <Typography variant="h5" align="center">
                          Save my trigger as...
                        </Typography>
                        {this.renderStepFour()}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignContent: 'stretch',
                        }}
                      >
                        <Button color="secondary" size="medium" onClick={previous}>
                          Previous
                        </Button>
                        {this.state.triggerName !== '' ? (
                          <Button
                            color="primary"
                            size="medium"
                            onClick={() => {
                              this.props.onSubmit(this.buildObj());
                            }}
                          >
                            Save Trigger
                          </Button>
                        ) : (
                          <Button
                            disabled
                            color="primary"
                            size="medium"
                            onClick={() => {
                              this.props.onSubmit(this.buildObj());
                            }}
                          >
                            Save Trigger
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                />
              </Steps>
            </Wizard>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeLocation,
    },
    dispatch
  );

TriggerWizard.propTypes = {
  classes: PropTypes.object.isRequired,
  changeLocation: PropTypes.func,
  thing: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(TriggerWizard));
