import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { Redirect, Link } from 'react-router-dom';
import cognitoActions from '../../redux/actions/cognitoActions';

import './auth.css';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 1,
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      validationMessage: '',
      requirePasswordChange: false,
    };
    this.submit = this.submit.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.passwordInput = React.createRef();
  }

  submit() {
    cognitoActions.login(this.state.email, this.state.password, this);
  }

  onLogin = (err, res) => {
    if (err) {
      this.setState({ validationMessage: err.message });
      return;
    }

    this.setState({
      authenticated: true,
      requiresPasswordChange: res.data.requiresPasswordChange,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      validationMessage: '',
    });
  };

  handleKeyPress = (fieldName) => (event) => {
    if (event.keyCode === 13) {
      // enter
      if (fieldName === 'email') {
        this.passwordInput.current.focus();
      }
      if (fieldName === 'password') {
        this.submit();
      }
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.authenticated) {
      if (this.state.requiresPasswordChange) {
        return <Redirect to="/login#updatePassword" />;
      }

      return <Redirect to="/" />;
    }

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <TextField
                autoComplete="email"
                type="email"
                id="email"
                label="Email Address"
                className={classes.textField}
                margin="normal"
                helperText="Enter your email address"
                onChange={this.handleChange('email')}
                onKeyDown={this.handleKeyPress('email')}
              />
              <TextField
                autoComplete="password"
                type="password"
                id="password"
                label="Password"
                className={classes.textField}
                margin="normal"
                helperText="Enter your password"
                inputRef={this.passwordInput}
                onChange={this.handleChange('password')}
                onKeyDown={this.handleKeyPress('password')}
              />
              <Typography align="center" className={classes.submit}>
                By logging in or signing up you accept the terms of our{' '}
                <a href="https://www.thinaer.io/eula/" target={'_blank'} rel="noreferrer">
                  End User License Agreement
                </a>
              </Typography>
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.submit}
            >
              Sign in
            </Button>
            <Typography align="center" className={classes.submit}>
              Don&apos;t have an account? <Link to="login#register">Register for an account</Link>
            </Typography>
            <Typography align="center">
              Forgot your username or password? <Link to="login#forgot">Recover your account</Link>
            </Typography>
            {this.state.validationMessage ? (
              <div className="alert alert-danger">{this.state.validationMessage}</div>
            ) : (
              false
            )}
          </form>
        </Paper>
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
