import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import OnlineStatusCell from '../../../components/grids/onlineStatus';
import Pager from '../../../components/pager';

const scrollStyle = {
  maxHeight: '20vh',
  overflow: 'auto',
};

const headerStyle = {
  cursor: 'pointer',
};
const rowStyle = {
  cursor: 'pointer',
};
class LocationTable extends Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateParent = this.updateParent.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.state = {
      limit: props.limit,
      page: props.page,
      sortOrder: props.sortOrder,
      sortField: props.sortField,
      filter: props.filter,
      onlineStatus: 1,
    };
  }

  handleSort(columnName) {
    let { sortField, sortOrder } = this.state;

    if (this.isSortedBy(columnName)) {
      sortOrder = sortOrder === 1 ? -1 : 1;
    } else {
      sortField = columnName;
      sortOrder = 1;
    }

    this.setState({ sortField: sortField, sortOrder: sortOrder }, () => this.updateParent());
  }

  handleChange(name, event) {
    if (this.state[name] !== event.target.value) {
      this.setState(
        {
          [name]: event.target.value,
        },
        this.updateParent
      );
    }
  }

  updateParent() {
    const { sortField, sortOrder, page, limit, filter } = this.state;
    if (page.length === 0 || limit.length === 0) {
      return;
    }
    this.props.onQueryChanged(page, limit, sortField, sortOrder, filter);
  }

  sortIcon(columnName) {
    let { sortDirection } = this.state;
    if (this.isSortedBy(columnName)) {
      return sortDirection === 1 ? (
        <i className="fa fa-caret-up" />
      ) : (
        <i className="fa fa-caret-down" />
      );
    }
    return;
  }

  handlePageChange(pageNumber) {
    let { page } = this.state;

    if (page !== pageNumber) {
      this.setState({ page: pageNumber }, () => this.updateParent());
    }
  }

  isSortedBy(columnName) {
    let { sortField } = this.state;
    return sortField === columnName;
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between form-inline">
          <div>
            <i className="fa fa-search mr-2" />
            <input
              className="form-control"
              value={this.state.filter}
              onChange={(e) => this.handleChange('filter', e)}
            />
          </div>
          <div>
            <span>Viewing </span>
            <input
              style={{ width: '40px', textAlign: 'center' }}
              className="form-control form-control-sm"
              value={this.state.limit}
              onChange={(e) => this.handleChange('limit', e)}
            />
            <span> of {this.props.totalItems} tags.</span>
          </div>
          <Pager
            onChange={this.handlePageChange}
            pageCount={this.props.pageCount}
            page={this.state.page}
          />
        </div>
        <div style={scrollStyle}>
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th scope="col" />
                <th
                  scope="col"
                  onClick={() => this.handleSort('name')}
                  style={headerStyle}
                  className={this.isSortedBy('name') ? 'text-primary' : ''}
                >
                  Name {this.sortIcon('name')}
                </th>
                <th
                  scope="col"
                  onClick={() => this.handleSort('zone')}
                  style={headerStyle}
                  className={this.isSortedBy('zone') ? 'text-primary' : ''}
                >
                  Zone {this.sortIcon('zone')}
                </th>
                <th
                  scope="col"
                  onClick={() => this.handleSort('timestamp')}
                  style={headerStyle}
                  className={this.isSortedBy('timestamp') ? 'text-primary' : ''}
                >
                  Timestamp {this.sortIcon('timestamp')}
                </th>
                <th
                  scope="col"
                  onClick={() => this.handleSort('distanceMoved')}
                  style={headerStyle}
                  className={this.isSortedBy('distanceMoved') ? 'text-primary' : ''}
                >
                  Distance Moved {this.sortIcon('distanceMoved')}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.locations.length > 0
                ? this.props.locations.map((location, index) => {
                    return (
                      <tr
                        key={location.index}
                        onClick={() => this.props.onRowSelect(location.index)}
                        className={location.isHighlighted ? 'table-primary' : 'table-default'}
                        style={rowStyle}
                      >
                        <td>
                          <OnlineStatusCell onlineStatus={location.onlineStatus} />
                        </td>
                        <td>{location.name}</td>
                        {/* <td>
                                              ({Math.round(location.x)}, {Math.round(location.y)})
                                          </td>*/}
                        <td>
                          {location
                            ? location.zones
                              ? location.zones[0]
                                ? location.zones[0].name
                                : ''
                              : ''
                            : ''}
                        </td>
                        <td>{moment(location.timestamp).format('L LTS')}</td>
                        <td>{Math.round(location.distanceMoved)} ft</td>
                      </tr>
                    );
                  })
                : false}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

LocationTable.propTypes = {
  locations: PropTypes.array,
  totalItems: PropTypes.string,
  pageCount: PropTypes.string,
  allowFilter: PropTypes.bool,
  sortField: PropTypes.string,
  sortOrder: PropTypes.number,
  limit: PropTypes.string,
  page: PropTypes.string,
  filter: PropTypes.string,
  onRowSelect: PropTypes.func,
  onQueryChanged: PropTypes.func,
};

export default LocationTable;
