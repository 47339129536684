import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { readOnlyCheck } from '../utils';

const iconStyle = {
  fontSize: '1.6em',
};

function CreateAsset() {
  if (readOnlyCheck()) return null;

  return (
    <Fragment>
      <span
        className="dropdown-toggle no-arrow d-inline-block pointer"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="fa fa-plus-circle" style={iconStyle} />
      </span>

      <div className="dropdown-menu dropdown-menu-right pb-0">
        <Link className="dropdown-item" to="/things/new">
          <i className="fa fa-tags mr-3" />
          <span>Beacon</span>
        </Link>
        <Link className="dropdown-item" to="/places/new">
          <i className="fa fa-router mr-3" />
          <span>Gateway</span>
        </Link>
        <Link className="dropdown-item" to="/maps/new">
          <i className="fa fa-map mr-3" />
          <span>Map</span>
        </Link>
      </div>
    </Fragment>
  );
}

export default CreateAsset;
