import { push } from 'react-router-redux';
import * as types from '../types';
import personService from '../../services/personService';
import toast from '../../components/utils/toast';

export const startPersonDelete = () => {
  return {
    type: types.PERSON_DELETE_STARTED,
  };
};

export const startPersonDeviceDelete = () => {
  return {
    type: types.PERSON_DEVICE_DELETE_STARTED,
  };
};

export const startPersonDevicesGet = () => {
  return {
    type: types.PERSON_DEVICES_REQUEST,
  };
};
export const startPersonDeviceCreate = () => {
  return {
    type: types.PERSON_DEVICE_POST_STARTED,
  };
};

export const startPersonDeviceRequest = () => {
  return {
    type: types.PERSON_DEVICE_REQUEST,
  };
};

export const startPersonDeviceUpdate = () => {
  return {
    type: types.PERSON_DEVICE_PATCH_STARTED,
  };
};

// Start of request for a person
export const requestPerson = () => {
  return {
    type: types.PERSON_REQUEST,
  };
};

// Start of request for patching aperson
export const startUpdatePerson = () => {
  return {
    type: types.PERSON_PATCH_STARTED,
  };
};

// Start of request for all people
export const requestPeople = () => {
  return {
    type: types.PEOPLE_REQUEST,
  };
};

// Start of request to create Person
export const startCreatePerson = () => {
  return {
    type: types.PERSON_POST_STARTED,
  };
};

export function deletePersonDevice(id, deviceId) {
  return function (dispatch) {
    dispatch(startPersonDeviceDelete());

    personService
      .deletePersonDevice(id, deviceId)
      .then((_res) => {
        toast.success();
        dispatch({
          type: types.PERSON_DEVICE_DELETE_COMPLETED,
        });
        dispatch(getPerson(id));
        dispatch(getPersonDevices(id));
      })
      .catch((res) =>
        dispatch({
          type: types.PLACES_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export function deletePerson(id) {
  return function (dispatch) {
    dispatch(startPersonDelete());

    personService
      .remove(id)
      .then((_res) => {
        toast.success();
        dispatch({
          type: types.PERSON_DELETE_COMPLETED,
        });
        dispatch(getAllPeople());
      })
      .catch((res) =>
        dispatch({
          type: types.PLACES_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

// Post a Person's Device
export function createPersonDevice(id, device) {
  return function (dispatch) {
    dispatch(startPersonDeviceCreate());

    personService
      .createPersonDevice(id, device)
      .then((res) => {
        toast.success();
        dispatch({
          type: types.PERSON_DEVICE_POST_COMPLETED,
          person: res.data,
        });
        dispatch(push(`/people/${id}`));
      })
      .catch((res) =>
        dispatch({
          type: types.PEOPLE_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

// Post a Person's Device
export function updatePersonDevice(id, deviceId, device) {
  return function (dispatch) {
    dispatch(startPersonDeviceUpdate());

    personService
      .updatePersonDevice(id, deviceId, device)
      .then((_res) => {
        toast.success();
        dispatch({
          type: types.PERSON_DEVICE_PATCH_COMPLETED,
        });
        dispatch(push(`/people/${id}/devices/${deviceId}`));
      })
      .catch((res) =>
        dispatch({
          type: types.PEOPLE_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

// Post a Person
export function createPerson(person) {
  return function (dispatch) {
    dispatch(startCreatePerson());

    personService
      .create(person)
      .then((res) => {
        toast.success();
        dispatch({
          type: types.PERSON_POST_COMPLETED,
          thing: res.data,
        });
        dispatch(push(`/people`));
      })
      .catch((res) =>
        dispatch({
          type: types.PEOPLE_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export function updatePerson(id, person) {
  return function (dispatch) {
    dispatch(startUpdatePerson());

    personService
      .update(id, person)
      .then((res) => {
        dispatch({
          type: types.PERSON_PATCH_COMPLETED,
          person: res.data,
        });
        dispatch(push(`/people/${id}`));
      })
      .catch((res) =>
        dispatch({
          type: types.PEOPLE_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export const getPersonDevice = (id, deviceId) => {
  return function (dispatch) {
    dispatch(startPersonDeviceRequest());

    personService
      .getPersonDevice(id, deviceId)
      .then((res) => {
        dispatch({
          type: types.PERSON_DEVICE_RECEIVED,
          device: res.data,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.PEOPLE_REQUEST_FAILURE,
          data: res,
        })
      );
  };
};

export const getPerson = (id) => {
  return function (dispatch) {
    dispatch(requestPerson());

    personService
      .get(id)
      .then((res) => {
        dispatch({
          type: types.PERSON_RECEIVED,
          person: res.data,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.PEOPLE_REQUEST_FAILURE,
          data: res,
        })
      );
  };
};

export function getAllPeople(page, limit, sort, order) {
  return function (dispatch) {
    dispatch(requestPeople());

    personService
      .getAll(page, limit, sort, order)
      .then((res) => {
        return dispatch({
          type: types.PEOPLE_RECEIVED,
          people: res.data.results,
          pages: res.data.pageCount,
          total: res.data.total,
          currentPage: page,
          currentOrder: order,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.PEOPLE_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}

export function getPersonDevices(id) {
  return function (dispatch) {
    dispatch(startPersonDevicesGet());

    personService
      .getPersonDevices(id)
      .then((res) => {
        return dispatch({
          type: types.PERSON_DEVICES_RECEIVED,
          devices: res.data,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.PEOPLE_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}
