import React from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

const styles = {
  link: {
    textDecoration: 'underline',
  },
};

function Export({ data, headers, filename }) {
  return (
    <CSVLink data={data} headers={headers} filename={filename}>
      <Button variant="link" style={styles.link}>
        Export
      </Button>
    </CSVLink>
  );
}

Export.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  headers: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  filename: PropTypes.string.isRequired,
};

export default Export;
