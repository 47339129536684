import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import cognitoActions from '../../redux/actions/cognitoActions';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Assignment from '@material-ui/icons/Assignment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';

import './auth.css';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 1,
  },
});

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      created: false,
      email: '',
      password: '',
      verifyPassword: '',
      company: '',
      phone: '',
      hasNumber: false,
      hasLowercase: false,
      hasUppercase: false,
      hasSpecial: false,
      isEightChars: false,
      solidPass: false,
      open: false,
      validationMessage: '',
    };
    this.submit = this.submit.bind(this);
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.verifyPassword === this.state.password &&
      this.passwordCheck(this.state.password)
    );
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      validationMessage: '',
    });
  };

  submit() {
    if (this.validateForm()) {
      cognitoActions.register(
        this.state.email,
        this.state.password,
        this.state.company,
        this.state.phone,
        this
      );
    } else {
      if (!this.passwordCheck(this.state.password)) {
        this.setState({ validationMessage: 'Password does not meet requirements' });
      } else if (this.state.verifyPassword !== this.state.password) {
        this.setState({ validationMessage: 'Passwords do not match' });
      } else if (!this.state.email) {
        this.setState({ validationMessage: 'Email format should be name@domain.com' });
      }
      return;
    }
  }

  onRegister = (err, result) => {
    if (err) {
      let validationMessage = err.response.data.message;
      if (err.response.data.message === 'Username should be an email.') {
        validationMessage = 'Email format should be name@domain.com';
      }
      this.setState({ validationMessage });
      return;
    }
    if (result.data.id) {
      this.setState({ created: true });
    } else if (result.data.message) {
      this.setState({ validationMessage: result.data.message });
      return;
    }
  };

  passwordCheck(data) {
    let score = 0;
    if (data.match(/[0-9]/)) {
      score++;
      this.setState({ hasNumber: true });
    } else {
      this.setState({ hasNumber: false });
    }
    if (data.match(/[a-z]/)) {
      score++;
      this.setState({ hasLowercase: true });
    } else {
      this.setState({ hasLowercase: false });
    }
    if (data.match(/[A-Z]/)) {
      score++;
      this.setState({ hasUppercase: true });
    } else {
      this.setState({ hasUppercase: false });
    }
    if (data.match(/[!@#$%^&*()_+={}`~-]/)) {
      score++;
      this.setState({ hasSpecial: true });
    } else {
      this.setState({ hasSpecial: false });
    }
    if (data.length >= 8) {
      score++;
      this.setState({ isEightChars: true });
    } else {
      this.setState({ isEightChars: false });
    }
    if (score === 5) {
      this.setState({ solidPass: true });
      return true;
    } else {
      this.setState({ solidPass: false });
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    if (this.state.created) {
      return <Redirect to="/login#registrationMessage" />;
    }
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Assignment />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register Account
          </Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <TextField
                autoComplete="email"
                type="email"
                id="email"
                label="Email Address"
                className={classes.textField}
                margin="normal"
                helperText="Enter your email address"
                onChange={this.handleChange('email')}
              />
              <TextField
                autoComplete="password"
                type="password"
                id="password"
                label="Password"
                className={classes.textField}
                margin="normal"
                helperText="Enter a password 8 or more characters. Requirements: 1 Lowercase, 1 Uppercase, 1 Number & 1 Special character"
                onChange={this.handleChange('password')}
              />
              <TextField
                autoComplete="password"
                type="password"
                id="verify-password"
                label="Verify Password"
                className={classes.textField}
                margin="normal"
                helperText="Enter your password again"
                onChange={this.handleChange('verifyPassword')}
              />
              <TextField
                autoComplete="company"
                type="company"
                id="company"
                label="Company"
                className={classes.textField}
                margin="normal"
                helperText="Enter your company name"
                onChange={this.handleChange('company')}
              />
              <TextField
                autoComplete="phone"
                type="phone"
                id="phone"
                label="Phone Number"
                className={classes.textField}
                margin="normal"
                helperText="Enter your phone number"
                onChange={this.handleChange('phone')}
              />
              <Typography align="center" className={classes.submit}>
                By logging in or signing up you accept the terms of our{' '}
                <a href="https://www.thinaer.io/eula/" target={'_blank'} rel="noreferrer">
                  End User License Agreement
                </a>
              </Typography>
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.submit}
            >
              Register
            </Button>
            <Typography align="center" className={classes.submit}>
              Trying to login? <Link to="login">Login Here</Link>
            </Typography>
            {this.state.validationMessage ? (
              <div className="alert alert-danger">{this.state.validationMessage}</div>
            ) : (
              false
            )}
          </form>
        </Paper>
      </main>
    );
  }
}

Register.propTypes = { classes: PropTypes.object.isRequired };

export default withStyles(styles)(Register);
