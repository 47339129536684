import React, { Component } from 'react';

import { emitter, getNewInvitee, EVENTS, inviteUsers, ROLES } from './utils';
import CloseModal from './CloseModal';
import AppSelector from './AppSelector';

/**
 * @desc Displays form to invite users by email and assign
 * their role
 */
export default class InviteUsersModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invitees: [getNewInvitee()],
      selectedApp: null,
    };
  }

  componentDidMount() {
    emitter.on(EVENTS.INVITE_USERS_CLOSED, () => {
      this.setState({ invitees: [getNewInvitee()] });
    });
  }

  componentWillUnmount() {
    emitter.removeListener(EVENTS.INVITE_USERS_CLOSED);
  }

  onChangeInviteeEmail = (e, index) => {
    const { invitees } = this.state;
    invitees[index].email = e.target.value;
    this.setState({ invitees });
  };

  /**
   * @desc Begin a new invitee row if first time focusing
   */
  onFocusEmail = (index) => {
    const { invitees } = this.state;

    if (invitees[index].hasFocused) return;

    invitees[index].hasFocused = true;

    this.setState({ invitees }, () => this.addInvitee());
  };

  onChangeInviteeRole = (e, index) => {
    console.log(e);
    const { invitees } = this.state;
    invitees[index].role = e.target.value;
    this.setState({ invitees });
  };

  addInvitee = () => {
    this.setState({ invitees: this.state.invitees.concat(getNewInvitee()) });
  };

  sendInvitations = () => {
    const invitees = this.state.invitees
      .filter((invitee) => !!invitee.email)
      .map((invitee) => ({
        username: invitee.email,
        role: invitee.role,
        applicationid: this.state.selectedApp.id,
      }));
    inviteUsers({ invitees, app: this.state.selectedApp });
  };

  render() {
    return (
      <div>
        <CloseModal />

        <div className="form-inline justify-content-center mb-4">
          Invite people to&nbsp;
          <AppSelector onChange={(app) => this.setState({ selectedApp: app })} />
        </div>

        <hr />

        {this.state.invitees.map((user, index) => {
          return (
            <div className="form-inline mb-4 justify-content-around" key={index}>
              <input
                type="email"
                placeholder="Email"
                className="form-control col-sm-6 mr-1"
                value={user.email}
                onChange={(e) => this.onChangeInviteeEmail(e, index)}
                onFocus={() => this.onFocusEmail(index)}
              />
              <select
                className="form-control col-sm-5"
                value={user.role}
                onChange={(e) => this.onChangeInviteeRole(e, index)}
              >
                {ROLES.map((role) => {
                  return (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        })}
        <button
          type="button"
          className="btn btn-primary"
          title="Add Msg"
          onClick={this.sendInvitations}
        >
          Send Invitations
        </button>
      </div>
    );
  }
}
