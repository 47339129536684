import BaseService from './BaseService';

class MapService extends BaseService {
  /**
   * @desc Get Map
   * @param {number} id Map id
   * @param {QueryOptions} queryOpts Query String Options
   */
  getMap = async (id, queryOpts = {}) => {
    queryOpts.isGetOperation = true;
    const data = await this.get(`/odata/maps(${id})`, queryOpts);
    return data;
  };
}

export default MapService;
