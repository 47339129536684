import jwtDecode from 'jwt-decode';

export function getDecodedToken() {
  const token = localStorage.getItem('token');
  if (!token) return {};
  const cleanToken = token.slice(7);
  const decoded = jwtDecode(cleanToken);
  return decoded;
}

export function readOnlyCheck() {
  return getDecodedToken().role === 'ReadOnly';
}

export function isAdmin() {
  return getDecodedToken().role === 'Admin';
}

export function isUser() {
  return getDecodedToken().role === 'User';
}
