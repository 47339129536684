// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// locals
import CrumbsHeader from '../../components/layout/crumbsHeader';
import PersonForm from '../../components/forms/personForm';
import { createPerson } from '../../redux/actions/personActions';
import { changeLocation } from '../../redux/actions/navigationActions';
import { convertCommaStringToArray } from '../../utils/stringUtils';

class PersonNewContainer extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
  }

  submit(values) {
    if (typeof values.isSensoringUser !== 'boolean') {
      values.isSensoringUser = false;
    }
    values.keywords = convertCommaStringToArray(values.keywords);
    this.props.createPerson(values);
  }

  reset() {
    this.props.changeLocation(`/people`);
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Person'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/people', label: 'People' },
            { route: '', label: 'New' },
          ]}
        />
        <section className="forms">
          <div className="container-fluid">
            <PersonForm
              onSubmit={this.submit}
              resetForm={this.reset}
              initialValues={{}}
              isLoading={this.props.isLoading}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  //toggleTodo: id => dispatch(toggleTodo(id))
  bindActionCreators(
    {
      createPerson,
      changeLocation,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    isLoading: state.things.isLoading,
  };
};

PersonNewContainer.propTypes = {
  isLoading: PropTypes.bool,
  changeLocation: PropTypes.func.isRequired,
  createPerson: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonNewContainer);
