// imports
import { reducer as form } from 'redux-form';
import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux';
import { persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import moment from 'moment';

// local reducers
import navigation from './navigationReducer';
import things from './thingReducer';
import places from './placeReducer';
import people from './personReducer';
import peripherals from './peripheralReducer';
import readings from './readingsReducer';
import user from './userReducer';
import reports from './reportReducer';
import search from './searchReducer';
import maps from './mapsReducer';
import trigger from './triggerReducer';
import progressBar from './progressBarReducer';
import contactTracing from './contactTracingReducer';

const appReducer = combineReducers({
  navigation,
  things,
  places,
  people,
  peripherals,
  readings,
  reports,
  form,
  router,
  user,
  search,
  maps,
  trigger,
  progressBar,
  contactTracing,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

// https://stackoverflow.com/questions/12756159/regex-and-iso8601-formatted-datetime
const ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

// tranform moments from iso to moment
const momentTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => {
    const saveState = {};
    for (let key in inboundState) {
      const value = inboundState[key];
      if (moment.isMoment(value)) {
        saveState[key] = value.toISOString();
      } else {
        saveState[key] = value;
      }
    }

    return saveState;
  },
  // transform state being rehydrated
  (outboundState) => {
    const hydratedState = {};
    for (let key in outboundState) {
      const value = outboundState[key];
      if (ISO_8601_FULL.test(value)) {
        hydratedState[key] = moment(value);
      } else {
        hydratedState[key] = value;
      }
    }

    return hydratedState;
  }
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['navigation', 'contactTracing'], // reducer keys to persist
  transforms: [momentTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;

export * from './contactTracingReducer';
