import React, { Component } from 'react';

import { useQueryParams } from '../../react/hooks';
import CrumbsHeader from '../../components/layout/crumbsHeader';
import beaconsDataContext from '../../services/dataContexts/beaconsDataContext';
import ThingsGrid from '../../components/grids/thingsGrid';
import StatusCard from '../../components/dashboard/statusCard';
import { withRouter } from 'react-router-dom';

class ThingsContainer extends Component {
  constructor(props) {
    super(props);

    const queryParams = useQueryParams();
    const sortOrder = queryParams.get('sortOrder') || 1;

    this.state = {
      query: {
        limit: '20',
        page: '1',
        sortOrder: Number(sortOrder),
        sortField: 'onlineStatus',
        filter: '',
        onlineStatus: 1,
      },
      totalItems: '0',
      totalPages: '0',
      items: [],
      summary: null,
      searchLast: '',
    };

    this.queryChange = this.queryChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  componentDidMount() {
    this.getBeacons();
    this.getSummary();
    let searchLast = localStorage.getItem('searchLastUrl');
    this.setState({ searchLast });
    if (!localStorage.getItem('searchLastUrl').includes('/things/')) {
      localStorage.setItem('searchString', '');
      localStorage.setItem('searchType', '');
      localStorage.setItem('searchTime', '');
    }
    localStorage.setItem('searchLastUrl', '');
  }

  queryChange(page, limit, sortField, sortOrder, filter) {
    this.setState(
      {
        query: {
          page: page,
          limit: limit,
          sortField: sortField,
          sortOrder: sortOrder,
          filter: filter,
        },
      },
      () => {
        setTimeout(() => {
          this.getBeacons();
        }, 100);
      }
    );
  }

  handleRowClick(id) {
    const { history } = this.props;
    history.push(`/things/${id}`);
  }

  getBeacons() {
    const { page, limit, sortField, sortOrder, filter } = this.state.query;
    beaconsDataContext.all(page, limit, sortField, sortOrder, filter).then((x) => {
      const { results, total, pageCount } = x.data;
      this.setState({
        items: results,
        pageCount: pageCount + '',
        totalItems: total + '',
      });
    });
  }

  getSummary() {
    beaconsDataContext.summary().then((x) => {
      this.setState({ summary: x.data });
    });
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Things'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/things', label: 'Things' },
          ]}
        />
        <section>
          <div id="summary" className="card mr-4 ml-4">
            <div className="card-body">
              {this.state.summary ? (
                <div className="row">
                  <div className="col-3">
                    <StatusCard
                      cardTitle={'Total'}
                      cardCount={this.state.summary.total}
                      cardType={'thing'}
                    />
                  </div>
                  <div className="col-3">
                    <StatusCard
                      cardTitle={'Online'}
                      cardCount={this.state.summary.online}
                      cardColor={'green'}
                      cardType={'online'}
                    />
                  </div>
                  <div className="col-3">
                    <StatusCard
                      cardTitle={'Offline'}
                      cardCount={this.state.summary.offline}
                      cardType={'offline'}
                      cardColor={'red'}
                    />
                  </div>
                  <div className="col-3">
                    <StatusCard
                      cardTitle={'Provisioned'}
                      cardCount={this.state.summary.provisioned}
                      cardType={'provisioned'}
                      cardColor={'#ffc36d'}
                    />
                  </div>
                </div>
              ) : (
                false
              )}
            </div>
          </div>

          <ThingsGrid
            allowFilter={true}
            items={this.state.items}
            totalItems={this.state.totalItems}
            pageCount={this.state.pageCount}
            sortField={this.state.query.sortField}
            sortOrder={this.state.query.sortOrder}
            limit={this.state.query.limit}
            page={this.state.query.page}
            filter={this.state.query.filter}
            onQueryChanged={this.queryChange}
            onRowSelect={this.handleRowClick}
            searchLast={this.state.searchLast}
          ></ThingsGrid>
        </section>
      </div>
    );
  }
}

export default withRouter(ThingsContainer);
