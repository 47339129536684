import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import moment from 'moment';

import './ContactTracingDetailScreen.css';
import { StartEndDatePicker, AgGrid, Text, Export, Loading } from '../../components';
import { contactTracingDetailColumnDefs } from './columnDefs';
import CustomTooltip from './CustomTooltip';
import * as loadingAnim from '../../animations/contactTracingLoading.json';
import LocationMap from '../../modules/location/components/locationMap';

function ContactTracingDetailScreen({
  loading,
  contactTracingData,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  title,
  onRefetch,
  exportData,
  map,
  locations,
  zones,
  colorMap,
  toggleZoneById,
}) {
  if (loading) return <Loading animationData={loadingAnim.default} />;

  const gridRef = useRef();
  const [gridApi, setGridApi] = useState();

  function renderHeader() {
    return (
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Form.Group controlId="contactTracingDate">
            <Form.Label>
              Date Range&nbsp;
              <i className="fa fa-calendar-alt fa-lg mr-3" />
            </Form.Label>
            <StartEndDatePicker
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={onChangeStartDate}
              onChangeEndDate={onChangeEndDate}
            />
          </Form.Group>

          <Button variant="outline-primary" size="sm" onClick={onRefetch}>
            Search
          </Button>
        </div>

        <Export
          data={exportData.data}
          headers={exportData.headers}
          filename={`ContactTracingDetail-${title}-${startDate.format('lll')}-${endDate.format(
            'lll'
          )}`}
        />
      </div>
    );
  }

  function renderLegend() {
    return (
      <div className="d-flex flex-row mt-1 align-items-center">
        <Text>Exposure Legend:&nbsp;</Text>
        {Object.entries(colorMap).map(([color, value]) => {
          return (
            <div
              key={color}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <div style={{ backgroundColor: color, width: '50px', height: '15px' }} />
              <Text style={{ fontSize: '0.7em' }} className="mr-1">{`${value}%`}</Text>
            </div>
          );
        })}
      </div>
    );
  }

  function getGridProps() {
    return {
      tooltipMouseTrack: true,
      frameworkComponents: {
        customTooltip: CustomTooltip,
      },
      getRowNodeId(data) {
        return data.contactBeaconId;
      },
    };
  }

  function renderZoneToggles() {
    return (
      <div className="d-flex flex-row flex-wrap">
        {zones.map((zone) => {
          return (
            <button key={zone.id} onClick={() => toggleZoneById(zone.id)}>
              <Badge pill variant={zone.isVisible ? 'primary' : 'light'}>
                <Text>{zone.name}</Text>
              </Badge>
            </button>
          );
        })}
      </div>
    );
  }

  function onGridReady(params) {
    gridRef.current.autoSizeColumns();
    setGridApi(params.api);
  }

  function onMouseOverLocation(e) {
    e.marker.openPopup();

    if (!gridApi) return;

    const node = gridApi.getRowNode(e.location.id);
    node.setSelected(true);
  }

  function onMouseOutLocation(e) {
    e.marker.closePopup();

    if (!gridApi) return;

    const node = gridApi.getRowNode(e.location.id);
    node.setSelected(false);
  }

  function getModifiedLocations() {
    const modLocations = [...locations].map((loc) => {
      loc.onmouseover = onMouseOverLocation;
      loc.onmouseout = onMouseOutLocation;
      return loc;
    });

    return modLocations;
  }

  return (
    <Container fluid className="contactDetail-container">
      <Row className="h-100">
        <Col xs={12} lg={5} className="h-100">
          <Text as="h2">{title}</Text>

          <div className="h-80">
            <AgGrid
              ref={gridRef}
              onGridReady={onGridReady}
              columnDefs={contactTracingDetailColumnDefs}
              rowData={contactTracingData}
              renderHeader={renderHeader}
              gridProps={getGridProps()}
            />
          </div>
        </Col>

        <Col xs={12} lg={7} className="h-100">
          <div className="contactDetail-heatmap mt-lg-0 mt-5">
            {renderLegend()}
            {renderZoneToggles()}

            {map && (
              <LocationMap
                map={map}
                locations={getModifiedLocations()}
                zones={zones}
                height="100%"
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

ContactTracingDetailScreen.defaultProps = {
  map: null,
};

ContactTracingDetailScreen.propTypes = {
  loading: PropTypes.bool.isRequired,
  contactTracingData: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onRefetch: PropTypes.func.isRequired,
  exportData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    headers: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  colorMap: PropTypes.object.isRequired,
  toggleZoneById: PropTypes.func.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,

  // defaults
  map: PropTypes.object,
};

export default ContactTracingDetailScreen;
