// modules
import React, { Component } from 'react';
import CrumbsHeader from '../../../components/layout/crumbsHeader';
import MapCard from '../components/mapCard';
import mapsDataContext from '../../../services/dataContexts/mapsDataContext';
import { Link } from 'react-router-dom';
import { readOnlyCheck } from '../../../utils';

class MapsGridContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maps: [],
      ready: false,
      currentZoom: 2,
    };
  }

  componentDidMount() {
    localStorage.setItem('searchLastUrl', '');
    mapsDataContext.all(1, 100, 'name', 'ascending', '').then((x) => {
      this.setState({
        ready: true,
        maps: x.data.results,
        cardStyle: {
          card: {
            width: '24rem',
          },
          img: {
            height: '24rem',
          },
        },
      });
    });
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'People'}
          crumbs={[
            {
              route: '/',
              label: 'Dashboard',
            },
            {
              route: '/Maps',
              label: 'Maps',
            },
          ]}
        />
        <div className="pl-5 pr-5">
          <div className="d-flex align-items-center justify-content-end">
            {!readOnlyCheck() && (
              <Link to="/maps/new" className="btn btn-primary">
                Create Map
              </Link>
            )}
          </div>

          <div className="d-flex flex-wrap">
            {this.props.map}
            {this.state.maps.map((m) => {
              return (
                <MapCard
                  key={m.id}
                  map={m}
                  classes="m-4 pl-0 pr-0"
                  cardStyle={this.state.cardStyle}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default MapsGridContainer;
