import React from 'react';
import PropTypes from 'prop-types';
import ZoneEditRow from './zoneEditRow';

class ZoneList extends React.Component {
  render() {
    return (
      <div>
        <div className="btn-group btn-group-sm">
          <button
            className="btn btn-sm ml-4 mt-2 mb-2"
            type="button"
            onClick={this.props.onAdd}
            disabled={this.props.editing}
          >
            Add Zone
          </button>
        </div>

        <ul className="list-group list-group-flush">
          <li className="d-flex list-group-item p-1 justify-content-between align-items-center pl-4 pr-4">
            <div className="w-50">Name</div>
            <div className="w-25"></div>
          </li>
          {this.props.zones.map((z, i) => {
            return (
              <li className="list-group-item list-group-item-action pl-4 pr-4 pb-1 pt-1" key={i}>
                {this.props.editing && z.index === this.props.editing.index ? (
                  <ZoneEditRow
                    zone={z}
                    excludedSearchItems={[]}
                    onSearchFilterChanged={this.props.onSearchFilterChanged}
                    searchItems={this.props.searchItems}
                    onEditCompleted={this.props.onEditCompleted}
                    onAddCanceled={this.props.onAddCanceled}
                  />
                ) : (
                  <div className="w-100 d-flex align-items-center justify-content-between p-0">
                    <a
                      href="# "
                      className="w-50"
                      onClick={() => this.props.onSelect('zones', 'index', z.index)}
                    >
                      {z.isVisible ? <strong>{z.name}</strong> : <span>{z.name}</span>}
                    </a>
                    <div className="w-25">
                      <div className="btn-group float-right">
                        <a
                          href="# "
                          className="btn flat-btn btn-default p-2"
                          onClick={() => this.props.onEdit(z)}
                          title="Edit Properties"
                        >
                          <i className="fa fa-edit" />
                        </a>

                        <a
                          href="# "
                          className="btn flat-btn btn-default p-2"
                          onClick={() => this.props.onEditMap(z)}
                          title="Change location on map"
                        >
                          <i className="fa fa-map-pin" />
                        </a>

                        <a
                          href="# "
                          onClick={() => this.props.onRemove('zones', z.index)}
                          className="btn flat-btn btn-default p-2"
                          title="Remove from map."
                        >
                          <i className="fas fa-trash text-danger" />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

ZoneList.propTypes = {
  editing: PropTypes.object,
  zones: PropTypes.array,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onEditMap: PropTypes.func,
  onSearchFilterChanged: PropTypes.func,
  searchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
};

export default ZoneList;
