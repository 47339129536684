import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import _ from 'lodash';

import './grid.css';

class TempAndHumidityGrid extends Component {
  render() {
    const columns = [
      {
        accessor: 'name',
        Header: 'Asset Name',
      },
      {
        accessor: 'deviceId',
        Header: 'Device Id',
      },
      {
        accessor: 'recordedAt',
        Header: 'Time Recorded',
        Cell: (row) => {
          if (row.value) {
            return <span className="pull-right">{row.value} </span>;
          } else {
            return <span className="pull-right">Not checked in</span>;
          }
        },
      },
      {
        accessor: 'averageCelsiusAndFahrenheit',
        Header: 'Temperature',
        headerStyle: { textAlign: 'right' },
        Cell: (row) => {
          if (row.value) {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                {row.value}
              </span>
            );
          } else {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                N/A
              </span>
            );
          }
        },
      },
      {
        accessor: 'averageRelativeHumidity',
        Header: 'Humidity',
        headerStyle: { textAlign: 'right', paddingRight: '15px' },
        Cell: (row) => {
          if (row.value) {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                {row.value} %
              </span>
            );
          } else {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                N/A
              </span>
            );
          }
        },
      },
      {
        accessor: 'positiveNegativeReading',
        Header: 'Air Pressure',
        headerStyle: { textAlign: 'right', paddingRight: '15px' },
        Cell: (row) => {
          if (row.value) {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                {row.value}
              </span>
            );
          } else {
            return (
              <span className="pull-right" style={{ float: 'right' }}>
                --
              </span>
            );
          }
        },
      },
    ];

    return (
      <div>
        <ReactTable
          data={
            this.props.data && _.isArray(this.props.data) && this.props.data.length > 0
              ? this.props.data
              : []
          }
          columns={columns}
          defaultPageSize={20}
          loading={this.props.isLoading} // Display the loading overlay when we need it
          showPageSizeOptions={false}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

TempAndHumidityGrid.propTypes = {
  data: PropTypes.array,
  onTableChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default TempAndHumidityGrid;
