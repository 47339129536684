// modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// locals
import CrumbsHeader from '../../components/layout/crumbsHeader';
import GatewayConfigurationForm from '../../components/forms/gatewayConfigurationForm';
import placeService from '../../services/placeService';
import { changeLocation } from '../../redux/actions/navigationActions';

class GatewayContainer extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
    this.defaultHash = this.defaultHash.bind(this);

    this.state = {
      gateway: {},
    };
  }

  submit(values) {
    let registrationId = this.props.match.params.registrationId;
    this.setState({ isLoading: true });
    placeService
      .putGateway(registrationId, values)
      .then((res) => {
        this.setState({ isLoading: false });
        this.props.changeLocation(`/admin/gateways`);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
  }

  defaultHash() {
    let registrationId = this.props.match.params.registrationId;
    placeService
      .resetGateway(registrationId)
      .then((_res) => {
        this.props.changeLocation(`/admin/gateways`);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
  }

  reset() {
    this.props.changeLocation(`/admin/gateways`);
  }

  componentDidMount() {
    let registrationId = this.props.match.params.registrationId;
    placeService
      .getGateway(registrationId)
      .then((res) => {
        this.setState({ gateway: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Gateway Configuration'}
          crumbs={[
            { route: '/admin/gateways', label: 'Gateways' },
            { route: '', label: 'Gateway Configuration' },
          ]}
        />
        <section className="forms">
          <div className="container-fluid">
            <GatewayConfigurationForm
              onSubmit={this.submit}
              resetHash={this.defaultHash}
              resetForm={this.reset}
              gateway={this.state.gateway}
              isLoading={this.props.isLoading}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  //toggleTodo: id => dispatch(toggleTodo(id))
  bindActionCreators(
    {
      changeLocation,
    },
    dispatch
  );

GatewayContainer.propTypes = {
  match: PropTypes.object,
  isLoading: PropTypes.bool,
  changeLocation: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(GatewayContainer);
