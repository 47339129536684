import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 1,
  },
});

class TriggerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      showCommentBox: false,
    };
  }

  activeTriggerIcon() {
    return (
      <span className="user-avatar border-0 size-50 bg-danger">
        <i className="fas fa-exclamation text-white" />
      </span>
    );
  }

  snoozedTriggerIcon() {
    return (
      <span className="user-avatar border-0 size-50 bg-blue-grey">
        <i className="fas fa-bed text-white" />
      </span>
    );
  }

  resolvedTriggerIcon() {
    return (
      <span className="user-avatar border-0 size-50 bg-light-green">
        <i className="fas fa-check text-white" />
      </span>
    );
  }

  allTriggerIcon() {
    return (
      <span className="user-avatar border-0 size-50 bg-blue-grey">
        <i className="fas fa-crosshairs text-white" />
      </span>
    );
  }

  triggerIcon(val) {
    if (val.toLowerCase() === 'met') {
      return this.activeTriggerIcon();
    } else if (val.toLowerCase() === 'resolved') {
      return this.resolvedTriggerIcon();
    } else if (val.toLowerCase() === 'snoozed') {
      return this.snoozedTriggerIcon();
    } else {
      return this.allTriggerIcon();
    }
  }

  snooze(event) {
    event.preventDefault();
    // snoozes for 2hr
    this.props.snooze(this.props.data.triggerId, localStorage.getItem('email'), 7200);
  }

  startResolve(event) {
    event.preventDefault();
    this.setState({ showCommentBox: !this.state.showCommentBox });
  }
  resolve(event) {
    event.preventDefault();
    let comment = this.state.comment;
    // if (!comment) {
    //   comment = { comment: '' };
    // } else {
    //   comment = { comment };
    // }

    this.props.resolve(this.props.data.triggerId, localStorage.getItem('email'), comment);
  }

  reset(event) {
    event.preventDefault();
    let comment = this.state.comment;
    if (!comment) {
      comment = { comment: '' };
    } else {
      comment = { comment };
    }
    this.props.reset(this.props.data.triggerId, localStorage.getItem('email'), comment);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={'card m-4'} style={{ width: '32rem' }}>
        <div className="card-body m-0 pb-0 pt-0">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              margin: '10px',
            }}
          >
            {/* TRIGGER STATUS ICON */}
            {this.triggerIcon(this.props.data.triggerStatus)}

            <div style={{ margin: '0px 10px' }}>
              <h5 className="">{this.props.data.triggerName}</h5>
              <Link to={`/things/${this.props.data.thingId}`}>
                <p style={{ fontWeight: '500' }} className="card-text">
                  {this.props.data.thingName}
                </p>
              </Link>
            </div>
            <div>
              {this.props.data.sent ? (
                <div>Last Sent: {moment(this.props.data.sent).format('LLL')}</div>
              ) : (
                ''
              )}
              {this.props.data.nextTrigger ? (
                <div>Next Trigger: {moment(this.props.data.nextTrigger).format('LLL')}</div>
              ) : (
                ''
              )}
              {this.props.data.actionTime ? (
                <div>
                  {this.props.data.triggerStatus === 'snoozed' ? 'Snoozed' : 'Resolved'} at{' '}
                  {this.props.data.nextTrigger}
                </div>
              ) : (
                ''
              )}
            </div>
            <div></div>
          </div>
          {/* TRIGGER EXPECTATIONS */}
          {/* TRIGGER TIME */}
          {/* LAST USER ACTION TRIGGER TIME */}
          {/* TRIGGERED VALUE */}
          <div>
            <h2 className={'text-center'}>Current Value: {this.props.data.currentValue}</h2>
          </div>

          {this.props.data.triggerStatus === 'set' ? (
            <div className="d-flex d-flex-row">
              <div className="w-100">
                <h6 className="text-center">
                  {this.props.data.expectedValue ? this.props.data.expectedValue : 'Unknown'}
                </h6>
                <h6 className="text-center font-weight-light">Expected Value</h6>
              </div>
            </div>
          ) : (
            <div className="d-flex d-flex-row">
              <div className="w-50">
                <h6 className="text-center">
                  {this.props.data.expectedValue ? this.props.data.expectedValue : 'Unknown'}
                </h6>
                <h6 className="text-center font-weight-light">Expected Value</h6>
              </div>
              <div className="w-50">
                {this.props.data.triggerStatus.toLowerCase() === 'set' ? (
                  ''
                ) : (
                  <div>
                    <h6 className="text-center">
                      {this.props.data.reportedValue ? this.props.data.reportedValue : 'Unknown'}
                    </h6>
                    <h6 className="text-center font-weight-light">Reported Value</h6>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="card-body">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            {this.props.data.triggerStatus.toLowerCase() === 'resolved' ? (
              <div className="d-flex d-flex-row">
                <div className="w-50">
                  <div>
                    Comment:{' '}
                    {this.props.data.recipients
                      ? this.props.data.recipients[0]
                        ? this.props.data.recipients[0].comment
                          ? this.props.data.recipients[0].comment
                            ? this.props.data.recipients[0].comment !== ''
                              ? this.props.data.recipients[0].comment
                              : 'No Comment'
                            : 'No Comment'
                          : 'No Comment'
                        : ''
                      : ''}
                  </div>
                  <div>
                    Recorded by:{' '}
                    {this.props.data.recipients
                      ? this.props.data.recipients[0]
                        ? this.props.data.recipients[0].recipient
                          ? `${this.props.data.recipients[0].recipient} at ${moment(
                              this.props.data.recipients[0].responseTime
                            ).format('LLLL')}`
                          : ''
                        : ''
                      : ''}
                  </div>
                </div>
                <div
                  className="w-50"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <button
                    onClick={(event) => this.reset(event)}
                    className="btn btn-primary"
                    style={{ margin: '0px 5px' }}
                  >
                    Reset Trigger
                  </button>
                </div>
              </div>
            ) : this.props.data.triggerStatus.toLowerCase() === 'snoozed' ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <button
                    onClick={(event) => this.startResolve(event)}
                    className="btn btn-primary"
                    style={{ margin: '0px 5px' }}
                  >
                    Resolve Trigger
                  </button>
                  <button
                    onClick={(event) => this.reset(event)}
                    className="btn btn-primary"
                    style={{ margin: '0px 5px' }}
                  >
                    Reset Trigger
                  </button>
                </div>
                {this.state.showCommentBox ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      id={`resolution-field`}
                      label="Resolution Notes"
                      className={classes.textField}
                      value={this.state.comment}
                      onChange={this.handleChange('comment')}
                      margin="normal"
                      helperText="Enter notes about this trigger"
                      multiline
                      rows="2"
                    />{' '}
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={(event) => this.resolve(event)}
                    >
                      Submit
                    </Button>
                  </div>
                ) : (
                  false
                )}
              </div>
            ) : this.props.data.triggerStatus.toLowerCase() === 'met' ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <button onClick={(event) => this.snooze(event)} className="btn btn-primary m-1">
                    Snooze Trigger
                  </button>
                  <button
                    onClick={(event) => this.startResolve(event)}
                    className="btn btn-primary m-1"
                  >
                    Resolve Trigger
                  </button>
                  <button onClick={(event) => this.reset(event)} className="btn btn-primary m-1">
                    Reset Trigger
                  </button>
                </div>
                {this.state.showCommentBox ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      id={`resolution-field`}
                      label="Resolution Notes"
                      className={classes.textField}
                      value={this.state.comment}
                      onChange={this.handleChange('comment')}
                      margin="normal"
                      helperText="Enter notes about this trigger"
                      multiline
                      rows="2"
                    />{' '}
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={(event) => this.resolve(event)}
                    >
                      Submit
                    </Button>
                  </div>
                ) : (
                  false
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

TriggerCard.propTypes = {
  data: PropTypes.object.isRequired,
  snooze: PropTypes.func,
  resolve: PropTypes.func,
  reset: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const mapStateToProps = (state) => {
  return {
    navigation: state.navigation,
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TriggerCard));
