// library imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import moment from 'moment';

// local imports
import LocationGrid from '../grids/locationReportGrid';
import CrumbsHeader from '../layout/crumbsHeader';
import { getLocationReport, getAssetTypes } from '../../redux/actions/reportActions';
// style imports
import './reports.css';

// <i className="fas fa-cloud-download-alt"></i>

class LocationReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assetType: '',
      sort: 'name',
      order: 'asc',
      page: 1,
      maintenanceDaysFrom: null,
      data: [],
    };

    this.handleAssetTypeChange = this.handleAssetTypeChange.bind(this);
    this.handleMaintenanceDaysChange = this.handleMaintenanceDaysChange.bind(this);
    this.handleUpdateReport = this.handleUpdateReport.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleTableColumnSort = this.handleTableColumnSort.bind(this);
  }

  getFileName() {
    return `Asset_Location_Report_${moment().format('YYYYMMDD-HHMM')}.csv`;
  }

  handleTableColumnSort(event) {
    if (event.length === 0) {
      return;
    }

    let sorted = _.orderBy(this.props.data, event[0].id, event[0].desc === true ? 'desc' : 'asc');
    this.setState({ data: sorted });
  }
  // when update button is pressed, handles the update
  handleUpdateReport(_event) {
    this.updateReportData(1, 10, 'name', 'asc');
  }

  handleAssetTypeChange(event) {
    this.setState({ assetType: event.target.value });
  }

  handleMaintenanceDaysChange(event) {
    this.setState({ maintenanceDaysFrom: event.target.value });
  }

  handleTableChange(table, _instance) {
    let sort;
    let order;

    if (table.sorted.length === 1) {
      sort = table.sorted[0].id;
      order = table.sorted[0].desc ? 'desc' : 'asc';
    }

    this.updateReportData(table.page + 1, 10, sort, order);
  }

  // updates the reports data by refreshing triggering an action
  updateReportData(page, pageSize, sort, order) {
    this.props.getLocationReport(
      page,
      pageSize,
      sort,
      order,
      this.state.assetType,
      this.state.maintenanceDaysFrom
    );
  }

  // when the component loads, refresh the data
  componentDidMount() {
    this.props.getAssetTypes();
    this.updateReportData(1, 10, 'name', 'asc');
  }

  // general render method
  render() {
    return (
      <div>
        <CrumbsHeader
          header={'Reports'}
          crumbs={[
            { route: '/', label: 'Dashboard' },
            { route: '/reports', label: 'Reports' },
            { route: '', label: 'Asset Location Report' },
          ]}
        />
        <section>
          <div className="container-fluid">
            <div className="card h-100">
              <div className="card-header d-flex ">
                <div className="col-8">
                  <h4 className="h4">
                    Customize Your Report &nbsp;&nbsp;
                    <a
                      className=""
                      data-toggle="collapse"
                      href="#collapseExample"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <i className="fa fa-filter"> </i>
                    </a>
                  </h4>
                </div>
                <div className="col">
                  <div className="pull-right" style={{ float: 'right' }}>
                    <CSVLink
                      filename={this.getFileName()}
                      data={
                        this.props.data && _.isArray(this.props.data) && this.props.data.length > 0
                          ? this.props.data
                          : []
                      }
                    >
                      <i className="fa fa-download"> Export </i>
                    </CSVLink>
                  </div>
                </div>
              </div>
              <div className="collapse" id="collapseExample">
                <div className="card-body">
                  <div className="row">
                    <label className="col-sm-3 form-control-label">How Many</label>
                    <div className="col-5">
                      <select
                        id="location"
                        onChange={this.handleAssetTypeChange}
                        name={'assetType'}
                      >
                        <option key="default" default value="">
                          --Select--
                        </option>
                        {this.props.assetTypes.map((x) => {
                          return (
                            <option key={x.id} value={x.id}>
                              {x.name}
                            </option>
                          );
                        })}
                      </select>
                      &nbsp;
                      <label className="form-control-label">do I have?</label>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-3 form-control-label">That need maintenance In</label>
                    <div className="col-5">
                      <select
                        onChange={this.handleMaintenanceDaysChange}
                        name={'maintenanceWindow'}
                      >
                        <option default value="">
                          --Select--
                        </option>
                        <option value="30">30 Days</option>
                        <option value="60">60 Days</option>
                        <option value="90">90 Days</option>
                        <option value="120">120 Days</option>
                      </select>
                      &nbsp;
                      <label className="form-control-label">?</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">&nbsp;</div>
                    <div className="col-5">
                      <button
                        type="button"
                        onClick={this.handleUpdateReport}
                        className="btn btn-primary"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card h-100">
              <LocationGrid
                data={this.props.data}
                totalPages={this.props.pages}
                isLoading={this.props.isLoading}
                onTableChange={this.handleTableChange}
                onColumnSort={this.handleTableColumnSort}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLocationReport,
      getAssetTypes,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    getLocationReport: PropTypes.func.isRequired,
    getAssetTypes: PropTypes.func.isRequired,
    data: state.reports.items,
    total: state.reports.total,
    pages: state.reports.pages,
    assetTypes: state.reports.assetTypes,
    isLoading: state.reports.isLoading,
  };
};

LocationReport.propTypes = {
  getLocationReport: PropTypes.func.isRequired,
  getAssetTypes: PropTypes.func.isRequired,
  data: PropTypes.array,
  assetTypes: PropTypes.array,
  total: PropTypes.number,
  pages: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationReport);
