import React from 'react';
import PropTypes from 'prop-types';

function Text({ as, children, ...restProps }) {
  const Comp = as;

  return <Comp {...restProps}>{children}</Comp>;
}

Text.defaultProps = {
  as: 'span',
};

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,

  // defaults
  as: PropTypes.string,
};

export default Text;
