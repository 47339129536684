import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import './charts.css';

class TemperatureChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                stepSize: 10,
              },
            },
          ],
        },
      },
      data: {
        labels: [],
        datasets: [{}],
      },
      showCal: false,
      date: null,
      time: '12',
    };
  }

  calendarClass() {
    if (this.state.showCal) {
      return 'showCalendar';
    } else {
      return 'hideCalendar';
    }
  }

  changeCal() {
    this.setState({ showCal: !this.state.showCal });
  }

  handleDateChange(name, startDate) {
    this.setState({ date: startDate });
    this.props.changeTime('externaltemperature', this.state.time, startDate);
  }

  callForChange(time) {
    this.props.changeTime('externaltemperature', time, this.state.date);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!Array.isArray(nextProps.readings) || nextProps.readings.length === 0) {
      return null;
    }

    let readings = nextProps.readings;
    let data = [];
    let labels = [];

    let filteredReadings = readings;

    let lowest = filteredReadings[0].mean * (9 / 5) + 32;
    let highest = filteredReadings[0].mean * (9 / 5) + 32;

    filteredReadings.forEach((reading) => {
      let tempurature = reading.mean * (9 / 5) + 32;

      data.push(tempurature);
      labels.push(moment.utc(reading.timestamp).local().format('MM/DD hh:mm:ss A'));

      if (tempurature < lowest) lowest = tempurature;
      if (tempurature > highest) highest = tempurature;
    });

    highest = Math.ceil(highest);
    lowest = Math.floor(lowest);

    data = data.map((val) => {
      return val.toFixed(2);
    });

    let range = highest - lowest;
    let minTick = Math.floor(lowest - range * 0.25);
    let maxTick = Math.ceil(highest + range * 0.25);
    let tickRange = maxTick - minTick;

    return {
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                min: minTick,
                max: maxTick,
                stepSize: tickRange / 4,
              },
            },
          ],
        },
      },
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            label: 'Temperature (°F)',
            fill: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
          },
        ],
      },
    };
  }

  render() {
    return (
      <div className="col-lg-6" style={{ marginTop: '20px' }}>
        <div className=" card h-100">
          <div className="card-close" />
          <div
            className="card-header"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h3 className="h4">Temperature</h3>
            <div className="btn-group float-right">
              <button
                type="button"
                className="btn btn-outline-dark btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ time: 12 }, this.callForChange(12));
                }}
              >
                12H
              </button>
              <button
                type="button"
                className="btn btn-outline-dark btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ time: 24 }, this.callForChange(24));
                }}
              >
                1D
              </button>
              <button
                type="button"
                className="btn btn-outline-dark btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ time: 72 }, this.callForChange(72));
                }}
              >
                3D
              </button>
              <button
                type="button"
                className="btn btn-outline-dark btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ time: 168 }, this.callForChange(168));
                }}
              >
                7D
              </button>
              <button
                type="button"
                className="btn btn-outline-dark btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  this.changeCal();
                }}
              >
                <i className="fa fa-calendar mr-2" />
              </button>
            </div>
          </div>
          <div
            className="row"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <div className={this.calendarClass()}>
              <i className="fa fa-calendar mr-2" />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <span>Start:</span>
                <DateTimePicker
                  value={this.state.date}
                  onChange={(e) => this.handleDateChange('startDate', e)}
                  className="form-control ml-2 mr-4"
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="card-body">
            <Line data={this.state.data} options={this.state.options} />
          </div>
        </div>
      </div>
    );
  }
}

TemperatureChart.propTypes = {
  readings: PropTypes.array,
  changeTime: PropTypes.func,
};

export default TemperatureChart;
