// public imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import moment from 'moment';

// local imports
import './grid.css';

class LocationGrid extends Component {
  render() {
    const columns = [
      {
        accessor: 'name',
        Header: 'Asset Name',
      },
      {
        accessor: 'placeName',
        Header: 'Last Location',
      },
      {
        accessor: 'assetManufacturer',
        Header: 'Asset Manufacturer',
      },
      {
        accessor: 'alternateIdentifier',
        Header: 'Alt. ID / Crest',
      },
      {
        accessor: 'lastPing',
        Header: 'Last Checkin',
        Cell: (row) => {
          if (row.value) {
            return <span>{moment(row.value).format('MM/DD/YYYY hh:mm:ss A')}</span>;
          } else {
            return <span>Not checked in</span>;
          }
        },
      },
      {
        accessor: 'lastMaintenanceDate',
        Header: 'Last Maintenance',
        Cell: (row) => {
          if (row.value) {
            return <span>{moment(row.value).format('MM/DD/YYYY')} </span>;
          } else {
            return <span>No Record</span>;
          }
        },
      },
    ];

    return (
      <div>
        <ReactTable
          data={this.props.data}
          columns={columns}
          // manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          defaultPageSize={20}
          loading={this.props.isLoading} // Display the loading overlay when we need it
          showPageSizeOptions={false}
          // onFetchData={this.props.onTableChange} // Request new data when things change
          className="-striped -highlight"
          onSortedChange={this.props.onColumnSort}
        />
      </div>
    );
  }
}

LocationGrid.propTypes = {
  data: PropTypes.array,
  onTableChange: PropTypes.func.isRequired,
  onColumnSort: PropTypes.func,
  totalPages: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};

export default LocationGrid;
