import * as types from '../types';
import peripheralService from '../../services/peripheralService';

/* 
    Request all peripherals initiation
*/
export const requestPeripherals = () => {
  return {
    type: types.PERIPHERALS_REQUEST,
  };
};

/* 
    All things action       
*/
export function getPeripherals(id) {
  return function (dispatch) {
    dispatch(requestPeripherals());

    peripheralService
      .getAll(id)
      .then((res) => {
        return dispatch({
          type: types.PERIPHERALS_RECEIVED,
          peripherals: res.data.results,
          total: res.data.total,
        });
      })
      .catch((res) =>
        dispatch({
          type: types.PERIPHERALS_REQUEST_FAILURE,
          data: res,
        })
      );
  };
}
