import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CrumbsHeader from '../../../components/layout/crumbsHeader';
import LocationTable from '../components/locationTable';
import LocationMap from '../components/locationMap';
import mapFunctions from '../services/mapFunctions';
import mapsDataContext from '../../../services/dataContexts/mapsDataContext';
import locationsDataContext from '../../../services/dataContexts/locationsDataContext';
import lastLocationFunctions from '../services/lastLocationFunctions';
import GeoLocationMap from './../components/geoLocationMap';
import axios from 'axios';

var call;

class LocationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      query: {
        limit: '50',
        page: '1',
        sortOrder: 1,
        sortField: 'name',
        filter: '',
        onlineStatus: 1,
      },
      totalItems: '0',
      totalPages: '0',
      locations: [],
      selectedLocations: [],
      visibility: {
        gateways: false,
        zones: false,
        beacons: false,
      },
      readings: ['temperature', 'humidity', 'battery'],
      selectedReading: 'temperature',
    };

    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.queryChange = this.queryChange.bind(this);
    this.changeSelection = this.changeSelection.bind(this);
    this.toggleReading = this.toggleReading.bind(this);
  }

  componentDidMount() {
    this.getMap();
    this.getLocations();
  }

  getMap() {
    const { mapId } = this.props.match.params;

    mapsDataContext.get(mapId).then((x) => {
      const map = mapFunctions.assignPropsToMap(x.data, {
        zoneVisibility: this.state.visibility.zones,
        gatewayVisibility: this.state.visibility.gateways,
        beaconVisibility: this.state.visibility.beacons,
      });
      this.setState({
        map: map,
      });
    });
  }

  getLocations() {
    const { mapId } = this.props.match.params;
    const { page, limit, sortField, sortOrder, filter } = this.state.query;

    if (call) {
      call.cancel('operation canceled');
    }

    call = axios.CancelToken.source();
    locationsDataContext
      .getLastLocations(mapId, page, limit, sortField, sortOrder, filter, call.token)
      .then((x) => {
        const { results, total, pageCount } = x.data.locations;
        const locations = lastLocationFunctions.assignPropsToLastLocations(results);

        this.setState({
          locations: locations,
          pageCount: pageCount + '',
          totalItems: total + '',
        });
      })
      .catch((e) => {
        call = null;
      });
  }

  toggleVisibility(name) {
    let { map, visibility } = this.state;

    map[name].map((i) => {
      i.isVisible = !visibility[name];
      return i;
    });

    visibility[name] = !visibility[name];
    this.setState({ map: map, visibility: visibility });
  }

  toggleReading(reading) {
    if (this.state.selectedReading !== reading) {
      this.setState({ selectedReading: reading });
    }
  }

  queryChange(page, limit, sortField, sortOrder, filter) {
    this.setState(
      {
        query: {
          page: page,
          limit: limit,
          sortField: sortField,
          sortOrder: sortOrder,
          filter: filter,
        },
      },
      () => {
        this.getLocations();
      }
    );
  }

  changeSelection(index) {
    let { locations } = this.state;
    locations = locations.map((l) => {
      if (l.index === index) {
        if (!l.isHighlighted) {
          l.isHighlighted = true;
          l.isVisible = true;
        } else {
          l.isHighlighted = false;
        }
      } else {
        l.isHighlighted = false;
      }

      return l;
    });

    this.setState({ locations: locations });
  }

  render() {
    // TODO: remove this if condition. just using to test Maps on PlaceScreen
    if (this.state.map) {
      localStorage.setItem(
        'mapTest',
        JSON.stringify({
          map: this.state.map,
          locations: this.state.locations,
          gateways: this.state.map.gateways,
          zones: this.state.map.zones,
          beacons: this.state.map.beacons,
          reading: this.state.selectedReading,
        })
      );
    }

    return (
      <div>
        <CrumbsHeader
          header={'Thing Location'}
          crumbs={[
            {
              route: '/',
              label: 'Dashboard',
            },
            {
              route: '/maps',
              label: 'Maps',
            },
          ]}
        />
        <div className="pl-5 pr-5">
          <div className="d-flex app-toolbar align-items-center justify-content-between mb-3 mt-3">
            <h4 className=" mb-0">
              <i className="fa fa-map" /> {this.state.map ? this.state.map.name : ''}
            </h4>
            <span className="ml-4 mb-0">{this.state.map ? this.state.map.description : ''}</span>
          </div>

          <div className="card">
            <LocationTable
              allowFilter={true}
              locations={this.state.locations}
              totalItems={this.state.totalItems}
              pageCount={this.state.pageCount}
              sortField={this.state.query.sortField}
              sortOrder={this.state.query.sortOrder}
              limit={this.state.query.limit}
              page={this.state.query.page}
              filter={this.state.query.filter}
              onQueryChanged={this.queryChange}
              onRowSelect={this.changeSelection}
            />
          </div>
          <div className="d-flex app-toolbar align-items-center justify-content-between mb-3 mt-3">
            <div></div>
            <div className="btn-group">
              <button
                type="button"
                className={`btn ${this.state.visibility.gateways ? 'btn-primary' : 'btn-default'}`}
                title="Toggle Gateways"
                onClick={() => this.toggleVisibility('gateways')}
              >
                <i className="fa fa-router" />
              </button>
              <button
                type="button"
                className={`btn ${this.state.visibility.zones ? 'btn-primary' : 'btn-default'}`}
                onClick={() => this.toggleVisibility('zones')}
                title="Toggle Zones"
              >
                <i className="fa fa-map" />
              </button>
              <button
                type="button"
                className={`btn ${this.state.visibility.beacons ? 'btn-primary' : 'btn-default'}`}
                onClick={() => this.toggleVisibility('beacons')}
                title="Toggle Beacons"
              >
                <i className="fa fa-tags" />
              </button>

              <button
                type="button"
                disabled={!this.state.visibility.beacons}
                className={`btn ${
                  this.state.visibility.beacons && this.state.selectedReading === 'temperature'
                    ? 'btn-primary'
                    : 'btn-default'
                }`}
                onClick={() => this.toggleReading('temperature')}
                title="Temperature"
              >
                <i className="fa fa-thermometer-three-quarters" />
              </button>
              <button
                disabled={!this.state.visibility.beacons}
                type="button"
                className={`btn ${
                  this.state.visibility.beacons && this.state.selectedReading === 'humidity'
                    ? 'btn-primary'
                    : 'btn-default'
                }`}
                onClick={() => this.toggleReading('humidity')}
                title="Humidity"
              >
                <i className="fa fa-humidity" />
              </button>
              <button
                disabled={!this.state.visibility.beacons}
                type="button"
                className={`btn ${
                  this.state.visibility.beacons && this.state.selectedReading === 'battery'
                    ? 'btn-primary'
                    : 'btn-default'
                }`}
                onClick={() => this.toggleReading('battery')}
                title="Battery"
              >
                <i className="fa fa-battery-empty" />
              </button>
            </div>
          </div>
          {this.state.map ? (
            this.state.map.id === '5e5eed642bbf0900015fb040' ? (
              <div className="card">
                <GeoLocationMap
                  map={this.state.map}
                  locations={this.state.locations}
                  gateways={this.state.map.gateways}
                  zones={this.state.map.zones}
                  beacons={this.state.map.beacons}
                  height="600px"
                  playback={false}
                  displayType={'points'}
                  reading={this.state.selectedReading}
                />
              </div>
            ) : (
              <div className="card">
                <LocationMap
                  map={this.state.map}
                  locations={this.state.locations}
                  gateways={this.state.map.gateways}
                  zones={this.state.map.zones}
                  beacons={this.state.map.beacons}
                  height="600px"
                  playback={false}
                  displayType={'points'}
                  reading={this.state.selectedReading}
                  static={true}
                />
              </div>
            )
          ) : (
            false
          )}
        </div>
      </div>
    );
  }
}

LocationsContainer.propTypes = {
  match: PropTypes.object,
};

export default LocationsContainer;
